/**********************************************
  Custom Variables
***********************************************/
/***********************
  Input Placeholder Color
***********************/
/***********************
  Media Queries
***********************/
/**********************************************
  END OF Custom Variables
***********************************************/
/**********************************************
  ANT DESIGN THEME
***********************************************/
.profileForm {
  width: 50%; }

.profileForm label {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

@media screen and (max-width: 1023px) {
  .profileForm {
    width: 100%; } }
