/**********************************************
  Custom Variables
***********************************************/
/***********************
  Input Placeholder Color
***********************/
/***********************
  Media Queries
***********************/
/**********************************************
  END OF Custom Variables
***********************************************/
/**********************************************
  ANT DESIGN THEME
***********************************************/
.updateSiteForm {
  width: 600px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 100px auto; }

.updateSiteForm label {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.updateSiteSection {
  width: 400px; }

@media screen and (max-width: 1023px) {
  .updateSiteSection {
    width: 100%; } }

.sectionTitle {
  border-bottom: 1px solid #005991;
  display: flex;
  flex-direction: row;
  align-items: flex-end; }

.siteButtons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px; }
