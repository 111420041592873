/**
 *
 * Theme: Moby
 * Author: Mobythemes
 * Website: mobythemes.com
 * Version: 1.0
 * Date: Mon Dec 09 2019
 * Title: HelpCenter Theme styles
 *
 */
/**********************************************
  Custom Variables
***********************************************/
/***********************
  Input Placeholder Color
***********************/
/***********************
  Media Queries
***********************/
/**********************************************
  END OF Custom Variables
***********************************************/
/**********************************************
  ANT DESIGN THEME
***********************************************/
.help-center .help-center-sub-header h1 {
  font-size: 20px;
  color: black; }
  @media screen and (max-width: 1440px) {
    .help-center .help-center-sub-header h1 {
      font-size: 17px; } }

.help-center .help-center-sub-header strong {
  display: block;
  font-size: 14px;
  font-weight: 700;
  color: #96a5ab; }
  @media screen and (max-width: 767px) {
    .help-center .help-center-sub-header strong {
      font-size: 13px; } }

.help-center h2 {
  font-size: 24px;
  color: black;
  margin-bottom: 30px; }
  @media screen and (max-width: 1440px) {
    .help-center h2 {
      font-size: 20px; } }

.help-center .title {
  font-size: 16px;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media screen and (max-width: 1440px) {
    .help-center .title {
      font-size: 15px; } }

.help-center .card-list {
  list-style: disc;
  margin-left: 15px !important; }
  .help-center .card-list li {
    color: #96a5ab;
    padding-bottom: 8px; }
    .help-center .card-list li:last-child {
      padding-bottom: 0; }
    @media screen and (max-width: 1440px) {
      .help-center .card-list li {
        font-size: 13px; } }

/**
 *
 * Theme: Moby
 * Author: Mobythemes
 * Website: mobythemes.com
 * Version: 1.0
 * Date: Mon Dec 09 2019
 * Title: ElementsComponents Theme styles
 *
 */
/**********************************************
  Custom Variables
***********************************************/
/***********************
  Input Placeholder Color
***********************/
/***********************
  Media Queries
***********************/
/**********************************************
  END OF Custom Variables
***********************************************/
/**********************************************
  ANT DESIGN THEME
***********************************************/
.f-16 {
  font-size: 16px; }

/* Forms Component */
.input-base {
  width: calc(50% - 12px);
  display: inline-block; }

.input-base-divider {
  width: 24px;
  display: inline-block; }

/* Ends Forms Component */
/* Page Header Component */
.flex-1 {
  flex: 1 1 0; }

.mr-l-80 {
  margin-left: 80px; }

/* Ends Page Header Component */
/* Popconfirms And Popovers Component */
.holder {
  white-space: nowrap;
  clear: both; }

.holder-r {
  margin-left: 270px; }

.w-70-px {
  width: 70px; }

.mr-l-70 {
  margin-left: 70px; }

/* Ends Popconfirms And Popovers Component */
/* Spins Component */
.f-size {
  font-size: 24px; }

.spin-h {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 50px 30px; }

/* Ends Spins Component */
/**********************************************
  Custom Variables
***********************************************/
/***********************
  Input Placeholder Color
***********************/
/***********************
  Media Queries
***********************/
/**********************************************
  END OF Custom Variables
***********************************************/
/**********************************************
  ANT DESIGN THEME
***********************************************/
h1 {
  font-size: 38px; }
  @media screen and (max-width: 1023px) {
    h1 {
      font-size: 34px; } }
  @media screen and (max-width: 480px) {
    h1 {
      font-size: 28px; } }

h2 {
  font-size: 30px; }
  @media screen and (max-width: 1023px) {
    h2 {
      font-size: 26px; } }
  @media screen and (max-width: 480px) {
    h2 {
      font-size: 20px; } }

h3 {
  font-size: 24px; }
  @media screen and (max-width: 1023px) {
    h3 {
      font-size: 20px; } }
  @media screen and (max-width: 480px) {
    h3 {
      font-size: 14px; } }

h4 {
  font-size: 20px; }
  @media screen and (max-width: 1023px) {
    h4 {
      font-size: 16px; } }
  @media screen and (max-width: 480px) {
    h4 {
      font-size: 10px; } }

h5 {
  font-size: 16px; }
  @media screen and (max-width: 1023px) {
    h5 {
      font-size: 12px; } }
  @media screen and (max-width: 480px) {
    h5 {
      font-size: 6px; } }

.ant-layout .main-content-inner .ant-form .unmuted-disabled:disabled {
  background-color: #DBA44F;
  border-color: #b87e26;
  color: white; }

.ant-layout .main-content-inner .ant-form .unmuted-disabled:disabled:hover {
  background-color: #e2b672;
  border-color: #DBA44F;
  color: white; }

/**********************************************
  Custom Variables
***********************************************/
/***********************
  Input Placeholder Color
***********************/
/***********************
  Media Queries
***********************/
/**********************************************
  END OF Custom Variables
***********************************************/
/**********************************************
  ANT DESIGN THEME
***********************************************/
/**********************************************
  Custom Variables
***********************************************/
/***********************
  Input Placeholder Color
***********************/
/***********************
  Media Queries
***********************/
/**********************************************
  END OF Custom Variables
***********************************************/
/**********************************************
  ANT DESIGN THEME
***********************************************/
/** Placeholder Color Mixin **/
/*******************
  Cards
*******************/
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-weight: 500; }

html,
#root {
  height: 100%; }

code {
  color: #ea4335; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important; }

h1.extraPadding {
  margin-bottom: 50px !important;
  font-size: 32px;
  font-weight: 600; }

a {
  color: #DBA44F; }

img {
  max-width: 100%; }

ul {
  list-style: none;
  margin: 0 !important;
  padding: 0; }

strong {
  font-weight: 700 !important; }

.cursor-pointer {
  cursor: pointer; }

.overflow-h {
  overflow: hidden; }

/** Loader **/
.loading-container {
  height: 100vh;
  background: #f5f7f7;
  display: flex;
  align-items: center;
  justify-content: center; }

/** Loader **/
/** Perfect Scrollbar **/
.ps__thumb-x,
.ps__thumb-y {
  background-color: transparent !important; }

/** Perfect Scrollbar **/
.box-shadow {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05), 0 1px 20px 0 rgba(0, 0, 0, 0.05), 0px 1px 11px 0px rgba(0, 0, 0, 0.05); }

.transition {
  transition: all ease-in-out 0.4s; }

.overlay {
  position: relative; }
  .overlay:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); }

.p-relative {
  position: relative; }

.p-absolute {
  position: absolute; }

.heartbeat {
  animation: heartbeat 1s infinite alternate; }

@keyframes heartbeat {
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); } }

@-webkit-keyframes heartbeat {
  to {
    -webkit-transform: scale(1.1); } }

.rotate {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 30s;
  animation-duration: 30s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear; }

@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

/***********************
    Moby Theme CSS
***********************/
.heading-w-divider.title {
  font-size: 21px;
  border-bottom: 1px solid #e3e8ea;
  padding-bottom: 15px;
  margin-bottom: 40px; }

.heading-w-divider.heading-w-divider-alternative.title {
  margin-bottom: 20px; }

@media screen and (max-width: 767px) {
  .heading-w-divider.title {
    font-size: 18px; } }

.heading-title {
  font-size: 17px;
  margin-bottom: 30px; }

/** Theme Layout **/
.moby__layout,
.ant-modal-content,
.ant-popover-inner,
.ant-drawer,
.ant-dropdown,
.ant-notification {
  /** Icons **/
  /** /.Icons **/
  /** Font Weight **/
  /** Font Weight **/
  /** Theme Padding **/
  /** /.Theme Padding **/
  /** Theme Margin **/
  /** /.Theme Margin **/
  /** Theme Filled **/
  /** /.Theme Filled **/
  /** Theme Helper Classes **/
  /** Theme Helper Classes **/
  /** Theme Text Color **/
  /** Theme Text Color **/
  /** Theme Background Color **/
  /** /.Theme Background Color **/
  /** Theme Button **/
  /** /.Theme Button **/
  /** Theme Radio Button **/
  /** /.Theme Radio Button **/
  /** Theme Checkbox **/
  /** Theme Checkbox **/
  /** Theme Group Button **/
  /** /.Theme Group Button **/ }
  .moby__layout .anticon,
  .ant-modal-content .anticon,
  .ant-popover-inner .anticon,
  .ant-drawer .anticon,
  .ant-dropdown .anticon,
  .ant-notification .anticon {
    font-size: 16px;
    transition: all ease-in-out 0.4s; }
    .moby__layout .anticon.normal-color,
    .ant-modal-content .anticon.normal-color,
    .ant-popover-inner .anticon.normal-color,
    .ant-drawer .anticon.normal-color,
    .ant-dropdown .anticon.normal-color,
    .ant-notification .anticon.normal-color {
      color: #96a5ab; }
    .moby__layout .anticon.black,
    .ant-modal-content .anticon.black,
    .ant-popover-inner .anticon.black,
    .ant-drawer .anticon.black,
    .ant-dropdown .anticon.black,
    .ant-notification .anticon.black {
      color: #202124; }
    .moby__layout .anticon.danger,
    .ant-modal-content .anticon.danger,
    .ant-popover-inner .anticon.danger,
    .ant-drawer .anticon.danger,
    .ant-dropdown .anticon.danger,
    .ant-notification .anticon.danger {
      color: #ea4335; }
    .moby__layout .anticon.primary,
    .ant-modal-content .anticon.primary,
    .ant-popover-inner .anticon.primary,
    .ant-drawer .anticon.primary,
    .ant-dropdown .anticon.primary,
    .ant-notification .anticon.primary {
      color: #DBA44F; }
    .moby__layout .anticon.success,
    .ant-modal-content .anticon.success,
    .ant-popover-inner .anticon.success,
    .ant-drawer .anticon.success,
    .ant-dropdown .anticon.success,
    .ant-notification .anticon.success {
      color: #34a853; }
    .moby__layout .anticon.warning,
    .ant-modal-content .anticon.warning,
    .ant-popover-inner .anticon.warning,
    .ant-drawer .anticon.warning,
    .ant-dropdown .anticon.warning,
    .ant-notification .anticon.warning {
      color: #fbbc05; }
  .moby__layout .icon,
  .ant-modal-content .icon,
  .ant-popover-inner .icon,
  .ant-drawer .icon,
  .ant-dropdown .icon,
  .ant-notification .icon {
    color: #96a5ab;
    font-size: 16px;
    transition: all ease-in-out 0.4s; }
    .moby__layout .icon svg,
    .ant-modal-content .icon svg,
    .ant-popover-inner .icon svg,
    .ant-drawer .icon svg,
    .ant-dropdown .icon svg,
    .ant-notification .icon svg {
      fill: #96a5ab; }
    .moby__layout .icon.black,
    .ant-modal-content .icon.black,
    .ant-popover-inner .icon.black,
    .ant-drawer .icon.black,
    .ant-dropdown .icon.black,
    .ant-notification .icon.black {
      color: #202124; }
      .moby__layout .icon.black svg,
      .ant-modal-content .icon.black svg,
      .ant-popover-inner .icon.black svg,
      .ant-drawer .icon.black svg,
      .ant-dropdown .icon.black svg,
      .ant-notification .icon.black svg {
        fill: #202124; }
    .moby__layout .icon.danger,
    .ant-modal-content .icon.danger,
    .ant-popover-inner .icon.danger,
    .ant-drawer .icon.danger,
    .ant-dropdown .icon.danger,
    .ant-notification .icon.danger {
      color: #ea4335; }
      .moby__layout .icon.danger svg,
      .ant-modal-content .icon.danger svg,
      .ant-popover-inner .icon.danger svg,
      .ant-drawer .icon.danger svg,
      .ant-dropdown .icon.danger svg,
      .ant-notification .icon.danger svg {
        fill: #ea4335; }
    .moby__layout .icon.primary,
    .ant-modal-content .icon.primary,
    .ant-popover-inner .icon.primary,
    .ant-drawer .icon.primary,
    .ant-dropdown .icon.primary,
    .ant-notification .icon.primary {
      color: #DBA44F; }
      .moby__layout .icon.primary svg,
      .ant-modal-content .icon.primary svg,
      .ant-popover-inner .icon.primary svg,
      .ant-drawer .icon.primary svg,
      .ant-dropdown .icon.primary svg,
      .ant-notification .icon.primary svg {
        fill: #DBA44F; }
      .moby__layout .icon.primary .path2::before,
      .ant-modal-content .icon.primary .path2::before,
      .ant-popover-inner .icon.primary .path2::before,
      .ant-drawer .icon.primary .path2::before,
      .ant-dropdown .icon.primary .path2::before,
      .ant-notification .icon.primary .path2::before {
        color: #DBA44F; }
      .moby__layout .icon.primary .path1::before,
      .ant-modal-content .icon.primary .path1::before,
      .ant-popover-inner .icon.primary .path1::before,
      .ant-drawer .icon.primary .path1::before,
      .ant-dropdown .icon.primary .path1::before,
      .ant-notification .icon.primary .path1::before {
        color: #DBA44F; }
    .moby__layout .icon.success,
    .ant-modal-content .icon.success,
    .ant-popover-inner .icon.success,
    .ant-drawer .icon.success,
    .ant-dropdown .icon.success,
    .ant-notification .icon.success {
      color: #34a853; }
      .moby__layout .icon.success svg,
      .ant-modal-content .icon.success svg,
      .ant-popover-inner .icon.success svg,
      .ant-drawer .icon.success svg,
      .ant-dropdown .icon.success svg,
      .ant-notification .icon.success svg {
        fill: #34a853; }
    .moby__layout .icon.warning,
    .ant-modal-content .icon.warning,
    .ant-popover-inner .icon.warning,
    .ant-drawer .icon.warning,
    .ant-dropdown .icon.warning,
    .ant-notification .icon.warning {
      color: #fbbc05; }
      .moby__layout .icon.warning svg,
      .ant-modal-content .icon.warning svg,
      .ant-popover-inner .icon.warning svg,
      .ant-drawer .icon.warning svg,
      .ant-dropdown .icon.warning svg,
      .ant-notification .icon.warning svg {
        fill: #fbbc05; }
  .moby__layout .f-w-600,
  .ant-modal-content .f-w-600,
  .ant-popover-inner .f-w-600,
  .ant-drawer .f-w-600,
  .ant-dropdown .f-w-600,
  .ant-notification .f-w-600 {
    font-weight: 700; }
  .moby__layout .pd-5,
  .ant-modal-content .pd-5,
  .ant-popover-inner .pd-5,
  .ant-drawer .pd-5,
  .ant-dropdown .pd-5,
  .ant-notification .pd-5 {
    padding: 5px; }
  .moby__layout .pd-t-5,
  .ant-modal-content .pd-t-5,
  .ant-popover-inner .pd-t-5,
  .ant-drawer .pd-t-5,
  .ant-dropdown .pd-t-5,
  .ant-notification .pd-t-5 {
    padding-top: 5px; }
  .moby__layout .pd-b-5,
  .ant-modal-content .pd-b-5,
  .ant-popover-inner .pd-b-5,
  .ant-drawer .pd-b-5,
  .ant-dropdown .pd-b-5,
  .ant-notification .pd-b-5 {
    padding-bottom: 5px; }
  .moby__layout .pd-l-5,
  .ant-modal-content .pd-l-5,
  .ant-popover-inner .pd-l-5,
  .ant-drawer .pd-l-5,
  .ant-dropdown .pd-l-5,
  .ant-notification .pd-l-5 {
    padding-left: 5px; }
  .moby__layout .pd-r-5,
  .ant-modal-content .pd-r-5,
  .ant-popover-inner .pd-r-5,
  .ant-drawer .pd-r-5,
  .ant-dropdown .pd-r-5,
  .ant-notification .pd-r-5 {
    padding-right: 5px; }
  .moby__layout .pd-t-b-5,
  .ant-modal-content .pd-t-b-5,
  .ant-popover-inner .pd-t-b-5,
  .ant-drawer .pd-t-b-5,
  .ant-dropdown .pd-t-b-5,
  .ant-notification .pd-t-b-5 {
    padding: 5px 0; }
  .moby__layout .pd-l-r-5,
  .ant-modal-content .pd-l-r-5,
  .ant-popover-inner .pd-l-r-5,
  .ant-drawer .pd-l-r-5,
  .ant-dropdown .pd-l-r-5,
  .ant-notification .pd-l-r-5 {
    padding: 0 5px; }
  .moby__layout .pd-10,
  .ant-modal-content .pd-10,
  .ant-popover-inner .pd-10,
  .ant-drawer .pd-10,
  .ant-dropdown .pd-10,
  .ant-notification .pd-10 {
    padding: 10px; }
  .moby__layout .pd-t-10,
  .ant-modal-content .pd-t-10,
  .ant-popover-inner .pd-t-10,
  .ant-drawer .pd-t-10,
  .ant-dropdown .pd-t-10,
  .ant-notification .pd-t-10 {
    padding-top: 10px; }
  .moby__layout .pd-b-10,
  .ant-modal-content .pd-b-10,
  .ant-popover-inner .pd-b-10,
  .ant-drawer .pd-b-10,
  .ant-dropdown .pd-b-10,
  .ant-notification .pd-b-10 {
    padding-bottom: 10px; }
  .moby__layout .pd-l-10,
  .ant-modal-content .pd-l-10,
  .ant-popover-inner .pd-l-10,
  .ant-drawer .pd-l-10,
  .ant-dropdown .pd-l-10,
  .ant-notification .pd-l-10 {
    padding-left: 10px; }
  .moby__layout .pd-r-10,
  .ant-modal-content .pd-r-10,
  .ant-popover-inner .pd-r-10,
  .ant-drawer .pd-r-10,
  .ant-dropdown .pd-r-10,
  .ant-notification .pd-r-10 {
    padding-right: 10px; }
  .moby__layout .pd-t-b-10,
  .ant-modal-content .pd-t-b-10,
  .ant-popover-inner .pd-t-b-10,
  .ant-drawer .pd-t-b-10,
  .ant-dropdown .pd-t-b-10,
  .ant-notification .pd-t-b-10 {
    padding: 10px 0; }
  .moby__layout .pd-l-r-10,
  .ant-modal-content .pd-l-r-10,
  .ant-popover-inner .pd-l-r-10,
  .ant-drawer .pd-l-r-10,
  .ant-dropdown .pd-l-r-10,
  .ant-notification .pd-l-r-10 {
    padding: 0 10px; }
  .moby__layout .pd-15,
  .ant-modal-content .pd-15,
  .ant-popover-inner .pd-15,
  .ant-drawer .pd-15,
  .ant-dropdown .pd-15,
  .ant-notification .pd-15 {
    padding: 15px; }
  .moby__layout .pd-t-15,
  .ant-modal-content .pd-t-15,
  .ant-popover-inner .pd-t-15,
  .ant-drawer .pd-t-15,
  .ant-dropdown .pd-t-15,
  .ant-notification .pd-t-15 {
    padding-top: 15px; }
  .moby__layout .pd-b-15,
  .ant-modal-content .pd-b-15,
  .ant-popover-inner .pd-b-15,
  .ant-drawer .pd-b-15,
  .ant-dropdown .pd-b-15,
  .ant-notification .pd-b-15 {
    padding-bottom: 15px; }
  .moby__layout .pd-l-15,
  .ant-modal-content .pd-l-15,
  .ant-popover-inner .pd-l-15,
  .ant-drawer .pd-l-15,
  .ant-dropdown .pd-l-15,
  .ant-notification .pd-l-15 {
    padding-left: 15px; }
  .moby__layout .pd-r-15,
  .ant-modal-content .pd-r-15,
  .ant-popover-inner .pd-r-15,
  .ant-drawer .pd-r-15,
  .ant-dropdown .pd-r-15,
  .ant-notification .pd-r-15 {
    padding-right: 15px; }
  .moby__layout .pd-t-b-15,
  .ant-modal-content .pd-t-b-15,
  .ant-popover-inner .pd-t-b-15,
  .ant-drawer .pd-t-b-15,
  .ant-dropdown .pd-t-b-15,
  .ant-notification .pd-t-b-15 {
    padding: 15px 0; }
  .moby__layout .pd-l-r-15,
  .ant-modal-content .pd-l-r-15,
  .ant-popover-inner .pd-l-r-15,
  .ant-drawer .pd-l-r-15,
  .ant-dropdown .pd-l-r-15,
  .ant-notification .pd-l-r-15 {
    padding: 0 15px; }
  .moby__layout .pd-20,
  .ant-modal-content .pd-20,
  .ant-popover-inner .pd-20,
  .ant-drawer .pd-20,
  .ant-dropdown .pd-20,
  .ant-notification .pd-20 {
    padding: 20px; }
  .moby__layout .pd-t-20,
  .ant-modal-content .pd-t-20,
  .ant-popover-inner .pd-t-20,
  .ant-drawer .pd-t-20,
  .ant-dropdown .pd-t-20,
  .ant-notification .pd-t-20 {
    padding-top: 20px; }
  .moby__layout .pd-b-20,
  .ant-modal-content .pd-b-20,
  .ant-popover-inner .pd-b-20,
  .ant-drawer .pd-b-20,
  .ant-dropdown .pd-b-20,
  .ant-notification .pd-b-20 {
    padding-bottom: 20px; }
  .moby__layout .pd-l-20,
  .ant-modal-content .pd-l-20,
  .ant-popover-inner .pd-l-20,
  .ant-drawer .pd-l-20,
  .ant-dropdown .pd-l-20,
  .ant-notification .pd-l-20 {
    padding-left: 20px; }
  .moby__layout .pd-r-20,
  .ant-modal-content .pd-r-20,
  .ant-popover-inner .pd-r-20,
  .ant-drawer .pd-r-20,
  .ant-dropdown .pd-r-20,
  .ant-notification .pd-r-20 {
    padding-right: 20px; }
  .moby__layout .pd-t-b-20,
  .ant-modal-content .pd-t-b-20,
  .ant-popover-inner .pd-t-b-20,
  .ant-drawer .pd-t-b-20,
  .ant-dropdown .pd-t-b-20,
  .ant-notification .pd-t-b-20 {
    padding: 20px 0; }
  .moby__layout .pd-l-r-20,
  .ant-modal-content .pd-l-r-20,
  .ant-popover-inner .pd-l-r-20,
  .ant-drawer .pd-l-r-20,
  .ant-dropdown .pd-l-r-20,
  .ant-notification .pd-l-r-20 {
    padding: 0 20px; }
  .moby__layout .pd-25,
  .ant-modal-content .pd-25,
  .ant-popover-inner .pd-25,
  .ant-drawer .pd-25,
  .ant-dropdown .pd-25,
  .ant-notification .pd-25 {
    padding: 25px; }
  .moby__layout .pd-t-25,
  .ant-modal-content .pd-t-25,
  .ant-popover-inner .pd-t-25,
  .ant-drawer .pd-t-25,
  .ant-dropdown .pd-t-25,
  .ant-notification .pd-t-25 {
    padding-top: 25px; }
  .moby__layout .pd-b-25,
  .ant-modal-content .pd-b-25,
  .ant-popover-inner .pd-b-25,
  .ant-drawer .pd-b-25,
  .ant-dropdown .pd-b-25,
  .ant-notification .pd-b-25 {
    padding-bottom: 25px; }
  .moby__layout .pd-l-25,
  .ant-modal-content .pd-l-25,
  .ant-popover-inner .pd-l-25,
  .ant-drawer .pd-l-25,
  .ant-dropdown .pd-l-25,
  .ant-notification .pd-l-25 {
    padding-left: 25px; }
  .moby__layout .pd-r-25,
  .ant-modal-content .pd-r-25,
  .ant-popover-inner .pd-r-25,
  .ant-drawer .pd-r-25,
  .ant-dropdown .pd-r-25,
  .ant-notification .pd-r-25 {
    padding-right: 25px; }
  .moby__layout .pd-t-b-25,
  .ant-modal-content .pd-t-b-25,
  .ant-popover-inner .pd-t-b-25,
  .ant-drawer .pd-t-b-25,
  .ant-dropdown .pd-t-b-25,
  .ant-notification .pd-t-b-25 {
    padding: 25px 0; }
  .moby__layout .pd-l-r-25,
  .ant-modal-content .pd-l-r-25,
  .ant-popover-inner .pd-l-r-25,
  .ant-drawer .pd-l-r-25,
  .ant-dropdown .pd-l-r-25,
  .ant-notification .pd-l-r-25 {
    padding: 0 25px; }
  .moby__layout .pd-30,
  .ant-modal-content .pd-30,
  .ant-popover-inner .pd-30,
  .ant-drawer .pd-30,
  .ant-dropdown .pd-30,
  .ant-notification .pd-30 {
    padding: 30px; }
  .moby__layout .pd-t-30,
  .ant-modal-content .pd-t-30,
  .ant-popover-inner .pd-t-30,
  .ant-drawer .pd-t-30,
  .ant-dropdown .pd-t-30,
  .ant-notification .pd-t-30 {
    padding-top: 30px; }
  .moby__layout .pd-b-30,
  .ant-modal-content .pd-b-30,
  .ant-popover-inner .pd-b-30,
  .ant-drawer .pd-b-30,
  .ant-dropdown .pd-b-30,
  .ant-notification .pd-b-30 {
    padding-bottom: 30px; }
  .moby__layout .pd-l-30,
  .ant-modal-content .pd-l-30,
  .ant-popover-inner .pd-l-30,
  .ant-drawer .pd-l-30,
  .ant-dropdown .pd-l-30,
  .ant-notification .pd-l-30 {
    padding-left: 30px; }
  .moby__layout .pd-r-30,
  .ant-modal-content .pd-r-30,
  .ant-popover-inner .pd-r-30,
  .ant-drawer .pd-r-30,
  .ant-dropdown .pd-r-30,
  .ant-notification .pd-r-30 {
    padding-right: 30px; }
  .moby__layout .pd-t-b-30,
  .ant-modal-content .pd-t-b-30,
  .ant-popover-inner .pd-t-b-30,
  .ant-drawer .pd-t-b-30,
  .ant-dropdown .pd-t-b-30,
  .ant-notification .pd-t-b-30 {
    padding: 30px 0; }
  .moby__layout .pd-l-r-30,
  .ant-modal-content .pd-l-r-30,
  .ant-popover-inner .pd-l-r-30,
  .ant-drawer .pd-l-r-30,
  .ant-dropdown .pd-l-r-30,
  .ant-notification .pd-l-r-30 {
    padding: 0 30px; }
  .moby__layout .pd-0,
  .ant-modal-content .pd-0,
  .ant-popover-inner .pd-0,
  .ant-drawer .pd-0,
  .ant-dropdown .pd-0,
  .ant-notification .pd-0 {
    padding: 0 !important; }
  .moby__layout .pd-b-0,
  .ant-modal-content .pd-b-0,
  .ant-popover-inner .pd-b-0,
  .ant-drawer .pd-b-0,
  .ant-dropdown .pd-b-0,
  .ant-notification .pd-b-0 {
    padding-bottom: 0 !important; }
  .moby__layout .pd-t-0,
  .ant-modal-content .pd-t-0,
  .ant-popover-inner .pd-t-0,
  .ant-drawer .pd-t-0,
  .ant-dropdown .pd-t-0,
  .ant-notification .pd-t-0 {
    padding-top: 0 !important; }
  .moby__layout .pd-l-0,
  .ant-modal-content .pd-l-0,
  .ant-popover-inner .pd-l-0,
  .ant-drawer .pd-l-0,
  .ant-dropdown .pd-l-0,
  .ant-notification .pd-l-0 {
    padding-left: 0 !important; }
  .moby__layout .pd-r-0,
  .ant-modal-content .pd-r-0,
  .ant-popover-inner .pd-r-0,
  .ant-drawer .pd-r-0,
  .ant-dropdown .pd-r-0,
  .ant-notification .pd-r-0 {
    padding-right: 0 !important; }
  @media (max-width: 767px) {
    .moby__layout .pd-0-sm,
    .ant-modal-content .pd-0-sm,
    .ant-popover-inner .pd-0-sm,
    .ant-drawer .pd-0-sm,
    .ant-dropdown .pd-0-sm,
    .ant-notification .pd-0-sm {
      padding: 0 !important; } }
  .moby__layout .mr-5,
  .ant-modal-content .mr-5,
  .ant-popover-inner .mr-5,
  .ant-drawer .mr-5,
  .ant-dropdown .mr-5,
  .ant-notification .mr-5 {
    margin: 5px; }
  .moby__layout .mr-t-5,
  .ant-modal-content .mr-t-5,
  .ant-popover-inner .mr-t-5,
  .ant-drawer .mr-t-5,
  .ant-dropdown .mr-t-5,
  .ant-notification .mr-t-5 {
    margin-top: 5px; }
  .moby__layout .mr-b-5,
  .ant-modal-content .mr-b-5,
  .ant-popover-inner .mr-b-5,
  .ant-drawer .mr-b-5,
  .ant-dropdown .mr-b-5,
  .ant-notification .mr-b-5 {
    margin-bottom: 5px; }
  .moby__layout .mr-l-5,
  .ant-modal-content .mr-l-5,
  .ant-popover-inner .mr-l-5,
  .ant-drawer .mr-l-5,
  .ant-dropdown .mr-l-5,
  .ant-notification .mr-l-5 {
    margin-left: 5px; }
  .moby__layout .mr-r-5,
  .ant-modal-content .mr-r-5,
  .ant-popover-inner .mr-r-5,
  .ant-drawer .mr-r-5,
  .ant-dropdown .mr-r-5,
  .ant-notification .mr-r-5 {
    margin-right: 5px; }
  .moby__layout .mr-t-b-5,
  .ant-modal-content .mr-t-b-5,
  .ant-popover-inner .mr-t-b-5,
  .ant-drawer .mr-t-b-5,
  .ant-dropdown .mr-t-b-5,
  .ant-notification .mr-t-b-5 {
    margin: 5px 0; }
  .moby__layout .mr-l-r-5,
  .ant-modal-content .mr-l-r-5,
  .ant-popover-inner .mr-l-r-5,
  .ant-drawer .mr-l-r-5,
  .ant-dropdown .mr-l-r-5,
  .ant-notification .mr-l-r-5 {
    margin: 0 5px; }
  .moby__layout .mr-10,
  .ant-modal-content .mr-10,
  .ant-popover-inner .mr-10,
  .ant-drawer .mr-10,
  .ant-dropdown .mr-10,
  .ant-notification .mr-10 {
    margin: 10px; }
  .moby__layout .mr-t-10,
  .ant-modal-content .mr-t-10,
  .ant-popover-inner .mr-t-10,
  .ant-drawer .mr-t-10,
  .ant-dropdown .mr-t-10,
  .ant-notification .mr-t-10 {
    margin-top: 10px; }
  .moby__layout .mr-b-10,
  .ant-modal-content .mr-b-10,
  .ant-popover-inner .mr-b-10,
  .ant-drawer .mr-b-10,
  .ant-dropdown .mr-b-10,
  .ant-notification .mr-b-10 {
    margin-bottom: 10px; }
  .moby__layout .mr-l-10,
  .ant-modal-content .mr-l-10,
  .ant-popover-inner .mr-l-10,
  .ant-drawer .mr-l-10,
  .ant-dropdown .mr-l-10,
  .ant-notification .mr-l-10 {
    margin-left: 10px; }
  .moby__layout .mr-r-10,
  .ant-modal-content .mr-r-10,
  .ant-popover-inner .mr-r-10,
  .ant-drawer .mr-r-10,
  .ant-dropdown .mr-r-10,
  .ant-notification .mr-r-10 {
    margin-right: 10px; }
  .moby__layout .mr-t-b-10,
  .ant-modal-content .mr-t-b-10,
  .ant-popover-inner .mr-t-b-10,
  .ant-drawer .mr-t-b-10,
  .ant-dropdown .mr-t-b-10,
  .ant-notification .mr-t-b-10 {
    margin: 10px 0; }
  .moby__layout .mr-l-r-10,
  .ant-modal-content .mr-l-r-10,
  .ant-popover-inner .mr-l-r-10,
  .ant-drawer .mr-l-r-10,
  .ant-dropdown .mr-l-r-10,
  .ant-notification .mr-l-r-10 {
    margin: 0 10px; }
  .moby__layout .mr-15,
  .ant-modal-content .mr-15,
  .ant-popover-inner .mr-15,
  .ant-drawer .mr-15,
  .ant-dropdown .mr-15,
  .ant-notification .mr-15 {
    margin: 15px; }
  .moby__layout .mr-t-15,
  .ant-modal-content .mr-t-15,
  .ant-popover-inner .mr-t-15,
  .ant-drawer .mr-t-15,
  .ant-dropdown .mr-t-15,
  .ant-notification .mr-t-15 {
    margin-top: 15px; }
  .moby__layout .mr-b-15,
  .ant-modal-content .mr-b-15,
  .ant-popover-inner .mr-b-15,
  .ant-drawer .mr-b-15,
  .ant-dropdown .mr-b-15,
  .ant-notification .mr-b-15 {
    margin-bottom: 15px; }
  .moby__layout .mr-l-15,
  .ant-modal-content .mr-l-15,
  .ant-popover-inner .mr-l-15,
  .ant-drawer .mr-l-15,
  .ant-dropdown .mr-l-15,
  .ant-notification .mr-l-15 {
    margin-left: 15px; }
  .moby__layout .mr-r-15,
  .ant-modal-content .mr-r-15,
  .ant-popover-inner .mr-r-15,
  .ant-drawer .mr-r-15,
  .ant-dropdown .mr-r-15,
  .ant-notification .mr-r-15 {
    margin-right: 15px; }
  .moby__layout .mr-t-b-15,
  .ant-modal-content .mr-t-b-15,
  .ant-popover-inner .mr-t-b-15,
  .ant-drawer .mr-t-b-15,
  .ant-dropdown .mr-t-b-15,
  .ant-notification .mr-t-b-15 {
    margin: 15px 0; }
  .moby__layout .mr-l-r-15,
  .ant-modal-content .mr-l-r-15,
  .ant-popover-inner .mr-l-r-15,
  .ant-drawer .mr-l-r-15,
  .ant-dropdown .mr-l-r-15,
  .ant-notification .mr-l-r-15 {
    margin: 0 15px; }
  .moby__layout .mr-20,
  .ant-modal-content .mr-20,
  .ant-popover-inner .mr-20,
  .ant-drawer .mr-20,
  .ant-dropdown .mr-20,
  .ant-notification .mr-20 {
    margin: 20px; }
  .moby__layout .mr-t-20,
  .ant-modal-content .mr-t-20,
  .ant-popover-inner .mr-t-20,
  .ant-drawer .mr-t-20,
  .ant-dropdown .mr-t-20,
  .ant-notification .mr-t-20 {
    margin-top: 20px; }
  .moby__layout .mr-b-20,
  .ant-modal-content .mr-b-20,
  .ant-popover-inner .mr-b-20,
  .ant-drawer .mr-b-20,
  .ant-dropdown .mr-b-20,
  .ant-notification .mr-b-20 {
    margin-bottom: 20px; }
  .moby__layout .mr-l-20,
  .ant-modal-content .mr-l-20,
  .ant-popover-inner .mr-l-20,
  .ant-drawer .mr-l-20,
  .ant-dropdown .mr-l-20,
  .ant-notification .mr-l-20 {
    margin-left: 20px; }
  .moby__layout .mr-r-20,
  .ant-modal-content .mr-r-20,
  .ant-popover-inner .mr-r-20,
  .ant-drawer .mr-r-20,
  .ant-dropdown .mr-r-20,
  .ant-notification .mr-r-20 {
    margin-right: 20px; }
  .moby__layout .mr-t-b-20,
  .ant-modal-content .mr-t-b-20,
  .ant-popover-inner .mr-t-b-20,
  .ant-drawer .mr-t-b-20,
  .ant-dropdown .mr-t-b-20,
  .ant-notification .mr-t-b-20 {
    margin: 20px 0; }
  .moby__layout .mr-l-r-20,
  .ant-modal-content .mr-l-r-20,
  .ant-popover-inner .mr-l-r-20,
  .ant-drawer .mr-l-r-20,
  .ant-dropdown .mr-l-r-20,
  .ant-notification .mr-l-r-20 {
    margin: 0 20px; }
  .moby__layout .mr-25,
  .ant-modal-content .mr-25,
  .ant-popover-inner .mr-25,
  .ant-drawer .mr-25,
  .ant-dropdown .mr-25,
  .ant-notification .mr-25 {
    margin: 25px; }
  .moby__layout .mr-t-25,
  .ant-modal-content .mr-t-25,
  .ant-popover-inner .mr-t-25,
  .ant-drawer .mr-t-25,
  .ant-dropdown .mr-t-25,
  .ant-notification .mr-t-25 {
    margin-top: 25px; }
  .moby__layout .mr-b-25,
  .ant-modal-content .mr-b-25,
  .ant-popover-inner .mr-b-25,
  .ant-drawer .mr-b-25,
  .ant-dropdown .mr-b-25,
  .ant-notification .mr-b-25 {
    margin-bottom: 25px; }
  .moby__layout .mr-l-25,
  .ant-modal-content .mr-l-25,
  .ant-popover-inner .mr-l-25,
  .ant-drawer .mr-l-25,
  .ant-dropdown .mr-l-25,
  .ant-notification .mr-l-25 {
    margin-left: 25px; }
  .moby__layout .mr-r-25,
  .ant-modal-content .mr-r-25,
  .ant-popover-inner .mr-r-25,
  .ant-drawer .mr-r-25,
  .ant-dropdown .mr-r-25,
  .ant-notification .mr-r-25 {
    margin-right: 25px; }
  .moby__layout .mr-t-b-25,
  .ant-modal-content .mr-t-b-25,
  .ant-popover-inner .mr-t-b-25,
  .ant-drawer .mr-t-b-25,
  .ant-dropdown .mr-t-b-25,
  .ant-notification .mr-t-b-25 {
    margin: 25px 0; }
  .moby__layout .mr-l-r-25,
  .ant-modal-content .mr-l-r-25,
  .ant-popover-inner .mr-l-r-25,
  .ant-drawer .mr-l-r-25,
  .ant-dropdown .mr-l-r-25,
  .ant-notification .mr-l-r-25 {
    margin: 0 25px; }
  .moby__layout .mr-30,
  .ant-modal-content .mr-30,
  .ant-popover-inner .mr-30,
  .ant-drawer .mr-30,
  .ant-dropdown .mr-30,
  .ant-notification .mr-30 {
    margin: 30px; }
  .moby__layout .mr-t-30,
  .ant-modal-content .mr-t-30,
  .ant-popover-inner .mr-t-30,
  .ant-drawer .mr-t-30,
  .ant-dropdown .mr-t-30,
  .ant-notification .mr-t-30 {
    margin-top: 30px; }
  .moby__layout .mr-b-30,
  .ant-modal-content .mr-b-30,
  .ant-popover-inner .mr-b-30,
  .ant-drawer .mr-b-30,
  .ant-dropdown .mr-b-30,
  .ant-notification .mr-b-30 {
    margin-bottom: 30px; }
  .moby__layout .mr-l-30,
  .ant-modal-content .mr-l-30,
  .ant-popover-inner .mr-l-30,
  .ant-drawer .mr-l-30,
  .ant-dropdown .mr-l-30,
  .ant-notification .mr-l-30 {
    margin-left: 30px; }
  .moby__layout .mr-r-30,
  .ant-modal-content .mr-r-30,
  .ant-popover-inner .mr-r-30,
  .ant-drawer .mr-r-30,
  .ant-dropdown .mr-r-30,
  .ant-notification .mr-r-30 {
    margin-right: 30px; }
  .moby__layout .mr-t-b-30,
  .ant-modal-content .mr-t-b-30,
  .ant-popover-inner .mr-t-b-30,
  .ant-drawer .mr-t-b-30,
  .ant-dropdown .mr-t-b-30,
  .ant-notification .mr-t-b-30 {
    margin: 30px 0; }
  .moby__layout .mr-l-r-30,
  .ant-modal-content .mr-l-r-30,
  .ant-popover-inner .mr-l-r-30,
  .ant-drawer .mr-l-r-30,
  .ant-dropdown .mr-l-r-30,
  .ant-notification .mr-l-r-30 {
    margin: 0 30px; }
  .moby__layout .mr-0,
  .ant-modal-content .mr-0,
  .ant-popover-inner .mr-0,
  .ant-drawer .mr-0,
  .ant-dropdown .mr-0,
  .ant-notification .mr-0 {
    margin: 0 !important; }
  .moby__layout .mr-b-0,
  .ant-modal-content .mr-b-0,
  .ant-popover-inner .mr-b-0,
  .ant-drawer .mr-b-0,
  .ant-dropdown .mr-b-0,
  .ant-notification .mr-b-0 {
    margin-bottom: 0 !important; }
  .moby__layout .mr-t-0,
  .ant-modal-content .mr-t-0,
  .ant-popover-inner .mr-t-0,
  .ant-drawer .mr-t-0,
  .ant-dropdown .mr-t-0,
  .ant-notification .mr-t-0 {
    margin-top: 0 !important; }
  .moby__layout .mr-l-0,
  .ant-modal-content .mr-l-0,
  .ant-popover-inner .mr-l-0,
  .ant-drawer .mr-l-0,
  .ant-dropdown .mr-l-0,
  .ant-notification .mr-l-0 {
    margin-left: 0 !important; }
  .moby__layout .mr-r-0,
  .ant-modal-content .mr-r-0,
  .ant-popover-inner .mr-r-0,
  .ant-drawer .mr-r-0,
  .ant-dropdown .mr-r-0,
  .ant-notification .mr-r-0 {
    margin-right: 0 !important; }
  @media (max-width: 767px) {
    .moby__layout .mr-0-sm,
    .ant-modal-content .mr-0-sm,
    .ant-popover-inner .mr-0-sm,
    .ant-drawer .mr-0-sm,
    .ant-dropdown .mr-0-sm,
    .ant-notification .mr-0-sm {
      margin: 0 !important; } }
  .moby__layout .filled,
  .ant-modal-content .filled,
  .ant-popover-inner .filled,
  .ant-drawer .filled,
  .ant-dropdown .filled,
  .ant-notification .filled {
    background: #f9fafa !important; }
  .moby__layout .filled-white,
  .ant-modal-content .filled-white,
  .ant-popover-inner .filled-white,
  .ant-drawer .filled-white,
  .ant-dropdown .filled-white,
  .ant-notification .filled-white {
    background: white !important; }
  .moby__layout .h-100,
  .ant-modal-content .h-100,
  .ant-popover-inner .h-100,
  .ant-drawer .h-100,
  .ant-dropdown .h-100,
  .ant-notification .h-100 {
    height: 100% !important; }
  .moby__layout .h-30-px,
  .ant-modal-content .h-30-px,
  .ant-popover-inner .h-30-px,
  .ant-drawer .h-30-px,
  .ant-dropdown .h-30-px,
  .ant-notification .h-30-px {
    height: 30px; }
  .moby__layout .h-50-px,
  .ant-modal-content .h-50-px,
  .ant-popover-inner .h-50-px,
  .ant-drawer .h-50-px,
  .ant-dropdown .h-50-px,
  .ant-notification .h-50-px {
    height: 50px; }
  .moby__layout .h-100-px,
  .ant-modal-content .h-100-px,
  .ant-popover-inner .h-100-px,
  .ant-drawer .h-100-px,
  .ant-dropdown .h-100-px,
  .ant-notification .h-100-px {
    height: 100px; }
  .moby__layout .h-200-px,
  .ant-modal-content .h-200-px,
  .ant-popover-inner .h-200-px,
  .ant-drawer .h-200-px,
  .ant-dropdown .h-200-px,
  .ant-notification .h-200-px {
    height: 200px; }
  .moby__layout .h-210-px,
  .ant-modal-content .h-210-px,
  .ant-popover-inner .h-210-px,
  .ant-drawer .h-210-px,
  .ant-dropdown .h-210-px,
  .ant-notification .h-210-px {
    height: 210px; }
  .moby__layout .h-300-px,
  .ant-modal-content .h-300-px,
  .ant-popover-inner .h-300-px,
  .ant-drawer .h-300-px,
  .ant-dropdown .h-300-px,
  .ant-notification .h-300-px {
    height: 300px; }
  .moby__layout .h-400-px,
  .ant-modal-content .h-400-px,
  .ant-popover-inner .h-400-px,
  .ant-drawer .h-400-px,
  .ant-dropdown .h-400-px,
  .ant-notification .h-400-px {
    height: 400px; }
  .moby__layout .w-100,
  .ant-modal-content .w-100,
  .ant-popover-inner .w-100,
  .ant-drawer .w-100,
  .ant-dropdown .w-100,
  .ant-notification .w-100 {
    width: 100% !important; }
  .moby__layout .w-30-px,
  .ant-modal-content .w-30-px,
  .ant-popover-inner .w-30-px,
  .ant-drawer .w-30-px,
  .ant-dropdown .w-30-px,
  .ant-notification .w-30-px {
    width: 30px; }
  .moby__layout .w-50-px,
  .ant-modal-content .w-50-px,
  .ant-popover-inner .w-50-px,
  .ant-drawer .w-50-px,
  .ant-dropdown .w-50-px,
  .ant-notification .w-50-px {
    width: 50px; }
  .moby__layout .w-100-px,
  .ant-modal-content .w-100-px,
  .ant-popover-inner .w-100-px,
  .ant-drawer .w-100-px,
  .ant-dropdown .w-100-px,
  .ant-notification .w-100-px {
    width: 100px; }
  .moby__layout .w-200-px,
  .ant-modal-content .w-200-px,
  .ant-popover-inner .w-200-px,
  .ant-drawer .w-200-px,
  .ant-dropdown .w-200-px,
  .ant-notification .w-200-px {
    width: 200px; }
  .moby__layout .w-210-px,
  .ant-modal-content .w-210-px,
  .ant-popover-inner .w-210-px,
  .ant-drawer .w-210-px,
  .ant-dropdown .w-210-px,
  .ant-notification .w-210-px {
    width: 210px; }
  .moby__layout .w-300-px,
  .ant-modal-content .w-300-px,
  .ant-popover-inner .w-300-px,
  .ant-drawer .w-300-px,
  .ant-dropdown .w-300-px,
  .ant-notification .w-300-px {
    width: 300px; }
  .moby__layout .w-400-px,
  .ant-modal-content .w-400-px,
  .ant-popover-inner .w-400-px,
  .ant-drawer .w-400-px,
  .ant-dropdown .w-400-px,
  .ant-notification .w-400-px {
    width: 400px; }
    @media (max-width: 480px) {
      .moby__layout .w-400-px,
      .ant-modal-content .w-400-px,
      .ant-popover-inner .w-400-px,
      .ant-drawer .w-400-px,
      .ant-dropdown .w-400-px,
      .ant-notification .w-400-px {
        width: 100%; } }
  .moby__layout .text-left,
  .ant-modal-content .text-left,
  .ant-popover-inner .text-left,
  .ant-drawer .text-left,
  .ant-dropdown .text-left,
  .ant-notification .text-left {
    text-align: left; }
  .moby__layout .text-center,
  .ant-modal-content .text-center,
  .ant-popover-inner .text-center,
  .ant-drawer .text-center,
  .ant-dropdown .text-center,
  .ant-notification .text-center {
    text-align: center; }
  .moby__layout .text-right,
  .ant-modal-content .text-right,
  .ant-popover-inner .text-right,
  .ant-drawer .text-right,
  .ant-dropdown .text-right,
  .ant-notification .text-right {
    text-align: right; }
  .moby__layout .border-0,
  .ant-modal-content .border-0,
  .ant-popover-inner .border-0,
  .ant-drawer .border-0,
  .ant-dropdown .border-0,
  .ant-notification .border-0 {
    border: 0 !important; }
  .moby__layout .border,
  .ant-modal-content .border,
  .ant-popover-inner .border,
  .ant-drawer .border,
  .ant-dropdown .border,
  .ant-notification .border {
    border: 1px solid #e3e8ea !important; }
  .moby__layout .border-top,
  .ant-modal-content .border-top,
  .ant-popover-inner .border-top,
  .ant-drawer .border-top,
  .ant-dropdown .border-top,
  .ant-notification .border-top {
    border-top: 1px solid #e3e8ea !important; }
  .moby__layout .border-bottom,
  .ant-modal-content .border-bottom,
  .ant-popover-inner .border-bottom,
  .ant-drawer .border-bottom,
  .ant-dropdown .border-bottom,
  .ant-notification .border-bottom {
    border-bottom: 1px solid #e3e8ea !important; }
  .moby__layout .border-left,
  .ant-modal-content .border-left,
  .ant-popover-inner .border-left,
  .ant-drawer .border-left,
  .ant-dropdown .border-left,
  .ant-notification .border-left {
    border-left: 1px solid #e3e8ea !important; }
  .moby__layout .border-right,
  .ant-modal-content .border-right,
  .ant-popover-inner .border-right,
  .ant-drawer .border-right,
  .ant-dropdown .border-right,
  .ant-notification .border-right {
    border-right: 1px solid #e3e8ea !important; }
  .moby__layout .border-white,
  .ant-modal-content .border-white,
  .ant-popover-inner .border-white,
  .ant-drawer .border-white,
  .ant-dropdown .border-white,
  .ant-notification .border-white {
    border: 1px solid white !important; }
  .moby__layout .border-top-white,
  .ant-modal-content .border-top-white,
  .ant-popover-inner .border-top-white,
  .ant-drawer .border-top-white,
  .ant-dropdown .border-top-white,
  .ant-notification .border-top-white {
    border-top: 1px solid white !important; }
  .moby__layout .border-bottom-white,
  .ant-modal-content .border-bottom-white,
  .ant-popover-inner .border-bottom-white,
  .ant-drawer .border-bottom-white,
  .ant-dropdown .border-bottom-white,
  .ant-notification .border-bottom-white {
    border-bottom: 1px solid white !important; }
  .moby__layout .border-left-white,
  .ant-modal-content .border-left-white,
  .ant-popover-inner .border-left-white,
  .ant-drawer .border-left-white,
  .ant-dropdown .border-left-white,
  .ant-notification .border-left-white {
    border-left: 1px solid white !important; }
  .moby__layout .border-right-white,
  .ant-modal-content .border-right-white,
  .ant-popover-inner .border-right-white,
  .ant-drawer .border-right-white,
  .ant-dropdown .border-right-white,
  .ant-notification .border-right-white {
    border-right: 1px solid white !important; }
  .moby__layout .border-black,
  .ant-modal-content .border-black,
  .ant-popover-inner .border-black,
  .ant-drawer .border-black,
  .ant-dropdown .border-black,
  .ant-notification .border-black {
    border: 1px solid #202124 !important; }
  .moby__layout .border-top-black,
  .ant-modal-content .border-top-black,
  .ant-popover-inner .border-top-black,
  .ant-drawer .border-top-black,
  .ant-dropdown .border-top-black,
  .ant-notification .border-top-black {
    border-top: 1px solid #202124 !important; }
  .moby__layout .border-bottom-black,
  .ant-modal-content .border-bottom-black,
  .ant-popover-inner .border-bottom-black,
  .ant-drawer .border-bottom-black,
  .ant-dropdown .border-bottom-black,
  .ant-notification .border-bottom-black {
    border-bottom: 1px solid #202124 !important; }
  .moby__layout .border-left-black,
  .ant-modal-content .border-left-black,
  .ant-popover-inner .border-left-black,
  .ant-drawer .border-left-black,
  .ant-dropdown .border-left-black,
  .ant-notification .border-left-black {
    border-left: 1px solid #202124 !important; }
  .moby__layout .border-right-black,
  .ant-modal-content .border-right-black,
  .ant-popover-inner .border-right-black,
  .ant-drawer .border-right-black,
  .ant-dropdown .border-right-black,
  .ant-notification .border-right-black {
    border-right: 1px solid #202124 !important; }
  .moby__layout .border-danger,
  .ant-modal-content .border-danger,
  .ant-popover-inner .border-danger,
  .ant-drawer .border-danger,
  .ant-dropdown .border-danger,
  .ant-notification .border-danger {
    border: 1px solid #ea4335 !important; }
  .moby__layout .border-top-danger,
  .ant-modal-content .border-top-danger,
  .ant-popover-inner .border-top-danger,
  .ant-drawer .border-top-danger,
  .ant-dropdown .border-top-danger,
  .ant-notification .border-top-danger {
    border-top: 1px solid #ea4335 !important; }
  .moby__layout .border-bottom-danger,
  .ant-modal-content .border-bottom-danger,
  .ant-popover-inner .border-bottom-danger,
  .ant-drawer .border-bottom-danger,
  .ant-dropdown .border-bottom-danger,
  .ant-notification .border-bottom-danger {
    border-bottom: 1px solid #ea4335 !important; }
  .moby__layout .border-left-danger,
  .ant-modal-content .border-left-danger,
  .ant-popover-inner .border-left-danger,
  .ant-drawer .border-left-danger,
  .ant-dropdown .border-left-danger,
  .ant-notification .border-left-danger {
    border-left: 1px solid #ea4335 !important; }
  .moby__layout .border-right-danger,
  .ant-modal-content .border-right-danger,
  .ant-popover-inner .border-right-danger,
  .ant-drawer .border-right-danger,
  .ant-dropdown .border-right-danger,
  .ant-notification .border-right-danger {
    border-right: 1px solid #ea4335 !important; }
  .moby__layout .border-primary,
  .ant-modal-content .border-primary,
  .ant-popover-inner .border-primary,
  .ant-drawer .border-primary,
  .ant-dropdown .border-primary,
  .ant-notification .border-primary {
    border: 1px solid #DBA44F !important; }
  .moby__layout .border-top-primary,
  .ant-modal-content .border-top-primary,
  .ant-popover-inner .border-top-primary,
  .ant-drawer .border-top-primary,
  .ant-dropdown .border-top-primary,
  .ant-notification .border-top-primary {
    border-top: 1px solid #DBA44F !important; }
  .moby__layout .border-bottom-primary,
  .ant-modal-content .border-bottom-primary,
  .ant-popover-inner .border-bottom-primary,
  .ant-drawer .border-bottom-primary,
  .ant-dropdown .border-bottom-primary,
  .ant-notification .border-bottom-primary {
    border-bottom: 1px solid #DBA44F !important; }
  .moby__layout .border-left-primary,
  .ant-modal-content .border-left-primary,
  .ant-popover-inner .border-left-primary,
  .ant-drawer .border-left-primary,
  .ant-dropdown .border-left-primary,
  .ant-notification .border-left-primary {
    border-left: 1px solid #DBA44F !important; }
  .moby__layout .border-right-primary,
  .ant-modal-content .border-right-primary,
  .ant-popover-inner .border-right-primary,
  .ant-drawer .border-right-primary,
  .ant-dropdown .border-right-primary,
  .ant-notification .border-right-primary {
    border-right: 1px solid #DBA44F !important; }
  .moby__layout .border-success,
  .ant-modal-content .border-success,
  .ant-popover-inner .border-success,
  .ant-drawer .border-success,
  .ant-dropdown .border-success,
  .ant-notification .border-success {
    border: 1px solid #34a853 !important; }
  .moby__layout .border-top-success,
  .ant-modal-content .border-top-success,
  .ant-popover-inner .border-top-success,
  .ant-drawer .border-top-success,
  .ant-dropdown .border-top-success,
  .ant-notification .border-top-success {
    border-top: 1px solid #34a853 !important; }
  .moby__layout .border-bottom-success,
  .ant-modal-content .border-bottom-success,
  .ant-popover-inner .border-bottom-success,
  .ant-drawer .border-bottom-success,
  .ant-dropdown .border-bottom-success,
  .ant-notification .border-bottom-success {
    border-bottom: 1px solid #34a853 !important; }
  .moby__layout .border-left-success,
  .ant-modal-content .border-left-success,
  .ant-popover-inner .border-left-success,
  .ant-drawer .border-left-success,
  .ant-dropdown .border-left-success,
  .ant-notification .border-left-success {
    border-left: 1px solid #34a853 !important; }
  .moby__layout .border-right-success,
  .ant-modal-content .border-right-success,
  .ant-popover-inner .border-right-success,
  .ant-drawer .border-right-success,
  .ant-dropdown .border-right-success,
  .ant-notification .border-right-success {
    border-right: 1px solid #34a853 !important; }
  .moby__layout .border-warning,
  .ant-modal-content .border-warning,
  .ant-popover-inner .border-warning,
  .ant-drawer .border-warning,
  .ant-dropdown .border-warning,
  .ant-notification .border-warning {
    border: 1px solid #fbbc05 !important; }
  .moby__layout .border-top-warning,
  .ant-modal-content .border-top-warning,
  .ant-popover-inner .border-top-warning,
  .ant-drawer .border-top-warning,
  .ant-dropdown .border-top-warning,
  .ant-notification .border-top-warning {
    border-top: 1px solid #fbbc05 !important; }
  .moby__layout .border-bottom-warning,
  .ant-modal-content .border-bottom-warning,
  .ant-popover-inner .border-bottom-warning,
  .ant-drawer .border-bottom-warning,
  .ant-dropdown .border-bottom-warning,
  .ant-notification .border-bottom-warning {
    border-bottom: 1px solid #fbbc05 !important; }
  .moby__layout .border-left-warning,
  .ant-modal-content .border-left-warning,
  .ant-popover-inner .border-left-warning,
  .ant-drawer .border-left-warning,
  .ant-dropdown .border-left-warning,
  .ant-notification .border-left-warning {
    border-left: 1px solid #fbbc05 !important; }
  .moby__layout .border-right-warning,
  .ant-modal-content .border-right-warning,
  .ant-popover-inner .border-right-warning,
  .ant-drawer .border-right-warning,
  .ant-dropdown .border-right-warning,
  .ant-notification .border-right-warning {
    border-right: 1px solid #fbbc05 !important; }
  .moby__layout .border-white-2,
  .ant-modal-content .border-white-2,
  .ant-popover-inner .border-white-2,
  .ant-drawer .border-white-2,
  .ant-dropdown .border-white-2,
  .ant-notification .border-white-2 {
    border: 2px solid white !important; }
  .moby__layout .border-top-white-2,
  .ant-modal-content .border-top-white-2,
  .ant-popover-inner .border-top-white-2,
  .ant-drawer .border-top-white-2,
  .ant-dropdown .border-top-white-2,
  .ant-notification .border-top-white-2 {
    border-top: 2px solid white !important; }
  .moby__layout .border-bottom-white-2,
  .ant-modal-content .border-bottom-white-2,
  .ant-popover-inner .border-bottom-white-2,
  .ant-drawer .border-bottom-white-2,
  .ant-dropdown .border-bottom-white-2,
  .ant-notification .border-bottom-white-2 {
    border-bottom: 2px solid white !important; }
  .moby__layout .border-left-white-2,
  .ant-modal-content .border-left-white-2,
  .ant-popover-inner .border-left-white-2,
  .ant-drawer .border-left-white-2,
  .ant-dropdown .border-left-white-2,
  .ant-notification .border-left-white-2 {
    border-left: 2px solid white !important; }
  .moby__layout .border-right-white-2,
  .ant-modal-content .border-right-white-2,
  .ant-popover-inner .border-right-white-2,
  .ant-drawer .border-right-white-2,
  .ant-dropdown .border-right-white-2,
  .ant-notification .border-right-white-2 {
    border-right: 2px solid white !important; }
  .moby__layout .border-black-2,
  .ant-modal-content .border-black-2,
  .ant-popover-inner .border-black-2,
  .ant-drawer .border-black-2,
  .ant-dropdown .border-black-2,
  .ant-notification .border-black-2 {
    border: 2px solid #202124 !important; }
  .moby__layout .border-top-black-2,
  .ant-modal-content .border-top-black-2,
  .ant-popover-inner .border-top-black-2,
  .ant-drawer .border-top-black-2,
  .ant-dropdown .border-top-black-2,
  .ant-notification .border-top-black-2 {
    border-top: 2px solid #202124 !important; }
  .moby__layout .border-bottom-black-2,
  .ant-modal-content .border-bottom-black-2,
  .ant-popover-inner .border-bottom-black-2,
  .ant-drawer .border-bottom-black-2,
  .ant-dropdown .border-bottom-black-2,
  .ant-notification .border-bottom-black-2 {
    border-bottom: 2px solid #202124 !important; }
  .moby__layout .border-left-black-2,
  .ant-modal-content .border-left-black-2,
  .ant-popover-inner .border-left-black-2,
  .ant-drawer .border-left-black-2,
  .ant-dropdown .border-left-black-2,
  .ant-notification .border-left-black-2 {
    border-left: 2px solid #202124 !important; }
  .moby__layout .border-right-black-2,
  .ant-modal-content .border-right-black-2,
  .ant-popover-inner .border-right-black-2,
  .ant-drawer .border-right-black-2,
  .ant-dropdown .border-right-black-2,
  .ant-notification .border-right-black-2 {
    border-right: 2px solid #202124 !important; }
  .moby__layout .border-danger-2,
  .ant-modal-content .border-danger-2,
  .ant-popover-inner .border-danger-2,
  .ant-drawer .border-danger-2,
  .ant-dropdown .border-danger-2,
  .ant-notification .border-danger-2 {
    border: 2px solid #ea4335 !important; }
  .moby__layout .border-top-danger-2,
  .ant-modal-content .border-top-danger-2,
  .ant-popover-inner .border-top-danger-2,
  .ant-drawer .border-top-danger-2,
  .ant-dropdown .border-top-danger-2,
  .ant-notification .border-top-danger-2 {
    border-top: 2px solid #ea4335 !important; }
  .moby__layout .border-bottom-danger-2,
  .ant-modal-content .border-bottom-danger-2,
  .ant-popover-inner .border-bottom-danger-2,
  .ant-drawer .border-bottom-danger-2,
  .ant-dropdown .border-bottom-danger-2,
  .ant-notification .border-bottom-danger-2 {
    border-bottom: 2px solid #ea4335 !important; }
  .moby__layout .border-left-danger-2,
  .ant-modal-content .border-left-danger-2,
  .ant-popover-inner .border-left-danger-2,
  .ant-drawer .border-left-danger-2,
  .ant-dropdown .border-left-danger-2,
  .ant-notification .border-left-danger-2 {
    border-left: 2px solid #ea4335 !important; }
  .moby__layout .border-right-danger-2,
  .ant-modal-content .border-right-danger-2,
  .ant-popover-inner .border-right-danger-2,
  .ant-drawer .border-right-danger-2,
  .ant-dropdown .border-right-danger-2,
  .ant-notification .border-right-danger-2 {
    border-right: 2px solid #ea4335 !important; }
  .moby__layout .border-primary-2,
  .ant-modal-content .border-primary-2,
  .ant-popover-inner .border-primary-2,
  .ant-drawer .border-primary-2,
  .ant-dropdown .border-primary-2,
  .ant-notification .border-primary-2 {
    border: 2px solid #DBA44F !important; }
  .moby__layout .border-top-primary-2,
  .ant-modal-content .border-top-primary-2,
  .ant-popover-inner .border-top-primary-2,
  .ant-drawer .border-top-primary-2,
  .ant-dropdown .border-top-primary-2,
  .ant-notification .border-top-primary-2 {
    border-top: 2px solid #DBA44F !important; }
  .moby__layout .border-bottom-primary-2,
  .ant-modal-content .border-bottom-primary-2,
  .ant-popover-inner .border-bottom-primary-2,
  .ant-drawer .border-bottom-primary-2,
  .ant-dropdown .border-bottom-primary-2,
  .ant-notification .border-bottom-primary-2 {
    border-bottom: 2px solid #DBA44F !important; }
  .moby__layout .border-left-primary-2,
  .ant-modal-content .border-left-primary-2,
  .ant-popover-inner .border-left-primary-2,
  .ant-drawer .border-left-primary-2,
  .ant-dropdown .border-left-primary-2,
  .ant-notification .border-left-primary-2 {
    border-left: 2px solid #DBA44F !important; }
  .moby__layout .border-right-primary-2,
  .ant-modal-content .border-right-primary-2,
  .ant-popover-inner .border-right-primary-2,
  .ant-drawer .border-right-primary-2,
  .ant-dropdown .border-right-primary-2,
  .ant-notification .border-right-primary-2 {
    border-right: 2px solid #DBA44F !important; }
  .moby__layout .border-success-2,
  .ant-modal-content .border-success-2,
  .ant-popover-inner .border-success-2,
  .ant-drawer .border-success-2,
  .ant-dropdown .border-success-2,
  .ant-notification .border-success-2 {
    border: 2px solid #34a853 !important; }
  .moby__layout .border-top-success-2,
  .ant-modal-content .border-top-success-2,
  .ant-popover-inner .border-top-success-2,
  .ant-drawer .border-top-success-2,
  .ant-dropdown .border-top-success-2,
  .ant-notification .border-top-success-2 {
    border-top: 2px solid #34a853 !important; }
  .moby__layout .border-bottom-success-2,
  .ant-modal-content .border-bottom-success-2,
  .ant-popover-inner .border-bottom-success-2,
  .ant-drawer .border-bottom-success-2,
  .ant-dropdown .border-bottom-success-2,
  .ant-notification .border-bottom-success-2 {
    border-bottom: 2px solid #34a853 !important; }
  .moby__layout .border-left-success-2,
  .ant-modal-content .border-left-success-2,
  .ant-popover-inner .border-left-success-2,
  .ant-drawer .border-left-success-2,
  .ant-dropdown .border-left-success-2,
  .ant-notification .border-left-success-2 {
    border-left: 2px solid #34a853 !important; }
  .moby__layout .border-right-success-2,
  .ant-modal-content .border-right-success-2,
  .ant-popover-inner .border-right-success-2,
  .ant-drawer .border-right-success-2,
  .ant-dropdown .border-right-success-2,
  .ant-notification .border-right-success-2 {
    border-right: 2px solid #34a853 !important; }
  .moby__layout .border-warning-2,
  .ant-modal-content .border-warning-2,
  .ant-popover-inner .border-warning-2,
  .ant-drawer .border-warning-2,
  .ant-dropdown .border-warning-2,
  .ant-notification .border-warning-2 {
    border: 2px solid #fbbc05 !important; }
  .moby__layout .border-top-warning-2,
  .ant-modal-content .border-top-warning-2,
  .ant-popover-inner .border-top-warning-2,
  .ant-drawer .border-top-warning-2,
  .ant-dropdown .border-top-warning-2,
  .ant-notification .border-top-warning-2 {
    border-top: 2px solid #fbbc05 !important; }
  .moby__layout .border-bottom-warning-2,
  .ant-modal-content .border-bottom-warning-2,
  .ant-popover-inner .border-bottom-warning-2,
  .ant-drawer .border-bottom-warning-2,
  .ant-dropdown .border-bottom-warning-2,
  .ant-notification .border-bottom-warning-2 {
    border-bottom: 2px solid #fbbc05 !important; }
  .moby__layout .border-left-warning-2,
  .ant-modal-content .border-left-warning-2,
  .ant-popover-inner .border-left-warning-2,
  .ant-drawer .border-left-warning-2,
  .ant-dropdown .border-left-warning-2,
  .ant-notification .border-left-warning-2 {
    border-left: 2px solid #fbbc05 !important; }
  .moby__layout .border-right-warning-2,
  .ant-modal-content .border-right-warning-2,
  .ant-popover-inner .border-right-warning-2,
  .ant-drawer .border-right-warning-2,
  .ant-dropdown .border-right-warning-2,
  .ant-notification .border-right-warning-2 {
    border-right: 2px solid #fbbc05 !important; }
  .moby__layout .border-radius,
  .ant-modal-content .border-radius,
  .ant-popover-inner .border-radius,
  .ant-drawer .border-radius,
  .ant-dropdown .border-radius,
  .ant-notification .border-radius {
    border-radius: 4px; }
  .moby__layout .border-radius-100,
  .ant-modal-content .border-radius-100,
  .ant-popover-inner .border-radius-100,
  .ant-drawer .border-radius-100,
  .ant-dropdown .border-radius-100,
  .ant-notification .border-radius-100 {
    border-radius: 100%; }
  .moby__layout .border-left-top-bottom,
  .ant-modal-content .border-left-top-bottom,
  .ant-popover-inner .border-left-top-bottom,
  .ant-drawer .border-left-top-bottom,
  .ant-dropdown .border-left-top-bottom,
  .ant-notification .border-left-top-bottom {
    border-radius: 0px 4px 4px 0px !important; }
  .moby__layout .border-right-top-bottom,
  .ant-modal-content .border-right-top-bottom,
  .ant-popover-inner .border-right-top-bottom,
  .ant-drawer .border-right-top-bottom,
  .ant-dropdown .border-right-top-bottom,
  .ant-notification .border-right-top-bottom {
    border-radius: 4px 0px 0px 4px !important; }
  .moby__layout .circle-border,
  .ant-modal-content .circle-border,
  .ant-popover-inner .circle-border,
  .ant-drawer .circle-border,
  .ant-dropdown .circle-border,
  .ant-notification .circle-border {
    width: 10px;
    height: 10px;
    background: white;
    border: 2px solid #202124;
    border-radius: 100%; }
    .moby__layout .circle-border.danger,
    .ant-modal-content .circle-border.danger,
    .ant-popover-inner .circle-border.danger,
    .ant-drawer .circle-border.danger,
    .ant-dropdown .circle-border.danger,
    .ant-notification .circle-border.danger {
      border-color: #ea4335; }
    .moby__layout .circle-border.primary,
    .ant-modal-content .circle-border.primary,
    .ant-popover-inner .circle-border.primary,
    .ant-drawer .circle-border.primary,
    .ant-dropdown .circle-border.primary,
    .ant-notification .circle-border.primary {
      border-color: #DBA44F; }
    .moby__layout .circle-border.success,
    .ant-modal-content .circle-border.success,
    .ant-popover-inner .circle-border.success,
    .ant-drawer .circle-border.success,
    .ant-dropdown .circle-border.success,
    .ant-notification .circle-border.success {
      border-color: #34a853; }
    .moby__layout .circle-border.warning,
    .ant-modal-content .circle-border.warning,
    .ant-popover-inner .circle-border.warning,
    .ant-drawer .circle-border.warning,
    .ant-dropdown .circle-border.warning,
    .ant-notification .circle-border.warning {
      border-color: #fbbc05; }
    .moby__layout .circle-border.circle-bg,
    .ant-modal-content .circle-border.circle-bg,
    .ant-popover-inner .circle-border.circle-bg,
    .ant-drawer .circle-border.circle-bg,
    .ant-dropdown .circle-border.circle-bg,
    .ant-notification .circle-border.circle-bg {
      width: 10px;
      height: 10px;
      background: #202124;
      border-radius: 50%; }
      .moby__layout .circle-border.circle-bg.danger,
      .ant-modal-content .circle-border.circle-bg.danger,
      .ant-popover-inner .circle-border.circle-bg.danger,
      .ant-drawer .circle-border.circle-bg.danger,
      .ant-dropdown .circle-border.circle-bg.danger,
      .ant-notification .circle-border.circle-bg.danger {
        background: #ea4335; }
      .moby__layout .circle-border.circle-bg.primary,
      .ant-modal-content .circle-border.circle-bg.primary,
      .ant-popover-inner .circle-border.circle-bg.primary,
      .ant-drawer .circle-border.circle-bg.primary,
      .ant-dropdown .circle-border.circle-bg.primary,
      .ant-notification .circle-border.circle-bg.primary {
        background: #DBA44F; }
      .moby__layout .circle-border.circle-bg.success,
      .ant-modal-content .circle-border.circle-bg.success,
      .ant-popover-inner .circle-border.circle-bg.success,
      .ant-drawer .circle-border.circle-bg.success,
      .ant-dropdown .circle-border.circle-bg.success,
      .ant-notification .circle-border.circle-bg.success {
        background: #34a853; }
      .moby__layout .circle-border.circle-bg.warning,
      .ant-modal-content .circle-border.circle-bg.warning,
      .ant-popover-inner .circle-border.circle-bg.warning,
      .ant-drawer .circle-border.circle-bg.warning,
      .ant-dropdown .circle-border.circle-bg.warning,
      .ant-notification .circle-border.circle-bg.warning {
        background: #fbbc05; }
  .moby__layout .float-none,
  .ant-modal-content .float-none,
  .ant-popover-inner .float-none,
  .ant-drawer .float-none,
  .ant-dropdown .float-none,
  .ant-notification .float-none {
    float: none; }
  .moby__layout .float-left,
  .ant-modal-content .float-left,
  .ant-popover-inner .float-left,
  .ant-drawer .float-left,
  .ant-dropdown .float-left,
  .ant-notification .float-left {
    float: left; }
  .moby__layout .float-right,
  .ant-modal-content .float-right,
  .ant-popover-inner .float-right,
  .ant-drawer .float-right,
  .ant-dropdown .float-right,
  .ant-notification .float-right {
    float: right; }
  .moby__layout .flex,
  .ant-modal-content .flex,
  .ant-popover-inner .flex,
  .ant-drawer .flex,
  .ant-dropdown .flex,
  .ant-notification .flex {
    display: flex;
    align-items: center; }
  .moby__layout .align-item-start,
  .ant-modal-content .align-item-start,
  .ant-popover-inner .align-item-start,
  .ant-drawer .align-item-start,
  .ant-dropdown .align-item-start,
  .ant-notification .align-item-start {
    align-items: flex-start; }
  .moby__layout .align-item-end,
  .ant-modal-content .align-item-end,
  .ant-popover-inner .align-item-end,
  .ant-drawer .align-item-end,
  .ant-dropdown .align-item-end,
  .ant-notification .align-item-end {
    align-items: flex-end; }
  .moby__layout .align-item-normal,
  .ant-modal-content .align-item-normal,
  .ant-popover-inner .align-item-normal,
  .ant-drawer .align-item-normal,
  .ant-dropdown .align-item-normal,
  .ant-notification .align-item-normal {
    align-items: normal; }
  .moby__layout .justify-flex-start,
  .ant-modal-content .justify-flex-start,
  .ant-popover-inner .justify-flex-start,
  .ant-drawer .justify-flex-start,
  .ant-dropdown .justify-flex-start,
  .ant-notification .justify-flex-start {
    justify-content: flex-start; }
  .moby__layout .justify-flex-end,
  .ant-modal-content .justify-flex-end,
  .ant-popover-inner .justify-flex-end,
  .ant-drawer .justify-flex-end,
  .ant-dropdown .justify-flex-end,
  .ant-notification .justify-flex-end {
    justify-content: flex-end; }
  .moby__layout .justify-center,
  .ant-modal-content .justify-center,
  .ant-popover-inner .justify-center,
  .ant-drawer .justify-center,
  .ant-dropdown .justify-center,
  .ant-notification .justify-center {
    justify-content: center; }
  .moby__layout .justify-space-between,
  .ant-modal-content .justify-space-between,
  .ant-popover-inner .justify-space-between,
  .ant-drawer .justify-space-between,
  .ant-dropdown .justify-space-between,
  .ant-notification .justify-space-between {
    justify-content: space-between; }
  .moby__layout .justify-space-around,
  .ant-modal-content .justify-space-around,
  .ant-popover-inner .justify-space-around,
  .ant-drawer .justify-space-around,
  .ant-dropdown .justify-space-around,
  .ant-notification .justify-space-around {
    justify-content: space-around; }
  .moby__layout .flex-flow,
  .ant-modal-content .flex-flow,
  .ant-popover-inner .flex-flow,
  .ant-drawer .flex-flow,
  .ant-dropdown .flex-flow,
  .ant-notification .flex-flow {
    flex-flow: row wrap; }
  .moby__layout .flex-direction-row,
  .ant-modal-content .flex-direction-row,
  .ant-popover-inner .flex-direction-row,
  .ant-drawer .flex-direction-row,
  .ant-dropdown .flex-direction-row,
  .ant-notification .flex-direction-row {
    flex-direction: row; }
  .moby__layout .flex-direction-column,
  .ant-modal-content .flex-direction-column,
  .ant-popover-inner .flex-direction-column,
  .ant-drawer .flex-direction-column,
  .ant-dropdown .flex-direction-column,
  .ant-notification .flex-direction-column {
    flex-direction: column; }
  .moby__layout .d-block,
  .ant-modal-content .d-block,
  .ant-popover-inner .d-block,
  .ant-drawer .d-block,
  .ant-dropdown .d-block,
  .ant-notification .d-block {
    display: block; }
  .moby__layout .d-none,
  .ant-modal-content .d-none,
  .ant-popover-inner .d-none,
  .ant-drawer .d-none,
  .ant-dropdown .d-none,
  .ant-notification .d-none {
    display: none; }
  .moby__layout .d-inline-block,
  .ant-modal-content .d-inline-block,
  .ant-popover-inner .d-inline-block,
  .ant-drawer .d-inline-block,
  .ant-dropdown .d-inline-block,
  .ant-notification .d-inline-block {
    display: inline-block; }
  .moby__layout .text-white,
  .ant-modal-content .text-white,
  .ant-popover-inner .text-white,
  .ant-drawer .text-white,
  .ant-dropdown .text-white,
  .ant-notification .text-white {
    color: white; }
  .moby__layout .text-black,
  .ant-modal-content .text-black,
  .ant-popover-inner .text-black,
  .ant-drawer .text-black,
  .ant-dropdown .text-black,
  .ant-notification .text-black {
    color: #202124; }
  .moby__layout .text-normal,
  .ant-modal-content .text-normal,
  .ant-popover-inner .text-normal,
  .ant-drawer .text-normal,
  .ant-dropdown .text-normal,
  .ant-notification .text-normal {
    color: #96a5ab; }
  .moby__layout .text-danger,
  .ant-modal-content .text-danger,
  .ant-popover-inner .text-danger,
  .ant-drawer .text-danger,
  .ant-dropdown .text-danger,
  .ant-notification .text-danger {
    color: #ea4335; }
  .moby__layout .text-primary,
  .ant-modal-content .text-primary,
  .ant-popover-inner .text-primary,
  .ant-drawer .text-primary,
  .ant-dropdown .text-primary,
  .ant-notification .text-primary {
    color: #DBA44F; }
  .moby__layout .text-success,
  .ant-modal-content .text-success,
  .ant-popover-inner .text-success,
  .ant-drawer .text-success,
  .ant-dropdown .text-success,
  .ant-notification .text-success {
    color: #34a853; }
  .moby__layout .text-warning,
  .ant-modal-content .text-warning,
  .ant-popover-inner .text-warning,
  .ant-drawer .text-warning,
  .ant-dropdown .text-warning,
  .ant-notification .text-warning {
    color: #fbbc05; }
  .moby__layout .bg-black,
  .ant-modal-content .bg-black,
  .ant-popover-inner .bg-black,
  .ant-drawer .bg-black,
  .ant-dropdown .bg-black,
  .ant-notification .bg-black {
    background: #202124; }
  .moby__layout .bg-base-light,
  .ant-modal-content .bg-base-light,
  .ant-popover-inner .bg-base-light,
  .ant-drawer .bg-base-light,
  .ant-dropdown .bg-base-light,
  .ant-notification .bg-base-light {
    background: #f9fafa; }
  .moby__layout .bg-base,
  .ant-modal-content .bg-base,
  .ant-popover-inner .bg-base,
  .ant-drawer .bg-base,
  .ant-dropdown .bg-base,
  .ant-notification .bg-base {
    background: #f5f7f7; }
  .moby__layout .bg-danger,
  .ant-modal-content .bg-danger,
  .ant-popover-inner .bg-danger,
  .ant-drawer .bg-danger,
  .ant-dropdown .bg-danger,
  .ant-notification .bg-danger {
    background: #ea4335; }
  .moby__layout .bg-primary,
  .ant-modal-content .bg-primary,
  .ant-popover-inner .bg-primary,
  .ant-drawer .bg-primary,
  .ant-dropdown .bg-primary,
  .ant-notification .bg-primary {
    background: #DBA44F; }
  .moby__layout .bg-success,
  .ant-modal-content .bg-success,
  .ant-popover-inner .bg-success,
  .ant-drawer .bg-success,
  .ant-dropdown .bg-success,
  .ant-notification .bg-success {
    background: #34a853; }
  .moby__layout .bg-warning,
  .ant-modal-content .bg-warning,
  .ant-popover-inner .bg-warning,
  .ant-drawer .bg-warning,
  .ant-dropdown .bg-warning,
  .ant-notification .bg-warning {
    background: #fbbc05; }
  .moby__layout .bg-transparent,
  .ant-modal-content .bg-transparent,
  .ant-popover-inner .bg-transparent,
  .ant-drawer .bg-transparent,
  .ant-dropdown .bg-transparent,
  .ant-notification .bg-transparent {
    background: transparent; }
  .moby__layout .ant-btn,
  .ant-modal-content .ant-btn,
  .ant-popover-inner .ant-btn,
  .ant-drawer .ant-btn,
  .ant-dropdown .ant-btn,
  .ant-notification .ant-btn {
    background: #f5f7f7;
    color: #202124; }
    .moby__layout .ant-btn:hover, .moby__layout .ant-btn:focus,
    .ant-modal-content .ant-btn:hover,
    .ant-modal-content .ant-btn:focus,
    .ant-popover-inner .ant-btn:hover,
    .ant-popover-inner .ant-btn:focus,
    .ant-drawer .ant-btn:hover,
    .ant-drawer .ant-btn:focus,
    .ant-dropdown .ant-btn:hover,
    .ant-dropdown .ant-btn:focus,
    .ant-notification .ant-btn:hover,
    .ant-notification .ant-btn:focus {
      background: #f5f7f7;
      border-color: #202124;
      color: #202124;
      opacity: .8;
      box-shadow: none; }
    .moby__layout .ant-btn .anticon,
    .ant-modal-content .ant-btn .anticon,
    .ant-popover-inner .ant-btn .anticon,
    .ant-drawer .ant-btn .anticon,
    .ant-dropdown .ant-btn .anticon,
    .ant-notification .ant-btn .anticon {
      font-size: 14px !important;
      color: #202124 !important; }
    .moby__layout .ant-btn .icon,
    .ant-modal-content .ant-btn .icon,
    .ant-popover-inner .ant-btn .icon,
    .ant-drawer .ant-btn .icon,
    .ant-dropdown .ant-btn .icon,
    .ant-notification .ant-btn .icon {
      font-size: 14px !important;
      color: #202124 !important;
      margin-right: 7px;
      font-weight: 500; }
    .moby__layout .ant-btn.ant-btn-primary,
    .ant-modal-content .ant-btn.ant-btn-primary,
    .ant-popover-inner .ant-btn.ant-btn-primary,
    .ant-drawer .ant-btn.ant-btn-primary,
    .ant-dropdown .ant-btn.ant-btn-primary,
    .ant-notification .ant-btn.ant-btn-primary {
      text-shadow: none;
      box-shadow: none;
      color: white;
      background: #DBA44F;
      border-color: #b87e26; }
      .moby__layout .ant-btn.ant-btn-primary:hover, .moby__layout .ant-btn.ant-btn-primary:focus,
      .ant-modal-content .ant-btn.ant-btn-primary:hover,
      .ant-modal-content .ant-btn.ant-btn-primary:focus,
      .ant-popover-inner .ant-btn.ant-btn-primary:hover,
      .ant-popover-inner .ant-btn.ant-btn-primary:focus,
      .ant-drawer .ant-btn.ant-btn-primary:hover,
      .ant-drawer .ant-btn.ant-btn-primary:focus,
      .ant-dropdown .ant-btn.ant-btn-primary:hover,
      .ant-dropdown .ant-btn.ant-btn-primary:focus,
      .ant-notification .ant-btn.ant-btn-primary:hover,
      .ant-notification .ant-btn.ant-btn-primary:focus {
        border-color: #b87e26;
        color: white; }
      .moby__layout .ant-btn.ant-btn-primary .anticon,
      .ant-modal-content .ant-btn.ant-btn-primary .anticon,
      .ant-popover-inner .ant-btn.ant-btn-primary .anticon,
      .ant-drawer .ant-btn.ant-btn-primary .anticon,
      .ant-dropdown .ant-btn.ant-btn-primary .anticon,
      .ant-notification .ant-btn.ant-btn-primary .anticon {
        color: white !important; }
      .moby__layout .ant-btn.ant-btn-primary .icon,
      .ant-modal-content .ant-btn.ant-btn-primary .icon,
      .ant-popover-inner .ant-btn.ant-btn-primary .icon,
      .ant-drawer .ant-btn.ant-btn-primary .icon,
      .ant-dropdown .ant-btn.ant-btn-primary .icon,
      .ant-notification .ant-btn.ant-btn-primary .icon {
        color: white !important; }
      .moby__layout .ant-btn.ant-btn-primary[disabled],
      .ant-modal-content .ant-btn.ant-btn-primary[disabled],
      .ant-popover-inner .ant-btn.ant-btn-primary[disabled],
      .ant-drawer .ant-btn.ant-btn-primary[disabled],
      .ant-dropdown .ant-btn.ant-btn-primary[disabled],
      .ant-notification .ant-btn.ant-btn-primary[disabled] {
        color: rgba(32, 33, 36, 0.7);
        background: #f9fafa;
        border-color: #e3e8ea; }
        .moby__layout .ant-btn.ant-btn-primary[disabled] .icon,
        .moby__layout .ant-btn.ant-btn-primary[disabled] .anticon,
        .ant-modal-content .ant-btn.ant-btn-primary[disabled] .icon,
        .ant-modal-content .ant-btn.ant-btn-primary[disabled] .anticon,
        .ant-popover-inner .ant-btn.ant-btn-primary[disabled] .icon,
        .ant-popover-inner .ant-btn.ant-btn-primary[disabled] .anticon,
        .ant-drawer .ant-btn.ant-btn-primary[disabled] .icon,
        .ant-drawer .ant-btn.ant-btn-primary[disabled] .anticon,
        .ant-dropdown .ant-btn.ant-btn-primary[disabled] .icon,
        .ant-dropdown .ant-btn.ant-btn-primary[disabled] .anticon,
        .ant-notification .ant-btn.ant-btn-primary[disabled] .icon,
        .ant-notification .ant-btn.ant-btn-primary[disabled] .anticon {
          color: rgba(32, 33, 36, 0.7) !important; }
        .moby__layout .ant-btn.ant-btn-primary[disabled]:hover,
        .ant-modal-content .ant-btn.ant-btn-primary[disabled]:hover,
        .ant-popover-inner .ant-btn.ant-btn-primary[disabled]:hover,
        .ant-drawer .ant-btn.ant-btn-primary[disabled]:hover,
        .ant-dropdown .ant-btn.ant-btn-primary[disabled]:hover,
        .ant-notification .ant-btn.ant-btn-primary[disabled]:hover {
          color: rgba(32, 33, 36, 0.7);
          background: #f9fafa;
          border-color: #e3e8ea; }
          .moby__layout .ant-btn.ant-btn-primary[disabled]:hover .icon,
          .moby__layout .ant-btn.ant-btn-primary[disabled]:hover .anticon,
          .ant-modal-content .ant-btn.ant-btn-primary[disabled]:hover .icon,
          .ant-modal-content .ant-btn.ant-btn-primary[disabled]:hover .anticon,
          .ant-popover-inner .ant-btn.ant-btn-primary[disabled]:hover .icon,
          .ant-popover-inner .ant-btn.ant-btn-primary[disabled]:hover .anticon,
          .ant-drawer .ant-btn.ant-btn-primary[disabled]:hover .icon,
          .ant-drawer .ant-btn.ant-btn-primary[disabled]:hover .anticon,
          .ant-dropdown .ant-btn.ant-btn-primary[disabled]:hover .icon,
          .ant-dropdown .ant-btn.ant-btn-primary[disabled]:hover .anticon,
          .ant-notification .ant-btn.ant-btn-primary[disabled]:hover .icon,
          .ant-notification .ant-btn.ant-btn-primary[disabled]:hover .anticon {
            color: rgba(32, 33, 36, 0.7) !important; }
    .moby__layout .ant-btn.ant-btn-danger,
    .ant-modal-content .ant-btn.ant-btn-danger,
    .ant-popover-inner .ant-btn.ant-btn-danger,
    .ant-drawer .ant-btn.ant-btn-danger,
    .ant-dropdown .ant-btn.ant-btn-danger,
    .ant-notification .ant-btn.ant-btn-danger {
      text-shadow: none;
      box-shadow: none;
      color: white;
      background: #ea4335;
      border-color: #bf2114; }
      .moby__layout .ant-btn.ant-btn-danger:hover, .moby__layout .ant-btn.ant-btn-danger:focus,
      .ant-modal-content .ant-btn.ant-btn-danger:hover,
      .ant-modal-content .ant-btn.ant-btn-danger:focus,
      .ant-popover-inner .ant-btn.ant-btn-danger:hover,
      .ant-popover-inner .ant-btn.ant-btn-danger:focus,
      .ant-drawer .ant-btn.ant-btn-danger:hover,
      .ant-drawer .ant-btn.ant-btn-danger:focus,
      .ant-dropdown .ant-btn.ant-btn-danger:hover,
      .ant-dropdown .ant-btn.ant-btn-danger:focus,
      .ant-notification .ant-btn.ant-btn-danger:hover,
      .ant-notification .ant-btn.ant-btn-danger:focus {
        border-color: #bf2114;
        color: white; }
      .moby__layout .ant-btn.ant-btn-danger .anticon,
      .ant-modal-content .ant-btn.ant-btn-danger .anticon,
      .ant-popover-inner .ant-btn.ant-btn-danger .anticon,
      .ant-drawer .ant-btn.ant-btn-danger .anticon,
      .ant-dropdown .ant-btn.ant-btn-danger .anticon,
      .ant-notification .ant-btn.ant-btn-danger .anticon {
        color: white !important; }
      .moby__layout .ant-btn.ant-btn-danger .icon,
      .ant-modal-content .ant-btn.ant-btn-danger .icon,
      .ant-popover-inner .ant-btn.ant-btn-danger .icon,
      .ant-drawer .ant-btn.ant-btn-danger .icon,
      .ant-dropdown .ant-btn.ant-btn-danger .icon,
      .ant-notification .ant-btn.ant-btn-danger .icon {
        color: white !important; }
      .moby__layout .ant-btn.ant-btn-danger[disabled],
      .ant-modal-content .ant-btn.ant-btn-danger[disabled],
      .ant-popover-inner .ant-btn.ant-btn-danger[disabled],
      .ant-drawer .ant-btn.ant-btn-danger[disabled],
      .ant-dropdown .ant-btn.ant-btn-danger[disabled],
      .ant-notification .ant-btn.ant-btn-danger[disabled] {
        color: rgba(32, 33, 36, 0.7);
        background: #f9fafa;
        border-color: #e3e8ea; }
        .moby__layout .ant-btn.ant-btn-danger[disabled] .icon,
        .moby__layout .ant-btn.ant-btn-danger[disabled] .anticon,
        .ant-modal-content .ant-btn.ant-btn-danger[disabled] .icon,
        .ant-modal-content .ant-btn.ant-btn-danger[disabled] .anticon,
        .ant-popover-inner .ant-btn.ant-btn-danger[disabled] .icon,
        .ant-popover-inner .ant-btn.ant-btn-danger[disabled] .anticon,
        .ant-drawer .ant-btn.ant-btn-danger[disabled] .icon,
        .ant-drawer .ant-btn.ant-btn-danger[disabled] .anticon,
        .ant-dropdown .ant-btn.ant-btn-danger[disabled] .icon,
        .ant-dropdown .ant-btn.ant-btn-danger[disabled] .anticon,
        .ant-notification .ant-btn.ant-btn-danger[disabled] .icon,
        .ant-notification .ant-btn.ant-btn-danger[disabled] .anticon {
          color: rgba(32, 33, 36, 0.7) !important; }
        .moby__layout .ant-btn.ant-btn-danger[disabled]:hover,
        .ant-modal-content .ant-btn.ant-btn-danger[disabled]:hover,
        .ant-popover-inner .ant-btn.ant-btn-danger[disabled]:hover,
        .ant-drawer .ant-btn.ant-btn-danger[disabled]:hover,
        .ant-dropdown .ant-btn.ant-btn-danger[disabled]:hover,
        .ant-notification .ant-btn.ant-btn-danger[disabled]:hover {
          color: rgba(32, 33, 36, 0.7);
          background: #f9fafa;
          border-color: #e3e8ea; }
          .moby__layout .ant-btn.ant-btn-danger[disabled]:hover .icon,
          .moby__layout .ant-btn.ant-btn-danger[disabled]:hover .anticon,
          .ant-modal-content .ant-btn.ant-btn-danger[disabled]:hover .icon,
          .ant-modal-content .ant-btn.ant-btn-danger[disabled]:hover .anticon,
          .ant-popover-inner .ant-btn.ant-btn-danger[disabled]:hover .icon,
          .ant-popover-inner .ant-btn.ant-btn-danger[disabled]:hover .anticon,
          .ant-drawer .ant-btn.ant-btn-danger[disabled]:hover .icon,
          .ant-drawer .ant-btn.ant-btn-danger[disabled]:hover .anticon,
          .ant-dropdown .ant-btn.ant-btn-danger[disabled]:hover .icon,
          .ant-dropdown .ant-btn.ant-btn-danger[disabled]:hover .anticon,
          .ant-notification .ant-btn.ant-btn-danger[disabled]:hover .icon,
          .ant-notification .ant-btn.ant-btn-danger[disabled]:hover .anticon {
            color: rgba(32, 33, 36, 0.7) !important; }
    .moby__layout .ant-btn.ant-btn-success,
    .ant-modal-content .ant-btn.ant-btn-success,
    .ant-popover-inner .ant-btn.ant-btn-success,
    .ant-drawer .ant-btn.ant-btn-success,
    .ant-dropdown .ant-btn.ant-btn-success,
    .ant-notification .ant-btn.ant-btn-success {
      text-shadow: none;
      box-shadow: none;
      color: white;
      background: #34a853;
      border-color: #226e36; }
      .moby__layout .ant-btn.ant-btn-success:hover, .moby__layout .ant-btn.ant-btn-success:focus,
      .ant-modal-content .ant-btn.ant-btn-success:hover,
      .ant-modal-content .ant-btn.ant-btn-success:focus,
      .ant-popover-inner .ant-btn.ant-btn-success:hover,
      .ant-popover-inner .ant-btn.ant-btn-success:focus,
      .ant-drawer .ant-btn.ant-btn-success:hover,
      .ant-drawer .ant-btn.ant-btn-success:focus,
      .ant-dropdown .ant-btn.ant-btn-success:hover,
      .ant-dropdown .ant-btn.ant-btn-success:focus,
      .ant-notification .ant-btn.ant-btn-success:hover,
      .ant-notification .ant-btn.ant-btn-success:focus {
        border-color: #226e36;
        color: white; }
      .moby__layout .ant-btn.ant-btn-success .anticon,
      .ant-modal-content .ant-btn.ant-btn-success .anticon,
      .ant-popover-inner .ant-btn.ant-btn-success .anticon,
      .ant-drawer .ant-btn.ant-btn-success .anticon,
      .ant-dropdown .ant-btn.ant-btn-success .anticon,
      .ant-notification .ant-btn.ant-btn-success .anticon {
        color: white !important; }
      .moby__layout .ant-btn.ant-btn-success .icon,
      .ant-modal-content .ant-btn.ant-btn-success .icon,
      .ant-popover-inner .ant-btn.ant-btn-success .icon,
      .ant-drawer .ant-btn.ant-btn-success .icon,
      .ant-dropdown .ant-btn.ant-btn-success .icon,
      .ant-notification .ant-btn.ant-btn-success .icon {
        color: white !important; }
      .moby__layout .ant-btn.ant-btn-success[disabled],
      .ant-modal-content .ant-btn.ant-btn-success[disabled],
      .ant-popover-inner .ant-btn.ant-btn-success[disabled],
      .ant-drawer .ant-btn.ant-btn-success[disabled],
      .ant-dropdown .ant-btn.ant-btn-success[disabled],
      .ant-notification .ant-btn.ant-btn-success[disabled] {
        color: rgba(32, 33, 36, 0.7);
        background: #f9fafa;
        border-color: #e3e8ea; }
        .moby__layout .ant-btn.ant-btn-success[disabled] .icon,
        .moby__layout .ant-btn.ant-btn-success[disabled] .anticon,
        .ant-modal-content .ant-btn.ant-btn-success[disabled] .icon,
        .ant-modal-content .ant-btn.ant-btn-success[disabled] .anticon,
        .ant-popover-inner .ant-btn.ant-btn-success[disabled] .icon,
        .ant-popover-inner .ant-btn.ant-btn-success[disabled] .anticon,
        .ant-drawer .ant-btn.ant-btn-success[disabled] .icon,
        .ant-drawer .ant-btn.ant-btn-success[disabled] .anticon,
        .ant-dropdown .ant-btn.ant-btn-success[disabled] .icon,
        .ant-dropdown .ant-btn.ant-btn-success[disabled] .anticon,
        .ant-notification .ant-btn.ant-btn-success[disabled] .icon,
        .ant-notification .ant-btn.ant-btn-success[disabled] .anticon {
          color: rgba(32, 33, 36, 0.7) !important; }
        .moby__layout .ant-btn.ant-btn-success[disabled]:hover,
        .ant-modal-content .ant-btn.ant-btn-success[disabled]:hover,
        .ant-popover-inner .ant-btn.ant-btn-success[disabled]:hover,
        .ant-drawer .ant-btn.ant-btn-success[disabled]:hover,
        .ant-dropdown .ant-btn.ant-btn-success[disabled]:hover,
        .ant-notification .ant-btn.ant-btn-success[disabled]:hover {
          color: rgba(32, 33, 36, 0.7);
          background: #f9fafa;
          border-color: #e3e8ea; }
          .moby__layout .ant-btn.ant-btn-success[disabled]:hover .icon,
          .moby__layout .ant-btn.ant-btn-success[disabled]:hover .anticon,
          .ant-modal-content .ant-btn.ant-btn-success[disabled]:hover .icon,
          .ant-modal-content .ant-btn.ant-btn-success[disabled]:hover .anticon,
          .ant-popover-inner .ant-btn.ant-btn-success[disabled]:hover .icon,
          .ant-popover-inner .ant-btn.ant-btn-success[disabled]:hover .anticon,
          .ant-drawer .ant-btn.ant-btn-success[disabled]:hover .icon,
          .ant-drawer .ant-btn.ant-btn-success[disabled]:hover .anticon,
          .ant-dropdown .ant-btn.ant-btn-success[disabled]:hover .icon,
          .ant-dropdown .ant-btn.ant-btn-success[disabled]:hover .anticon,
          .ant-notification .ant-btn.ant-btn-success[disabled]:hover .icon,
          .ant-notification .ant-btn.ant-btn-success[disabled]:hover .anticon {
            color: rgba(32, 33, 36, 0.7) !important; }
    .moby__layout .ant-btn.ant-btn-warning,
    .ant-modal-content .ant-btn.ant-btn-warning,
    .ant-popover-inner .ant-btn.ant-btn-warning,
    .ant-drawer .ant-btn.ant-btn-warning,
    .ant-dropdown .ant-btn.ant-btn-warning,
    .ant-notification .ant-btn.ant-btn-warning {
      text-shadow: none;
      box-shadow: none;
      color: white;
      background: #fbbc05;
      border-color: #b18403; }
      .moby__layout .ant-btn.ant-btn-warning:hover, .moby__layout .ant-btn.ant-btn-warning:focus,
      .ant-modal-content .ant-btn.ant-btn-warning:hover,
      .ant-modal-content .ant-btn.ant-btn-warning:focus,
      .ant-popover-inner .ant-btn.ant-btn-warning:hover,
      .ant-popover-inner .ant-btn.ant-btn-warning:focus,
      .ant-drawer .ant-btn.ant-btn-warning:hover,
      .ant-drawer .ant-btn.ant-btn-warning:focus,
      .ant-dropdown .ant-btn.ant-btn-warning:hover,
      .ant-dropdown .ant-btn.ant-btn-warning:focus,
      .ant-notification .ant-btn.ant-btn-warning:hover,
      .ant-notification .ant-btn.ant-btn-warning:focus {
        border-color: #b18403;
        color: white; }
      .moby__layout .ant-btn.ant-btn-warning .anticon,
      .ant-modal-content .ant-btn.ant-btn-warning .anticon,
      .ant-popover-inner .ant-btn.ant-btn-warning .anticon,
      .ant-drawer .ant-btn.ant-btn-warning .anticon,
      .ant-dropdown .ant-btn.ant-btn-warning .anticon,
      .ant-notification .ant-btn.ant-btn-warning .anticon {
        color: white !important; }
      .moby__layout .ant-btn.ant-btn-warning .icon,
      .ant-modal-content .ant-btn.ant-btn-warning .icon,
      .ant-popover-inner .ant-btn.ant-btn-warning .icon,
      .ant-drawer .ant-btn.ant-btn-warning .icon,
      .ant-dropdown .ant-btn.ant-btn-warning .icon,
      .ant-notification .ant-btn.ant-btn-warning .icon {
        color: white !important; }
      .moby__layout .ant-btn.ant-btn-warning[disabled],
      .ant-modal-content .ant-btn.ant-btn-warning[disabled],
      .ant-popover-inner .ant-btn.ant-btn-warning[disabled],
      .ant-drawer .ant-btn.ant-btn-warning[disabled],
      .ant-dropdown .ant-btn.ant-btn-warning[disabled],
      .ant-notification .ant-btn.ant-btn-warning[disabled] {
        color: rgba(32, 33, 36, 0.7);
        background: #f9fafa;
        border-color: #e3e8ea; }
        .moby__layout .ant-btn.ant-btn-warning[disabled] .icon,
        .moby__layout .ant-btn.ant-btn-warning[disabled] .anticon,
        .ant-modal-content .ant-btn.ant-btn-warning[disabled] .icon,
        .ant-modal-content .ant-btn.ant-btn-warning[disabled] .anticon,
        .ant-popover-inner .ant-btn.ant-btn-warning[disabled] .icon,
        .ant-popover-inner .ant-btn.ant-btn-warning[disabled] .anticon,
        .ant-drawer .ant-btn.ant-btn-warning[disabled] .icon,
        .ant-drawer .ant-btn.ant-btn-warning[disabled] .anticon,
        .ant-dropdown .ant-btn.ant-btn-warning[disabled] .icon,
        .ant-dropdown .ant-btn.ant-btn-warning[disabled] .anticon,
        .ant-notification .ant-btn.ant-btn-warning[disabled] .icon,
        .ant-notification .ant-btn.ant-btn-warning[disabled] .anticon {
          color: rgba(32, 33, 36, 0.7) !important; }
        .moby__layout .ant-btn.ant-btn-warning[disabled]:hover,
        .ant-modal-content .ant-btn.ant-btn-warning[disabled]:hover,
        .ant-popover-inner .ant-btn.ant-btn-warning[disabled]:hover,
        .ant-drawer .ant-btn.ant-btn-warning[disabled]:hover,
        .ant-dropdown .ant-btn.ant-btn-warning[disabled]:hover,
        .ant-notification .ant-btn.ant-btn-warning[disabled]:hover {
          color: rgba(32, 33, 36, 0.7);
          background: #f9fafa;
          border-color: #e3e8ea; }
          .moby__layout .ant-btn.ant-btn-warning[disabled]:hover .icon,
          .moby__layout .ant-btn.ant-btn-warning[disabled]:hover .anticon,
          .ant-modal-content .ant-btn.ant-btn-warning[disabled]:hover .icon,
          .ant-modal-content .ant-btn.ant-btn-warning[disabled]:hover .anticon,
          .ant-popover-inner .ant-btn.ant-btn-warning[disabled]:hover .icon,
          .ant-popover-inner .ant-btn.ant-btn-warning[disabled]:hover .anticon,
          .ant-drawer .ant-btn.ant-btn-warning[disabled]:hover .icon,
          .ant-drawer .ant-btn.ant-btn-warning[disabled]:hover .anticon,
          .ant-dropdown .ant-btn.ant-btn-warning[disabled]:hover .icon,
          .ant-dropdown .ant-btn.ant-btn-warning[disabled]:hover .anticon,
          .ant-notification .ant-btn.ant-btn-warning[disabled]:hover .icon,
          .ant-notification .ant-btn.ant-btn-warning[disabled]:hover .anticon {
            color: rgba(32, 33, 36, 0.7) !important; }
    .moby__layout .ant-btn.ant-btn-boxshadow,
    .ant-modal-content .ant-btn.ant-btn-boxshadow,
    .ant-popover-inner .ant-btn.ant-btn-boxshadow,
    .ant-drawer .ant-btn.ant-btn-boxshadow,
    .ant-dropdown .ant-btn.ant-btn-boxshadow,
    .ant-notification .ant-btn.ant-btn-boxshadow {
      box-shadow: 0 7px 17px -6px rgba(0, 0, 0, 0.3);
      transition: all ease-in-out .6s; }
      .moby__layout .ant-btn.ant-btn-boxshadow:hover, .moby__layout .ant-btn.ant-btn-boxshadow:focus,
      .ant-modal-content .ant-btn.ant-btn-boxshadow:hover,
      .ant-modal-content .ant-btn.ant-btn-boxshadow:focus,
      .ant-popover-inner .ant-btn.ant-btn-boxshadow:hover,
      .ant-popover-inner .ant-btn.ant-btn-boxshadow:focus,
      .ant-drawer .ant-btn.ant-btn-boxshadow:hover,
      .ant-drawer .ant-btn.ant-btn-boxshadow:focus,
      .ant-dropdown .ant-btn.ant-btn-boxshadow:hover,
      .ant-dropdown .ant-btn.ant-btn-boxshadow:focus,
      .ant-notification .ant-btn.ant-btn-boxshadow:hover,
      .ant-notification .ant-btn.ant-btn-boxshadow:focus {
        box-shadow: none; }
    .moby__layout .ant-btn.ant-btn-boxshadow-danger,
    .ant-modal-content .ant-btn.ant-btn-boxshadow-danger,
    .ant-popover-inner .ant-btn.ant-btn-boxshadow-danger,
    .ant-drawer .ant-btn.ant-btn-boxshadow-danger,
    .ant-dropdown .ant-btn.ant-btn-boxshadow-danger,
    .ant-notification .ant-btn.ant-btn-boxshadow-danger {
      box-shadow: 0 7px 17px -6px rgba(234, 67, 53, 0.7);
      transition: all ease-in-out .6s; }
      .moby__layout .ant-btn.ant-btn-boxshadow-danger:hover, .moby__layout .ant-btn.ant-btn-boxshadow-danger:focus,
      .ant-modal-content .ant-btn.ant-btn-boxshadow-danger:hover,
      .ant-modal-content .ant-btn.ant-btn-boxshadow-danger:focus,
      .ant-popover-inner .ant-btn.ant-btn-boxshadow-danger:hover,
      .ant-popover-inner .ant-btn.ant-btn-boxshadow-danger:focus,
      .ant-drawer .ant-btn.ant-btn-boxshadow-danger:hover,
      .ant-drawer .ant-btn.ant-btn-boxshadow-danger:focus,
      .ant-dropdown .ant-btn.ant-btn-boxshadow-danger:hover,
      .ant-dropdown .ant-btn.ant-btn-boxshadow-danger:focus,
      .ant-notification .ant-btn.ant-btn-boxshadow-danger:hover,
      .ant-notification .ant-btn.ant-btn-boxshadow-danger:focus {
        box-shadow: none; }
    .moby__layout .ant-btn.ant-btn-boxshadow-primary,
    .ant-modal-content .ant-btn.ant-btn-boxshadow-primary,
    .ant-popover-inner .ant-btn.ant-btn-boxshadow-primary,
    .ant-drawer .ant-btn.ant-btn-boxshadow-primary,
    .ant-dropdown .ant-btn.ant-btn-boxshadow-primary,
    .ant-notification .ant-btn.ant-btn-boxshadow-primary {
      box-shadow: 0 7px 17px -6px rgba(219, 164, 79, 0.7);
      transition: all ease-in-out .6s; }
      .moby__layout .ant-btn.ant-btn-boxshadow-primary:hover, .moby__layout .ant-btn.ant-btn-boxshadow-primary:focus,
      .ant-modal-content .ant-btn.ant-btn-boxshadow-primary:hover,
      .ant-modal-content .ant-btn.ant-btn-boxshadow-primary:focus,
      .ant-popover-inner .ant-btn.ant-btn-boxshadow-primary:hover,
      .ant-popover-inner .ant-btn.ant-btn-boxshadow-primary:focus,
      .ant-drawer .ant-btn.ant-btn-boxshadow-primary:hover,
      .ant-drawer .ant-btn.ant-btn-boxshadow-primary:focus,
      .ant-dropdown .ant-btn.ant-btn-boxshadow-primary:hover,
      .ant-dropdown .ant-btn.ant-btn-boxshadow-primary:focus,
      .ant-notification .ant-btn.ant-btn-boxshadow-primary:hover,
      .ant-notification .ant-btn.ant-btn-boxshadow-primary:focus {
        box-shadow: none; }
    .moby__layout .ant-btn.ant-btn-boxshadow-warning,
    .ant-modal-content .ant-btn.ant-btn-boxshadow-warning,
    .ant-popover-inner .ant-btn.ant-btn-boxshadow-warning,
    .ant-drawer .ant-btn.ant-btn-boxshadow-warning,
    .ant-dropdown .ant-btn.ant-btn-boxshadow-warning,
    .ant-notification .ant-btn.ant-btn-boxshadow-warning {
      box-shadow: 0 7px 17px -6px rgba(251, 188, 5, 0.7);
      transition: all ease-in-out .6s; }
      .moby__layout .ant-btn.ant-btn-boxshadow-warning:hover, .moby__layout .ant-btn.ant-btn-boxshadow-warning:focus,
      .ant-modal-content .ant-btn.ant-btn-boxshadow-warning:hover,
      .ant-modal-content .ant-btn.ant-btn-boxshadow-warning:focus,
      .ant-popover-inner .ant-btn.ant-btn-boxshadow-warning:hover,
      .ant-popover-inner .ant-btn.ant-btn-boxshadow-warning:focus,
      .ant-drawer .ant-btn.ant-btn-boxshadow-warning:hover,
      .ant-drawer .ant-btn.ant-btn-boxshadow-warning:focus,
      .ant-dropdown .ant-btn.ant-btn-boxshadow-warning:hover,
      .ant-dropdown .ant-btn.ant-btn-boxshadow-warning:focus,
      .ant-notification .ant-btn.ant-btn-boxshadow-warning:hover,
      .ant-notification .ant-btn.ant-btn-boxshadow-warning:focus {
        box-shadow: none; }
    .moby__layout .ant-btn.ant-btn-boxshadow-success,
    .ant-modal-content .ant-btn.ant-btn-boxshadow-success,
    .ant-popover-inner .ant-btn.ant-btn-boxshadow-success,
    .ant-drawer .ant-btn.ant-btn-boxshadow-success,
    .ant-dropdown .ant-btn.ant-btn-boxshadow-success,
    .ant-notification .ant-btn.ant-btn-boxshadow-success {
      box-shadow: 0 7px 17px -6px rgba(52, 168, 83, 0.7);
      transition: all ease-in-out .6s; }
      .moby__layout .ant-btn.ant-btn-boxshadow-success:hover, .moby__layout .ant-btn.ant-btn-boxshadow-success:focus,
      .ant-modal-content .ant-btn.ant-btn-boxshadow-success:hover,
      .ant-modal-content .ant-btn.ant-btn-boxshadow-success:focus,
      .ant-popover-inner .ant-btn.ant-btn-boxshadow-success:hover,
      .ant-popover-inner .ant-btn.ant-btn-boxshadow-success:focus,
      .ant-drawer .ant-btn.ant-btn-boxshadow-success:hover,
      .ant-drawer .ant-btn.ant-btn-boxshadow-success:focus,
      .ant-dropdown .ant-btn.ant-btn-boxshadow-success:hover,
      .ant-dropdown .ant-btn.ant-btn-boxshadow-success:focus,
      .ant-notification .ant-btn.ant-btn-boxshadow-success:hover,
      .ant-notification .ant-btn.ant-btn-boxshadow-success:focus {
        box-shadow: none; }
    .moby__layout .ant-btn.ant-btn-link,
    .ant-modal-content .ant-btn.ant-btn-link,
    .ant-popover-inner .ant-btn.ant-btn-link,
    .ant-drawer .ant-btn.ant-btn-link,
    .ant-dropdown .ant-btn.ant-btn-link,
    .ant-notification .ant-btn.ant-btn-link {
      text-shadow: none;
      box-shadow: none;
      color: #DBA44F;
      background: transparent;
      border-color: rgba(0, 0, 0, 0); }
      .moby__layout .ant-btn.ant-btn-link:hover, .moby__layout .ant-btn.ant-btn-link:focus,
      .ant-modal-content .ant-btn.ant-btn-link:hover,
      .ant-modal-content .ant-btn.ant-btn-link:focus,
      .ant-popover-inner .ant-btn.ant-btn-link:hover,
      .ant-popover-inner .ant-btn.ant-btn-link:focus,
      .ant-drawer .ant-btn.ant-btn-link:hover,
      .ant-drawer .ant-btn.ant-btn-link:focus,
      .ant-dropdown .ant-btn.ant-btn-link:hover,
      .ant-dropdown .ant-btn.ant-btn-link:focus,
      .ant-notification .ant-btn.ant-btn-link:hover,
      .ant-notification .ant-btn.ant-btn-link:focus {
        border-color: rgba(0, 0, 0, 0);
        color: #DBA44F; }
      .moby__layout .ant-btn.ant-btn-link .anticon,
      .ant-modal-content .ant-btn.ant-btn-link .anticon,
      .ant-popover-inner .ant-btn.ant-btn-link .anticon,
      .ant-drawer .ant-btn.ant-btn-link .anticon,
      .ant-dropdown .ant-btn.ant-btn-link .anticon,
      .ant-notification .ant-btn.ant-btn-link .anticon {
        color: #DBA44F !important; }
      .moby__layout .ant-btn.ant-btn-link .icon,
      .ant-modal-content .ant-btn.ant-btn-link .icon,
      .ant-popover-inner .ant-btn.ant-btn-link .icon,
      .ant-drawer .ant-btn.ant-btn-link .icon,
      .ant-dropdown .ant-btn.ant-btn-link .icon,
      .ant-notification .ant-btn.ant-btn-link .icon {
        color: #DBA44F !important; }
      .moby__layout .ant-btn.ant-btn-link[disabled],
      .ant-modal-content .ant-btn.ant-btn-link[disabled],
      .ant-popover-inner .ant-btn.ant-btn-link[disabled],
      .ant-drawer .ant-btn.ant-btn-link[disabled],
      .ant-dropdown .ant-btn.ant-btn-link[disabled],
      .ant-notification .ant-btn.ant-btn-link[disabled] {
        color: rgba(32, 33, 36, 0.7);
        background: #f9fafa;
        border-color: #e3e8ea; }
        .moby__layout .ant-btn.ant-btn-link[disabled] .icon,
        .moby__layout .ant-btn.ant-btn-link[disabled] .anticon,
        .ant-modal-content .ant-btn.ant-btn-link[disabled] .icon,
        .ant-modal-content .ant-btn.ant-btn-link[disabled] .anticon,
        .ant-popover-inner .ant-btn.ant-btn-link[disabled] .icon,
        .ant-popover-inner .ant-btn.ant-btn-link[disabled] .anticon,
        .ant-drawer .ant-btn.ant-btn-link[disabled] .icon,
        .ant-drawer .ant-btn.ant-btn-link[disabled] .anticon,
        .ant-dropdown .ant-btn.ant-btn-link[disabled] .icon,
        .ant-dropdown .ant-btn.ant-btn-link[disabled] .anticon,
        .ant-notification .ant-btn.ant-btn-link[disabled] .icon,
        .ant-notification .ant-btn.ant-btn-link[disabled] .anticon {
          color: rgba(32, 33, 36, 0.7) !important; }
        .moby__layout .ant-btn.ant-btn-link[disabled]:hover,
        .ant-modal-content .ant-btn.ant-btn-link[disabled]:hover,
        .ant-popover-inner .ant-btn.ant-btn-link[disabled]:hover,
        .ant-drawer .ant-btn.ant-btn-link[disabled]:hover,
        .ant-dropdown .ant-btn.ant-btn-link[disabled]:hover,
        .ant-notification .ant-btn.ant-btn-link[disabled]:hover {
          color: rgba(32, 33, 36, 0.7);
          background: #f9fafa;
          border-color: #e3e8ea; }
          .moby__layout .ant-btn.ant-btn-link[disabled]:hover .icon,
          .moby__layout .ant-btn.ant-btn-link[disabled]:hover .anticon,
          .ant-modal-content .ant-btn.ant-btn-link[disabled]:hover .icon,
          .ant-modal-content .ant-btn.ant-btn-link[disabled]:hover .anticon,
          .ant-popover-inner .ant-btn.ant-btn-link[disabled]:hover .icon,
          .ant-popover-inner .ant-btn.ant-btn-link[disabled]:hover .anticon,
          .ant-drawer .ant-btn.ant-btn-link[disabled]:hover .icon,
          .ant-drawer .ant-btn.ant-btn-link[disabled]:hover .anticon,
          .ant-dropdown .ant-btn.ant-btn-link[disabled]:hover .icon,
          .ant-dropdown .ant-btn.ant-btn-link[disabled]:hover .anticon,
          .ant-notification .ant-btn.ant-btn-link[disabled]:hover .icon,
          .ant-notification .ant-btn.ant-btn-link[disabled]:hover .anticon {
            color: rgba(32, 33, 36, 0.7) !important; }
    .moby__layout .ant-btn.ant-btn-icon-only,
    .ant-modal-content .ant-btn.ant-btn-icon-only,
    .ant-popover-inner .ant-btn.ant-btn-icon-only,
    .ant-drawer .ant-btn.ant-btn-icon-only,
    .ant-dropdown .ant-btn.ant-btn-icon-only,
    .ant-notification .ant-btn.ant-btn-icon-only {
      width: 32px;
      height: 32px;
      padding: 0; }
    .moby__layout .ant-btn.ant-btn-round,
    .ant-modal-content .ant-btn.ant-btn-round,
    .ant-popover-inner .ant-btn.ant-btn-round,
    .ant-drawer .ant-btn.ant-btn-round,
    .ant-dropdown .ant-btn.ant-btn-round,
    .ant-notification .ant-btn.ant-btn-round {
      border-radius: 30px;
      padding: 0 13px; }
      .moby__layout .ant-btn.ant-btn-round.ant-btn-icon-only,
      .ant-modal-content .ant-btn.ant-btn-round.ant-btn-icon-only,
      .ant-popover-inner .ant-btn.ant-btn-round.ant-btn-icon-only,
      .ant-drawer .ant-btn.ant-btn-round.ant-btn-icon-only,
      .ant-dropdown .ant-btn.ant-btn-round.ant-btn-icon-only,
      .ant-notification .ant-btn.ant-btn-round.ant-btn-icon-only {
        width: auto; }
    .moby__layout .ant-btn.ant-btn-circle,
    .ant-modal-content .ant-btn.ant-btn-circle,
    .ant-popover-inner .ant-btn.ant-btn-circle,
    .ant-drawer .ant-btn.ant-btn-circle,
    .ant-dropdown .ant-btn.ant-btn-circle,
    .ant-notification .ant-btn.ant-btn-circle {
      border-radius: 50%;
      line-height: 32px;
      text-align: center;
      padding: 0; }
      .moby__layout .ant-btn.ant-btn-circle .icon,
      .ant-modal-content .ant-btn.ant-btn-circle .icon,
      .ant-popover-inner .ant-btn.ant-btn-circle .icon,
      .ant-drawer .ant-btn.ant-btn-circle .icon,
      .ant-dropdown .ant-btn.ant-btn-circle .icon,
      .ant-notification .ant-btn.ant-btn-circle .icon {
        margin-right: 0; }
    .moby__layout .ant-btn.ant-btn-background-ghost,
    .ant-modal-content .ant-btn.ant-btn-background-ghost,
    .ant-popover-inner .ant-btn.ant-btn-background-ghost,
    .ant-drawer .ant-btn.ant-btn-background-ghost,
    .ant-dropdown .ant-btn.ant-btn-background-ghost,
    .ant-notification .ant-btn.ant-btn-background-ghost {
      border-color: #202124;
      color: #202124; }
      .moby__layout .ant-btn.ant-btn-background-ghost .icon,
      .moby__layout .ant-btn.ant-btn-background-ghost .anticon,
      .ant-modal-content .ant-btn.ant-btn-background-ghost .icon,
      .ant-modal-content .ant-btn.ant-btn-background-ghost .anticon,
      .ant-popover-inner .ant-btn.ant-btn-background-ghost .icon,
      .ant-popover-inner .ant-btn.ant-btn-background-ghost .anticon,
      .ant-drawer .ant-btn.ant-btn-background-ghost .icon,
      .ant-drawer .ant-btn.ant-btn-background-ghost .anticon,
      .ant-dropdown .ant-btn.ant-btn-background-ghost .icon,
      .ant-dropdown .ant-btn.ant-btn-background-ghost .anticon,
      .ant-notification .ant-btn.ant-btn-background-ghost .icon,
      .ant-notification .ant-btn.ant-btn-background-ghost .anticon {
        color: #202124 !important; }
      .moby__layout .ant-btn.ant-btn-background-ghost:hover,
      .ant-modal-content .ant-btn.ant-btn-background-ghost:hover,
      .ant-popover-inner .ant-btn.ant-btn-background-ghost:hover,
      .ant-drawer .ant-btn.ant-btn-background-ghost:hover,
      .ant-dropdown .ant-btn.ant-btn-background-ghost:hover,
      .ant-notification .ant-btn.ant-btn-background-ghost:hover {
        opacity: .7; }
      .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-primary,
      .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-primary,
      .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-primary,
      .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-primary,
      .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-primary,
      .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-primary {
        border-color: #DBA44F;
        color: #DBA44F; }
        .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-primary .icon,
        .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-primary .anticon,
        .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-primary .icon,
        .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-primary .anticon,
        .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-primary .icon,
        .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-primary .anticon,
        .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-primary .icon,
        .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-primary .anticon,
        .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-primary .icon,
        .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-primary .anticon,
        .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-primary .icon,
        .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-primary .anticon {
          color: #DBA44F !important; }
        .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-primary:hover,
        .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-primary:hover,
        .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-primary:hover,
        .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-primary:hover,
        .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-primary:hover,
        .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-primary:hover {
          opacity: 1;
          background: #DBA44F !important;
          color: white; }
          .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-primary:hover .icon,
          .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-primary:hover .anticon,
          .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-primary:hover .icon,
          .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-primary:hover .anticon,
          .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-primary:hover .icon,
          .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-primary:hover .anticon,
          .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-primary:hover .icon,
          .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-primary:hover .anticon,
          .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-primary:hover .icon,
          .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-primary:hover .anticon,
          .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-primary:hover .icon,
          .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-primary:hover .anticon {
            color: white !important; }
      .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-danger,
      .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-danger,
      .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-danger,
      .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-danger,
      .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-danger,
      .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-danger {
        border-color: #ea4335;
        color: #ea4335; }
        .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-danger .icon,
        .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-danger .anticon,
        .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-danger .icon,
        .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-danger .anticon,
        .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-danger .icon,
        .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-danger .anticon,
        .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-danger .icon,
        .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-danger .anticon,
        .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-danger .icon,
        .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-danger .anticon,
        .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-danger .icon,
        .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-danger .anticon {
          color: #ea4335 !important; }
        .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-danger:hover,
        .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-danger:hover,
        .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-danger:hover,
        .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-danger:hover,
        .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-danger:hover,
        .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-danger:hover {
          opacity: 1;
          background: #ea4335 !important;
          color: white; }
          .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-danger:hover .icon,
          .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-danger:hover .anticon,
          .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-danger:hover .icon,
          .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-danger:hover .anticon,
          .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-danger:hover .icon,
          .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-danger:hover .anticon,
          .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-danger:hover .icon,
          .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-danger:hover .anticon,
          .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-danger:hover .icon,
          .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-danger:hover .anticon,
          .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-danger:hover .icon,
          .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-danger:hover .anticon {
            color: white !important; }
      .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-success,
      .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-success,
      .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-success,
      .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-success,
      .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-success,
      .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-success {
        border-color: #34a853;
        color: #34a853; }
        .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-success .icon,
        .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-success .anticon,
        .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-success .icon,
        .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-success .anticon,
        .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-success .icon,
        .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-success .anticon,
        .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-success .icon,
        .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-success .anticon,
        .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-success .icon,
        .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-success .anticon,
        .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-success .icon,
        .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-success .anticon {
          color: #34a853 !important; }
        .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-success:hover,
        .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-success:hover,
        .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-success:hover,
        .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-success:hover,
        .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-success:hover,
        .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-success:hover {
          opacity: 1;
          background: #34a853 !important;
          color: white; }
          .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-success:hover .icon,
          .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-success:hover .anticon,
          .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-success:hover .icon,
          .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-success:hover .anticon,
          .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-success:hover .icon,
          .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-success:hover .anticon,
          .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-success:hover .icon,
          .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-success:hover .anticon,
          .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-success:hover .icon,
          .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-success:hover .anticon,
          .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-success:hover .icon,
          .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-success:hover .anticon {
            color: white !important; }
      .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-warning,
      .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-warning,
      .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-warning,
      .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-warning,
      .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-warning,
      .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-warning {
        border-color: #fbbc05;
        color: #fbbc05; }
        .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-warning .icon,
        .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-warning .anticon,
        .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-warning .icon,
        .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-warning .anticon,
        .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-warning .icon,
        .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-warning .anticon,
        .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-warning .icon,
        .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-warning .anticon,
        .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-warning .icon,
        .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-warning .anticon,
        .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-warning .icon,
        .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-warning .anticon {
          color: #fbbc05 !important; }
        .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-warning:hover,
        .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-warning:hover,
        .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-warning:hover,
        .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-warning:hover,
        .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-warning:hover,
        .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-warning:hover {
          opacity: 1;
          background: #fbbc05 !important;
          color: white; }
          .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-warning:hover .icon,
          .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-warning:hover .anticon,
          .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-warning:hover .icon,
          .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-warning:hover .anticon,
          .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-warning:hover .icon,
          .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-warning:hover .anticon,
          .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-warning:hover .icon,
          .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-warning:hover .anticon,
          .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-warning:hover .icon,
          .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-warning:hover .anticon,
          .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-warning:hover .icon,
          .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-warning:hover .anticon {
            color: white !important; }
      .moby__layout .ant-btn.ant-btn-background-ghost.ant-btn-link,
      .ant-modal-content .ant-btn.ant-btn-background-ghost.ant-btn-link,
      .ant-popover-inner .ant-btn.ant-btn-background-ghost.ant-btn-link,
      .ant-drawer .ant-btn.ant-btn-background-ghost.ant-btn-link,
      .ant-dropdown .ant-btn.ant-btn-background-ghost.ant-btn-link,
      .ant-notification .ant-btn.ant-btn-background-ghost.ant-btn-link {
        border: 0; }
    .moby__layout .ant-btn.btn-filled,
    .ant-modal-content .ant-btn.btn-filled,
    .ant-popover-inner .ant-btn.btn-filled,
    .ant-drawer .ant-btn.btn-filled,
    .ant-dropdown .ant-btn.btn-filled,
    .ant-notification .ant-btn.btn-filled {
      background: #202124 !important;
      color: white !important; }
      .moby__layout .ant-btn.btn-filled .icon,
      .moby__layout .ant-btn.btn-filled .anticon,
      .ant-modal-content .ant-btn.btn-filled .icon,
      .ant-modal-content .ant-btn.btn-filled .anticon,
      .ant-popover-inner .ant-btn.btn-filled .icon,
      .ant-popover-inner .ant-btn.btn-filled .anticon,
      .ant-drawer .ant-btn.btn-filled .icon,
      .ant-drawer .ant-btn.btn-filled .anticon,
      .ant-dropdown .ant-btn.btn-filled .icon,
      .ant-dropdown .ant-btn.btn-filled .anticon,
      .ant-notification .ant-btn.btn-filled .icon,
      .ant-notification .ant-btn.btn-filled .anticon {
        color: white !important; }
      .moby__layout .ant-btn.btn-filled:hover,
      .ant-modal-content .ant-btn.btn-filled:hover,
      .ant-popover-inner .ant-btn.btn-filled:hover,
      .ant-drawer .ant-btn.btn-filled:hover,
      .ant-dropdown .ant-btn.btn-filled:hover,
      .ant-notification .ant-btn.btn-filled:hover {
        opacity: .7; }
      .moby__layout .ant-btn.btn-filled.ant-btn-primary,
      .ant-modal-content .ant-btn.btn-filled.ant-btn-primary,
      .ant-popover-inner .ant-btn.btn-filled.ant-btn-primary,
      .ant-drawer .ant-btn.btn-filled.ant-btn-primary,
      .ant-dropdown .ant-btn.btn-filled.ant-btn-primary,
      .ant-notification .ant-btn.btn-filled.ant-btn-primary {
        background: #DBA44F !important;
        color: white !important; }
        .moby__layout .ant-btn.btn-filled.ant-btn-primary .icon,
        .moby__layout .ant-btn.btn-filled.ant-btn-primary .anticon,
        .ant-modal-content .ant-btn.btn-filled.ant-btn-primary .icon,
        .ant-modal-content .ant-btn.btn-filled.ant-btn-primary .anticon,
        .ant-popover-inner .ant-btn.btn-filled.ant-btn-primary .icon,
        .ant-popover-inner .ant-btn.btn-filled.ant-btn-primary .anticon,
        .ant-drawer .ant-btn.btn-filled.ant-btn-primary .icon,
        .ant-drawer .ant-btn.btn-filled.ant-btn-primary .anticon,
        .ant-dropdown .ant-btn.btn-filled.ant-btn-primary .icon,
        .ant-dropdown .ant-btn.btn-filled.ant-btn-primary .anticon,
        .ant-notification .ant-btn.btn-filled.ant-btn-primary .icon,
        .ant-notification .ant-btn.btn-filled.ant-btn-primary .anticon {
          color: white !important; }
        .moby__layout .ant-btn.btn-filled.ant-btn-primary:hover,
        .ant-modal-content .ant-btn.btn-filled.ant-btn-primary:hover,
        .ant-popover-inner .ant-btn.btn-filled.ant-btn-primary:hover,
        .ant-drawer .ant-btn.btn-filled.ant-btn-primary:hover,
        .ant-dropdown .ant-btn.btn-filled.ant-btn-primary:hover,
        .ant-notification .ant-btn.btn-filled.ant-btn-primary:hover {
          opacity: .7; }
      .moby__layout .ant-btn.btn-filled.ant-btn-danger,
      .ant-modal-content .ant-btn.btn-filled.ant-btn-danger,
      .ant-popover-inner .ant-btn.btn-filled.ant-btn-danger,
      .ant-drawer .ant-btn.btn-filled.ant-btn-danger,
      .ant-dropdown .ant-btn.btn-filled.ant-btn-danger,
      .ant-notification .ant-btn.btn-filled.ant-btn-danger {
        background: #ea4335 !important;
        color: white !important; }
        .moby__layout .ant-btn.btn-filled.ant-btn-danger .icon,
        .moby__layout .ant-btn.btn-filled.ant-btn-danger .anticon,
        .ant-modal-content .ant-btn.btn-filled.ant-btn-danger .icon,
        .ant-modal-content .ant-btn.btn-filled.ant-btn-danger .anticon,
        .ant-popover-inner .ant-btn.btn-filled.ant-btn-danger .icon,
        .ant-popover-inner .ant-btn.btn-filled.ant-btn-danger .anticon,
        .ant-drawer .ant-btn.btn-filled.ant-btn-danger .icon,
        .ant-drawer .ant-btn.btn-filled.ant-btn-danger .anticon,
        .ant-dropdown .ant-btn.btn-filled.ant-btn-danger .icon,
        .ant-dropdown .ant-btn.btn-filled.ant-btn-danger .anticon,
        .ant-notification .ant-btn.btn-filled.ant-btn-danger .icon,
        .ant-notification .ant-btn.btn-filled.ant-btn-danger .anticon {
          color: white !important; }
        .moby__layout .ant-btn.btn-filled.ant-btn-danger:hover,
        .ant-modal-content .ant-btn.btn-filled.ant-btn-danger:hover,
        .ant-popover-inner .ant-btn.btn-filled.ant-btn-danger:hover,
        .ant-drawer .ant-btn.btn-filled.ant-btn-danger:hover,
        .ant-dropdown .ant-btn.btn-filled.ant-btn-danger:hover,
        .ant-notification .ant-btn.btn-filled.ant-btn-danger:hover {
          opacity: .7; }
      .moby__layout .ant-btn.btn-filled.ant-btn-success,
      .ant-modal-content .ant-btn.btn-filled.ant-btn-success,
      .ant-popover-inner .ant-btn.btn-filled.ant-btn-success,
      .ant-drawer .ant-btn.btn-filled.ant-btn-success,
      .ant-dropdown .ant-btn.btn-filled.ant-btn-success,
      .ant-notification .ant-btn.btn-filled.ant-btn-success {
        background: #34a853 !important;
        color: white !important; }
        .moby__layout .ant-btn.btn-filled.ant-btn-success .icon,
        .moby__layout .ant-btn.btn-filled.ant-btn-success .anticon,
        .ant-modal-content .ant-btn.btn-filled.ant-btn-success .icon,
        .ant-modal-content .ant-btn.btn-filled.ant-btn-success .anticon,
        .ant-popover-inner .ant-btn.btn-filled.ant-btn-success .icon,
        .ant-popover-inner .ant-btn.btn-filled.ant-btn-success .anticon,
        .ant-drawer .ant-btn.btn-filled.ant-btn-success .icon,
        .ant-drawer .ant-btn.btn-filled.ant-btn-success .anticon,
        .ant-dropdown .ant-btn.btn-filled.ant-btn-success .icon,
        .ant-dropdown .ant-btn.btn-filled.ant-btn-success .anticon,
        .ant-notification .ant-btn.btn-filled.ant-btn-success .icon,
        .ant-notification .ant-btn.btn-filled.ant-btn-success .anticon {
          color: white !important; }
        .moby__layout .ant-btn.btn-filled.ant-btn-success:hover,
        .ant-modal-content .ant-btn.btn-filled.ant-btn-success:hover,
        .ant-popover-inner .ant-btn.btn-filled.ant-btn-success:hover,
        .ant-drawer .ant-btn.btn-filled.ant-btn-success:hover,
        .ant-dropdown .ant-btn.btn-filled.ant-btn-success:hover,
        .ant-notification .ant-btn.btn-filled.ant-btn-success:hover {
          opacity: .7; }
      .moby__layout .ant-btn.btn-filled.ant-btn-warning,
      .ant-modal-content .ant-btn.btn-filled.ant-btn-warning,
      .ant-popover-inner .ant-btn.btn-filled.ant-btn-warning,
      .ant-drawer .ant-btn.btn-filled.ant-btn-warning,
      .ant-dropdown .ant-btn.btn-filled.ant-btn-warning,
      .ant-notification .ant-btn.btn-filled.ant-btn-warning {
        background: #fbbc05 !important;
        color: white !important; }
        .moby__layout .ant-btn.btn-filled.ant-btn-warning .icon,
        .moby__layout .ant-btn.btn-filled.ant-btn-warning .anticon,
        .ant-modal-content .ant-btn.btn-filled.ant-btn-warning .icon,
        .ant-modal-content .ant-btn.btn-filled.ant-btn-warning .anticon,
        .ant-popover-inner .ant-btn.btn-filled.ant-btn-warning .icon,
        .ant-popover-inner .ant-btn.btn-filled.ant-btn-warning .anticon,
        .ant-drawer .ant-btn.btn-filled.ant-btn-warning .icon,
        .ant-drawer .ant-btn.btn-filled.ant-btn-warning .anticon,
        .ant-dropdown .ant-btn.btn-filled.ant-btn-warning .icon,
        .ant-dropdown .ant-btn.btn-filled.ant-btn-warning .anticon,
        .ant-notification .ant-btn.btn-filled.ant-btn-warning .icon,
        .ant-notification .ant-btn.btn-filled.ant-btn-warning .anticon {
          color: white !important; }
        .moby__layout .ant-btn.btn-filled.ant-btn-warning:hover,
        .ant-modal-content .ant-btn.btn-filled.ant-btn-warning:hover,
        .ant-popover-inner .ant-btn.btn-filled.ant-btn-warning:hover,
        .ant-drawer .ant-btn.btn-filled.ant-btn-warning:hover,
        .ant-dropdown .ant-btn.btn-filled.ant-btn-warning:hover,
        .ant-notification .ant-btn.btn-filled.ant-btn-warning:hover {
          opacity: .7; }
    .moby__layout .ant-btn.ant-btn-lg,
    .ant-modal-content .ant-btn.ant-btn-lg,
    .ant-popover-inner .ant-btn.ant-btn-lg,
    .ant-drawer .ant-btn.ant-btn-lg,
    .ant-dropdown .ant-btn.ant-btn-lg,
    .ant-notification .ant-btn.ant-btn-lg {
      line-height: 38px; }
      .moby__layout .ant-btn.ant-btn-lg.ant-btn-icon-only,
      .ant-modal-content .ant-btn.ant-btn-lg.ant-btn-icon-only,
      .ant-popover-inner .ant-btn.ant-btn-lg.ant-btn-icon-only,
      .ant-drawer .ant-btn.ant-btn-lg.ant-btn-icon-only,
      .ant-dropdown .ant-btn.ant-btn-lg.ant-btn-icon-only,
      .ant-notification .ant-btn.ant-btn-lg.ant-btn-icon-only {
        width: 40px;
        height: 40px; }
    .moby__layout .ant-btn.ant-btn-sm,
    .ant-modal-content .ant-btn.ant-btn-sm,
    .ant-popover-inner .ant-btn.ant-btn-sm,
    .ant-drawer .ant-btn.ant-btn-sm,
    .ant-dropdown .ant-btn.ant-btn-sm,
    .ant-notification .ant-btn.ant-btn-sm {
      line-height: 16px;
      padding: 0 7px;
      font-size: 12px; }
  .moby__layout .social-btn,
  .ant-modal-content .social-btn,
  .ant-popover-inner .social-btn,
  .ant-drawer .social-btn,
  .ant-dropdown .social-btn,
  .ant-notification .social-btn {
    text-align: center;
    border: 1px solid #202124 !important;
    border-radius: 4px !important;
    background: transparent !important;
    color: #202124 !important;
    font-size: 16px !important;
    height: 40px !important;
    line-height: 40px !important;
    padding: 6px 12px !important;
    margin: 0 0 15px !important;
    width: 100% !important;
    box-shadow: none !important;
    transition: all ease-in-out .4s; }
    .moby__layout .social-btn svg,
    .ant-modal-content .social-btn svg,
    .ant-popover-inner .social-btn svg,
    .ant-drawer .social-btn svg,
    .ant-dropdown .social-btn svg,
    .ant-notification .social-btn svg {
      width: 20px !important;
      height: 20px !important;
      fill: #202124 !important;
      transition: all ease-in-out .4s; }
      .moby__layout .social-btn svg path,
      .ant-modal-content .social-btn svg path,
      .ant-popover-inner .social-btn svg path,
      .ant-drawer .social-btn svg path,
      .ant-dropdown .social-btn svg path,
      .ant-notification .social-btn svg path {
        fill: #202124 !important; }
    .moby__layout .social-btn div:nth-child(1),
    .ant-modal-content .social-btn div:nth-child(1),
    .ant-popover-inner .social-btn div:nth-child(1),
    .ant-drawer .social-btn div:nth-child(1),
    .ant-dropdown .social-btn div:nth-child(1),
    .ant-notification .social-btn div:nth-child(1) {
      justify-content: center; }
    .moby__layout .social-btn div:nth-child(2),
    .ant-modal-content .social-btn div:nth-child(2),
    .ant-popover-inner .social-btn div:nth-child(2),
    .ant-drawer .social-btn div:nth-child(2),
    .ant-dropdown .social-btn div:nth-child(2),
    .ant-notification .social-btn div:nth-child(2) {
      width: 0px !important; }
    .moby__layout .social-btn div:nth-child(3),
    .ant-modal-content .social-btn div:nth-child(3),
    .ant-popover-inner .social-btn div:nth-child(3),
    .ant-drawer .social-btn div:nth-child(3),
    .ant-dropdown .social-btn div:nth-child(3),
    .ant-notification .social-btn div:nth-child(3) {
      width: 40% !important;
      margin-left: 10px; }
      @media screen and (max-width: 1440px) {
        .moby__layout .social-btn div:nth-child(3),
        .ant-modal-content .social-btn div:nth-child(3),
        .ant-popover-inner .social-btn div:nth-child(3),
        .ant-drawer .social-btn div:nth-child(3),
        .ant-dropdown .social-btn div:nth-child(3),
        .ant-notification .social-btn div:nth-child(3) {
          width: 55% !important; } }
      @media screen and (max-width: 375px) {
        .moby__layout .social-btn div:nth-child(3),
        .ant-modal-content .social-btn div:nth-child(3),
        .ant-popover-inner .social-btn div:nth-child(3),
        .ant-drawer .social-btn div:nth-child(3),
        .ant-dropdown .social-btn div:nth-child(3),
        .ant-notification .social-btn div:nth-child(3) {
          width: 70% !important; } }
    .moby__layout .social-btn:hover,
    .ant-modal-content .social-btn:hover,
    .ant-popover-inner .social-btn:hover,
    .ant-drawer .social-btn:hover,
    .ant-dropdown .social-btn:hover,
    .ant-notification .social-btn:hover {
      color: white !important;
      background: #202124 !important; }
      .moby__layout .social-btn:hover svg,
      .ant-modal-content .social-btn:hover svg,
      .ant-popover-inner .social-btn:hover svg,
      .ant-drawer .social-btn:hover svg,
      .ant-dropdown .social-btn:hover svg,
      .ant-notification .social-btn:hover svg {
        fill: white !important; }
        .moby__layout .social-btn:hover svg path,
        .ant-modal-content .social-btn:hover svg path,
        .ant-popover-inner .social-btn:hover svg path,
        .ant-drawer .social-btn:hover svg path,
        .ant-dropdown .social-btn:hover svg path,
        .ant-notification .social-btn:hover svg path {
          fill: white !important; }
    .moby__layout .social-btn.fb-btn,
    .ant-modal-content .social-btn.fb-btn,
    .ant-popover-inner .social-btn.fb-btn,
    .ant-drawer .social-btn.fb-btn,
    .ant-dropdown .social-btn.fb-btn,
    .ant-notification .social-btn.fb-btn {
      text-align: center;
      border: 1px solid #3d599a !important;
      border-radius: 4px !important;
      background: transparent !important;
      color: #3d599a !important;
      font-size: 16px !important;
      height: 40px !important;
      line-height: 40px !important;
      padding: 6px 12px !important;
      margin: 0 0 15px !important;
      width: 100% !important;
      box-shadow: none !important;
      transition: all ease-in-out .4s; }
      .moby__layout .social-btn.fb-btn svg,
      .ant-modal-content .social-btn.fb-btn svg,
      .ant-popover-inner .social-btn.fb-btn svg,
      .ant-drawer .social-btn.fb-btn svg,
      .ant-dropdown .social-btn.fb-btn svg,
      .ant-notification .social-btn.fb-btn svg {
        width: 20px !important;
        height: 20px !important;
        fill: #3d599a !important;
        transition: all ease-in-out .4s; }
        .moby__layout .social-btn.fb-btn svg path,
        .ant-modal-content .social-btn.fb-btn svg path,
        .ant-popover-inner .social-btn.fb-btn svg path,
        .ant-drawer .social-btn.fb-btn svg path,
        .ant-dropdown .social-btn.fb-btn svg path,
        .ant-notification .social-btn.fb-btn svg path {
          fill: #3d599a !important; }
      .moby__layout .social-btn.fb-btn div:nth-child(1),
      .ant-modal-content .social-btn.fb-btn div:nth-child(1),
      .ant-popover-inner .social-btn.fb-btn div:nth-child(1),
      .ant-drawer .social-btn.fb-btn div:nth-child(1),
      .ant-dropdown .social-btn.fb-btn div:nth-child(1),
      .ant-notification .social-btn.fb-btn div:nth-child(1) {
        justify-content: center; }
      .moby__layout .social-btn.fb-btn div:nth-child(2),
      .ant-modal-content .social-btn.fb-btn div:nth-child(2),
      .ant-popover-inner .social-btn.fb-btn div:nth-child(2),
      .ant-drawer .social-btn.fb-btn div:nth-child(2),
      .ant-dropdown .social-btn.fb-btn div:nth-child(2),
      .ant-notification .social-btn.fb-btn div:nth-child(2) {
        width: 0px !important; }
      .moby__layout .social-btn.fb-btn div:nth-child(3),
      .ant-modal-content .social-btn.fb-btn div:nth-child(3),
      .ant-popover-inner .social-btn.fb-btn div:nth-child(3),
      .ant-drawer .social-btn.fb-btn div:nth-child(3),
      .ant-dropdown .social-btn.fb-btn div:nth-child(3),
      .ant-notification .social-btn.fb-btn div:nth-child(3) {
        width: 40% !important;
        margin-left: 10px; }
        @media screen and (max-width: 1440px) {
          .moby__layout .social-btn.fb-btn div:nth-child(3),
          .ant-modal-content .social-btn.fb-btn div:nth-child(3),
          .ant-popover-inner .social-btn.fb-btn div:nth-child(3),
          .ant-drawer .social-btn.fb-btn div:nth-child(3),
          .ant-dropdown .social-btn.fb-btn div:nth-child(3),
          .ant-notification .social-btn.fb-btn div:nth-child(3) {
            width: 55% !important; } }
        @media screen and (max-width: 375px) {
          .moby__layout .social-btn.fb-btn div:nth-child(3),
          .ant-modal-content .social-btn.fb-btn div:nth-child(3),
          .ant-popover-inner .social-btn.fb-btn div:nth-child(3),
          .ant-drawer .social-btn.fb-btn div:nth-child(3),
          .ant-dropdown .social-btn.fb-btn div:nth-child(3),
          .ant-notification .social-btn.fb-btn div:nth-child(3) {
            width: 70% !important; } }
      .moby__layout .social-btn.fb-btn:hover,
      .ant-modal-content .social-btn.fb-btn:hover,
      .ant-popover-inner .social-btn.fb-btn:hover,
      .ant-drawer .social-btn.fb-btn:hover,
      .ant-dropdown .social-btn.fb-btn:hover,
      .ant-notification .social-btn.fb-btn:hover {
        color: white !important;
        background: #3d599a !important; }
        .moby__layout .social-btn.fb-btn:hover svg,
        .ant-modal-content .social-btn.fb-btn:hover svg,
        .ant-popover-inner .social-btn.fb-btn:hover svg,
        .ant-drawer .social-btn.fb-btn:hover svg,
        .ant-dropdown .social-btn.fb-btn:hover svg,
        .ant-notification .social-btn.fb-btn:hover svg {
          fill: white !important; }
          .moby__layout .social-btn.fb-btn:hover svg path,
          .ant-modal-content .social-btn.fb-btn:hover svg path,
          .ant-popover-inner .social-btn.fb-btn:hover svg path,
          .ant-drawer .social-btn.fb-btn:hover svg path,
          .ant-dropdown .social-btn.fb-btn:hover svg path,
          .ant-notification .social-btn.fb-btn:hover svg path {
            fill: white !important; }
    .moby__layout .social-btn.twitter-btn,
    .ant-modal-content .social-btn.twitter-btn,
    .ant-popover-inner .social-btn.twitter-btn,
    .ant-drawer .social-btn.twitter-btn,
    .ant-dropdown .social-btn.twitter-btn,
    .ant-notification .social-btn.twitter-btn {
      text-align: center;
      border: 1px solid #00a7e6 !important;
      border-radius: 4px !important;
      background: transparent !important;
      color: #00a7e6 !important;
      font-size: 16px !important;
      height: 40px !important;
      line-height: 40px !important;
      padding: 6px 12px !important;
      margin: 0 0 15px !important;
      width: 100% !important;
      box-shadow: none !important;
      transition: all ease-in-out .4s; }
      .moby__layout .social-btn.twitter-btn svg,
      .ant-modal-content .social-btn.twitter-btn svg,
      .ant-popover-inner .social-btn.twitter-btn svg,
      .ant-drawer .social-btn.twitter-btn svg,
      .ant-dropdown .social-btn.twitter-btn svg,
      .ant-notification .social-btn.twitter-btn svg {
        width: 20px !important;
        height: 20px !important;
        fill: #00a7e6 !important;
        transition: all ease-in-out .4s; }
        .moby__layout .social-btn.twitter-btn svg path,
        .ant-modal-content .social-btn.twitter-btn svg path,
        .ant-popover-inner .social-btn.twitter-btn svg path,
        .ant-drawer .social-btn.twitter-btn svg path,
        .ant-dropdown .social-btn.twitter-btn svg path,
        .ant-notification .social-btn.twitter-btn svg path {
          fill: #00a7e6 !important; }
      .moby__layout .social-btn.twitter-btn div:nth-child(1),
      .ant-modal-content .social-btn.twitter-btn div:nth-child(1),
      .ant-popover-inner .social-btn.twitter-btn div:nth-child(1),
      .ant-drawer .social-btn.twitter-btn div:nth-child(1),
      .ant-dropdown .social-btn.twitter-btn div:nth-child(1),
      .ant-notification .social-btn.twitter-btn div:nth-child(1) {
        justify-content: center; }
      .moby__layout .social-btn.twitter-btn div:nth-child(2),
      .ant-modal-content .social-btn.twitter-btn div:nth-child(2),
      .ant-popover-inner .social-btn.twitter-btn div:nth-child(2),
      .ant-drawer .social-btn.twitter-btn div:nth-child(2),
      .ant-dropdown .social-btn.twitter-btn div:nth-child(2),
      .ant-notification .social-btn.twitter-btn div:nth-child(2) {
        width: 0px !important; }
      .moby__layout .social-btn.twitter-btn div:nth-child(3),
      .ant-modal-content .social-btn.twitter-btn div:nth-child(3),
      .ant-popover-inner .social-btn.twitter-btn div:nth-child(3),
      .ant-drawer .social-btn.twitter-btn div:nth-child(3),
      .ant-dropdown .social-btn.twitter-btn div:nth-child(3),
      .ant-notification .social-btn.twitter-btn div:nth-child(3) {
        width: 40% !important;
        margin-left: 10px; }
        @media screen and (max-width: 1440px) {
          .moby__layout .social-btn.twitter-btn div:nth-child(3),
          .ant-modal-content .social-btn.twitter-btn div:nth-child(3),
          .ant-popover-inner .social-btn.twitter-btn div:nth-child(3),
          .ant-drawer .social-btn.twitter-btn div:nth-child(3),
          .ant-dropdown .social-btn.twitter-btn div:nth-child(3),
          .ant-notification .social-btn.twitter-btn div:nth-child(3) {
            width: 55% !important; } }
        @media screen and (max-width: 375px) {
          .moby__layout .social-btn.twitter-btn div:nth-child(3),
          .ant-modal-content .social-btn.twitter-btn div:nth-child(3),
          .ant-popover-inner .social-btn.twitter-btn div:nth-child(3),
          .ant-drawer .social-btn.twitter-btn div:nth-child(3),
          .ant-dropdown .social-btn.twitter-btn div:nth-child(3),
          .ant-notification .social-btn.twitter-btn div:nth-child(3) {
            width: 70% !important; } }
      .moby__layout .social-btn.twitter-btn:hover,
      .ant-modal-content .social-btn.twitter-btn:hover,
      .ant-popover-inner .social-btn.twitter-btn:hover,
      .ant-drawer .social-btn.twitter-btn:hover,
      .ant-dropdown .social-btn.twitter-btn:hover,
      .ant-notification .social-btn.twitter-btn:hover {
        color: white !important;
        background: #00a7e6 !important; }
        .moby__layout .social-btn.twitter-btn:hover svg,
        .ant-modal-content .social-btn.twitter-btn:hover svg,
        .ant-popover-inner .social-btn.twitter-btn:hover svg,
        .ant-drawer .social-btn.twitter-btn:hover svg,
        .ant-dropdown .social-btn.twitter-btn:hover svg,
        .ant-notification .social-btn.twitter-btn:hover svg {
          fill: white !important; }
          .moby__layout .social-btn.twitter-btn:hover svg path,
          .ant-modal-content .social-btn.twitter-btn:hover svg path,
          .ant-popover-inner .social-btn.twitter-btn:hover svg path,
          .ant-drawer .social-btn.twitter-btn:hover svg path,
          .ant-dropdown .social-btn.twitter-btn:hover svg path,
          .ant-notification .social-btn.twitter-btn:hover svg path {
            fill: white !important; }
    .moby__layout .social-btn.google-btn,
    .ant-modal-content .social-btn.google-btn,
    .ant-popover-inner .social-btn.google-btn,
    .ant-drawer .social-btn.google-btn,
    .ant-dropdown .social-btn.google-btn,
    .ant-notification .social-btn.google-btn {
      text-align: center;
      border: 1px solid #f34735 !important;
      border-radius: 4px !important;
      background: transparent !important;
      color: #f34735 !important;
      font-size: 16px !important;
      height: 40px !important;
      line-height: 40px !important;
      padding: 6px 12px !important;
      margin: 0 0 15px !important;
      width: 100% !important;
      box-shadow: none !important;
      transition: all ease-in-out .4s; }
      .moby__layout .social-btn.google-btn svg,
      .ant-modal-content .social-btn.google-btn svg,
      .ant-popover-inner .social-btn.google-btn svg,
      .ant-drawer .social-btn.google-btn svg,
      .ant-dropdown .social-btn.google-btn svg,
      .ant-notification .social-btn.google-btn svg {
        width: 20px !important;
        height: 20px !important;
        fill: #f34735 !important;
        transition: all ease-in-out .4s; }
        .moby__layout .social-btn.google-btn svg path,
        .ant-modal-content .social-btn.google-btn svg path,
        .ant-popover-inner .social-btn.google-btn svg path,
        .ant-drawer .social-btn.google-btn svg path,
        .ant-dropdown .social-btn.google-btn svg path,
        .ant-notification .social-btn.google-btn svg path {
          fill: #f34735 !important; }
      .moby__layout .social-btn.google-btn div:nth-child(1),
      .ant-modal-content .social-btn.google-btn div:nth-child(1),
      .ant-popover-inner .social-btn.google-btn div:nth-child(1),
      .ant-drawer .social-btn.google-btn div:nth-child(1),
      .ant-dropdown .social-btn.google-btn div:nth-child(1),
      .ant-notification .social-btn.google-btn div:nth-child(1) {
        justify-content: center; }
      .moby__layout .social-btn.google-btn div:nth-child(2),
      .ant-modal-content .social-btn.google-btn div:nth-child(2),
      .ant-popover-inner .social-btn.google-btn div:nth-child(2),
      .ant-drawer .social-btn.google-btn div:nth-child(2),
      .ant-dropdown .social-btn.google-btn div:nth-child(2),
      .ant-notification .social-btn.google-btn div:nth-child(2) {
        width: 0px !important; }
      .moby__layout .social-btn.google-btn div:nth-child(3),
      .ant-modal-content .social-btn.google-btn div:nth-child(3),
      .ant-popover-inner .social-btn.google-btn div:nth-child(3),
      .ant-drawer .social-btn.google-btn div:nth-child(3),
      .ant-dropdown .social-btn.google-btn div:nth-child(3),
      .ant-notification .social-btn.google-btn div:nth-child(3) {
        width: 40% !important;
        margin-left: 10px; }
        @media screen and (max-width: 1440px) {
          .moby__layout .social-btn.google-btn div:nth-child(3),
          .ant-modal-content .social-btn.google-btn div:nth-child(3),
          .ant-popover-inner .social-btn.google-btn div:nth-child(3),
          .ant-drawer .social-btn.google-btn div:nth-child(3),
          .ant-dropdown .social-btn.google-btn div:nth-child(3),
          .ant-notification .social-btn.google-btn div:nth-child(3) {
            width: 55% !important; } }
        @media screen and (max-width: 375px) {
          .moby__layout .social-btn.google-btn div:nth-child(3),
          .ant-modal-content .social-btn.google-btn div:nth-child(3),
          .ant-popover-inner .social-btn.google-btn div:nth-child(3),
          .ant-drawer .social-btn.google-btn div:nth-child(3),
          .ant-dropdown .social-btn.google-btn div:nth-child(3),
          .ant-notification .social-btn.google-btn div:nth-child(3) {
            width: 70% !important; } }
      .moby__layout .social-btn.google-btn:hover,
      .ant-modal-content .social-btn.google-btn:hover,
      .ant-popover-inner .social-btn.google-btn:hover,
      .ant-drawer .social-btn.google-btn:hover,
      .ant-dropdown .social-btn.google-btn:hover,
      .ant-notification .social-btn.google-btn:hover {
        color: white !important;
        background: #f34735 !important; }
        .moby__layout .social-btn.google-btn:hover svg,
        .ant-modal-content .social-btn.google-btn:hover svg,
        .ant-popover-inner .social-btn.google-btn:hover svg,
        .ant-drawer .social-btn.google-btn:hover svg,
        .ant-dropdown .social-btn.google-btn:hover svg,
        .ant-notification .social-btn.google-btn:hover svg {
          fill: white !important; }
          .moby__layout .social-btn.google-btn:hover svg path,
          .ant-modal-content .social-btn.google-btn:hover svg path,
          .ant-popover-inner .social-btn.google-btn:hover svg path,
          .ant-drawer .social-btn.google-btn:hover svg path,
          .ant-dropdown .social-btn.google-btn:hover svg path,
          .ant-notification .social-btn.google-btn:hover svg path {
            fill: white !important; }
    .moby__layout .social-btn.linkedin-btn,
    .ant-modal-content .social-btn.linkedin-btn,
    .ant-popover-inner .social-btn.linkedin-btn,
    .ant-drawer .social-btn.linkedin-btn,
    .ant-dropdown .social-btn.linkedin-btn,
    .ant-notification .social-btn.linkedin-btn {
      text-align: center;
      border: 1px solid #0073b1 !important;
      border-radius: 4px !important;
      background: transparent !important;
      color: #0073b1 !important;
      font-size: 16px !important;
      height: 40px !important;
      line-height: 40px !important;
      padding: 6px 12px !important;
      margin: 0 0 15px !important;
      width: 100% !important;
      box-shadow: none !important;
      transition: all ease-in-out .4s; }
      .moby__layout .social-btn.linkedin-btn svg,
      .ant-modal-content .social-btn.linkedin-btn svg,
      .ant-popover-inner .social-btn.linkedin-btn svg,
      .ant-drawer .social-btn.linkedin-btn svg,
      .ant-dropdown .social-btn.linkedin-btn svg,
      .ant-notification .social-btn.linkedin-btn svg {
        width: 20px !important;
        height: 20px !important;
        fill: #0073b1 !important;
        transition: all ease-in-out .4s; }
        .moby__layout .social-btn.linkedin-btn svg path,
        .ant-modal-content .social-btn.linkedin-btn svg path,
        .ant-popover-inner .social-btn.linkedin-btn svg path,
        .ant-drawer .social-btn.linkedin-btn svg path,
        .ant-dropdown .social-btn.linkedin-btn svg path,
        .ant-notification .social-btn.linkedin-btn svg path {
          fill: #0073b1 !important; }
      .moby__layout .social-btn.linkedin-btn div:nth-child(1),
      .ant-modal-content .social-btn.linkedin-btn div:nth-child(1),
      .ant-popover-inner .social-btn.linkedin-btn div:nth-child(1),
      .ant-drawer .social-btn.linkedin-btn div:nth-child(1),
      .ant-dropdown .social-btn.linkedin-btn div:nth-child(1),
      .ant-notification .social-btn.linkedin-btn div:nth-child(1) {
        justify-content: center; }
      .moby__layout .social-btn.linkedin-btn div:nth-child(2),
      .ant-modal-content .social-btn.linkedin-btn div:nth-child(2),
      .ant-popover-inner .social-btn.linkedin-btn div:nth-child(2),
      .ant-drawer .social-btn.linkedin-btn div:nth-child(2),
      .ant-dropdown .social-btn.linkedin-btn div:nth-child(2),
      .ant-notification .social-btn.linkedin-btn div:nth-child(2) {
        width: 0px !important; }
      .moby__layout .social-btn.linkedin-btn div:nth-child(3),
      .ant-modal-content .social-btn.linkedin-btn div:nth-child(3),
      .ant-popover-inner .social-btn.linkedin-btn div:nth-child(3),
      .ant-drawer .social-btn.linkedin-btn div:nth-child(3),
      .ant-dropdown .social-btn.linkedin-btn div:nth-child(3),
      .ant-notification .social-btn.linkedin-btn div:nth-child(3) {
        width: 40% !important;
        margin-left: 10px; }
        @media screen and (max-width: 1440px) {
          .moby__layout .social-btn.linkedin-btn div:nth-child(3),
          .ant-modal-content .social-btn.linkedin-btn div:nth-child(3),
          .ant-popover-inner .social-btn.linkedin-btn div:nth-child(3),
          .ant-drawer .social-btn.linkedin-btn div:nth-child(3),
          .ant-dropdown .social-btn.linkedin-btn div:nth-child(3),
          .ant-notification .social-btn.linkedin-btn div:nth-child(3) {
            width: 55% !important; } }
        @media screen and (max-width: 375px) {
          .moby__layout .social-btn.linkedin-btn div:nth-child(3),
          .ant-modal-content .social-btn.linkedin-btn div:nth-child(3),
          .ant-popover-inner .social-btn.linkedin-btn div:nth-child(3),
          .ant-drawer .social-btn.linkedin-btn div:nth-child(3),
          .ant-dropdown .social-btn.linkedin-btn div:nth-child(3),
          .ant-notification .social-btn.linkedin-btn div:nth-child(3) {
            width: 70% !important; } }
      .moby__layout .social-btn.linkedin-btn:hover,
      .ant-modal-content .social-btn.linkedin-btn:hover,
      .ant-popover-inner .social-btn.linkedin-btn:hover,
      .ant-drawer .social-btn.linkedin-btn:hover,
      .ant-dropdown .social-btn.linkedin-btn:hover,
      .ant-notification .social-btn.linkedin-btn:hover {
        color: white !important;
        background: #0073b1 !important; }
        .moby__layout .social-btn.linkedin-btn:hover svg,
        .ant-modal-content .social-btn.linkedin-btn:hover svg,
        .ant-popover-inner .social-btn.linkedin-btn:hover svg,
        .ant-drawer .social-btn.linkedin-btn:hover svg,
        .ant-dropdown .social-btn.linkedin-btn:hover svg,
        .ant-notification .social-btn.linkedin-btn:hover svg {
          fill: white !important; }
          .moby__layout .social-btn.linkedin-btn:hover svg path,
          .ant-modal-content .social-btn.linkedin-btn:hover svg path,
          .ant-popover-inner .social-btn.linkedin-btn:hover svg path,
          .ant-drawer .social-btn.linkedin-btn:hover svg path,
          .ant-dropdown .social-btn.linkedin-btn:hover svg path,
          .ant-notification .social-btn.linkedin-btn:hover svg path {
            fill: white !important; }
  .moby__layout .ant-btn-group .ant-btn,
  .ant-modal-content .ant-btn-group .ant-btn,
  .ant-popover-inner .ant-btn-group .ant-btn,
  .ant-drawer .ant-btn-group .ant-btn,
  .ant-dropdown .ant-btn-group .ant-btn,
  .ant-notification .ant-btn-group .ant-btn {
    border-radius: 0; }
  .moby__layout .ant-btn-group .ant-btn:not(:first-child):not(:last-child),
  .ant-modal-content .ant-btn-group .ant-btn:not(:first-child):not(:last-child),
  .ant-popover-inner .ant-btn-group .ant-btn:not(:first-child):not(:last-child),
  .ant-drawer .ant-btn-group .ant-btn:not(:first-child):not(:last-child),
  .ant-dropdown .ant-btn-group .ant-btn:not(:first-child):not(:last-child),
  .ant-notification .ant-btn-group .ant-btn:not(:first-child):not(:last-child) {
    border-right-color: #202124 !important; }
  .moby__layout .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child),
  .ant-modal-content .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child),
  .ant-popover-inner .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child),
  .ant-drawer .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child),
  .ant-dropdown .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child),
  .ant-notification .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
    border-right-color: #b87e26 !important; }
  .moby__layout .ant-btn-group .ant-btn-danger:not(:first-child):not(:last-child),
  .ant-modal-content .ant-btn-group .ant-btn-danger:not(:first-child):not(:last-child),
  .ant-popover-inner .ant-btn-group .ant-btn-danger:not(:first-child):not(:last-child),
  .ant-drawer .ant-btn-group .ant-btn-danger:not(:first-child):not(:last-child),
  .ant-dropdown .ant-btn-group .ant-btn-danger:not(:first-child):not(:last-child),
  .ant-notification .ant-btn-group .ant-btn-danger:not(:first-child):not(:last-child) {
    border-right-color: #bf2114 !important; }
  .moby__layout .ant-btn-group .ant-btn-success:not(:first-child):not(:last-child),
  .ant-modal-content .ant-btn-group .ant-btn-success:not(:first-child):not(:last-child),
  .ant-popover-inner .ant-btn-group .ant-btn-success:not(:first-child):not(:last-child),
  .ant-drawer .ant-btn-group .ant-btn-success:not(:first-child):not(:last-child),
  .ant-dropdown .ant-btn-group .ant-btn-success:not(:first-child):not(:last-child),
  .ant-notification .ant-btn-group .ant-btn-success:not(:first-child):not(:last-child) {
    border-right-color: #226e36 !important; }
  .moby__layout .ant-btn-group .ant-btn-warning:not(:first-child):not(:last-child),
  .ant-modal-content .ant-btn-group .ant-btn-warning:not(:first-child):not(:last-child),
  .ant-popover-inner .ant-btn-group .ant-btn-warning:not(:first-child):not(:last-child),
  .ant-drawer .ant-btn-group .ant-btn-warning:not(:first-child):not(:last-child),
  .ant-dropdown .ant-btn-group .ant-btn-warning:not(:first-child):not(:last-child),
  .ant-notification .ant-btn-group .ant-btn-warning:not(:first-child):not(:last-child) {
    border-right-color: #b18403 !important; }
  .moby__layout .ant-btn-group .ant-btn-primary + .ant-btn,
  .ant-modal-content .ant-btn-group .ant-btn-primary + .ant-btn,
  .ant-popover-inner .ant-btn-group .ant-btn-primary + .ant-btn,
  .ant-drawer .ant-btn-group .ant-btn-primary + .ant-btn,
  .ant-dropdown .ant-btn-group .ant-btn-primary + .ant-btn,
  .ant-notification .ant-btn-group .ant-btn-primary + .ant-btn {
    border-left-color: #202124 !important; }
  .moby__layout .ant-btn-group .ant-btn-primary + .ant-btn-primary,
  .ant-modal-content .ant-btn-group .ant-btn-primary + .ant-btn-primary,
  .ant-popover-inner .ant-btn-group .ant-btn-primary + .ant-btn-primary,
  .ant-drawer .ant-btn-group .ant-btn-primary + .ant-btn-primary,
  .ant-dropdown .ant-btn-group .ant-btn-primary + .ant-btn-primary,
  .ant-notification .ant-btn-group .ant-btn-primary + .ant-btn-primary {
    border-left-color: #b87e26 !important; }
  .moby__layout .ant-btn-group .ant-btn-danger + .ant-btn-danger,
  .ant-modal-content .ant-btn-group .ant-btn-danger + .ant-btn-danger,
  .ant-popover-inner .ant-btn-group .ant-btn-danger + .ant-btn-danger,
  .ant-drawer .ant-btn-group .ant-btn-danger + .ant-btn-danger,
  .ant-dropdown .ant-btn-group .ant-btn-danger + .ant-btn-danger,
  .ant-notification .ant-btn-group .ant-btn-danger + .ant-btn-danger {
    border-left-color: #bf2114 !important; }
  .moby__layout .ant-btn-group .ant-btn-success + .ant-btn-success,
  .ant-modal-content .ant-btn-group .ant-btn-success + .ant-btn-success,
  .ant-popover-inner .ant-btn-group .ant-btn-success + .ant-btn-success,
  .ant-drawer .ant-btn-group .ant-btn-success + .ant-btn-success,
  .ant-dropdown .ant-btn-group .ant-btn-success + .ant-btn-success,
  .ant-notification .ant-btn-group .ant-btn-success + .ant-btn-success {
    border-left-color: #226e36 !important; }
  .moby__layout .ant-btn-group .ant-btn-warning + .ant-btn-warning,
  .ant-modal-content .ant-btn-group .ant-btn-warning + .ant-btn-warning,
  .ant-popover-inner .ant-btn-group .ant-btn-warning + .ant-btn-warning,
  .ant-drawer .ant-btn-group .ant-btn-warning + .ant-btn-warning,
  .ant-dropdown .ant-btn-group .ant-btn-warning + .ant-btn-warning,
  .ant-notification .ant-btn-group .ant-btn-warning + .ant-btn-warning {
    border-left-color: #b18403 !important; }
  .moby__layout .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child,
  .ant-modal-content .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child,
  .ant-popover-inner .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child,
  .ant-drawer .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child,
  .ant-dropdown .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child,
  .ant-notification .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
    border-right-color: #DBA44F; }
  .moby__layout .ant-radio-group,
  .ant-modal-content .ant-radio-group,
  .ant-popover-inner .ant-radio-group,
  .ant-drawer .ant-radio-group,
  .ant-dropdown .ant-radio-group,
  .ant-notification .ant-radio-group {
    color: #202124 !important; }
    .moby__layout .ant-radio-group.ant-radio-group-outline.danger .ant-radio-checked .ant-radio-inner,
    .ant-modal-content .ant-radio-group.ant-radio-group-outline.danger .ant-radio-checked .ant-radio-inner,
    .ant-popover-inner .ant-radio-group.ant-radio-group-outline.danger .ant-radio-checked .ant-radio-inner,
    .ant-drawer .ant-radio-group.ant-radio-group-outline.danger .ant-radio-checked .ant-radio-inner,
    .ant-dropdown .ant-radio-group.ant-radio-group-outline.danger .ant-radio-checked .ant-radio-inner,
    .ant-notification .ant-radio-group.ant-radio-group-outline.danger .ant-radio-checked .ant-radio-inner {
      border-color: #ea4335; }
      .moby__layout .ant-radio-group.ant-radio-group-outline.danger .ant-radio-checked .ant-radio-inner:after,
      .ant-modal-content .ant-radio-group.ant-radio-group-outline.danger .ant-radio-checked .ant-radio-inner:after,
      .ant-popover-inner .ant-radio-group.ant-radio-group-outline.danger .ant-radio-checked .ant-radio-inner:after,
      .ant-drawer .ant-radio-group.ant-radio-group-outline.danger .ant-radio-checked .ant-radio-inner:after,
      .ant-dropdown .ant-radio-group.ant-radio-group-outline.danger .ant-radio-checked .ant-radio-inner:after,
      .ant-notification .ant-radio-group.ant-radio-group-outline.danger .ant-radio-checked .ant-radio-inner:after {
        background-color: #ea4335; }
    .moby__layout .ant-radio-group.ant-radio-group-outline.danger .ant-radio-wrapper .ant-radio:hover .ant-radio-inner,
    .ant-modal-content .ant-radio-group.ant-radio-group-outline.danger .ant-radio-wrapper .ant-radio:hover .ant-radio-inner,
    .ant-popover-inner .ant-radio-group.ant-radio-group-outline.danger .ant-radio-wrapper .ant-radio:hover .ant-radio-inner,
    .ant-drawer .ant-radio-group.ant-radio-group-outline.danger .ant-radio-wrapper .ant-radio:hover .ant-radio-inner,
    .ant-dropdown .ant-radio-group.ant-radio-group-outline.danger .ant-radio-wrapper .ant-radio:hover .ant-radio-inner,
    .ant-notification .ant-radio-group.ant-radio-group-outline.danger .ant-radio-wrapper .ant-radio:hover .ant-radio-inner {
      border-color: #ea4335; }
    .moby__layout .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-modal-content .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-popover-inner .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-drawer .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-dropdown .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-notification .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
      border-right-color: #ea4335; }
    .moby__layout .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper:hover,
    .ant-modal-content .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper:hover,
    .ant-popover-inner .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper:hover,
    .ant-drawer .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper:hover,
    .ant-dropdown .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper:hover,
    .ant-notification .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper:hover {
      color: #ea4335; }
    .moby__layout .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-modal-content .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-popover-inner .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-drawer .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-dropdown .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-notification .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: #ea4335;
      border-color: #ea4335; }
      .moby__layout .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-modal-content .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-popover-inner .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-drawer .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-dropdown .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-notification .ant-radio-group.ant-radio-group-outline.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
        background-color: #ea4335; }
    .moby__layout .ant-radio-group.ant-radio-group-outline.success .ant-radio-checked .ant-radio-inner,
    .ant-modal-content .ant-radio-group.ant-radio-group-outline.success .ant-radio-checked .ant-radio-inner,
    .ant-popover-inner .ant-radio-group.ant-radio-group-outline.success .ant-radio-checked .ant-radio-inner,
    .ant-drawer .ant-radio-group.ant-radio-group-outline.success .ant-radio-checked .ant-radio-inner,
    .ant-dropdown .ant-radio-group.ant-radio-group-outline.success .ant-radio-checked .ant-radio-inner,
    .ant-notification .ant-radio-group.ant-radio-group-outline.success .ant-radio-checked .ant-radio-inner {
      border-color: #34a853; }
      .moby__layout .ant-radio-group.ant-radio-group-outline.success .ant-radio-checked .ant-radio-inner:after,
      .ant-modal-content .ant-radio-group.ant-radio-group-outline.success .ant-radio-checked .ant-radio-inner:after,
      .ant-popover-inner .ant-radio-group.ant-radio-group-outline.success .ant-radio-checked .ant-radio-inner:after,
      .ant-drawer .ant-radio-group.ant-radio-group-outline.success .ant-radio-checked .ant-radio-inner:after,
      .ant-dropdown .ant-radio-group.ant-radio-group-outline.success .ant-radio-checked .ant-radio-inner:after,
      .ant-notification .ant-radio-group.ant-radio-group-outline.success .ant-radio-checked .ant-radio-inner:after {
        background-color: #34a853; }
    .moby__layout .ant-radio-group.ant-radio-group-outline.success .ant-radio-wrapper .ant-radio:hover .ant-radio-inner,
    .ant-modal-content .ant-radio-group.ant-radio-group-outline.success .ant-radio-wrapper .ant-radio:hover .ant-radio-inner,
    .ant-popover-inner .ant-radio-group.ant-radio-group-outline.success .ant-radio-wrapper .ant-radio:hover .ant-radio-inner,
    .ant-drawer .ant-radio-group.ant-radio-group-outline.success .ant-radio-wrapper .ant-radio:hover .ant-radio-inner,
    .ant-dropdown .ant-radio-group.ant-radio-group-outline.success .ant-radio-wrapper .ant-radio:hover .ant-radio-inner,
    .ant-notification .ant-radio-group.ant-radio-group-outline.success .ant-radio-wrapper .ant-radio:hover .ant-radio-inner {
      border-color: #34a853; }
    .moby__layout .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-modal-content .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-popover-inner .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-drawer .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-dropdown .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-notification .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
      border-right-color: #34a853; }
    .moby__layout .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper:hover,
    .ant-modal-content .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper:hover,
    .ant-popover-inner .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper:hover,
    .ant-drawer .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper:hover,
    .ant-dropdown .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper:hover,
    .ant-notification .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper:hover {
      color: #34a853; }
    .moby__layout .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-modal-content .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-popover-inner .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-drawer .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-dropdown .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-notification .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: #34a853;
      border-color: #34a853; }
      .moby__layout .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-modal-content .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-popover-inner .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-drawer .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-dropdown .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-notification .ant-radio-group.ant-radio-group-outline.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
        background-color: #34a853; }
    .moby__layout .ant-radio-group.ant-radio-group-outline.warning .ant-radio-checked .ant-radio-inner,
    .ant-modal-content .ant-radio-group.ant-radio-group-outline.warning .ant-radio-checked .ant-radio-inner,
    .ant-popover-inner .ant-radio-group.ant-radio-group-outline.warning .ant-radio-checked .ant-radio-inner,
    .ant-drawer .ant-radio-group.ant-radio-group-outline.warning .ant-radio-checked .ant-radio-inner,
    .ant-dropdown .ant-radio-group.ant-radio-group-outline.warning .ant-radio-checked .ant-radio-inner,
    .ant-notification .ant-radio-group.ant-radio-group-outline.warning .ant-radio-checked .ant-radio-inner {
      border-color: #fbbc05; }
      .moby__layout .ant-radio-group.ant-radio-group-outline.warning .ant-radio-checked .ant-radio-inner:after,
      .ant-modal-content .ant-radio-group.ant-radio-group-outline.warning .ant-radio-checked .ant-radio-inner:after,
      .ant-popover-inner .ant-radio-group.ant-radio-group-outline.warning .ant-radio-checked .ant-radio-inner:after,
      .ant-drawer .ant-radio-group.ant-radio-group-outline.warning .ant-radio-checked .ant-radio-inner:after,
      .ant-dropdown .ant-radio-group.ant-radio-group-outline.warning .ant-radio-checked .ant-radio-inner:after,
      .ant-notification .ant-radio-group.ant-radio-group-outline.warning .ant-radio-checked .ant-radio-inner:after {
        background-color: #fbbc05; }
    .moby__layout .ant-radio-group.ant-radio-group-outline.warning .ant-radio-wrapper .ant-radio:hover .ant-radio-inner,
    .ant-modal-content .ant-radio-group.ant-radio-group-outline.warning .ant-radio-wrapper .ant-radio:hover .ant-radio-inner,
    .ant-popover-inner .ant-radio-group.ant-radio-group-outline.warning .ant-radio-wrapper .ant-radio:hover .ant-radio-inner,
    .ant-drawer .ant-radio-group.ant-radio-group-outline.warning .ant-radio-wrapper .ant-radio:hover .ant-radio-inner,
    .ant-dropdown .ant-radio-group.ant-radio-group-outline.warning .ant-radio-wrapper .ant-radio:hover .ant-radio-inner,
    .ant-notification .ant-radio-group.ant-radio-group-outline.warning .ant-radio-wrapper .ant-radio:hover .ant-radio-inner {
      border-color: #fbbc05; }
    .moby__layout .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-modal-content .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-popover-inner .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-drawer .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-dropdown .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-notification .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
      border-right-color: #fbbc05; }
    .moby__layout .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper:hover,
    .ant-modal-content .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper:hover,
    .ant-popover-inner .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper:hover,
    .ant-drawer .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper:hover,
    .ant-dropdown .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper:hover,
    .ant-notification .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper:hover {
      color: #fbbc05; }
    .moby__layout .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-modal-content .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-popover-inner .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-drawer .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-dropdown .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-notification .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: #fbbc05;
      border-color: #fbbc05; }
      .moby__layout .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-modal-content .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-popover-inner .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-drawer .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-dropdown .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-notification .ant-radio-group.ant-radio-group-outline.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
        background-color: #fbbc05; }
    .moby__layout .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-modal-content .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-popover-inner .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-drawer .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-dropdown .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-notification .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
      border-right-color: #ea4335; }
    .moby__layout .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper:hover,
    .ant-modal-content .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper:hover,
    .ant-popover-inner .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper:hover,
    .ant-drawer .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper:hover,
    .ant-dropdown .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper:hover,
    .ant-notification .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper:hover {
      color: white;
      border-color: #d62516;
      background-color: #ea4335; }
      .moby__layout .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper:hover:before,
      .ant-modal-content .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper:hover:before,
      .ant-popover-inner .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper:hover:before,
      .ant-drawer .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper:hover:before,
      .ant-dropdown .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper:hover:before,
      .ant-notification .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper:hover:before {
        background-color: #d62516; }
    .moby__layout .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-modal-content .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-popover-inner .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-drawer .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-dropdown .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-notification .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: white;
      border-color: #d62516;
      background-color: #ea4335; }
      .moby__layout .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-modal-content .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-popover-inner .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-drawer .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-dropdown .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-notification .ant-radio-group.ant-radio-group-solid.danger .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
        background-color: #d62516; }
    .moby__layout .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-modal-content .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-popover-inner .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-drawer .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-dropdown .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-notification .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
      border-right-color: #34a853; }
    .moby__layout .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper:hover,
    .ant-modal-content .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper:hover,
    .ant-popover-inner .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper:hover,
    .ant-drawer .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper:hover,
    .ant-dropdown .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper:hover,
    .ant-notification .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper:hover {
      color: white;
      border-color: #288140;
      background-color: #34a853; }
      .moby__layout .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper:hover:before,
      .ant-modal-content .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper:hover:before,
      .ant-popover-inner .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper:hover:before,
      .ant-drawer .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper:hover:before,
      .ant-dropdown .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper:hover:before,
      .ant-notification .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper:hover:before {
        background-color: #288140; }
    .moby__layout .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-modal-content .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-popover-inner .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-drawer .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-dropdown .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-notification .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: white;
      border-color: #288140;
      background-color: #34a853; }
      .moby__layout .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-modal-content .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-popover-inner .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-drawer .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-dropdown .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-notification .ant-radio-group.ant-radio-group-solid.success .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
        background-color: #288140; }
    .moby__layout .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-modal-content .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-popover-inner .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-drawer .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-dropdown .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-notification .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
      border-right-color: #fbbc05; }
    .moby__layout .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper:hover,
    .ant-modal-content .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper:hover,
    .ant-popover-inner .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper:hover,
    .ant-drawer .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper:hover,
    .ant-dropdown .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper:hover,
    .ant-notification .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper:hover {
      color: white;
      border-color: #ca9703;
      background-color: #fbbc05; }
      .moby__layout .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper:hover:before,
      .ant-modal-content .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper:hover:before,
      .ant-popover-inner .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper:hover:before,
      .ant-drawer .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper:hover:before,
      .ant-dropdown .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper:hover:before,
      .ant-notification .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper:hover:before {
        background-color: #ca9703; }
    .moby__layout .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-modal-content .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-popover-inner .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-drawer .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-dropdown .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-notification .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: white;
      border-color: #ca9703;
      background-color: #fbbc05; }
      .moby__layout .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-modal-content .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-popover-inner .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-drawer .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-dropdown .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-notification .ant-radio-group.ant-radio-group-solid.warning .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
        background-color: #ca9703; }
    .moby__layout .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-modal-content .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-popover-inner .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-drawer .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-dropdown .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child,
    .ant-notification .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
      border-right-color: #f5f7f7; }
    .moby__layout .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper:hover,
    .ant-modal-content .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper:hover,
    .ant-popover-inner .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper:hover,
    .ant-drawer .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper:hover,
    .ant-dropdown .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper:hover,
    .ant-notification .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper:hover {
      color: #202124;
      border-color: #d9e0e0;
      background-color: #f5f7f7; }
      .moby__layout .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper:hover:before,
      .ant-modal-content .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper:hover:before,
      .ant-popover-inner .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper:hover:before,
      .ant-drawer .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper:hover:before,
      .ant-dropdown .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper:hover:before,
      .ant-notification .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper:hover:before {
        background-color: #d9e0e0; }
    .moby__layout .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-modal-content .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-popover-inner .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-drawer .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-dropdown .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
    .ant-notification .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: #202124;
      border-color: #d9e0e0;
      background-color: #f5f7f7; }
      .moby__layout .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-modal-content .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-popover-inner .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-drawer .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-dropdown .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before,
      .ant-notification .ant-radio-group.ant-radio-group-solid.grey .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
        background-color: #d9e0e0; }
    .moby__layout .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-modal-content .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-popover-inner .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-drawer .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-dropdown .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-notification .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper {
      margin-right: 10px;
      border-radius: 4px;
      border: 1px solid #e3e8ea !important;
      color: #96a5ab;
      font-weight: 700; }
      .moby__layout .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-modal-content .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-popover-inner .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-drawer .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-dropdown .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-notification .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper::before {
        background-color: transparent; }
      .moby__layout .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper:hover,
      .ant-modal-content .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper:hover,
      .ant-popover-inner .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper:hover,
      .ant-drawer .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper:hover,
      .ant-dropdown .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper:hover,
      .ant-notification .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper:hover {
        background: #DBA44F;
        border-color: #cd8d2a !important;
        color: white; }
      @media screen and (max-width: 1440px) {
        .moby__layout .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper,
        .ant-modal-content .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper,
        .ant-popover-inner .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper,
        .ant-drawer .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper,
        .ant-dropdown .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper,
        .ant-notification .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper {
          padding: 0 10px;
          font-size: 12px;
          height: 30px;
          line-height: 28px; } }
      @media screen and (max-width: 480px) {
        .moby__layout .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper,
        .ant-modal-content .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper,
        .ant-popover-inner .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper,
        .ant-drawer .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper,
        .ant-dropdown .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper,
        .ant-notification .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper {
          margin-bottom: 10px; } }
      .moby__layout .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
      .ant-modal-content .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
      .ant-popover-inner .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
      .ant-drawer .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
      .ant-dropdown .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
      .ant-notification .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
        background: #DBA44F;
        border-color: #cd8d2a !important;
        color: white; }
      .moby__layout .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper:last-child,
      .ant-modal-content .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper:last-child,
      .ant-popover-inner .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper:last-child,
      .ant-drawer .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper:last-child,
      .ant-dropdown .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper:last-child,
      .ant-notification .ant-radio-group.radio-space-b.ant-radio-group-solid .ant-radio-button-wrapper:last-child {
        margin-right: 0; }
    @media screen and (max-width: 570px) {
      .moby__layout .ant-radio-group.radio-space-b.ant-radio-group-solid,
      .ant-modal-content .ant-radio-group.radio-space-b.ant-radio-group-solid,
      .ant-popover-inner .ant-radio-group.radio-space-b.ant-radio-group-solid,
      .ant-drawer .ant-radio-group.radio-space-b.ant-radio-group-solid,
      .ant-dropdown .ant-radio-group.radio-space-b.ant-radio-group-solid,
      .ant-notification .ant-radio-group.radio-space-b.ant-radio-group-solid {
        padding-top: 10px;
        width: 100%; } }
    .moby__layout .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-modal-content .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-popover-inner .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-drawer .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-dropdown .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-notification .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper {
      margin-bottom: 10px; }
    .moby__layout .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-modal-content .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-popover-inner .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-drawer .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-dropdown .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-notification .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper {
      box-shadow: none !important;
      border: 1px solid #e3e8ea !important;
      outline: none !important; }
      .moby__layout .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-modal-content .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-popover-inner .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-drawer .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-dropdown .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-notification .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper::before {
        background-color: transparent; }
    .moby__layout .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-modal-content .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-popover-inner .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-drawer .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-dropdown .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-notification .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover {
      background: #f9fafa !important;
      border-color: #c6d0d4 !important;
      color: #202124 !important; }
      .moby__layout .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover:after,
      .ant-modal-content .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover:after,
      .ant-popover-inner .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover:after,
      .ant-drawer .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover:after,
      .ant-dropdown .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover:after,
      .ant-notification .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover:after {
        background: transparent !important; }
    .moby__layout .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-modal-content .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-popover-inner .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-drawer .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-dropdown .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-notification .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper-checked {
      background: #f9fafa !important;
      border-color: #c6d0d4 !important;
      color: #202124 !important; }
      .moby__layout .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper-checked:after,
      .ant-modal-content .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper-checked:after,
      .ant-popover-inner .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper-checked:after,
      .ant-drawer .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper-checked:after,
      .ant-dropdown .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper-checked:after,
      .ant-notification .ant-radio-group.radio-space-b.grey.ant-radio-group-solid .ant-radio-button-wrapper-checked:after {
        background: transparent !important; }
    .moby__layout .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-modal-content .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-popover-inner .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-drawer .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-dropdown .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-notification .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper {
      box-shadow: none !important;
      border: 1px solid #e3e8ea !important;
      outline: none !important; }
      .moby__layout .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-modal-content .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-popover-inner .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-drawer .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-dropdown .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-notification .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper::before {
        background-color: transparent; }
    .moby__layout .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-modal-content .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-popover-inner .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-drawer .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-dropdown .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-notification .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover {
      background: #ea4335 !important;
      border-color: #d62516 !important;
      color: white !important; }
      .moby__layout .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover:after,
      .ant-modal-content .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover:after,
      .ant-popover-inner .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover:after,
      .ant-drawer .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover:after,
      .ant-dropdown .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover:after,
      .ant-notification .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover:after {
        background: transparent !important; }
    .moby__layout .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-modal-content .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-popover-inner .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-drawer .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-dropdown .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-notification .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked {
      background: #ea4335 !important;
      border-color: #d62516 !important;
      color: white !important; }
      .moby__layout .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked:after,
      .ant-modal-content .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked:after,
      .ant-popover-inner .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked:after,
      .ant-drawer .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked:after,
      .ant-dropdown .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked:after,
      .ant-notification .ant-radio-group.radio-space-b.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked:after {
        background: transparent !important; }
    .moby__layout .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-modal-content .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-popover-inner .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-drawer .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-dropdown .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-notification .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper {
      box-shadow: none !important;
      border: 1px solid #e3e8ea !important;
      outline: none !important; }
      .moby__layout .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-modal-content .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-popover-inner .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-drawer .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-dropdown .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-notification .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper::before {
        background-color: transparent; }
    .moby__layout .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-modal-content .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-popover-inner .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-drawer .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-dropdown .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-notification .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper:hover {
      background: #34a853 !important;
      border-color: #288140 !important;
      color: white !important; }
      .moby__layout .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper:hover:after,
      .ant-modal-content .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper:hover:after,
      .ant-popover-inner .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper:hover:after,
      .ant-drawer .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper:hover:after,
      .ant-dropdown .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper:hover:after,
      .ant-notification .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper:hover:after {
        background: transparent !important; }
    .moby__layout .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-modal-content .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-popover-inner .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-drawer .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-dropdown .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-notification .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper-checked {
      background: #34a853 !important;
      border-color: #288140 !important;
      color: white !important; }
      .moby__layout .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper-checked:after,
      .ant-modal-content .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper-checked:after,
      .ant-popover-inner .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper-checked:after,
      .ant-drawer .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper-checked:after,
      .ant-dropdown .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper-checked:after,
      .ant-notification .ant-radio-group.radio-space-b.success.ant-radio-group-solid .ant-radio-button-wrapper-checked:after {
        background: transparent !important; }
    .moby__layout .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-modal-content .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-popover-inner .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-drawer .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-dropdown .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper,
    .ant-notification .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper {
      box-shadow: none !important;
      border: 1px solid #e3e8ea !important;
      outline: none !important; }
      .moby__layout .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-modal-content .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-popover-inner .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-drawer .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-dropdown .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper::before,
      .ant-notification .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper::before {
        background-color: transparent; }
    .moby__layout .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-modal-content .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-popover-inner .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-drawer .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-dropdown .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-notification .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover {
      background: #fbbc05 !important;
      border-color: #ca9703 !important;
      color: white !important; }
      .moby__layout .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover:after,
      .ant-modal-content .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover:after,
      .ant-popover-inner .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover:after,
      .ant-drawer .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover:after,
      .ant-dropdown .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover:after,
      .ant-notification .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover:after {
        background: transparent !important; }
    .moby__layout .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-modal-content .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-popover-inner .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-drawer .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-dropdown .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-notification .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked {
      background: #fbbc05 !important;
      border-color: #ca9703 !important;
      color: white !important; }
      .moby__layout .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked:after,
      .ant-modal-content .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked:after,
      .ant-popover-inner .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked:after,
      .ant-drawer .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked:after,
      .ant-dropdown .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked:after,
      .ant-notification .ant-radio-group.radio-space-b.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked:after {
        background: transparent !important; }
    .moby__layout .ant-radio-group.circle-radio.ant-radio-group-solid,
    .ant-modal-content .ant-radio-group.circle-radio.ant-radio-group-solid,
    .ant-popover-inner .ant-radio-group.circle-radio.ant-radio-group-solid,
    .ant-drawer .ant-radio-group.circle-radio.ant-radio-group-solid,
    .ant-dropdown .ant-radio-group.circle-radio.ant-radio-group-solid,
    .ant-notification .ant-radio-group.circle-radio.ant-radio-group-solid {
      display: flex;
      align-content: center;
      justify-content: space-between; }
      .moby__layout .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper,
      .ant-modal-content .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper,
      .ant-popover-inner .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper,
      .ant-drawer .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper,
      .ant-dropdown .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper,
      .ant-notification .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper {
        border-radius: 4px;
        padding: 0 10px;
        border: 0;
        color: #96a5ab;
        font-weight: 700;
        box-shadow: none !important;
        outline: none !important; }
        .moby__layout .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper::before,
        .ant-modal-content .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper::before,
        .ant-popover-inner .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper::before,
        .ant-drawer .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper::before,
        .ant-dropdown .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper::before,
        .ant-notification .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper::before {
          background-color: transparent !important; }
        .moby__layout .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper::after,
        .ant-modal-content .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper::after,
        .ant-popover-inner .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper::after,
        .ant-drawer .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper::after,
        .ant-dropdown .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper::after,
        .ant-notification .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper::after {
          content: "";
          position: absolute;
          bottom: -3px;
          left: 0;
          right: 0;
          width: 6px;
          height: 6px;
          margin: 0 auto;
          border-radius: 44px;
          background-color: transparent !important; }
        .moby__layout .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper:hover,
        .ant-modal-content .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper:hover,
        .ant-popover-inner .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper:hover,
        .ant-drawer .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper:hover,
        .ant-dropdown .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper:hover,
        .ant-notification .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper:hover {
          background: transparent !important;
          color: #202124 !important;
          border: 0 !important;
          box-shadow: none !important; }
          .moby__layout .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
          .ant-modal-content .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
          .ant-popover-inner .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
          .ant-drawer .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
          .ant-dropdown .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
          .ant-notification .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper:hover::before {
            background-color: transparent !important; }
          .moby__layout .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
          .ant-modal-content .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
          .ant-popover-inner .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
          .ant-drawer .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
          .ant-dropdown .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
          .ant-notification .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper:hover::after {
            background-color: #DBA44F !important; }
      .moby__layout .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked,
      .ant-modal-content .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked,
      .ant-popover-inner .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked,
      .ant-drawer .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked,
      .ant-dropdown .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked,
      .ant-notification .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked {
        background: transparent !important;
        color: #202124 !important;
        border: 0 !important;
        box-shadow: none !important; }
        .moby__layout .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked::before,
        .ant-modal-content .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked::before,
        .ant-popover-inner .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked::before,
        .ant-drawer .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked::before,
        .ant-dropdown .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked::before,
        .ant-notification .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked::before {
          background-color: transparent !important; }
        .moby__layout .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
        .ant-modal-content .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
        .ant-popover-inner .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
        .ant-drawer .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
        .ant-dropdown .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
        .ant-notification .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked::after {
          background-color: #DBA44F !important; }
        .moby__layout .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked:focus,
        .ant-modal-content .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked:focus,
        .ant-popover-inner .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked:focus,
        .ant-drawer .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked:focus,
        .ant-dropdown .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked:focus,
        .ant-notification .ant-radio-group.circle-radio.ant-radio-group-solid .ant-radio-button-wrapper-checked:focus {
          outline: none !important; }
    .moby__layout .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-modal-content .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-popover-inner .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-drawer .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-dropdown .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-notification .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover {
      background: transparent;
      border-color: transparent !important;
      color: #202124 !important; }
      .moby__layout .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
      .ant-modal-content .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
      .ant-popover-inner .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
      .ant-drawer .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
      .ant-dropdown .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
      .ant-notification .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover::before {
        background-color: transparent !important; }
      .moby__layout .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-modal-content .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-popover-inner .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-drawer .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-dropdown .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-notification .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover::after {
        background-color: #ea4335 !important; }
    .moby__layout .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
    .ant-modal-content .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
    .ant-popover-inner .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
    .ant-drawer .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
    .ant-dropdown .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
    .ant-notification .ant-radio-group.circle-radio.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked::after {
      background-color: #ea4335 !important; }
    .moby__layout .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-modal-content .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-popover-inner .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-drawer .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-dropdown .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-notification .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper:hover {
      background: transparent;
      border-color: transparent !important;
      color: #202124 !important; }
      .moby__layout .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
      .ant-modal-content .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
      .ant-popover-inner .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
      .ant-drawer .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
      .ant-dropdown .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
      .ant-notification .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper:hover::before {
        background-color: transparent !important; }
      .moby__layout .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-modal-content .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-popover-inner .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-drawer .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-dropdown .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-notification .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper:hover::after {
        background-color: #34a853 !important; }
    .moby__layout .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
    .ant-modal-content .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
    .ant-popover-inner .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
    .ant-drawer .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
    .ant-dropdown .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
    .ant-notification .ant-radio-group.circle-radio.success.ant-radio-group-solid .ant-radio-button-wrapper-checked::after {
      background-color: #34a853 !important; }
    .moby__layout .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-modal-content .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-popover-inner .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-drawer .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-dropdown .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-notification .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover {
      background: transparent;
      border-color: transparent !important;
      color: #202124 !important; }
      .moby__layout .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
      .ant-modal-content .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
      .ant-popover-inner .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
      .ant-drawer .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
      .ant-dropdown .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
      .ant-notification .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover::before {
        background-color: transparent !important; }
      .moby__layout .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-modal-content .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-popover-inner .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-drawer .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-dropdown .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-notification .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover::after {
        background-color: #fbbc05 !important; }
    .moby__layout .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
    .ant-modal-content .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
    .ant-popover-inner .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
    .ant-drawer .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
    .ant-dropdown .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
    .ant-notification .ant-radio-group.circle-radio.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked::after {
      background-color: #fbbc05 !important; }
    .moby__layout .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-modal-content .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-popover-inner .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-drawer .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-dropdown .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-notification .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover {
      background: transparent;
      border-color: transparent !important;
      color: #202124 !important; }
      .moby__layout .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
      .ant-modal-content .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
      .ant-popover-inner .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
      .ant-drawer .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
      .ant-dropdown .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
      .ant-notification .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover::before {
        background-color: transparent !important; }
      .moby__layout .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-modal-content .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-popover-inner .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-drawer .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-dropdown .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-notification .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper:hover::after {
        background-color: #c6d0d4 !important; }
    .moby__layout .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
    .ant-modal-content .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
    .ant-popover-inner .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
    .ant-drawer .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
    .ant-dropdown .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
    .ant-notification .ant-radio-group.circle-radio.grey.ant-radio-group-solid .ant-radio-button-wrapper-checked::after {
      background-color: #c6d0d4 !important; }
    .moby__layout .ant-radio-group.border-radios.ant-radio-group-solid,
    .ant-modal-content .ant-radio-group.border-radios.ant-radio-group-solid,
    .ant-popover-inner .ant-radio-group.border-radios.ant-radio-group-solid,
    .ant-drawer .ant-radio-group.border-radios.ant-radio-group-solid,
    .ant-dropdown .ant-radio-group.border-radios.ant-radio-group-solid,
    .ant-notification .ant-radio-group.border-radios.ant-radio-group-solid {
      display: flex;
      align-content: center;
      justify-content: space-between; }
      .moby__layout .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper,
      .ant-modal-content .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper,
      .ant-popover-inner .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper,
      .ant-drawer .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper,
      .ant-dropdown .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper,
      .ant-notification .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper {
        border-radius: 4px;
        padding: 0;
        border: 0;
        color: #96a5ab;
        font-weight: 700;
        box-shadow: none !important;
        outline: none !important;
        background: transparent;
        height: 50px;
        line-height: 50px; }
        .moby__layout .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper::before,
        .ant-modal-content .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper::before,
        .ant-popover-inner .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper::before,
        .ant-drawer .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper::before,
        .ant-dropdown .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper::before,
        .ant-notification .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper::before {
          background-color: transparent !important; }
        .moby__layout .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper::after,
        .ant-modal-content .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper::after,
        .ant-popover-inner .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper::after,
        .ant-drawer .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper::after,
        .ant-dropdown .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper::after,
        .ant-notification .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background-color: transparent !important; }
        .moby__layout .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper:hover,
        .ant-modal-content .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper:hover,
        .ant-popover-inner .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper:hover,
        .ant-drawer .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper:hover,
        .ant-dropdown .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper:hover,
        .ant-notification .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper:hover {
          background: transparent !important;
          color: #202124 !important;
          border: 0 !important;
          box-shadow: none !important; }
          .moby__layout .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
          .ant-modal-content .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
          .ant-popover-inner .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
          .ant-drawer .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
          .ant-dropdown .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper:hover::before,
          .ant-notification .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper:hover::before {
            background-color: transparent !important; }
          .moby__layout .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
          .ant-modal-content .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
          .ant-popover-inner .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
          .ant-drawer .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
          .ant-dropdown .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
          .ant-notification .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper:hover::after {
            background-color: #c6d0d4 !important; }
      .moby__layout .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked,
      .ant-modal-content .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked,
      .ant-popover-inner .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked,
      .ant-drawer .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked,
      .ant-dropdown .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked,
      .ant-notification .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked {
        background: transparent !important;
        color: #202124 !important;
        border: 0 !important;
        box-shadow: none !important; }
        .moby__layout .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked::before,
        .ant-modal-content .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked::before,
        .ant-popover-inner .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked::before,
        .ant-drawer .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked::before,
        .ant-dropdown .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked::before,
        .ant-notification .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked::before {
          background-color: transparent !important; }
        .moby__layout .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
        .ant-modal-content .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
        .ant-popover-inner .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
        .ant-drawer .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
        .ant-dropdown .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
        .ant-notification .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked::after {
          background-color: #c6d0d4 !important; }
        .moby__layout .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked:focus,
        .ant-modal-content .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked:focus,
        .ant-popover-inner .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked:focus,
        .ant-drawer .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked:focus,
        .ant-dropdown .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked:focus,
        .ant-notification .ant-radio-group.border-radios.ant-radio-group-solid .ant-radio-button-wrapper-checked:focus {
          outline: none !important; }
    .moby__layout .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-modal-content .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-popover-inner .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-drawer .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-dropdown .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-notification .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper:hover {
      color: #DBA44F !important; }
      .moby__layout .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-modal-content .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-popover-inner .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-drawer .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-dropdown .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-notification .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper:hover::after {
        background-color: #DBA44F !important; }
    .moby__layout .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-modal-content .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-popover-inner .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-drawer .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-dropdown .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-notification .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper-checked {
      color: #DBA44F !important; }
      .moby__layout .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
      .ant-modal-content .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
      .ant-popover-inner .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
      .ant-drawer .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
      .ant-dropdown .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
      .ant-notification .ant-radio-group.border-radios.primary.ant-radio-group-solid .ant-radio-button-wrapper-checked::after {
        background-color: #DBA44F !important; }
    .moby__layout .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-modal-content .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-popover-inner .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-drawer .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-dropdown .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-notification .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover {
      color: #ea4335 !important; }
      .moby__layout .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-modal-content .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-popover-inner .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-drawer .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-dropdown .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-notification .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper:hover::after {
        background-color: #ea4335 !important; }
    .moby__layout .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-modal-content .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-popover-inner .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-drawer .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-dropdown .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-notification .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked {
      color: #ea4335 !important; }
      .moby__layout .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
      .ant-modal-content .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
      .ant-popover-inner .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
      .ant-drawer .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
      .ant-dropdown .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
      .ant-notification .ant-radio-group.border-radios.danger.ant-radio-group-solid .ant-radio-button-wrapper-checked::after {
        background-color: #ea4335 !important; }
    .moby__layout .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-modal-content .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-popover-inner .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-drawer .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-dropdown .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-notification .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover {
      color: #fbbc05 !important; }
      .moby__layout .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-modal-content .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-popover-inner .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-drawer .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-dropdown .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-notification .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper:hover::after {
        background-color: #fbbc05 !important; }
    .moby__layout .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-modal-content .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-popover-inner .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-drawer .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-dropdown .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-notification .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked {
      color: #fbbc05 !important; }
      .moby__layout .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
      .ant-modal-content .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
      .ant-popover-inner .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
      .ant-drawer .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
      .ant-dropdown .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
      .ant-notification .ant-radio-group.border-radios.warning.ant-radio-group-solid .ant-radio-button-wrapper-checked::after {
        background-color: #fbbc05 !important; }
    .moby__layout .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-modal-content .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-popover-inner .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-drawer .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-dropdown .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper:hover,
    .ant-notification .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper:hover {
      color: #34a853 !important; }
      .moby__layout .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-modal-content .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-popover-inner .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-drawer .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-dropdown .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper:hover::after,
      .ant-notification .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper:hover::after {
        background-color: #34a853 !important; }
    .moby__layout .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-modal-content .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-popover-inner .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-drawer .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-dropdown .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper-checked,
    .ant-notification .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper-checked {
      color: #34a853 !important; }
      .moby__layout .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
      .ant-modal-content .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
      .ant-popover-inner .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
      .ant-drawer .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
      .ant-dropdown .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper-checked::after,
      .ant-notification .ant-radio-group.border-radios.success.ant-radio-group-solid .ant-radio-button-wrapper-checked::after {
        background-color: #34a853 !important; }
    @media (max-width: 480px) {
      .moby__layout .ant-radio-group.ant-radio-group-solid .ant-radio-button-wrapper, .moby__layout .ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper,
      .ant-modal-content .ant-radio-group.ant-radio-group-solid .ant-radio-button-wrapper,
      .ant-modal-content .ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper,
      .ant-popover-inner .ant-radio-group.ant-radio-group-solid .ant-radio-button-wrapper,
      .ant-popover-inner .ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper,
      .ant-drawer .ant-radio-group.ant-radio-group-solid .ant-radio-button-wrapper,
      .ant-drawer .ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper,
      .ant-dropdown .ant-radio-group.ant-radio-group-solid .ant-radio-button-wrapper,
      .ant-dropdown .ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper,
      .ant-notification .ant-radio-group.ant-radio-group-solid .ant-radio-button-wrapper,
      .ant-notification .ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper {
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 0 !important; } }
  .moby__layout .ant-checkbox-wrapper.danger .ant-checkbox-checked:after,
  .ant-modal-content .ant-checkbox-wrapper.danger .ant-checkbox-checked:after,
  .ant-popover-inner .ant-checkbox-wrapper.danger .ant-checkbox-checked:after,
  .ant-drawer .ant-checkbox-wrapper.danger .ant-checkbox-checked:after,
  .ant-dropdown .ant-checkbox-wrapper.danger .ant-checkbox-checked:after,
  .ant-notification .ant-checkbox-wrapper.danger .ant-checkbox-checked:after {
    border-color: #ea4335; }
  .moby__layout .ant-checkbox-wrapper.danger .ant-checkbox-checked .ant-checkbox-inner,
  .ant-modal-content .ant-checkbox-wrapper.danger .ant-checkbox-checked .ant-checkbox-inner,
  .ant-popover-inner .ant-checkbox-wrapper.danger .ant-checkbox-checked .ant-checkbox-inner,
  .ant-drawer .ant-checkbox-wrapper.danger .ant-checkbox-checked .ant-checkbox-inner,
  .ant-dropdown .ant-checkbox-wrapper.danger .ant-checkbox-checked .ant-checkbox-inner,
  .ant-notification .ant-checkbox-wrapper.danger .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #ea4335;
    background-color: #ea4335; }
  .moby__layout .ant-checkbox-wrapper.success .ant-checkbox-checked:after,
  .ant-modal-content .ant-checkbox-wrapper.success .ant-checkbox-checked:after,
  .ant-popover-inner .ant-checkbox-wrapper.success .ant-checkbox-checked:after,
  .ant-drawer .ant-checkbox-wrapper.success .ant-checkbox-checked:after,
  .ant-dropdown .ant-checkbox-wrapper.success .ant-checkbox-checked:after,
  .ant-notification .ant-checkbox-wrapper.success .ant-checkbox-checked:after {
    border-color: #34a853; }
  .moby__layout .ant-checkbox-wrapper.success .ant-checkbox-checked .ant-checkbox-inner,
  .ant-modal-content .ant-checkbox-wrapper.success .ant-checkbox-checked .ant-checkbox-inner,
  .ant-popover-inner .ant-checkbox-wrapper.success .ant-checkbox-checked .ant-checkbox-inner,
  .ant-drawer .ant-checkbox-wrapper.success .ant-checkbox-checked .ant-checkbox-inner,
  .ant-dropdown .ant-checkbox-wrapper.success .ant-checkbox-checked .ant-checkbox-inner,
  .ant-notification .ant-checkbox-wrapper.success .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #34a853;
    background-color: #34a853; }
  .moby__layout .ant-checkbox-wrapper.warning .ant-checkbox-checked:after,
  .ant-modal-content .ant-checkbox-wrapper.warning .ant-checkbox-checked:after,
  .ant-popover-inner .ant-checkbox-wrapper.warning .ant-checkbox-checked:after,
  .ant-drawer .ant-checkbox-wrapper.warning .ant-checkbox-checked:after,
  .ant-dropdown .ant-checkbox-wrapper.warning .ant-checkbox-checked:after,
  .ant-notification .ant-checkbox-wrapper.warning .ant-checkbox-checked:after {
    border-color: #fbbc05; }
  .moby__layout .ant-checkbox-wrapper.warning .ant-checkbox-checked .ant-checkbox-inner,
  .ant-modal-content .ant-checkbox-wrapper.warning .ant-checkbox-checked .ant-checkbox-inner,
  .ant-popover-inner .ant-checkbox-wrapper.warning .ant-checkbox-checked .ant-checkbox-inner,
  .ant-drawer .ant-checkbox-wrapper.warning .ant-checkbox-checked .ant-checkbox-inner,
  .ant-dropdown .ant-checkbox-wrapper.warning .ant-checkbox-checked .ant-checkbox-inner,
  .ant-notification .ant-checkbox-wrapper.warning .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #fbbc05;
    background-color: #fbbc05; }
  .moby__layout .ant-checkbox-group.danger .ant-checkbox-checked:after,
  .ant-modal-content .ant-checkbox-group.danger .ant-checkbox-checked:after,
  .ant-popover-inner .ant-checkbox-group.danger .ant-checkbox-checked:after,
  .ant-drawer .ant-checkbox-group.danger .ant-checkbox-checked:after,
  .ant-dropdown .ant-checkbox-group.danger .ant-checkbox-checked:after,
  .ant-notification .ant-checkbox-group.danger .ant-checkbox-checked:after {
    border-color: #ea4335; }
  .moby__layout .ant-checkbox-group.danger .ant-checkbox-checked .ant-checkbox-inner,
  .ant-modal-content .ant-checkbox-group.danger .ant-checkbox-checked .ant-checkbox-inner,
  .ant-popover-inner .ant-checkbox-group.danger .ant-checkbox-checked .ant-checkbox-inner,
  .ant-drawer .ant-checkbox-group.danger .ant-checkbox-checked .ant-checkbox-inner,
  .ant-dropdown .ant-checkbox-group.danger .ant-checkbox-checked .ant-checkbox-inner,
  .ant-notification .ant-checkbox-group.danger .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #ea4335;
    background-color: #ea4335; }
  .moby__layout .ant-checkbox-group.success .ant-checkbox-checked:after,
  .ant-modal-content .ant-checkbox-group.success .ant-checkbox-checked:after,
  .ant-popover-inner .ant-checkbox-group.success .ant-checkbox-checked:after,
  .ant-drawer .ant-checkbox-group.success .ant-checkbox-checked:after,
  .ant-dropdown .ant-checkbox-group.success .ant-checkbox-checked:after,
  .ant-notification .ant-checkbox-group.success .ant-checkbox-checked:after {
    border-color: #34a853; }
  .moby__layout .ant-checkbox-group.success .ant-checkbox-checked .ant-checkbox-inner,
  .ant-modal-content .ant-checkbox-group.success .ant-checkbox-checked .ant-checkbox-inner,
  .ant-popover-inner .ant-checkbox-group.success .ant-checkbox-checked .ant-checkbox-inner,
  .ant-drawer .ant-checkbox-group.success .ant-checkbox-checked .ant-checkbox-inner,
  .ant-dropdown .ant-checkbox-group.success .ant-checkbox-checked .ant-checkbox-inner,
  .ant-notification .ant-checkbox-group.success .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #34a853;
    background-color: #34a853; }
  .moby__layout .ant-checkbox-group.warning .ant-checkbox-checked:after,
  .ant-modal-content .ant-checkbox-group.warning .ant-checkbox-checked:after,
  .ant-popover-inner .ant-checkbox-group.warning .ant-checkbox-checked:after,
  .ant-drawer .ant-checkbox-group.warning .ant-checkbox-checked:after,
  .ant-dropdown .ant-checkbox-group.warning .ant-checkbox-checked:after,
  .ant-notification .ant-checkbox-group.warning .ant-checkbox-checked:after {
    border-color: #fbbc05; }
  .moby__layout .ant-checkbox-group.warning .ant-checkbox-checked .ant-checkbox-inner,
  .ant-modal-content .ant-checkbox-group.warning .ant-checkbox-checked .ant-checkbox-inner,
  .ant-popover-inner .ant-checkbox-group.warning .ant-checkbox-checked .ant-checkbox-inner,
  .ant-drawer .ant-checkbox-group.warning .ant-checkbox-checked .ant-checkbox-inner,
  .ant-dropdown .ant-checkbox-group.warning .ant-checkbox-checked .ant-checkbox-inner,
  .ant-notification .ant-checkbox-group.warning .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #fbbc05;
    background-color: #fbbc05; }
  .moby__layout .group-btn .ant-btn,
  .ant-modal-content .group-btn .ant-btn,
  .ant-popover-inner .group-btn .ant-btn,
  .ant-drawer .group-btn .ant-btn,
  .ant-dropdown .group-btn .ant-btn,
  .ant-notification .group-btn .ant-btn {
    margin-right: 10px; }
    .moby__layout .group-btn .ant-btn:first-child,
    .ant-modal-content .group-btn .ant-btn:first-child,
    .ant-popover-inner .group-btn .ant-btn:first-child,
    .ant-drawer .group-btn .ant-btn:first-child,
    .ant-dropdown .group-btn .ant-btn:first-child,
    .ant-notification .group-btn .ant-btn:first-child {
      margin-left: 0; }
    .moby__layout .group-btn .ant-btn .icon,
    .ant-modal-content .group-btn .ant-btn .icon,
    .ant-popover-inner .group-btn .ant-btn .icon,
    .ant-drawer .group-btn .ant-btn .icon,
    .ant-dropdown .group-btn .ant-btn .icon,
    .ant-notification .group-btn .ant-btn .icon {
      margin-right: 7px; }
    .moby__layout .group-btn .ant-btn .anticon,
    .ant-modal-content .group-btn .ant-btn .anticon,
    .ant-popover-inner .group-btn .ant-btn .anticon,
    .ant-drawer .group-btn .ant-btn .anticon,
    .ant-dropdown .group-btn .ant-btn .anticon,
    .ant-notification .group-btn .ant-btn .anticon {
      margin-right: 0; }
    .moby__layout .group-btn .ant-btn.ant-btn-circle .icon,
    .moby__layout .group-btn .ant-btn.ant-btn-circle .anticon,
    .ant-modal-content .group-btn .ant-btn.ant-btn-circle .icon,
    .ant-modal-content .group-btn .ant-btn.ant-btn-circle .anticon,
    .ant-popover-inner .group-btn .ant-btn.ant-btn-circle .icon,
    .ant-popover-inner .group-btn .ant-btn.ant-btn-circle .anticon,
    .ant-drawer .group-btn .ant-btn.ant-btn-circle .icon,
    .ant-drawer .group-btn .ant-btn.ant-btn-circle .anticon,
    .ant-dropdown .group-btn .ant-btn.ant-btn-circle .icon,
    .ant-dropdown .group-btn .ant-btn.ant-btn-circle .anticon,
    .ant-notification .group-btn .ant-btn.ant-btn-circle .icon,
    .ant-notification .group-btn .ant-btn.ant-btn-circle .anticon {
      margin: 0; }
  .moby__layout .group-btn-mr-r-b .ant-btn,
  .ant-modal-content .group-btn-mr-r-b .ant-btn,
  .ant-popover-inner .group-btn-mr-r-b .ant-btn,
  .ant-drawer .group-btn-mr-r-b .ant-btn,
  .ant-dropdown .group-btn-mr-r-b .ant-btn,
  .ant-notification .group-btn-mr-r-b .ant-btn {
    margin-right: 10px;
    margin-bottom: 15px; }
    .moby__layout .group-btn-mr-r-b .ant-btn:last-child,
    .ant-modal-content .group-btn-mr-r-b .ant-btn:last-child,
    .ant-popover-inner .group-btn-mr-r-b .ant-btn:last-child,
    .ant-drawer .group-btn-mr-r-b .ant-btn:last-child,
    .ant-dropdown .group-btn-mr-r-b .ant-btn:last-child,
    .ant-notification .group-btn-mr-r-b .ant-btn:last-child {
      margin-right: 0;
      margin-bottom: 0; }
    .moby__layout .group-btn-mr-r-b .ant-btn .icon,
    .ant-modal-content .group-btn-mr-r-b .ant-btn .icon,
    .ant-popover-inner .group-btn-mr-r-b .ant-btn .icon,
    .ant-drawer .group-btn-mr-r-b .ant-btn .icon,
    .ant-dropdown .group-btn-mr-r-b .ant-btn .icon,
    .ant-notification .group-btn-mr-r-b .ant-btn .icon {
      margin-right: 7px; }
    .moby__layout .group-btn-mr-r-b .ant-btn .anticon,
    .ant-modal-content .group-btn-mr-r-b .ant-btn .anticon,
    .ant-popover-inner .group-btn-mr-r-b .ant-btn .anticon,
    .ant-drawer .group-btn-mr-r-b .ant-btn .anticon,
    .ant-dropdown .group-btn-mr-r-b .ant-btn .anticon,
    .ant-notification .group-btn-mr-r-b .ant-btn .anticon {
      margin-right: 0; }
    .moby__layout .group-btn-mr-r-b .ant-btn.ant-btn-circle .icon,
    .moby__layout .group-btn-mr-r-b .ant-btn.ant-btn-circle .anticon,
    .ant-modal-content .group-btn-mr-r-b .ant-btn.ant-btn-circle .icon,
    .ant-modal-content .group-btn-mr-r-b .ant-btn.ant-btn-circle .anticon,
    .ant-popover-inner .group-btn-mr-r-b .ant-btn.ant-btn-circle .icon,
    .ant-popover-inner .group-btn-mr-r-b .ant-btn.ant-btn-circle .anticon,
    .ant-drawer .group-btn-mr-r-b .ant-btn.ant-btn-circle .icon,
    .ant-drawer .group-btn-mr-r-b .ant-btn.ant-btn-circle .anticon,
    .ant-dropdown .group-btn-mr-r-b .ant-btn.ant-btn-circle .icon,
    .ant-dropdown .group-btn-mr-r-b .ant-btn.ant-btn-circle .anticon,
    .ant-notification .group-btn-mr-r-b .ant-btn.ant-btn-circle .icon,
    .ant-notification .group-btn-mr-r-b .ant-btn.ant-btn-circle .anticon {
      margin: 0; }

/** /.Theme Layout **/
/** Theme Default Card **/
.ant-card {
  margin-bottom: 30px !important;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05), 0 1px 20px 0 rgba(0, 0, 0, 0.05), 0px 1px 11px 0px rgba(0, 0, 0, 0.05);
  /** Card Head **/
  /** /.Card Head **/
  /** Card Extra **/
  /** /.Card Extra **/
  /** Card Head Title **/
  /** /.Card Head Title **/
  /** Card Extra Actions **/
  /** Card Extra Actions **/
  /** Card Extra Menu **/
  /** /.Card Extra Menu **/
  /** Card Body With Not Padding Top **/
  /** Ends Card Body With Not Padding Top **/
  /** Card Cover **/
  /** Card Cover **/
  /** Card Body **/
  /** Card Body **/
  /** Card With Head Border 0 **/
  /** /.Card With Head Border 0 **/
  /** Card With Head Margin Bottom 0 **/
  /** /.Card With Head Margin Bottom 0 **/
  /** Card With Head Bg White **/
  /** /.Card With Head Bg White **/
  /** Card With Head Bg Grey **/
  /** /.Card With Head Bg Grey **/
  /** Card With Head SubTitle Color Grey **/
  /** /.Card With Head SubTitle Color Grey **/
  /** Card With Body Bg Primary **/
  /** /.Card With Body Bg Primary **/
  /** Card With Body Padding **/
  /** /.Card With Body Padding **/
  /** Card With Body Padding None **/
  /** /.Card With Body Padding None **/
  /** Card With Body Max_Height 100% **/
  /** /.Card With Body Max_Height 100% **/
  /** Card Overflow Hidden **/
  /** /.Card Overflow Hidden **/
  /** Card With Body Overflow Hidden **/
  /** /.Card With Body Overflow Hideden **/
  /** Card With Body Overflow Visible **/
  /** /.Card With Body Overflow Visible **/
  /** Card Box Shadow None **/
  /** /.Card Box Shadow **/
  /** Theme Stat Card **/
  /** /.Theme Stat Card **/
  /** Theme Real State Card **/
  /** /.Theme Real State Card **/
  /** Theme Meetup Cards **/
  /** /.Theme Meetup Cards **/
  /** Theme Social Connection Cards **/
  /** /.Theme Social Connection Cards **/
  /** Theme Meetup Cards **/
  /** /.Theme Meetup Cards **/
  /** Theme Filed Cards **/
  /** /.Theme Filed Cards **/
  /** Theme Conference Cards **/
  /** /.Theme Conference Cards **/
  /** Theme Topic Card **/
  /** /.Theme Topic Card **/
  /** Theme File Card **/
  /** /.Theme File Card **/
  /** Theme Blog Post Card **/
  /** /.Theme Blog Post Card **/
  /** Theme Profile Overview Card **/
  /** /.Theme Profile Overview Card **/
  /** Theme Project Card **/
  /** /.Theme Project Card **/ }
  .ant-card .ant-card-head {
    border-color: transparent; }
  .ant-card .ant-card-extra {
    float: none;
    display: flex;
    padding: 12px 0; }
    .ant-card .ant-card-extra .list-num {
      font-size: 16px;
      color: #96a5ab;
      display: inline-block; }
      @media screen and (max-width: 1440px) {
        .ant-card .ant-card-extra .list-num {
          font-size: 15px; } }
  .ant-card .ant-card-head-title {
    font-weight: 800;
    font-size: 18px;
    /** Card Head SubTitle **/
    /** /.Card Head SubTitle **/ }
    @media (max-width: 1280px) {
      .ant-card .ant-card-head-title {
        font-size: 16px; } }
    .ant-card .ant-card-head-title .sub-title {
      font-size: 12px;
      color: #a0b0b6;
      padding-left: 5px; }
    .ant-card .ant-card-head-title .title-icon {
      font-size: 30px;
      margin-right: 10px; }
  .ant-card .moby__extra-actions {
    margin-right: 10px;
    /** Card Extra Button **/
    /** /.Card Extra Button **/
    /** Card Extra Select **/
    /** Card Extra Select **/ }
    .ant-card .moby__extra-actions .ant-btn-background-ghost {
      border: transparent;
      padding: 0;
      color: #96a5ab;
      font-weight: 500;
      margin: 0 10px;
      transition: all ease-in-out 0.4s;
      box-shadow: none; }
      .ant-card .moby__extra-actions .ant-btn-background-ghost .icon {
        font-size: 11px !important;
        margin-right: 8px !important; }
      .ant-card .moby__extra-actions .ant-btn-background-ghost:hover {
        color: #202124; }
      @media screen and (max-width: 1024px) {
        .ant-card .moby__extra-actions .ant-btn-background-ghost {
          margin: 0 5px;
          font-size: 12px; } }
    .ant-card .moby__extra-actions .ant-select {
      margin-left: 10px;
      font-size: 13px;
      /** Card Extra Select Style 1 **/
      /** /.Card Extra Select Style 1 **/ }
      .ant-card .moby__extra-actions .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 32px;
        padding: 0 10px; }
      .ant-card .moby__extra-actions .ant-select.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        padding-right: 13px !important; }
      .ant-card .moby__extra-actions .ant-select.select-borderless {
        margin-left: 0;
        color: #a0b0b6;
        font-weight: 500;
        letter-spacing: 1px; }
        .ant-card .moby__extra-actions .ant-select.select-borderless.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
          border-color: transparent !important;
          background: transparent;
          height: 28px; }
          .ant-card .moby__extra-actions .ant-select.select-borderless.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
            line-height: 28px; }
          .ant-card .moby__extra-actions .ant-select.select-borderless.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:hover, .ant-card .moby__extra-actions .ant-select.select-borderless.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:focus {
            box-shadow: none;
            border-color: transparent;
            outline: none; }
        .ant-card .moby__extra-actions .ant-select.select-borderless.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
          box-shadow: none;
          border-color: transparent;
          outline: none; }
        .ant-card .moby__extra-actions .ant-select.select-borderless .ant-select-arrow {
          top: 50%; }
        @media screen and (max-width: 1440px) {
          .ant-card .moby__extra-actions .ant-select.select-borderless {
            font-size: 12px; } }
    @media screen and (max-width: 1024px) {
      .ant-card .moby__extra-actions {
        margin-right: 5px !important; } }
    @media screen and (max-width: 480px) {
      .ant-card .moby__extra-actions {
        display: none; } }
  .ant-card .moby__icon-menu {
    display: flex;
    align-items: center;
    margin-left: 5px !important; }
    .ant-card .moby__icon-menu li {
      padding: 0 8px; }
      .ant-card .moby__icon-menu li:last-child {
        padding-right: 0;
        padding-left: 5px; }
      .ant-card .moby__icon-menu li .anticon {
        cursor: pointer;
        vertical-align: middle; }
        .ant-card .moby__icon-menu li .anticon:hover {
          color: #202124 !important; }
      .ant-card .moby__icon-menu li .icon {
        transition: all ease-in-out 0.4s;
        font-size: 16px;
        cursor: pointer;
        vertical-align: middle; }
        .ant-card .moby__icon-menu li .icon:hover {
          border-color: #202124 !important;
          color: #202124 !important; }
        @media screen and (max-width: 1024px) {
          .ant-card .moby__icon-menu li .icon {
            font-size: 15px; } }
      @media screen and (max-width: 1024px) {
        .ant-card .moby__icon-menu li {
          padding: 0 6px; } }
  .ant-card > *:nth-child(2) {
    padding-top: 0; }
  .ant-card .ant-card-cover {
    border-bottom: 1px solid #e3e8ea;
    margin-bottom: 20px; }
    .ant-card .ant-card-cover > div {
      border-radius: 4px 4px 0 0;
      min-height: 300px;
      background-repeat: no-repeat;
      background-position: 50% 0%;
      background-size: cover; }
  .ant-card .ant-card-body {
    max-height: 520px; }
    @media (max-width: 480px) {
      .ant-card .ant-card-body {
        max-height: 100%;
        min-width: 100%; } }
  .ant-card .description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 20px;
    /* fallback */
    -webkit-line-clamp: 5;
    /* number of lines to show */
    -webkit-box-orient: vertical; }
  .ant-card.head-border-0 .ant-card-head {
    border: 0; }
  .ant-card.head-mr-b-0 .ant-card-head {
    margin-bottom: 0; }
  .ant-card.head-bg-white .ant-card-head {
    background: white; }
  .ant-card.head-bg-grey .ant-card-head {
    background: #f9fafa; }
  .ant-card.head-subtitle-color-grey .ant-card-head-title {
    /** Card Head SubTitle **/
    /** /.Card Head SubTitle **/ }
    .ant-card.head-subtitle-color-grey .ant-card-head-title .sub-title {
      color: rgba(32, 33, 36, 0.5) !important; }
  .ant-card.body-bg-grey .ant-card-body {
    background: #f9fafa; }
  .ant-card.body-pd-top-bottom .ant-card-body {
    padding: 20px 0px; }
  .ant-card.body-pd-left-right .ant-card-body {
    padding: 0 20px; }
  .ant-card.body-pd-top .ant-card-body {
    padding-top: 20px; }
  .ant-card.body-pd-bottom .ant-card-body {
    padding-bottom: 20px; }
  .ant-card.body-pd-0 .ant-card-body {
    padding: 0; }
  .ant-card.body-height-100 .ant-card-body {
    max-height: 100% !important; }
  .ant-card .card-overflow-h {
    overflow: hidden !important; }
  .ant-card.body-overflow-hidden .ant-card-body {
    overflow: hidden !important; }
  .ant-card.body-overflow-hidden-x .ant-card-body {
    overflow-x: hidden !important; }
  .ant-card.body-overflow-hidden-y .ant-card-body {
    overflow-y: hidden !important; }
  .ant-card.body-overflow-visible .ant-card-body {
    overflow: visible !important; }
  .ant-card.card-shadow-none {
    box-shadow: none; }
  .ant-card.stat-card {
    border: 0;
    border-left: 1px solid #e3e8ea;
    border-radius: 0px;
    transition: all ease-in-out .4s;
    overflow: hidden;
    margin-bottom: 0 !important;
    /** Card Head **/
    /** /.Card Head **/
    /** Card Extra **/
    /** Card Extra **/
    /** Card Boday **/
    /** /.Card Body **/
    /** Card Footer **/
    /** /.Card Footer **/
    /** Card Footer List **/
    /** /.Card Footer List **/
    /** Card Footer Text **/
    /** /.Card Footer Text **/
    /** Card Color Class Danger **/
    /** /.Card Color Class Danger **/
    /** Card Color Class Primary **/
    /** /.Card Color Class Primary **/
    /** Card Color Class Success **/
    /** /.Card Color Class Success **/
    /** Card Color Class Warning **/
    /** /.Card Color Class Warning **/ }
    .ant-card.stat-card:hover {
      background: #f5f7f7; }
    .ant-card.stat-card .ant-card-head {
      border: 0;
      background: transparent; }
      @media screen and (max-width: 1024px) {
        .ant-card.stat-card .ant-card-head {
          padding: 0 10px; } }
    .ant-card.stat-card .ant-card-extra {
      width: 100%;
      justify-content: space-between;
      padding: 20px 0; }
      .ant-card.stat-card .ant-card-extra .icon {
        font-size: 18px;
        cursor: pointer; }
        @media screen and (max-width: 1440px) {
          .ant-card.stat-card .ant-card-extra .icon svg {
            width: 16px !important;
            height: 16px !important; } }
    .ant-card.stat-card .ant-card-body {
      position: relative;
      /** Card Animate Icon **/
      /** /.Card Animate Icon **/
      /** Card Body Title **/
      /** /.Card Body Title **/
      /** Card Body SubTitle **/
      /** Card Body SubTitle **/
      /** Card Body Progress Bar **/
      /** /.Card Body Progress Bar **/ }
      .ant-card.stat-card .ant-card-body .animate-icon {
        font-size: 26px;
        color: rgba(150, 165, 171, 0.5);
        transition: all ease-in-out .4s; }
        @media screen and (max-width: 1440px) {
          .ant-card.stat-card .ant-card-body .animate-icon {
            font-size: 24px; } }
      .ant-card.stat-card .ant-card-body .animate-icon-left {
        position: absolute;
        top: 20px;
        left: -5px; }
      .ant-card.stat-card .ant-card-body .animate-icon-right {
        position: absolute;
        bottom: 80px;
        right: -5px;
        font-size: 30px; }
      .ant-card.stat-card .ant-card-body .card-title {
        font-size: 15px;
        color: #202124;
        font-weight: 500;
        margin-bottom: 10px; }
        @media screen and (max-width: 1440px) {
          .ant-card.stat-card .ant-card-body .card-title {
            font-size: 14px; } }
      .ant-card.stat-card .ant-card-body .card-sub-title {
        font-size: 25px;
        color: #202124;
        font-weight: 700;
        display: block;
        margin-bottom: 30px; }
        @media screen and (max-width: 1440px) {
          .ant-card.stat-card .ant-card-body .card-sub-title {
            font-size: 20px; } }
      .ant-card.stat-card .ant-card-body .card-progress-bar {
        position: relative;
        padding-bottom: 30px; }
        .ant-card.stat-card .ant-card-body .card-progress-bar .progress-bar-icon {
          position: absolute;
          top: 35px;
          left: 1px;
          right: 0;
          margin: 0 auto; }
          .ant-card.stat-card .ant-card-body .card-progress-bar .progress-bar-icon.icon {
            color: #E5E1E1 !important;
            font-size: 30.8px;
            transition: all ease-in-out .4s; }
        .ant-card.stat-card .ant-card-body .card-progress-bar .ant-progress-circle-path {
          stroke: #202124 !important; }
        .ant-card.stat-card .ant-card-body .card-progress-bar .ant-progress-inner {
          width: 100px !important;
          height: 100px !important; }
          .ant-card.stat-card .ant-card-body .card-progress-bar .ant-progress-inner .ant-progress-text {
            display: none; }
    .ant-card.stat-card .card-footer {
      padding: 0 20px 20px; }
      @media screen and (max-width: 1024px) {
        .ant-card.stat-card .card-footer {
          padding: 0 10px 20px; } }
    .ant-card.stat-card .card-footer-list {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .ant-card.stat-card .card-footer-list li {
        width: 50%;
        position: relative;
        line-height: 20px; }
        .ant-card.stat-card .card-footer-list li::before {
          content: "";
          position: absolute;
          top: 4px;
          left: 0;
          height: 85%;
          border-right: 1px solid #e3e8ea; }
          @media screen and (max-width: 1440px) {
            .ant-card.stat-card .card-footer-list li::before {
              left: 6px;
              height: 80%; } }
          @media screen and (max-width: 1300px) {
            .ant-card.stat-card .card-footer-list li::before {
              left: 0px; } }
        .ant-card.stat-card .card-footer-list li:first-child::before {
          display: none; }
        .ant-card.stat-card .card-footer-list li:last-child {
          padding-left: 15px; }
          @media screen and (max-width: 1300px) {
            .ant-card.stat-card .card-footer-list li:last-child {
              padding-left: 10px; } }
      .ant-card.stat-card .card-footer-list .card-footer-icon {
        margin-right: 7px;
        font-size: 16px;
        vertical-align: top; }
        .ant-card.stat-card .card-footer-list .card-footer-icon .icon svg {
          width: 15px !important;
          height: 15px !important; }
        @media screen and (max-width: 1440px) {
          .ant-card.stat-card .card-footer-list .card-footer-icon {
            margin-right: 5px;
            font-size: 14px;
            vertical-align: 16px; }
            .ant-card.stat-card .card-footer-list .card-footer-icon .icon svg {
              width: 14px !important;
              height: 14px !important; } }
    .ant-card.stat-card .card-footer-num {
      font-size: 18px;
      font-weight: 700;
      color: #202124;
      display: inline-block; }
      @media screen and (max-width: 1440px) {
        .ant-card.stat-card .card-footer-num {
          font-size: 16px; } }
      @media screen and (max-width: 1370px) {
        .ant-card.stat-card .card-footer-num {
          font-size: 15px; } }
    .ant-card.stat-card .card-footer-text {
      display: block;
      font-size: 13px;
      font-weight: 500;
      color: #202124; }
      @media screen and (max-width: 1370px) {
        .ant-card.stat-card .card-footer-text {
          font-size: 12px; } }
    .ant-card.stat-card .card-footer-month {
      display: inline-block;
      font-size: 13px;
      font-weight: 500;
      color: #202124;
      padding-left: 10px; }
      @media screen and (max-width: 1440px) {
        .ant-card.stat-card .card-footer-month {
          font-size: 12px; } }
    .ant-card.stat-card .moby__extra-m {
      margin-left: 10px; }
      @media screen and (max-width: 1440px) {
        .ant-card.stat-card .moby__extra-m {
          margin-left: 5px; } }
    .ant-card.stat-card .icon-mr {
      margin-right: 12px !important; }
    .ant-card.stat-card.ant-card-type-danger .ant-card-body .animate-icon {
      color: #ea4335;
      opacity: .2; }
    .ant-card.stat-card.ant-card-type-danger .ant-card-body .card-title {
      color: #ea4335; }
    .ant-card.stat-card.ant-card-type-danger .ant-card-body .card-progress-bar .ant-progress-circle-path {
      stroke: #ea4335 !important; }
    .ant-card.stat-card.ant-card-type-danger .card-footer-list .card-footer-icon {
      color: #ea4335 !important; }
      .ant-card.stat-card.ant-card-type-danger .card-footer-list .card-footer-icon.icon {
        color: #ea4335 !important; }
    .ant-card.stat-card.ant-card-type-danger:hover .ant-card-extra .icon {
      color: #ea4335 !important; }
    .ant-card.stat-card.ant-card-type-danger:hover .ant-card-body .animate-icon {
      opacity: 1; }
    .ant-card.stat-card.ant-card-type-danger:hover .ant-card-body .card-progress-bar .progress-bar-icon.icon {
      color: #ea4335 !important; }
    .ant-card.stat-card.ant-card-type-danger:hover .ant-card-body .card-progress-bar .ant-progress-circle-trail {
      stroke: white !important; }
    .ant-card.stat-card.ant-card-type-primary .ant-card-body .animate-icon {
      color: #DBA44F;
      opacity: .2; }
    .ant-card.stat-card.ant-card-type-primary .ant-card-body .card-title {
      color: #DBA44F; }
    .ant-card.stat-card.ant-card-type-primary .ant-card-body .card-progress-bar .ant-progress-circle-path {
      stroke: #DBA44F !important; }
    .ant-card.stat-card.ant-card-type-primary .card-footer-list .card-footer-icon {
      color: #DBA44F !important; }
      .ant-card.stat-card.ant-card-type-primary .card-footer-list .card-footer-icon.icon {
        color: #DBA44F !important; }
    .ant-card.stat-card.ant-card-type-primary:hover .ant-card-extra .icon {
      color: #DBA44F !important; }
    .ant-card.stat-card.ant-card-type-primary:hover .ant-card-body .animate-icon {
      opacity: 1; }
    .ant-card.stat-card.ant-card-type-primary:hover .ant-card-body .card-progress-bar .progress-bar-icon.icon {
      color: #DBA44F !important; }
    .ant-card.stat-card.ant-card-type-primary:hover .ant-card-body .card-progress-bar .ant-progress-circle-trail {
      stroke: white !important; }
    .ant-card.stat-card.ant-card-type-success .ant-card-body .animate-icon {
      color: #34a853;
      opacity: .2; }
    .ant-card.stat-card.ant-card-type-success .ant-card-body .card-title {
      color: #34a853; }
    .ant-card.stat-card.ant-card-type-success .ant-card-body .card-progress-bar .ant-progress-circle-path {
      stroke: #34a853 !important; }
    .ant-card.stat-card.ant-card-type-success .card-footer-list .card-footer-icon {
      color: #34a853 !important; }
      .ant-card.stat-card.ant-card-type-success .card-footer-list .card-footer-icon.icon {
        color: #34a853 !important; }
    .ant-card.stat-card.ant-card-type-success:hover .ant-card-extra .icon {
      color: #34a853 !important; }
    .ant-card.stat-card.ant-card-type-success:hover .ant-card-body .animate-icon {
      opacity: 1; }
    .ant-card.stat-card.ant-card-type-success:hover .ant-card-body .card-progress-bar .progress-bar-icon.icon {
      color: #34a853 !important; }
    .ant-card.stat-card.ant-card-type-success:hover .ant-card-body .card-progress-bar .ant-progress-circle-trail {
      stroke: white !important; }
    .ant-card.stat-card.ant-card-type-warning .ant-card-body .animate-icon {
      color: #fbbc05;
      opacity: .2; }
    .ant-card.stat-card.ant-card-type-warning .ant-card-body .card-title {
      color: #fbbc05; }
    .ant-card.stat-card.ant-card-type-warning .ant-card-body .card-progress-bar .ant-progress-circle-path {
      stroke: #fbbc05 !important; }
    .ant-card.stat-card.ant-card-type-warning .card-footer-list .card-footer-icon {
      color: #fbbc05 !important; }
      .ant-card.stat-card.ant-card-type-warning .card-footer-list .card-footer-icon.icon {
        color: #fbbc05 !important; }
    .ant-card.stat-card.ant-card-type-warning:hover .ant-card-extra .icon {
      color: #fbbc05 !important; }
    .ant-card.stat-card.ant-card-type-warning:hover .ant-card-body .animate-icon {
      opacity: 1; }
    .ant-card.stat-card.ant-card-type-warning:hover .ant-card-body .card-progress-bar .progress-bar-icon.icon {
      color: #fbbc05 !important; }
    .ant-card.stat-card.ant-card-type-warning:hover .ant-card-body .card-progress-bar .ant-progress-circle-trail {
      stroke: white !important; }
  .ant-card.re-widget-card {
    line-height: 22px;
    /** Card Head **/
    /** /.Card Head **/
    /** Card Head Title **/
    /** /.Card Head Title **/
    /** Card Extra **/
    /** /.Card Extra **/
    /** Card Body **/
    /** /.Card Body **/
    /** Card Footer **/
    /** /.Card Footer **/
    /** Card Color Class Danger **/
    /** /.Card Color Class Danger **/
    /** Card Color Class Primary **/
    /** /.Card Color Class Primary **/
    /** Card Color Class Success **/
    /** /.Card Color Class Success **/
    /** Card Color Class Warning **/
    /** /.Card Color Class Warning **/ }
    .ant-card.re-widget-card .ant-card-head {
      border-bottom: 0;
      background: transparent;
      min-height: auto; }
    .ant-card.re-widget-card .ant-card-head-title {
      padding: 14px 0;
      color: #202124;
      font-size: 18px; }
      @media screen and (max-width: 1370px) {
        .ant-card.re-widget-card .ant-card-head-title {
          font-size: 16px; } }
    .ant-card.re-widget-card .ant-card-extra {
      padding: 0; }
      .ant-card.re-widget-card .ant-card-extra .icon {
        cursor: pointer;
        font-size: 16px;
        transition: all ease-in-out .4s; }
        .ant-card.re-widget-card .ant-card-extra .icon svg {
          transition: all ease-in-out .4s; }
        @media screen and (max-width: 1440px) {
          .ant-card.re-widget-card .ant-card-extra .icon {
            font-size: 14px; }
            .ant-card.re-widget-card .ant-card-extra .icon svg {
              width: 14px;
              height: 14px; } }
    .ant-card.re-widget-card .ant-card-body {
      min-height: 100%;
      padding: 0 20px 15px;
      /** Card Body Title **/
      /** Card Body Title **/
      /** Card Body Progress Bar **/
      /** /.Card Body Progress Bar **/ }
      .ant-card.re-widget-card .ant-card-body .card-title {
        font-size: 18px;
        color: #202124;
        margin-bottom: 5px; }
        @media screen and (max-width: 1440px) {
          .ant-card.re-widget-card .ant-card-body .card-title {
            font-size: 16px; } }
      .ant-card.re-widget-card .ant-card-body .subtitle {
        font-size: 14px;
        color: #96a5ab;
        display: block;
        margin-bottom: 25px; }
        @media screen and (max-width: 1440px) {
          .ant-card.re-widget-card .ant-card-body .subtitle {
            font-size: 13px; } }
        @media screen and (max-width: 991px) {
          .ant-card.re-widget-card .ant-card-body .subtitle {
            font-size: 14px; } }
        .ant-card.re-widget-card .ant-card-body .subtitle .icon {
          margin-right: 7px;
          font-size: 11px;
          vertical-align: 1px; }
      .ant-card.re-widget-card .ant-card-body .card-progress-bar {
        text-align: center;
        position: relative;
        padding-bottom: 25px; }
        .ant-card.re-widget-card .ant-card-body .card-progress-bar .ant-progress-circle-path {
          stroke: #202124 !important; }
        .ant-card.re-widget-card .ant-card-body .card-progress-bar .ant-progress-inner {
          width: 100px !important;
          height: 100px !important; }
        .ant-card.re-widget-card .ant-card-body .card-progress-bar .ant-progress-text {
          display: none; }
      .ant-card.re-widget-card .ant-card-body .progress-bar-icon {
        position: absolute;
        top: 40px;
        left: 0;
        right: 0;
        margin: 0 auto; }
        .ant-card.re-widget-card .ant-card-body .progress-bar-icon.icon {
          color: #E5E1E1 !important;
          font-size: 25px;
          transition: all ease-in-out .4s; }
        .ant-card.re-widget-card .ant-card-body .progress-bar-icon.icon-resize {
          font-size: 40px;
          top: 30px; }
    .ant-card.re-widget-card .card-footer {
      /** Card Footer List **/
      /** /.Card Footer List **/
      /** Card Footer Text **/
      /** Card Footer Text **/ }
      .ant-card.re-widget-card .card-footer .card-footer-list {
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .ant-card.re-widget-card .card-footer .card-footer-list li {
          position: relative;
          line-height: 20px; }
      .ant-card.re-widget-card .card-footer .card-footer-icon {
        margin-right: 7px;
        font-size: 12px;
        vertical-align: 1px; }
        @media screen and (max-width: 1440px) {
          .ant-card.re-widget-card .card-footer .card-footer-icon {
            margin-right: 5px; } }
      .ant-card.re-widget-card .card-footer .card-footer-text {
        font-size: 14px;
        color: #96a5ab;
        display: inline-block; }
        @media screen and (max-width: 1440px) {
          .ant-card.re-widget-card .card-footer .card-footer-text {
            font-size: 13px; } }
    .ant-card.re-widget-card.ant-card-type-danger {
      /** Card Head Title **/
      /** /.Card Head Title **/
      /** Card Extra **/
      /** /.Card Extra **/
      /** Card Body Progress Bar **/
      /** /.Card Body Progress Bar **/ }
      .ant-card.re-widget-card.ant-card-type-danger .ant-card-head-title {
        color: #ea4335; }
      .ant-card.re-widget-card.ant-card-type-danger .ant-card-extra .icon:hover {
        color: #ea4335 !important; }
      .ant-card.re-widget-card.ant-card-type-danger .card-progress-bar .ant-progress-circle-path {
        stroke: #ea4335 !important; }
    .ant-card.re-widget-card.ant-card-type-primary {
      /** Card Head Title **/
      /** /.Card Head Title **/
      /** Card Extra **/
      /** /.Card Extra **/
      /** Card Body Progress Bar **/
      /** /.Card Body Progress Bar **/ }
      .ant-card.re-widget-card.ant-card-type-primary .ant-card-head-title {
        color: #DBA44F; }
      .ant-card.re-widget-card.ant-card-type-primary .ant-card-extra .icon:hover {
        color: #DBA44F !important; }
      .ant-card.re-widget-card.ant-card-type-primary .card-progress-bar .ant-progress-circle-path {
        stroke: #DBA44F !important; }
    .ant-card.re-widget-card.ant-card-type-success {
      /** Card Head Title **/
      /** /.Card Head Title **/
      /** Card Extra **/
      /** /.Card Extra **/
      /** Card Body Progress Bar **/
      /** /.Card Body Progress Bar **/ }
      .ant-card.re-widget-card.ant-card-type-success .ant-card-head-title {
        color: #34a853; }
      .ant-card.re-widget-card.ant-card-type-success .ant-card-extra .icon:hover {
        color: #34a853 !important; }
      .ant-card.re-widget-card.ant-card-type-success .card-progress-bar .ant-progress-circle-path {
        stroke: #34a853 !important; }
    .ant-card.re-widget-card.ant-card-type-warning {
      /** Card Head Title **/
      /** /.Card Head Title **/
      /** Card Extra **/
      /** /.Card Extra **/
      /** Card Body Progress Bar **/
      /** /.Card Body Progress Bar **/ }
      .ant-card.re-widget-card.ant-card-type-warning .ant-card-head-title {
        color: #fbbc05; }
      .ant-card.re-widget-card.ant-card-type-warning .ant-card-extra .icon:hover {
        color: #fbbc05 !important; }
      .ant-card.re-widget-card.ant-card-type-warning .card-progress-bar .ant-progress-circle-path {
        stroke: #fbbc05 !important; }
    .ant-card.re-widget-card.re-widget-alternative .ant-card-head {
      padding: 0 20px; }
      @media (max-width: 1440px) {
        .ant-card.re-widget-card.re-widget-alternative .ant-card-head {
          padding: 0 15px; } }
    .ant-card.re-widget-card.re-widget-alternative .ant-card-head-title {
      padding: 15px 0 10px;
      color: #202124; }
    .ant-card.re-widget-card.re-widget-alternative .ant-card-body {
      padding: 0 20px 15px; }
      @media (max-width: 1440px) {
        .ant-card.re-widget-card.re-widget-alternative .ant-card-body {
          padding: 0 15px 15px; } }
      .ant-card.re-widget-card.re-widget-alternative .ant-card-body .card-title {
        font-size: 16px;
        margin-bottom: 5px;
        color: #202124; }
        @media screen and (max-width: 1370px) {
          .ant-card.re-widget-card.re-widget-alternative .ant-card-body .card-title {
            font-size: 14px; } }
    .ant-card.re-widget-card.re-widget-alternative .card-footer .card-footer-list li {
      width: 100%;
      padding: 0;
      text-align: left; }
    .ant-card.re-widget-card.re-widget-alternative .card-footer .card-footer-icon {
      font-size: 9px; }
    .ant-card.re-widget-card.re-widget-alternative .card-footer .card-footer-text {
      font-size: 14px;
      font-weight: 700; }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-danger .ant-card-head {
      padding: 0 20px; }
      @media (max-width: 1440px) {
        .ant-card.re-widget-card.re-widget-alternative.ant-card-type-danger .ant-card-head {
          padding: 0 15px; } }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-danger .ant-card-head-title {
      padding: 15px 0 10px;
      color: #202124; }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-danger .ant-card-body {
      padding: 0 20px 15px; }
      @media (max-width: 1440px) {
        .ant-card.re-widget-card.re-widget-alternative.ant-card-type-danger .ant-card-body {
          padding: 0 15px 15px; } }
      .ant-card.re-widget-card.re-widget-alternative.ant-card-type-danger .ant-card-body .card-title {
        font-size: 16px;
        margin-bottom: 5px;
        color: #ea4335; }
        @media screen and (max-width: 1370px) {
          .ant-card.re-widget-card.re-widget-alternative.ant-card-type-danger .ant-card-body .card-title {
            font-size: 14px; } }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-danger .card-footer .card-footer-list li {
      width: 100%;
      padding: 0;
      text-align: left; }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-danger .card-footer .card-footer-icon {
      font-size: 9px; }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-danger .card-footer .card-footer-text {
      font-size: 14px;
      font-weight: 700; }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-primary .ant-card-head {
      padding: 0 20px; }
      @media (max-width: 1440px) {
        .ant-card.re-widget-card.re-widget-alternative.ant-card-type-primary .ant-card-head {
          padding: 0 15px; } }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-primary .ant-card-head-title {
      padding: 15px 0 10px;
      color: #202124; }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-primary .ant-card-body {
      padding: 0 20px 15px; }
      @media (max-width: 1440px) {
        .ant-card.re-widget-card.re-widget-alternative.ant-card-type-primary .ant-card-body {
          padding: 0 15px 15px; } }
      .ant-card.re-widget-card.re-widget-alternative.ant-card-type-primary .ant-card-body .card-title {
        font-size: 16px;
        margin-bottom: 5px;
        color: #DBA44F; }
        @media screen and (max-width: 1370px) {
          .ant-card.re-widget-card.re-widget-alternative.ant-card-type-primary .ant-card-body .card-title {
            font-size: 14px; } }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-primary .card-footer .card-footer-list li {
      width: 100%;
      padding: 0;
      text-align: left; }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-primary .card-footer .card-footer-icon {
      font-size: 9px; }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-primary .card-footer .card-footer-text {
      font-size: 14px;
      font-weight: 700; }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-success .ant-card-head {
      padding: 0 20px; }
      @media (max-width: 1440px) {
        .ant-card.re-widget-card.re-widget-alternative.ant-card-type-success .ant-card-head {
          padding: 0 15px; } }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-success .ant-card-head-title {
      padding: 15px 0 10px;
      color: #202124; }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-success .ant-card-body {
      padding: 0 20px 15px; }
      @media (max-width: 1440px) {
        .ant-card.re-widget-card.re-widget-alternative.ant-card-type-success .ant-card-body {
          padding: 0 15px 15px; } }
      .ant-card.re-widget-card.re-widget-alternative.ant-card-type-success .ant-card-body .card-title {
        font-size: 16px;
        margin-bottom: 5px;
        color: #34a853; }
        @media screen and (max-width: 1370px) {
          .ant-card.re-widget-card.re-widget-alternative.ant-card-type-success .ant-card-body .card-title {
            font-size: 14px; } }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-success .card-footer .card-footer-list li {
      width: 100%;
      padding: 0;
      text-align: left; }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-success .card-footer .card-footer-icon {
      font-size: 9px; }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-success .card-footer .card-footer-text {
      font-size: 14px;
      font-weight: 700; }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-warning .ant-card-head {
      padding: 0 20px; }
      @media (max-width: 1440px) {
        .ant-card.re-widget-card.re-widget-alternative.ant-card-type-warning .ant-card-head {
          padding: 0 15px; } }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-warning .ant-card-head-title {
      padding: 15px 0 10px;
      color: #202124; }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-warning .ant-card-body {
      padding: 0 20px 15px; }
      @media (max-width: 1440px) {
        .ant-card.re-widget-card.re-widget-alternative.ant-card-type-warning .ant-card-body {
          padding: 0 15px 15px; } }
      .ant-card.re-widget-card.re-widget-alternative.ant-card-type-warning .ant-card-body .card-title {
        font-size: 16px;
        margin-bottom: 5px;
        color: #fbbc05; }
        @media screen and (max-width: 1370px) {
          .ant-card.re-widget-card.re-widget-alternative.ant-card-type-warning .ant-card-body .card-title {
            font-size: 14px; } }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-warning .card-footer .card-footer-list li {
      width: 100%;
      padding: 0;
      text-align: left; }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-warning .card-footer .card-footer-icon {
      font-size: 9px; }
    .ant-card.re-widget-card.re-widget-alternative.ant-card-type-warning .card-footer .card-footer-text {
      font-size: 14px;
      font-weight: 700; }
  .ant-card.meetup-card .ant-card-cover {
    position: relative;
    border-bottom: 1px solid #e3e8ea;
    margin-bottom: 30px; }
    .ant-card.meetup-card .ant-card-cover > * {
      border-radius: 4px 4px 0 0;
      min-height: 120px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover; }
    .ant-card.meetup-card .ant-card-cover .ant-avatar {
      position: absolute;
      bottom: -25px;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: #202124;
      color: white;
      border-color: black; }
  .ant-card.meetup-card .ant-btn.ant-btn-background-ghost {
    border-color: #202124;
    color: #202124; }
    .ant-card.meetup-card .ant-btn.ant-btn-background-ghost:hover {
      border-color: transparent;
      background: #202124 !important;
      color: white;
      opacity: 1; }
    .ant-card.meetup-card .ant-btn.ant-btn-background-ghost.btn-filled {
      background: #202124 !important;
      color: white !important; }
      .ant-card.meetup-card .ant-btn.ant-btn-background-ghost.btn-filled:hover {
        border-color: #202124 !important; }
  .ant-card.meetup-card .ant-card-actions {
    overflow: hidden;
    border-radius: 0 0 4px 4px; }
    .ant-card.meetup-card .ant-card-actions > li {
      margin: 0; }
      .ant-card.meetup-card .ant-card-actions > li > span {
        padding: 12px 0;
        background: white;
        color: #202124;
        font-size: 16px;
        font-weight: 700;
        transition: all ease-in-out .4s; }
        .ant-card.meetup-card .ant-card-actions > li > span:hover {
          background: #202124;
          color: white; }
    .ant-card.meetup-card .ant-card-actions > li > span {
      font-size: 14px; }
  .ant-card.meetup-card .ant-card-body {
    padding: 10px 20px 20px;
    text-align: center;
    min-height: 100%; }
    .ant-card.meetup-card .ant-card-body .card-title {
      font-size: 18px;
      font-weight: 800 !important;
      line-height: 20px;
      margin-bottom: 10px; }
    .ant-card.meetup-card .ant-card-body .card-subtitle {
      display: block;
      font-size: 15px;
      font-weight: 500 !important;
      color: #96a5ab;
      margin-bottom: 15px; }
    .ant-card.meetup-card .ant-card-body .group-avatars {
      margin-bottom: 15px; }
  .ant-card.meetup-card.ant-card-type-danger .ant-card-cover {
    position: relative;
    border-bottom: 1px solid #e3e8ea;
    margin-bottom: 30px; }
    .ant-card.meetup-card.ant-card-type-danger .ant-card-cover > * {
      border-radius: 4px 4px 0 0;
      min-height: 120px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover; }
    .ant-card.meetup-card.ant-card-type-danger .ant-card-cover .ant-avatar {
      position: absolute;
      bottom: -25px;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: #ea4335;
      color: white;
      border-color: #bf2114; }
  .ant-card.meetup-card.ant-card-type-danger .ant-btn.ant-btn-background-ghost {
    border-color: #ea4335;
    color: #ea4335; }
    .ant-card.meetup-card.ant-card-type-danger .ant-btn.ant-btn-background-ghost:hover {
      border-color: transparent;
      background: #ea4335 !important;
      color: white;
      opacity: 1; }
    .ant-card.meetup-card.ant-card-type-danger .ant-btn.ant-btn-background-ghost.btn-filled {
      background: #ea4335 !important;
      color: white !important; }
      .ant-card.meetup-card.ant-card-type-danger .ant-btn.ant-btn-background-ghost.btn-filled:hover {
        border-color: #ea4335 !important; }
  .ant-card.meetup-card.ant-card-type-danger .ant-card-actions {
    overflow: hidden;
    border-radius: 0 0 4px 4px; }
    .ant-card.meetup-card.ant-card-type-danger .ant-card-actions > li {
      margin: 0; }
      .ant-card.meetup-card.ant-card-type-danger .ant-card-actions > li > span {
        padding: 12px 0;
        background: white;
        color: #202124;
        font-size: 16px;
        font-weight: 700;
        transition: all ease-in-out .4s; }
        .ant-card.meetup-card.ant-card-type-danger .ant-card-actions > li > span:hover {
          background: #ea4335;
          color: white; }
    .ant-card.meetup-card.ant-card-type-danger .ant-card-actions > li > span {
      font-size: 14px; }
  .ant-card.meetup-card.ant-card-type-danger .ant-card-body {
    padding: 10px 20px 20px;
    text-align: center;
    min-height: 100%; }
    .ant-card.meetup-card.ant-card-type-danger .ant-card-body .card-title {
      font-size: 18px;
      font-weight: 800 !important;
      line-height: 20px;
      margin-bottom: 10px; }
    .ant-card.meetup-card.ant-card-type-danger .ant-card-body .card-subtitle {
      display: block;
      font-size: 15px;
      font-weight: 500 !important;
      color: #96a5ab;
      margin-bottom: 15px; }
    .ant-card.meetup-card.ant-card-type-danger .ant-card-body .group-avatars {
      margin-bottom: 15px; }
  .ant-card.meetup-card.ant-card-type-primary .ant-card-cover {
    position: relative;
    border-bottom: 1px solid #e3e8ea;
    margin-bottom: 30px; }
    .ant-card.meetup-card.ant-card-type-primary .ant-card-cover > * {
      border-radius: 4px 4px 0 0;
      min-height: 120px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover; }
    .ant-card.meetup-card.ant-card-type-primary .ant-card-cover .ant-avatar {
      position: absolute;
      bottom: -25px;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: #DBA44F;
      color: white;
      border-color: #b87e26; }
  .ant-card.meetup-card.ant-card-type-primary .ant-btn.ant-btn-background-ghost {
    border-color: #DBA44F;
    color: #DBA44F; }
    .ant-card.meetup-card.ant-card-type-primary .ant-btn.ant-btn-background-ghost:hover {
      border-color: transparent;
      background: #DBA44F !important;
      color: white;
      opacity: 1; }
    .ant-card.meetup-card.ant-card-type-primary .ant-btn.ant-btn-background-ghost.btn-filled {
      background: #DBA44F !important;
      color: white !important; }
      .ant-card.meetup-card.ant-card-type-primary .ant-btn.ant-btn-background-ghost.btn-filled:hover {
        border-color: #DBA44F !important; }
  .ant-card.meetup-card.ant-card-type-primary .ant-card-actions {
    overflow: hidden;
    border-radius: 0 0 4px 4px; }
    .ant-card.meetup-card.ant-card-type-primary .ant-card-actions > li {
      margin: 0; }
      .ant-card.meetup-card.ant-card-type-primary .ant-card-actions > li > span {
        padding: 12px 0;
        background: white;
        color: #202124;
        font-size: 16px;
        font-weight: 700;
        transition: all ease-in-out .4s; }
        .ant-card.meetup-card.ant-card-type-primary .ant-card-actions > li > span:hover {
          background: #DBA44F;
          color: white; }
    .ant-card.meetup-card.ant-card-type-primary .ant-card-actions > li > span {
      font-size: 14px; }
  .ant-card.meetup-card.ant-card-type-primary .ant-card-body {
    padding: 10px 20px 20px;
    text-align: center;
    min-height: 100%; }
    .ant-card.meetup-card.ant-card-type-primary .ant-card-body .card-title {
      font-size: 18px;
      font-weight: 800 !important;
      line-height: 20px;
      margin-bottom: 10px; }
    .ant-card.meetup-card.ant-card-type-primary .ant-card-body .card-subtitle {
      display: block;
      font-size: 15px;
      font-weight: 500 !important;
      color: #96a5ab;
      margin-bottom: 15px; }
    .ant-card.meetup-card.ant-card-type-primary .ant-card-body .group-avatars {
      margin-bottom: 15px; }
  .ant-card.meetup-card.ant-card-type-success .ant-card-cover {
    position: relative;
    border-bottom: 1px solid #e3e8ea;
    margin-bottom: 30px; }
    .ant-card.meetup-card.ant-card-type-success .ant-card-cover > * {
      border-radius: 4px 4px 0 0;
      min-height: 120px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover; }
    .ant-card.meetup-card.ant-card-type-success .ant-card-cover .ant-avatar {
      position: absolute;
      bottom: -25px;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: #34a853;
      color: white;
      border-color: #226e36; }
  .ant-card.meetup-card.ant-card-type-success .ant-btn.ant-btn-background-ghost {
    border-color: #34a853;
    color: #34a853; }
    .ant-card.meetup-card.ant-card-type-success .ant-btn.ant-btn-background-ghost:hover {
      border-color: transparent;
      background: #34a853 !important;
      color: white;
      opacity: 1; }
    .ant-card.meetup-card.ant-card-type-success .ant-btn.ant-btn-background-ghost.btn-filled {
      background: #34a853 !important;
      color: white !important; }
      .ant-card.meetup-card.ant-card-type-success .ant-btn.ant-btn-background-ghost.btn-filled:hover {
        border-color: #34a853 !important; }
  .ant-card.meetup-card.ant-card-type-success .ant-card-actions {
    overflow: hidden;
    border-radius: 0 0 4px 4px; }
    .ant-card.meetup-card.ant-card-type-success .ant-card-actions > li {
      margin: 0; }
      .ant-card.meetup-card.ant-card-type-success .ant-card-actions > li > span {
        padding: 12px 0;
        background: white;
        color: #202124;
        font-size: 16px;
        font-weight: 700;
        transition: all ease-in-out .4s; }
        .ant-card.meetup-card.ant-card-type-success .ant-card-actions > li > span:hover {
          background: #34a853;
          color: white; }
    .ant-card.meetup-card.ant-card-type-success .ant-card-actions > li > span {
      font-size: 14px; }
  .ant-card.meetup-card.ant-card-type-success .ant-card-body {
    padding: 10px 20px 20px;
    text-align: center;
    min-height: 100%; }
    .ant-card.meetup-card.ant-card-type-success .ant-card-body .card-title {
      font-size: 18px;
      font-weight: 800 !important;
      line-height: 20px;
      margin-bottom: 10px; }
    .ant-card.meetup-card.ant-card-type-success .ant-card-body .card-subtitle {
      display: block;
      font-size: 15px;
      font-weight: 500 !important;
      color: #96a5ab;
      margin-bottom: 15px; }
    .ant-card.meetup-card.ant-card-type-success .ant-card-body .group-avatars {
      margin-bottom: 15px; }
  .ant-card.meetup-card.ant-card-type-warning .ant-card-cover {
    position: relative;
    border-bottom: 1px solid #e3e8ea;
    margin-bottom: 30px; }
    .ant-card.meetup-card.ant-card-type-warning .ant-card-cover > * {
      border-radius: 4px 4px 0 0;
      min-height: 120px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover; }
    .ant-card.meetup-card.ant-card-type-warning .ant-card-cover .ant-avatar {
      position: absolute;
      bottom: -25px;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: #fbbc05;
      color: white;
      border-color: #b18403; }
  .ant-card.meetup-card.ant-card-type-warning .ant-btn.ant-btn-background-ghost {
    border-color: #fbbc05;
    color: #fbbc05; }
    .ant-card.meetup-card.ant-card-type-warning .ant-btn.ant-btn-background-ghost:hover {
      border-color: transparent;
      background: #fbbc05 !important;
      color: white;
      opacity: 1; }
    .ant-card.meetup-card.ant-card-type-warning .ant-btn.ant-btn-background-ghost.btn-filled {
      background: #fbbc05 !important;
      color: white !important; }
      .ant-card.meetup-card.ant-card-type-warning .ant-btn.ant-btn-background-ghost.btn-filled:hover {
        border-color: #fbbc05 !important; }
  .ant-card.meetup-card.ant-card-type-warning .ant-card-actions {
    overflow: hidden;
    border-radius: 0 0 4px 4px; }
    .ant-card.meetup-card.ant-card-type-warning .ant-card-actions > li {
      margin: 0; }
      .ant-card.meetup-card.ant-card-type-warning .ant-card-actions > li > span {
        padding: 12px 0;
        background: white;
        color: #202124;
        font-size: 16px;
        font-weight: 700;
        transition: all ease-in-out .4s; }
        .ant-card.meetup-card.ant-card-type-warning .ant-card-actions > li > span:hover {
          background: #fbbc05;
          color: white; }
    .ant-card.meetup-card.ant-card-type-warning .ant-card-actions > li > span {
      font-size: 14px; }
  .ant-card.meetup-card.ant-card-type-warning .ant-card-body {
    padding: 10px 20px 20px;
    text-align: center;
    min-height: 100%; }
    .ant-card.meetup-card.ant-card-type-warning .ant-card-body .card-title {
      font-size: 18px;
      font-weight: 800 !important;
      line-height: 20px;
      margin-bottom: 10px; }
    .ant-card.meetup-card.ant-card-type-warning .ant-card-body .card-subtitle {
      display: block;
      font-size: 15px;
      font-weight: 500 !important;
      color: #96a5ab;
      margin-bottom: 15px; }
    .ant-card.meetup-card.ant-card-type-warning .ant-card-body .group-avatars {
      margin-bottom: 15px; }
  .ant-card.meetup-card.cover-img-height .ant-card-cover > div {
    min-height: 163px; }
  .ant-card.social-connection .ant-card-body {
    padding: 30px 20px;
    text-align: center; }
    .ant-card.social-connection .ant-card-body .ant-avatar {
      background: #e1e1e1;
      margin-bottom: 20px; }
    .ant-card.social-connection .ant-card-body .card-title {
      font-size: 17px;
      line-height: 20px;
      margin-bottom: 20px; }
    .ant-card.social-connection .ant-card-body .ant-btn {
      margin-bottom: 20px; }
  .ant-card.meetup .ant-card-cover {
    border-bottom: 1px solid #e3e8ea; }
    .ant-card.meetup .ant-card-cover > div {
      border-radius: 4px 4px 0 0;
      min-height: 300px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover; }
      @media (max-width: 767px) {
        .ant-card.meetup .ant-card-cover > div {
          min-height: 250px; } }
      @media (max-width: 480px) {
        .ant-card.meetup .ant-card-cover > div {
          min-height: 200px; } }
  .ant-card.meetup .ant-card-body .card-title {
    font-size: 18px;
    font-weight: 800 !important;
    margin-bottom: 5px; }
  .ant-card.meetup .ant-card-body strong {
    display: block;
    font-size: 15px;
    color: rgba(32, 33, 36, 0.7); }
  .ant-card.meetup .ant-card-body .name {
    display: block;
    color: #96a5ab; }
  @media (max-width: 560px) {
    .ant-card.meetup .ant-card-body .ant-btn {
      margin-top: 20px;
      width: 100%; } }
  .ant-card.filed {
    text-align: center;
    min-height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 20px;
    cursor: pointer; }
    .ant-card.filed:hover .ant-card-body {
      transform: scale(1.2); }
    .ant-card.filed .ant-card-body {
      transition: all ease-in-out 0.15s; }
    .ant-card.filed .icon {
      line-height: 1; }
    .ant-card.filed .card-title {
      font-size: 16px;
      line-height: 20px;
      margin: 15px 0 0; }
  .ant-card.conference .ant-card-head {
    border-bottom: 0;
    background: transparent; }
  .ant-card.conference .ant-card-head-title {
    padding: 20px 0; }
  .ant-card.conference .ant-card-extra .icon {
    color: #a0b0b6 !important;
    font-size: 16px; }
  .ant-card.conference .ant-card-cover {
    position: relative;
    border-bottom: 1px solid #e3e8ea; }
    .ant-card.conference .ant-card-cover > div {
      border-radius: 4px 4px 0 0;
      min-height: 270px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover; }
  .ant-card.conference .ant-card-body {
    padding: 0 20px 20px; }
    .ant-card.conference .ant-card-body .title-holder {
      padding: 20px 0;
      border-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .ant-card.conference .ant-card-body .card-title {
      font-size: 18px;
      font-weight: 800 !important;
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis; }
    .ant-card.conference .ant-card-body .icon {
      color: #a0b0b6 !important;
      font-size: 16px; }
    .ant-card.conference .ant-card-body .text {
      font-size: 14px;
      color: #96a5ab;
      margin-bottom: 20px; }
  .ant-card.topic {
    margin-bottom: 50px !important; }
    .ant-card.topic .ant-card-body {
      padding: 0;
      overflow: visible !important; }
      .ant-card.topic .ant-card-body .card-body {
        text-align: center;
        padding: 20px; }
        .ant-card.topic .ant-card-body .card-body .icon {
          background: #f9fafa;
          height: 65px;
          line-height: 65px;
          width: 65px;
          border: 1px solid #e3e8ea;
          border-radius: 54px;
          display: block;
          font-size: 22px;
          margin: -52px auto 20px; }
      .ant-card.topic .ant-card-body .card-title {
        font-size: 17px;
        color: black;
        margin-bottom: 6px; }
        @media screen and (max-width: 1200px) {
          .ant-card.topic .ant-card-body .card-title {
            font-size: 15px; } }
      .ant-card.topic .ant-card-body .text {
        font-size: 14px;
        color: #8392ad;
        margin-bottom: 0; }
        @media screen and (max-width: 1200px) {
          .ant-card.topic .ant-card-body .text {
            font-size: 13px; } }
      .ant-card.topic .ant-card-body .topic {
        font-size: 14px;
        font-weight: 500;
        color: black;
        display: inline-block; }
        @media screen and (max-width: 1200px) {
          .ant-card.topic .ant-card-body .topic {
            font-size: 13px; } }
      .ant-card.topic .ant-card-body .card-footer {
        padding: 10px 20px;
        background: #f9fafa;
        border-top: 1px solid #e3e8ea;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .ant-card.topic .ant-card-body .card-footer .icon {
          font-size: 16px;
          color: black !important; }
          @media screen and (max-width: 1200px) {
            .ant-card.topic .ant-card-body .card-footer .icon {
              font-size: 15px; } }
    @media screen and (max-width: 767px) {
      .ant-card.topic {
        margin-bottom: 30px; } }
  .ant-card.file-card {
    border-color: transparent;
    background: #f9fafa; }
    .ant-card.file-card .ant-card-head {
      border-bottom: 0;
      background: transparent;
      padding: 0 15px; }
      @media (max-width: 991px) {
        .ant-card.file-card .ant-card-head {
          padding: 0 20px; } }
    .ant-card.file-card .ant-card-head-title {
      padding: 10px 0; }
    .ant-card.file-card .title-icon {
      font-size: 24px;
      color: #202124 !important; }
    .ant-card.file-card .ant-card-extra {
      padding: 10px 0; }
      .ant-card.file-card .ant-card-extra .icon {
        color: #a0b0b6 !important;
        font-size: 16px; }
    .ant-card.file-card .ant-card-actions {
      margin-top: 10px !important;
      border-radius: 0 0 4px 4px; }
      .ant-card.file-card .ant-card-actions li {
        padding: 15px 0;
        margin: 0; }
    .ant-card.file-card .ant-card-body {
      padding: 0 15px 10px; }
      @media (max-width: 991px) {
        .ant-card.file-card .ant-card-body {
          padding: 0 20px 10px; } }
      .ant-card.file-card .ant-card-body .card-title {
        margin-bottom: 5px; }
      .ant-card.file-card .ant-card-body .card-sub-title {
        font-size: 15px;
        line-height: 25px;
        font-weight: 500;
        color: #96a5ab;
        display: block; }
      .ant-card.file-card .ant-card-body .group-avatars {
        padding: 5px 0; }
      .ant-card.file-card .ant-card-body .progress {
        padding-top: 5px; }
        .ant-card.file-card .ant-card-body .progress .ant-progress-text {
          color: #DBA44F; }
    .ant-card.file-card .ant-card-actions li > span:hover .icon {
      color: #DBA44F; }
    .ant-card.file-card .ant-card-actions li .icon {
      font-size: 20px; }
      @media (max-width: 1023px) {
        .ant-card.file-card .ant-card-actions li .icon {
          font-size: 18px; } }
      @media (max-width: 767px) {
        .ant-card.file-card .ant-card-actions li .icon {
          font-size: 16px; } }
  .ant-card.concert-post-card .ant-card-cover {
    border-right: 1px solid #e3e8ea;
    height: 100%;
    width: 50%;
    margin-bottom: 0; }
    .ant-card.concert-post-card .ant-card-cover > div {
      border-radius: 4px 0 0 4px;
      height: 100%;
      min-height: 297px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover; }
    @media (max-width: 480px) {
      .ant-card.concert-post-card .ant-card-cover {
        width: 100%;
        border-right: 0;
        border-bottom: 1px solid #e3e8ea; }
        .ant-card.concert-post-card .ant-card-cover > * {
          border-radius: 4px 4px 0 0;
          min-height: 250px;
          background-position: 50% 50%; } }
  .ant-card.concert-post-card .ant-card-body {
    width: 50%; }
    @media (max-width: 480px) {
      .ant-card.concert-post-card .ant-card-body {
        width: 100%; } }
  .ant-card.concert-post-card .user-info .title {
    font-size: 15px; }
  @media (max-width: 480px) {
    .ant-card.concert-post-card {
      flex-flow: row wrap; } }
  .ant-card.profile-overview-card .ant-card-body {
    padding-top: 0; }
  .ant-card.profile-overview-card .user-info .title {
    font-size: 18px;
    font-weight: 800 !important;
    line-height: 25px; }
  .ant-card.profile-overview-card .user-info .designation {
    margin-bottom: 15px; }
  .ant-card.profile-overview-card .group-btn .ant-btn {
    margin-bottom: 10px; }
    .ant-card.profile-overview-card .group-btn .ant-btn:last-child {
      margin-bottom: 0; }
  .ant-card.profile-overview-card .ant-card-actions {
    overflow: hidden;
    border-radius: 0 0 4px 4px; }
    .ant-card.profile-overview-card .ant-card-actions > li {
      margin: 0; }
      .ant-card.profile-overview-card .ant-card-actions > li > span {
        padding: 12px 0;
        background: white;
        color: #202124;
        font-size: 16px;
        font-weight: 700;
        transition: all ease-in-out .4s; }
        .ant-card.profile-overview-card .ant-card-actions > li > span:hover {
          background: #DBA44F;
          color: white; }
  .ant-card.profile-overview-card.ant-card-type-primary .ant-card-actions {
    overflow: hidden;
    border-radius: 0 0 4px 4px; }
    .ant-card.profile-overview-card.ant-card-type-primary .ant-card-actions > li {
      margin: 0; }
      .ant-card.profile-overview-card.ant-card-type-primary .ant-card-actions > li > span {
        padding: 12px 0;
        background: white;
        color: #202124;
        font-size: 16px;
        font-weight: 700;
        transition: all ease-in-out .4s; }
        .ant-card.profile-overview-card.ant-card-type-primary .ant-card-actions > li > span:hover {
          background: #DBA44F;
          color: white; }
  .ant-card.profile-overview-card.ant-card-type-primary .user-info .ant-avatar {
    background: #DBA44F;
    color: white;
    border-color: #b87e26; }
  .ant-card.profile-overview-card.ant-card-type-danger .ant-card-actions {
    overflow: hidden;
    border-radius: 0 0 4px 4px; }
    .ant-card.profile-overview-card.ant-card-type-danger .ant-card-actions > li {
      margin: 0; }
      .ant-card.profile-overview-card.ant-card-type-danger .ant-card-actions > li > span {
        padding: 12px 0;
        background: white;
        color: #202124;
        font-size: 16px;
        font-weight: 700;
        transition: all ease-in-out .4s; }
        .ant-card.profile-overview-card.ant-card-type-danger .ant-card-actions > li > span:hover {
          background: #ea4335;
          color: white; }
  .ant-card.profile-overview-card.ant-card-type-danger .user-info .ant-avatar {
    background: #ea4335;
    color: white;
    border-color: #bf2114; }
  .ant-card.profile-overview-card.ant-card-type-success .ant-card-actions {
    overflow: hidden;
    border-radius: 0 0 4px 4px; }
    .ant-card.profile-overview-card.ant-card-type-success .ant-card-actions > li {
      margin: 0; }
      .ant-card.profile-overview-card.ant-card-type-success .ant-card-actions > li > span {
        padding: 12px 0;
        background: white;
        color: #202124;
        font-size: 16px;
        font-weight: 700;
        transition: all ease-in-out .4s; }
        .ant-card.profile-overview-card.ant-card-type-success .ant-card-actions > li > span:hover {
          background: #34a853;
          color: white; }
  .ant-card.profile-overview-card.ant-card-type-success .user-info .ant-avatar {
    background: #34a853;
    color: white;
    border-color: #226e36; }
  .ant-card.profile-overview-card.ant-card-type-warning .ant-card-actions {
    overflow: hidden;
    border-radius: 0 0 4px 4px; }
    .ant-card.profile-overview-card.ant-card-type-warning .ant-card-actions > li {
      margin: 0; }
      .ant-card.profile-overview-card.ant-card-type-warning .ant-card-actions > li > span {
        padding: 12px 0;
        background: white;
        color: #202124;
        font-size: 16px;
        font-weight: 700;
        transition: all ease-in-out .4s; }
        .ant-card.profile-overview-card.ant-card-type-warning .ant-card-actions > li > span:hover {
          background: #fbbc05;
          color: white; }
  .ant-card.profile-overview-card.ant-card-type-warning .user-info .ant-avatar {
    background: #fbbc05;
    color: white;
    border-color: #b18403; }
  .ant-card.moby__project-card .ant-card-head {
    background: white; }
  .ant-card.moby__project-card .ant-card-body {
    padding-top: 0; }
    .ant-card.moby__project-card .ant-card-body .card-title {
      margin-bottom: 5px; }
    .ant-card.moby__project-card .ant-card-body a:hover {
      color: #202124; }
  .ant-card.moby__project-card .subtitle {
    color: #96a5ab; }
    .ant-card.moby__project-card .subtitle .icon {
      font-size: 12px;
      vertical-align: middle; }
  .ant-card.moby__project-card.project-card-w-bg-grey .ant-card-head {
    background: #f5f7f7; }
  .ant-card.moby__project-card.project-card-w-bg-grey .ant-card-body {
    background: #f5f7f7; }
  .ant-card.moby__project-card.ant-card-type-primary .ant-card-head-title .ant-avatar {
    background: #DBA44F;
    color: white;
    border-color: #b87e26; }
  .ant-card.moby__project-card.ant-card-type-primary .ant-card-body a:hover {
    color: #DBA44F; }
  .ant-card.moby__project-card.ant-card-type-primary .ant-progress .ant-progress-bg {
    background-color: #DBA44F; }
  .ant-card.moby__project-card.ant-card-type-primary .ant-progress .ant-progress-text {
    color: #DBA44F !important; }
  .ant-card.moby__project-card.ant-card-type-primary .subtitle {
    color: #DBA44F; }
    .ant-card.moby__project-card.ant-card-type-primary .subtitle .icon {
      color: #DBA44F !important; }
  .ant-card.moby__project-card.ant-card-type-danger .ant-card-head-title .ant-avatar {
    background: #ea4335;
    color: white;
    border-color: #bf2114; }
  .ant-card.moby__project-card.ant-card-type-danger .ant-card-body a:hover {
    color: #ea4335; }
  .ant-card.moby__project-card.ant-card-type-danger .ant-progress .ant-progress-bg {
    background-color: #ea4335; }
  .ant-card.moby__project-card.ant-card-type-danger .ant-progress .ant-progress-text {
    color: #ea4335 !important; }
  .ant-card.moby__project-card.ant-card-type-danger .subtitle {
    color: #ea4335; }
    .ant-card.moby__project-card.ant-card-type-danger .subtitle .icon {
      color: #ea4335 !important; }
  .ant-card.moby__project-card.ant-card-type-success .ant-card-head-title .ant-avatar {
    background: #34a853;
    color: white;
    border-color: #226e36; }
  .ant-card.moby__project-card.ant-card-type-success .ant-card-body a:hover {
    color: #34a853; }
  .ant-card.moby__project-card.ant-card-type-success .ant-progress .ant-progress-bg {
    background-color: #34a853; }
  .ant-card.moby__project-card.ant-card-type-success .ant-progress .ant-progress-text {
    color: #34a853 !important; }
  .ant-card.moby__project-card.ant-card-type-success .subtitle {
    color: #34a853; }
    .ant-card.moby__project-card.ant-card-type-success .subtitle .icon {
      color: #34a853 !important; }
  .ant-card.moby__project-card.ant-card-type-warning .ant-card-head-title .ant-avatar {
    background: #fbbc05;
    color: white;
    border-color: #b18403; }
  .ant-card.moby__project-card.ant-card-type-warning .ant-card-body a:hover {
    color: #fbbc05; }
  .ant-card.moby__project-card.ant-card-type-warning .ant-progress .ant-progress-bg {
    background-color: #fbbc05; }
  .ant-card.moby__project-card.ant-card-type-warning .ant-progress .ant-progress-text {
    color: #fbbc05 !important; }
  .ant-card.moby__project-card.ant-card-type-warning .subtitle {
    color: #fbbc05; }
    .ant-card.moby__project-card.ant-card-type-warning .subtitle .icon {
      color: #fbbc05 !important; }

/** /.Theme Default Card **/
/** Theme Stat Cards **/
.stat-cards {
  border-radius: 4px;
  border: 1px solid #e3e8ea;
  margin-bottom: 30px;
  overflow: hidden; }
  .stat-cards .ant-row .ant-col:first-child .stat-card {
    border-left: 0; }
    @media screen and (max-width: 991px) {
      .stat-cards .ant-row .ant-col:first-child .stat-card {
        border-left: 1px solid #e3e8ea; } }
  @media screen and (max-width: 991px) {
    .stat-cards {
      border: 0;
      margin-bottom: 0; } }
  .stat-cards .stat-card {
    box-shadow: none !important; }
    @media screen and (max-width: 991px) {
      .stat-cards .stat-card {
        border: 1px solid #e3e8ea;
        border-radius: 4px;
        margin-bottom: 30px !important; } }
  .stat-cards.stat-cards-alternative {
    border: 0; }
    .stat-cards.stat-cards-alternative .ant-row .ant-col:first-child .stat-card {
      border-left: 0; }
    .stat-cards.stat-cards-alternative .stat-card {
      border: 0; }
      .stat-cards.stat-cards-alternative .stat-card:hover {
        background: white; }
      .stat-cards.stat-cards-alternative .stat-card .ant-card-head {
        display: none; }
      .stat-cards.stat-cards-alternative .stat-card .ant-card-body {
        padding: 22px 20px; }
        @media (max-width: 1440px) {
          .stat-cards.stat-cards-alternative .stat-card .ant-card-body {
            padding: 22px 0; } }
        .stat-cards.stat-cards-alternative .stat-card .ant-card-body .animate-icon {
          display: none; }
      .stat-cards.stat-cards-alternative .stat-card .card-footer {
        padding: 0 !important; }
        .stat-cards.stat-cards-alternative .stat-card .card-footer .card-footer-list li {
          text-align: center;
          width: 100% !important; }
          .stat-cards.stat-cards-alternative .stat-card .card-footer .card-footer-list li:last-child {
            display: none; }
        @media screen and (max-width: 1440px) {
          .stat-cards.stat-cards-alternative .stat-card .card-footer .card-footer-icon {
            vertical-align: middle;
            font-size: 12px; } }
        @media (max-width: 767px) {
          .stat-cards.stat-cards-alternative .stat-card .card-footer .card-footer-icon {
            font-size: 14px; } }
        .stat-cards.stat-cards-alternative .stat-card .card-footer .card-footer-num {
          font-size: 15px; }
          @media screen and (max-width: 1440px) {
            .stat-cards.stat-cards-alternative .stat-card .card-footer .card-footer-num {
              font-size: 12px; } }
          @media (max-width: 767px) {
            .stat-cards.stat-cards-alternative .stat-card .card-footer .card-footer-num {
              font-size: 14px; } }
        .stat-cards.stat-cards-alternative .stat-card .card-footer .card-footer-text {
          display: inline-block;
          margin-left: 7px;
          color: rgba(150, 165, 171, 0.85); }
          @media screen and (max-width: 1440px) {
            .stat-cards.stat-cards-alternative .stat-card .card-footer .card-footer-text {
              font-size: 12px; } }
          @media (max-width: 767px) {
            .stat-cards.stat-cards-alternative .stat-card .card-footer .card-footer-text {
              font-size: 14px; } }
        @media screen and (max-width: 768px) {
          .stat-cards.stat-cards-alternative .stat-card .card-footer {
            text-align: center; } }
        @media screen and (max-width: 1440px) {
          .stat-cards.stat-cards-alternative .stat-card .card-footer {
            padding: 0 10px 15px; } }
      .stat-cards.stat-cards-alternative .stat-card.ant-card-type-danger:hover .card-progress-bar .ant-progress-circle-trail, .stat-cards.stat-cards-alternative .stat-card.ant-card-type-primary:hover .card-progress-bar .ant-progress-circle-trail, .stat-cards.stat-cards-alternative .stat-card.ant-card-type-success:hover .card-progress-bar .ant-progress-circle-trail, .stat-cards.stat-cards-alternative .stat-card.ant-card-type-warning:hover .card-progress-bar .ant-progress-circle-trail {
        stroke: #f3f3f3 !important; }
  .stat-cards.stat-cards-shadow {
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05), 0 1px 20px 0 rgba(0, 0, 0, 0.05), 0px 1px 11px 0px rgba(0, 0, 0, 0.05); }
    @media (max-width: 991px) {
      .stat-cards.stat-cards-shadow {
        box-shadow: none;
        overflow: visible; }
        .stat-cards.stat-cards-shadow .ant-row .ant-col .stat-card {
          box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05), 0 1px 20px 0 rgba(0, 0, 0, 0.05), 0px 1px 11px 0px rgba(0, 0, 0, 0.05) !important; } }

/** /.Theme Stat Cards **/
/** Social List **/
.social-list {
  display: flex;
  align-items: center;
  justify-content: center; }
  .social-list li {
    padding: 0 5px; }
    .social-list li .social-link {
      display: block;
      width: 35px;
      height: 35px;
      line-height: 38px;
      background: #202124;
      border-radius: 50%;
      transition: all ease-in-out 0.4s;
      text-align: center; }
      .social-list li .social-link .anticon,
      .social-list li .social-link .icon {
        color: white !important;
        font-size: 16px; }
      .social-list li .social-link.fb-bg {
        background: #3d599a; }
      .social-list li .social-link.google-bg {
        background: #f34735; }
      .social-list li .social-link.twitter-bg {
        background: #00a7e6; }
      .social-list li .social-link.linkedin-bg {
        background: #0073b1; }
      .social-list li .social-link:hover {
        transform: scale(1.2); }

/** Ends Social List **/
/** Theme Group Avatars **/
.group-avatars .ant-avatar {
  margin-right: -10px;
  transition: all ease-in-out 0.4s;
  z-index: 10; }
  .group-avatars .ant-avatar:hover {
    z-index: 30;
    transform: scale(1.2); }

.group-avatars .more-text {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #96a5ab;
  padding-left: 20px; }

/** /.Theme Group Avatars **/
/** Avatar **/
.ant-avatar {
  cursor: pointer;
  min-width: 35px;
  font-weight: 700;
  border: 1px solid #e3e8ea;
  box-shadow: none; }
  .ant-avatar .anticon,
  .ant-avatar .icon {
    font-size: 14px;
    color: #202124 !important; }
  .ant-avatar.ant-avatar-lg {
    min-width: 55px;
    font-size: 18px; }
    .ant-avatar.ant-avatar-lg .anticon,
    .ant-avatar.ant-avatar-lg .icon {
      font-size: 18px; }
  .ant-avatar.ant-avatar-sm {
    min-width: 28px;
    font-size: 12px; }
    .ant-avatar.ant-avatar-sm .anticon,
    .ant-avatar.ant-avatar-sm .icon {
      font-size: 12px; }
  .ant-avatar.avatar-bg-transparent {
    background: transparent; }
  .ant-avatar.avatar-dot {
    display: block; }
    .ant-avatar.avatar-dot:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 0 0 auto;
      border-radius: 50%;
      border: 1px solid white;
      width: 10px;
      height: 10px;
      background: #48c76a; }
    .ant-avatar.avatar-dot.ant-avatar-lg:after {
      width: 20px;
      height: 20px; }
  .ant-avatar.danger {
    background: #ea4335;
    color: white;
    border-color: #bf2114; }
    .ant-avatar.danger .anticon,
    .ant-avatar.danger .icon {
      color: white !important; }
  .ant-avatar.primary {
    background: #DBA44F;
    color: white;
    border-color: #b87e26; }
    .ant-avatar.primary .anticon,
    .ant-avatar.primary .icon {
      color: white !important; }
  .ant-avatar.success {
    background: #34a853;
    color: white;
    border-color: #226e36; }
    .ant-avatar.success .anticon,
    .ant-avatar.success .icon {
      color: white !important; }
  .ant-avatar.warning {
    background: #fbbc05;
    color: white;
    border-color: #b18403; }
    .ant-avatar.warning .anticon,
    .ant-avatar.warning .icon {
      color: white !important; }
  .ant-avatar.secondary {
    background: #00cccc;
    color: white;
    border-color: teal; }
    .ant-avatar.secondary .anticon,
    .ant-avatar.secondary .icon {
      color: white !important; }

/** /.Avatar **/
/** Switch **/
.ant-switch .anticon {
  font-size: 12px; }

.ant-switch.square {
  border-radius: 0; }
  .ant-switch.square:after {
    border-radius: 0; }

.ant-switch.danger.ant-switch-checked {
  background-color: #ea4335; }

.ant-switch.success.ant-switch-checked {
  background-color: #34a853; }

.ant-switch.warning.ant-switch-checked {
  background-color: #fbbc05; }

/** /.Switch **/
/** Calender **/
.ant-picker-calendar .ant-picker-cell-selected .ant-picker-calendar-date-value,
.ant-picker-calendar .ant-picker-cell-selected .ant-picker-calendar-date-content {
  color: white !important; }

.ant-picker-calendar .ant-picker-cell-selected .ant-badge-status-text {
  color: white !important; }

.ant-picker-calendar .ant-picker-cell-inner:hover .ant-picker-calendar-date-value,
.ant-picker-calendar .ant-picker-cell-inner:hover .ant-picker-calendar-date-content {
  color: white !important; }

.ant-picker-calendar .ant-picker-cell-inner:hover .ant-badge-status-text {
  color: white !important; }

@media (max-width: 480px) {
  .ant-picker-calendar.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    padding: 5px 5px 0; } }

/** Calender **/
/** Drawer **/
.ant-drawer {
  z-index: 99999 !important; }
  .ant-drawer .side-bar-inner-menu {
    padding-top: 20px; }

/** /.Drawer **/
/** Backtop **/
.ant-back-top {
  bottom: 100px; }
  .ant-back-top .ant-back-top-inner {
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 4px;
    background-color: #1088e9;
    color: #fff;
    text-align: center;
    font-size: 20px; }
  .ant-back-top.deference {
    bottom: 120px; }

/** /.Backtop **/
/** Notice Calendar **/
.events {
  list-style: none;
  margin: 0;
  padding: 0; }
  .events .ant-badge-status {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 12px; }

.notes-month {
  text-align: center;
  font-size: 28px; }

.notes-month section {
  font-size: 28px; }

/** /.Notice Calendar **/
/** Modal **/
.ant-modal-mask,
.ant-modal-wrap {
  z-index: 9999999 !important; }

.t-20 {
  top: 20px !important; }

.ant-modal-content .ant-modal-header {
  padding: 15px 20px; }

/** Modal **/
/** Notifications **/
.ant-notification {
  z-index: 9999999 !important; }
  .ant-notification .ant-notification-notice-with-icon .anticon {
    font-size: 25px !important; }
  .ant-notification .custom-notification {
    margin-left: -265px;
    width: 600px; }

/** Notifications **/
/** Spins **/
.ant-spin.danger {
  color: #ea4335; }
  .ant-spin.danger .ant-spin-dot-item {
    background-color: #ea4335; }
  .ant-spin.danger .ant-spin-text {
    color: #ea4335; }

.ant-spin.success {
  color: #34a853; }
  .ant-spin.success .ant-spin-dot-item {
    background-color: #34a853; }
  .ant-spin.success .ant-spin-text {
    color: #34a853; }

.ant-spin.warning {
  color: #fbbc05; }
  .ant-spin.warning .ant-spin-dot-item {
    background-color: #fbbc05; }
  .ant-spin.warning .ant-spin-text {
    color: #fbbc05; }

/** Spins **/
/** Theme Progress **/
.ant-progress.ant-progress-status-exception .ant-progress-bg {
  background-color: #ea4335; }

.ant-progress.ant-progress-status-exception .ant-progress-text {
  color: #ea4335 !important; }

.ant-progress.ant-progress-status-success .ant-progress-bg {
  background-color: #34a853; }

.ant-progress.ant-progress-status-success .ant-progress-text {
  color: #34a853 !important; }

.ant-progress.warning .ant-progress-bg {
  background-color: #fbbc05; }

.ant-progress.warning .ant-progress-text {
  color: #fbbc05 !important; }

.ant-progress.secondary .ant-progress-bg {
  background-color: #00cccc; }

.ant-progress.secondary .ant-progress-text {
  color: #00cccc !important; }

/** /.Theme Progress **/
/** Newsfeed Timeline **/
.ant-timeline {
  color: #202124; }
  .ant-timeline .ant-timeline-item {
    padding: 0 0 15px;
    color: #96a5ab; }
    .ant-timeline .ant-timeline-item.danger .subtitle {
      color: #ea4335; }
    .ant-timeline .ant-timeline-item.primary .subtitle {
      color: #DBA44F; }
    .ant-timeline .ant-timeline-item.success .subtitle {
      color: #34a853; }
    .ant-timeline .ant-timeline-item.warning .subtitle {
      color: #fbbc05; }
    .ant-timeline .ant-timeline-item.filled-bg .ant-timeline-item-head.ant-timeline-item-head-blue {
      background: #202124;
      border-color: #202124 !important; }
  .ant-timeline .ant-timeline-item-tail {
    left: 6px; }
  .ant-timeline .ant-timeline-item-head {
    margin: 0px 13px;
    margin-left: 0;
    width: 13px;
    height: 13px; }
    .ant-timeline .ant-timeline-item-head.ant-timeline-item-head-yellow {
      border-color: #fbbc05 !important; }
  .ant-timeline .ant-timeline-item-content {
    margin-left: 20px;
    top: -3px; }
  .ant-timeline .title {
    font-size: 18px;
    font-weight: 800 !important;
    color: #202124;
    margin-bottom: 5px; }
    @media (max-width: 1380px) {
      .ant-timeline .title {
        font-size: 16px; } }
  .ant-timeline .subtitle {
    font-size: 16px;
    color: #202124;
    display: inline-block;
    padding-bottom: 5px; }
    @media (max-width: 1380px) {
      .ant-timeline .subtitle {
        font-size: 14px; } }
  .ant-timeline p {
    font-size: 14px;
    color: #202124;
    margin-bottom: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 20px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
  .ant-timeline .media-holder {
    margin-bottom: 15px; }
    .ant-timeline .media-holder .media-img {
      width: 100%; }
    .ant-timeline .media-holder .media-video {
      width: 100%;
      height: 100%; }
  .ant-timeline .like-comment-holder {
    display: flex;
    align-items: center; }
    .ant-timeline .like-comment-holder li {
      padding: 0 10px; }
      .ant-timeline .like-comment-holder li:first-child {
        padding-left: 0; }
      .ant-timeline .like-comment-holder li a {
        font-size: 14px;
        color: #96a5ab;
        transition: all ease-in-out 0.4s; }
        .ant-timeline .like-comment-holder li a .icon {
          font-size: 14px;
          color: #96a5ab !important;
          padding-right: 5px;
          transition: all ease-in-out 0.4s; }
        .ant-timeline .like-comment-holder li a:hover {
          color: #202124; }
          .ant-timeline .like-comment-holder li a:hover .icon {
            color: #202124 !important; }
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head {
    margin-left: -6px !important; }
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom {
    margin-left: -2px !important; }
  .ant-timeline.ant-timeline-right .ant-timeline-item-right.ant-timeline-item .ant-timeline-item-head {
    margin-left: -6px; }
  .ant-timeline.ant-timeline-right .ant-timeline-item-right.ant-timeline-item .ant-timeline-item-head-custom {
    margin-left: -2px; }

/** /.Newsfeed Timeline **/
/** Theme Rate **/
.ant-rate {
  font-size: 14px; }
  .ant-rate .ant-rate-star-first .anticon,
  .ant-rate .ant-rate-star-second .anticon {
    font-size: 18px; }

/** /.Theme Rate **/
/** /.Theme Select **/
.theme-select-holder .ant-select {
  width: 100%;
  color: rgba(150, 165, 171, 0.5); }
  .theme-select-holder .ant-select .ant-select-selection--single {
    height: 40px;
    border-radius: 4px; }
    .theme-select-holder .ant-select .ant-select-selection--single .ant-select-selection__rendered {
      line-height: 38px; }

.multi-selecter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; }
  .multi-selecter .ant-select {
    width: 48%;
    color: #96a5ab; }
    .multi-selecter .ant-select:last-child {
      margin-bottom: 0; }
    @media screen and (max-width: 1440px) {
      .multi-selecter .ant-select {
        width: 100%;
        margin-bottom: 20px; } }
    @media screen and (max-width: 1023px) {
      .multi-selecter .ant-select {
        width: 48%;
        margin-bottom: 0px; } }
    @media screen and (max-width: 480px) {
      .multi-selecter .ant-select {
        width: 100%;
        margin-bottom: 20px; } }
  @media screen and (max-width: 1440px) {
    .multi-selecter {
      flex-flow: row wrap; } }
  @media screen and (max-width: 1023px) {
    .multi-selecter {
      flex-flow: row; } }
  @media screen and (max-width: 480px) {
    .multi-selecter {
      flex-flow: row wrap; } }
  .multi-selecter.multi-selecter-alternative .ant-select {
    width: 31%;
    margin-bottom: 20px; }
  @media (max-width: 567px) {
    .multi-selecter.multi-selecter-alternative {
      flex-flow: row wrap; }
      .multi-selecter.multi-selecter-alternative .ant-select {
        width: 100%; }
        .multi-selecter.multi-selecter-alternative .ant-select:not(:last-child) {
          width: 48%; } }
  @media (max-width: 374px) {
    .multi-selecter.multi-selecter-alternative .ant-select {
      margin-bottom: 15px; }
      .multi-selecter.multi-selecter-alternative .ant-select:not(:last-child) {
        width: 100%; } }

/** /.Theme Select **/
/** Theme Widget Filters **/
.widget-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px !important; }
  .widget-filters li {
    font-size: 14px;
    color: rgba(150, 165, 171, 0.5);
    margin: 0 15px;
    line-height: 1;
    cursor: pointer; }
    .widget-filters li:last-child {
      margin-right: 0; }
  .widget-filters .active-filter {
    color: rgba(150, 165, 171, 0.5);
    font-weight: 700;
    text-decoration: underline; }
  @media screen and (max-width: 1440px) {
    .widget-filters li {
      font-size: 13px;
      margin: 0 10px; } }
  @media screen and (max-width: 480px) {
    .widget-filters {
      width: 100%; } }
  .widget-filters.widget-filters-black {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0 !important; }
    .widget-filters.widget-filters-black li {
      font-size: 14px;
      color: rgba(150, 165, 171, 0.5);
      margin: 0 15px;
      line-height: 1;
      cursor: pointer; }
      .widget-filters.widget-filters-black li:last-child {
        margin-right: 0; }
    .widget-filters.widget-filters-black .active-filter {
      color: #202124;
      font-weight: 700;
      text-decoration: underline; }
    @media screen and (max-width: 1440px) {
      .widget-filters.widget-filters-black li {
        font-size: 13px;
        margin: 0 10px; } }
    @media screen and (max-width: 480px) {
      .widget-filters.widget-filters-black {
        width: 100%; } }

/** Theme Widget Filters **/
/** Theme Multi Combination **/
.multi-combo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px; }
  @media screen and (max-width: 480px) {
    .multi-combo {
      flex-flow: row wrap; } }
  .multi-combo.multi-combo-alternative {
    padding: 0 20px 30px; }
    @media screen and (max-width: 1660px) {
      .multi-combo.multi-combo-alternative {
        flex-flow: row wrap; } }
    .multi-combo.multi-combo-alternative .multi-selecter .ant-select {
      width: 100%; }
    @media screen and (max-width: 1660px) {
      .multi-combo.multi-combo-alternative .multi-selecter {
        padding-bottom: 20px;
        width: 100%; } }
    @media screen and (max-width: 1660px) {
      .multi-combo.multi-combo-alternative .widget-filters.widget-filters-black {
        width: 100%; } }

/** /.Theme Multi Combination **/
/** Theme Multi Date Picker **/
.multi-date-picker {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .multi-date-picker .ant-picker.date-picker-bg-white {
    width: 48%; }
    @media screen and (max-width: 480px) {
      .multi-date-picker .ant-picker.date-picker-bg-white {
        width: 100%;
        margin-bottom: 20px; } }
  @media screen and (max-width: 1440px) {
    .multi-date-picker {
      flex-flow: row wrap; } }
  @media screen and (max-width: 1023px) {
    .multi-date-picker {
      flex-flow: row; } }
  @media screen and (max-width: 480px) {
    .multi-date-picker {
      flex-flow: row wrap; } }

/** /.Theme Multi Date Picker **/
/** Theme Graph Header **/
.graph-header {
  padding: 0 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e3e8ea;
  /** Select **/
  /** /.Select **/
  /** Button **/
  /** /.Button **/ }
  .graph-header .ant-select {
    margin-right: -1px;
    min-width: 170px;
    color: #d0d8db;
    font-size: 13px; }
    .graph-header .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 34px;
      border-right: 0;
      margin-right: -1px;
      border-radius: 4px 0 0 4px; }
      .graph-header .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:hover, .graph-header .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:focus {
        border-color: #adb7bb;
        box-shadow: none;
        outline: none; }
      .graph-header .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        height: 32px; }
      .graph-header .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
        line-height: 34px; }
    .graph-header .ant-select.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      box-shadow: none; }
      .graph-header .ant-select.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:hover, .graph-header .ant-select.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:focus {
        border-color: #adb7bb;
        box-shadow: none;
        outline: none; }
    @media screen and (max-width: 767px) {
      .graph-header .ant-select {
        min-width: 140px; } }
    @media screen and (max-width: 560px) {
      .graph-header .ant-select {
        min-width: 100%;
        margin-bottom: 20px; }
        .graph-header .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
          border-radius: 4px;
          border-right: 1px solid #e3e8ea; } }
  @media screen and (max-width: 560px) {
    .graph-header .ant-picker {
      margin-bottom: 20px;
      width: 100%; } }
  .graph-header .ant-btn {
    font-size: 14px; }
    .graph-header .ant-btn .icon {
      margin-left: 10px;
      font-size: 10px !important; }
    @media screen and (max-width: 560px) {
      .graph-header .ant-btn {
        width: 100%; } }
  @media screen and (max-width: 560px) {
    .graph-header {
      justify-content: center;
      flex-flow: row wrap; }
      .graph-header .flex {
        justify-content: center;
        flex-flow: row wrap; } }

/** /.Theme Graph Header **/
/** Theme Error Pages Style **/
.error-page {
  margin-bottom: 30px; }
  .error-page .page-title {
    font-size: 26px;
    margin-bottom: 15px; }
    .error-page .page-title strong {
      font-size: 39px;
      font-weight: 700; }
    @media screen and (max-width: 767px) {
      .error-page .page-title {
        font-size: 20px; }
        .error-page .page-title strong {
          font-size: 29px; } }
    @media screen and (max-width: 480px) {
      .error-page .page-title {
        font-size: 18px; }
        .error-page .page-title strong {
          font-size: 24px; } }
  .error-page p {
    font-size: 16px;
    color: rgba(150, 165, 171, 0.5);
    margin-bottom: 20px; }
    .error-page p:last-child {
      margin-bottom: 0; }
    @media screen and (max-width: 767px) {
      .error-page p {
        font-size: 14px; } }
    @media screen and (max-width: 480px) {
      .error-page p {
        font-size: 13px;
        margin-bottom: 30px; } }
  .error-page .error-img {
    margin-bottom: 40px; }
    @media screen and (max-width: 767px) {
      .error-page .error-img > img {
        max-width: 350px; } }
    @media screen and (max-width: 480px) {
      .error-page .error-img {
        margin-bottom: 30px; }
        .error-page .error-img > img {
          max-width: 280px; } }
    @media screen and (max-width: 375px) {
      .error-page .error-img {
        margin-bottom: 30px; }
        .error-page .error-img > img {
          max-width: 250px; } }
  .error-page .page-btn-holder {
    margin-bottom: 30px;
    text-align: center; }
    .error-page .page-btn-holder .ant-btn {
      margin-right: 20px; }
      .error-page .page-btn-holder .ant-btn:last-child {
        margin-right: 0; }
      @media screen and (max-width: 320px) {
        .error-page .page-btn-holder .ant-btn {
          margin-right: 0;
          margin-bottom: 20px; }
          .error-page .page-btn-holder .ant-btn:last-child {
            margin-bottom: 0; } }
    .error-page .page-btn-holder .ant-btn-lg {
      height: 55px;
      min-width: 170px; }
    @media screen and (max-width: 767px) {
      .error-page .page-btn-holder .ant-btn-lg {
        font-size: 13px;
        height: 45px;
        min-width: 140px; } }
  .error-page .inner-pages-link {
    padding: 0;
    color: #DBA44F; }
    @media screen and (max-width: 767px) {
      .error-page .inner-pages-link {
        font-size: 13px; } }
  .error-page .search-holder {
    max-width: 40%;
    margin: 0 auto 30px; }
    @media screen and (max-width: 1440px) {
      .error-page .search-holder {
        max-width: 50%; } }
    @media screen and (max-width: 1024px) {
      .error-page .search-holder {
        max-width: 60%; } }
    @media screen and (max-width: 768px) {
      .error-page .search-holder {
        max-width: 80%; } }
    @media screen and (max-width: 480px) {
      .error-page .search-holder {
        max-width: 100%; } }
    .error-page .search-holder .ant-input-search-enter-button {
      padding: 0; }
      .error-page .search-holder .ant-input-search-enter-button .ant-input-prefix {
        margin: 0 10px; }
        .error-page .search-holder .ant-input-search-enter-button .ant-input-prefix .anticon {
          color: rgba(150, 165, 171, 0.5); }
      .error-page .search-holder .ant-input-search-enter-button .ant-input-group .ant-input-affix-wrapper:hover .ant-input, .error-page .search-holder .ant-input-search-enter-button .ant-input-group .ant-input-affix-wrapper:focus .ant-input {
        border: 0;
        box-shadow: none;
        outline: none; }
      .error-page .search-holder .ant-input-search-enter-button .ant-input-group .ant-input-affix-wrapper.ant-input-affix-wrapper-focused .ant-input {
        border: 0;
        box-shadow: none;
        outline: none; }
        .error-page .search-holder .ant-input-search-enter-button .ant-input-group .ant-input-affix-wrapper.ant-input-affix-wrapper-focused .ant-input:hover, .error-page .search-holder .ant-input-search-enter-button .ant-input-group .ant-input-affix-wrapper.ant-input-affix-wrapper-focused .ant-input:focus {
          border: 0;
          box-shadow: none;
          outline: none; }
      .error-page .search-holder .ant-input-search-enter-button .ant-input {
        padding-left: 0; }
      .error-page .search-holder .ant-input-search-enter-button.input-normal .ant-input-affix-wrapper .ant-input {
        height: 32px;
        line-height: 32px; }

/** Theme Error Pages Style **/
/** Ant Tables **/
.ant-table-wrapper .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 3px !important; }

.ant-table-wrapper .ant-table-small {
  border: 0; }
  .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0; }

.ant-table-wrapper .ant-table-pagination {
  padding: 15px 15px 0; }
  .ant-table-wrapper .ant-table-pagination .anticon {
    font-size: 16px; }

@media (max-width: 700px) {
  .ant-table-wrapper.table-wrapper-min-w-100 {
    min-width: 100%; } }

.ant-table-filter-dropdown .ant-btn .anticon {
  font-size: 12px;
  color: white !important; }

/** Ends Ant Tables **/
/** /.Theme Tables **/
.moby__table {
  width: 100%;
  color: #202124;
  /** Theme Table TBody with Bg Grey **/
  /** /.Theme Table TBody with Bg Grey **/
  /** Theme Table TBody With Color Black**/
  /** /.Theme Table TBody With Color Black**/
  /** Theme Table TBody With Color Light Grey **/
  /** /.Theme Table TBody With Color Light Grey **/
  /** Theme Table Bordeless **/
  /** /.Theme Table Bordeless **/
  /** /.Theme Table Tbody Hover bg  **/
  /** /.Theme Table Tbody Hover bg  **/
  /** Theme Table Animate None  **/
  /** /.Theme Table Animate None  **/
  /** Theme Table Min Width **/
  /** /.Theme Table Min Width **/
  /** Theme Table Min Width **/
  /** /.Theme Table Min Width **/
  /** Theme Table Min Width **/
  /** /.Theme Table Min Width **/ }
  @media screen and (max-width: 480px) {
    .moby__table {
      max-width: 440px; } }
  .moby__table tr {
    border-bottom: 1px solid #e3e8ea; }
    .moby__table tr td,
    .moby__table tr th {
      line-height: 20px;
      border-left: 1px solid #e3e8ea;
      padding: 15px; }
      .moby__table tr td:first-child,
      .moby__table tr th:first-child {
        border-left: 0; }
  .moby__table thead tr th {
    line-height: 25px;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize; }
  .moby__table tbody tr {
    transition: all ease-in-out 0.2s; }
    .moby__table tbody tr:hover {
      transform: scale(1.02); }
    .moby__table tbody tr:last-child {
      border-bottom: 0; }
    .moby__table tbody tr td {
      color: #96a5ab;
      padding: 10px 15px;
      position: relative; }
      .moby__table tbody tr td a {
        display: inline-block; }
  .moby__table tbody .arrow-icon {
    font-size: 10px;
    vertical-align: 1px;
    margin-right: 3px; }
  .moby__table tbody .amount {
    font-size: 15px;
    font-weight: 800;
    display: inline-block; }
  .moby__table tbody .close-icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 30px;
    height: 100%;
    margin: 0 0 0 auto;
    line-height: 110px;
    background: #e3e8ea; }
  .moby__table tbody .ant-avatar {
    margin-right: 10px;
    width: 45px;
    height: 45px;
    line-height: 43px; }
    @media screen and (max-width: 768px) {
      .moby__table tbody .ant-avatar {
        display: none; } }
  .moby__table tbody .ant-tag {
    margin-right: 0; }
  .moby__table tbody .user-info {
    color: #202124;
    line-height: 30px;
    padding: 0;
    margin: 0;
    font-size: 16px; }
    .moby__table tbody .user-info span {
      line-height: 1;
      color: rgba(150, 165, 171, 0.8);
      display: block;
      font-size: 12px; }
  .moby__table tbody .exchange-icon {
    font-size: 14px; }
  .moby__table tbody .product .product-img {
    float: left;
    width: 38px;
    height: 38px;
    border: 1px solid #e3e8ea;
    border-radius: 50%;
    background-color: #fff;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin-right: 6px; }
  .moby__table tbody .product .item {
    line-height: 38px; }
  .moby__table tbody .company-icon {
    font-size: 40px;
    color: #b3bbc7 !important;
    margin-right: 10px;
    margin-left: 0; }
    @media screen and (max-width: 768px) {
      .moby__table tbody .company-icon {
        display: none; } }
  .moby__table tbody .company-name {
    line-height: 40px; }
  .moby__table tbody .dot {
    position: relative;
    color: #202124; }
    .moby__table tbody .dot:after {
      content: "";
      position: absolute;
      top: 45%;
      left: 15px;
      width: 7px;
      height: 7px;
      background: #202124;
      border-radius: 50%; }
    .moby__table tbody .dot span {
      padding-left: 15px;
      display: inline-block; }
    .moby__table tbody .dot.danger {
      color: #ea4335; }
      .moby__table tbody .dot.danger:after {
        background: #ea4335; }
    .moby__table tbody .dot.primary {
      color: #DBA44F; }
      .moby__table tbody .dot.primary:after {
        background: #DBA44F; }
    .moby__table tbody .dot.success {
      color: #34a853; }
      .moby__table tbody .dot.success:after {
        background: #34a853; }
    .moby__table tbody .dot.warning {
      color: #fbbc05; }
      .moby__table tbody .dot.warning:after {
        background: #fbbc05; }
  .moby__table tbody .file-title {
    font-size: 16px;
    color: #202124;
    font-weight: 700; }
    @media screen and (max-width: 767px) {
      .moby__table tbody .file-title {
        font-size: 15px; } }
  .moby__table tbody .file-icon {
    font-size: 25px;
    color: #202124;
    margin-left: 0;
    margin-right: 16px; }
    @media screen and (max-width: 767px) {
      .moby__table tbody .file-icon {
        font-size: 20px;
        margin-right: 6px; } }
  .moby__table.table-tbody-bg-grey tbody tr {
    transition: all ease-in-out 0.4s; }
    .moby__table.table-tbody-bg-grey tbody tr:nth-child(odd) {
      background: #f9fafa; }
  .moby__table.table-td-color-black td {
    color: #202124; }
  .moby__table.table-td-color-light-grey thead th {
    font-size: 18px; }
  .moby__table.table-td-color-light-grey tbody tr td {
    color: rgba(150, 165, 171, 0.8); }
  .moby__table.table-borderless tr {
    border-bottom: 0; }
    .moby__table.table-borderless tr td,
    .moby__table.table-borderless tr th {
      border-left: 0; }
  .moby__table.table-hover tbody tr {
    transition: all ease-in-out 0.4s;
    border-radius: 4px; }
    .moby__table.table-hover tbody tr:hover {
      background: #f9fafa; }
  .moby__table.table-animate-none tbody tr:hover {
    transform: none; }
  @media screen and (max-width: 767px) {
    .moby__table.table-m-w {
      min-width: 820px; } }
  @media screen and (max-width: 480px) {
    .moby__table.table-w-100 {
      max-width: 100%; } }
  .moby__table.table-invoice-total {
    border: 1px solid #e3e8ea; }
    .moby__table.table-invoice-total tbody tr td {
      padding: 10px; }
    .moby__table.table-invoice-total .ant-input {
      padding: 0;
      text-align: center; }

/** /.Theme Tables **/
/** Input **/
.ant-input,
.ant-input-number {
  height: 34px;
  line-height: 34px; }

/** Input **/
/** Theme Input **/
.ant-input-search .ant-input-group,
.ant-input-search-enter-button .ant-input-group,
.ant-input-group-wrapper .ant-input-group {
  color: #202124; }
  .ant-input-search .ant-input-group .ant-input-affix-wrapper:hover .ant-input, .ant-input-search .ant-input-group .ant-input-affix-wrapper:focus .ant-input,
  .ant-input-search-enter-button .ant-input-group .ant-input-affix-wrapper:hover .ant-input,
  .ant-input-search-enter-button .ant-input-group .ant-input-affix-wrapper:focus .ant-input,
  .ant-input-group-wrapper .ant-input-group .ant-input-affix-wrapper:hover .ant-input,
  .ant-input-group-wrapper .ant-input-group .ant-input-affix-wrapper:focus .ant-input {
    border-color: #DBA44F;
    box-shadow: 0 0 0 2px rgba(104, 157, 246, 0.3); }
  .ant-input-search .ant-input-group .ant-input,
  .ant-input-search-enter-button .ant-input-group .ant-input,
  .ant-input-group-wrapper .ant-input-group .ant-input {
    border-color: #e3e8ea;
    padding-left: 40px;
    color: #202124; }
    .ant-input-search .ant-input-group .ant-input:hover, .ant-input-search .ant-input-group .ant-input:focus,
    .ant-input-search-enter-button .ant-input-group .ant-input:hover,
    .ant-input-search-enter-button .ant-input-group .ant-input:focus,
    .ant-input-group-wrapper .ant-input-group .ant-input:hover,
    .ant-input-group-wrapper .ant-input-group .ant-input:focus {
      border-color: #DBA44F;
      box-shadow: 0 0 0 2px rgba(104, 157, 246, 0.3); }
    @media screen and (max-width: 767px) {
      .ant-input-search .ant-input-group .ant-input,
      .ant-input-search-enter-button .ant-input-group .ant-input,
      .ant-input-group-wrapper .ant-input-group .ant-input {
        font-size: 13px; } }
  .ant-input-search .ant-input-group .ant-input-lg,
  .ant-input-search-enter-button .ant-input-group .ant-input-lg,
  .ant-input-group-wrapper .ant-input-group .ant-input-lg {
    height: 55px; }
    @media screen and (max-width: 767px) {
      .ant-input-search .ant-input-group .ant-input-lg,
      .ant-input-search-enter-button .ant-input-group .ant-input-lg,
      .ant-input-group-wrapper .ant-input-group .ant-input-lg {
        height: 45px; } }
  .ant-input-search .ant-input-group .ant-input-suffix,
  .ant-input-search-enter-button .ant-input-group .ant-input-suffix,
  .ant-input-group-wrapper .ant-input-group .ant-input-suffix {
    right: 0 !important;
    left: 0px;
    width: 15px;
    margin: 0 auto 0 15px; }
    .ant-input-search .ant-input-group .ant-input-suffix .anticon,
    .ant-input-search-enter-button .ant-input-group .ant-input-suffix .anticon,
    .ant-input-group-wrapper .ant-input-group .ant-input-suffix .anticon {
      font-size: 14px;
      color: rgba(150, 165, 171, 0.5) !important; }
      @media screen and (max-width: 767px) {
        .ant-input-search .ant-input-group .ant-input-suffix .anticon,
        .ant-input-search-enter-button .ant-input-group .ant-input-suffix .anticon,
        .ant-input-group-wrapper .ant-input-group .ant-input-suffix .anticon {
          font-size: 13px; } }
  .ant-input-search .ant-input-group .ant-input-group-addon,
  .ant-input-search-enter-button .ant-input-group .ant-input-group-addon,
  .ant-input-group-wrapper .ant-input-group .ant-input-group-addon {
    min-width: 100px; }
    .ant-input-search .ant-input-group .ant-input-group-addon .ant-btn,
    .ant-input-search-enter-button .ant-input-group .ant-input-group-addon .ant-btn,
    .ant-input-group-wrapper .ant-input-group .ant-input-group-addon .ant-btn {
      width: 100%;
      border-color: transparent; }
      .ant-input-search .ant-input-group .ant-input-group-addon .ant-btn .icon,
      .ant-input-search-enter-button .ant-input-group .ant-input-group-addon .ant-btn .icon,
      .ant-input-group-wrapper .ant-input-group .ant-input-group-addon .ant-btn .icon {
        margin-right: 0; }
      @media screen and (max-width: 767px) {
        .ant-input-search .ant-input-group .ant-input-group-addon .ant-btn,
        .ant-input-search-enter-button .ant-input-group .ant-input-group-addon .ant-btn,
        .ant-input-group-wrapper .ant-input-group .ant-input-group-addon .ant-btn {
          font-size: 13px; } }
      .ant-input-search .ant-input-group .ant-input-group-addon .ant-btn.ant-btn-lg,
      .ant-input-search-enter-button .ant-input-group .ant-input-group-addon .ant-btn.ant-btn-lg,
      .ant-input-group-wrapper .ant-input-group .ant-input-group-addon .ant-btn.ant-btn-lg {
        height: 55px; }
        @media screen and (max-width: 767px) {
          .ant-input-search .ant-input-group .ant-input-group-addon .ant-btn.ant-btn-lg,
          .ant-input-search-enter-button .ant-input-group .ant-input-group-addon .ant-btn.ant-btn-lg,
          .ant-input-group-wrapper .ant-input-group .ant-input-group-addon .ant-btn.ant-btn-lg {
            height: 45px; } }
    @media screen and (max-width: 480px) {
      .ant-input-search .ant-input-group .ant-input-group-addon,
      .ant-input-search-enter-button .ant-input-group .ant-input-group-addon,
      .ant-input-group-wrapper .ant-input-group .ant-input-group-addon {
        min-width: 80px; } }

.ant-input-search.search-w-btn input,
.ant-input-search-enter-button.search-w-btn input,
.ant-input-group-wrapper.search-w-btn input {
  border-right: 1px solid #e3e8ea;
  padding: 0 35px 0 10px !important; }

.ant-input-search.search-w-btn .ant-input-group .ant-input-affix-wrapper,
.ant-input-search-enter-button.search-w-btn .ant-input-group .ant-input-affix-wrapper,
.ant-input-group-wrapper.search-w-btn .ant-input-group .ant-input-affix-wrapper {
  border: 1px solid #e3e8ea;
  border-radius: 4px;
  padding: 0 11px;
  height: 45px;
  line-height: 45px; }
  .ant-input-search.search-w-btn .ant-input-group .ant-input-affix-wrapper .ant-input,
  .ant-input-search-enter-button.search-w-btn .ant-input-group .ant-input-affix-wrapper .ant-input,
  .ant-input-group-wrapper.search-w-btn .ant-input-group .ant-input-affix-wrapper .ant-input {
    border-radius: 4px;
    height: 43px;
    line-height: 43px; }
    .ant-input-search.search-w-btn .ant-input-group .ant-input-affix-wrapper .ant-input:hover, .ant-input-search.search-w-btn .ant-input-group .ant-input-affix-wrapper .ant-input:focus,
    .ant-input-search-enter-button.search-w-btn .ant-input-group .ant-input-affix-wrapper .ant-input:hover,
    .ant-input-search-enter-button.search-w-btn .ant-input-group .ant-input-affix-wrapper .ant-input:focus,
    .ant-input-group-wrapper.search-w-btn .ant-input-group .ant-input-affix-wrapper .ant-input:hover,
    .ant-input-group-wrapper.search-w-btn .ant-input-group .ant-input-affix-wrapper .ant-input:focus {
      box-shadow: none;
      border: 0;
      outline: none; }

.ant-input-search.search-w-btn .ant-input-group .ant-input-prefix .anticon,
.ant-input-search-enter-button.search-w-btn .ant-input-group .ant-input-prefix .anticon,
.ant-input-group-wrapper.search-w-btn .ant-input-group .ant-input-prefix .anticon {
  font-size: 16px !important;
  color: rgba(150, 165, 171, 0.6) !important; }

.ant-input-search.search-w-btn .ant-input-group .ant-input-group-addon,
.ant-input-search-enter-button.search-w-btn .ant-input-group .ant-input-group-addon,
.ant-input-group-wrapper.search-w-btn .ant-input-group .ant-input-group-addon {
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 35px;
  min-width: 35px;
  line-height: 45px;
  height: 100%;
  margin: 0 10px 0 auto;
  z-index: 999;
  background: transparent; }
  .ant-input-search.search-w-btn .ant-input-group .ant-input-group-addon .ant-btn,
  .ant-input-search-enter-button.search-w-btn .ant-input-group .ant-input-group-addon .ant-btn,
  .ant-input-group-wrapper.search-w-btn .ant-input-group .ant-input-group-addon .ant-btn {
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 4px; }
    .ant-input-search.search-w-btn .ant-input-group .ant-input-group-addon .ant-btn .icon,
    .ant-input-search-enter-button.search-w-btn .ant-input-group .ant-input-group-addon .ant-btn .icon,
    .ant-input-group-wrapper.search-w-btn .ant-input-group .ant-input-group-addon .ant-btn .icon {
      font-size: 16px !important; }
  @media screen and (max-width: 1440px) {
    .ant-input-search.search-w-btn .ant-input-group .ant-input-group-addon,
    .ant-input-search-enter-button.search-w-btn .ant-input-group .ant-input-group-addon,
    .ant-input-group-wrapper.search-w-btn .ant-input-group .ant-input-group-addon {
      line-height: 45px; } }

/** /.Theme Input **/
/** Theme Search Bar **/
.ant-input-affix-wrapper {
  /** Search Bar Icon **/
  /** Search Bar Icon **/ }
  .ant-input-affix-wrapper input::-webkit-input-placeholder {
    color: #d0d8db; }
  .ant-input-affix-wrapper input:-moz-placeholder {
    color: #d0d8db; }
  .ant-input-affix-wrapper input::-moz-placeholder {
    color: #d0d8db; }
  .ant-input-affix-wrapper input:-ms-input-placeholder {
    color: #d0d8db; }
  .ant-input-affix-wrapper .ant-input {
    border-color: #e3e8ea;
    height: 24px;
    line-height: 24px;
    color: #202124;
    font-size: 14px; }
    .ant-input-affix-wrapper .ant-input:focus {
      box-shadow: none; }
    @media screen and (max-width: 1440px) {
      .ant-input-affix-wrapper .ant-input {
        font-size: 13px; } }
  .ant-input-affix-wrapper .ant-input-suffix .anticon {
    font-size: 14px;
    color: #d0d8db !important; }
    @media screen and (max-width: 1440px) {
      .ant-input-affix-wrapper .ant-input-suffix .anticon {
        font-size: 13px; } }
  .ant-input-affix-wrapper.borderless {
    padding: 0;
    border: 0; }
    .ant-input-affix-wrapper.borderless .ant-input {
      padding: 0 11px !important;
      padding-left: 30px !important; }
      @media screen and (max-width: 1024px) {
        .ant-input-affix-wrapper.borderless .ant-input {
          font-size: 13px; } }
      @media screen and (max-width: 480px) {
        .ant-input-affix-wrapper.borderless .ant-input {
          padding-left: 25px !important; } }
    .ant-input-affix-wrapper.borderless .ant-input-suffix {
      width: 30px;
      margin: 0 auto;
      justify-content: center;
      position: absolute;
      top: 0px;
      height: 100%; }
    .ant-input-affix-wrapper.borderless .ant-input-search-icon {
      padding: 0;
      margin: 0; }
      .ant-input-affix-wrapper.borderless .ant-input-search-icon:before {
        border-left: 0; }
    .ant-input-affix-wrapper.borderless:hover, .ant-input-affix-wrapper.borderless:focus, .ant-input-affix-wrapper.borderless.ant-input-affix-wrapper-focused {
      border: 0;
      box-shadow: none; }
  .ant-input-affix-wrapper.input-w-multi-items .ant-input-prefix .icon,
  .ant-input-affix-wrapper.input-w-multi-items .ant-input-prefix .anticon {
    font-size: 18px;
    cursor: pointer; }
  .ant-input-affix-wrapper.input-w-multi-items .ant-input {
    padding-left: 10px !important; }
  .ant-input-affix-wrapper.input-w-multi-items .ant-input-suffix {
    position: relative;
    width: 60px; }
    .ant-input-affix-wrapper.input-w-multi-items .ant-input-suffix .icon,
    .ant-input-affix-wrapper.input-w-multi-items .ant-input-suffix .anticon {
      cursor: pointer;
      width: 50%;
      text-align: center;
      font-size: 18px; }
  .ant-input-affix-wrapper.input-suffix-right.ant-input-affix-wrapper .ant-input {
    padding-left: 20px !important; }
  .ant-input-affix-wrapper.input-suffix-right.ant-input-affix-wrapper .ant-input-suffix {
    margin: 0 0 0 auto; }

.global-search.ant-select-auto-complete .ant-select-selection__placeholder {
  margin-left: 20px; }

/** /.Theme Search Bar **/
/** Theme Form **/
.ant-form .ant-form-item .ant-form-item-label > label,
.ant-legacy-form .ant-form-item .ant-form-item-label > label {
  font-weight: 700;
  font-size: 16px; }
  .ant-form .ant-form-item .ant-form-item-label > label:after,
  .ant-legacy-form .ant-form-item .ant-form-item-label > label:after {
    display: none; }
  .ant-form .ant-form-item .ant-form-item-label > label:before,
  .ant-legacy-form .ant-form-item .ant-form-item-label > label:before {
    position: absolute;
    top: 0;
    right: -12px;
    height: 100%;
    margin-right: 0;
    font-size: 10px; }

.ant-form .ant-form-item .ant-form-item-control,
.ant-legacy-form .ant-form-item .ant-form-item-control {
  line-height: 29px; }
  .ant-form .ant-form-item .ant-form-item-control .ant-input,
  .ant-legacy-form .ant-form-item .ant-form-item-control .ant-input {
    color: #202124; }
    .ant-form .ant-form-item .ant-form-item-control .ant-input.ant-input-lg,
    .ant-legacy-form .ant-form-item .ant-form-item-control .ant-input.ant-input-lg {
      height: 40px;
      font-size: 16px !important;
      padding: 9px 11px !important; }
    .ant-form .ant-form-item .ant-form-item-control .ant-input.ant-input-sm,
    .ant-legacy-form .ant-form-item .ant-form-item-control .ant-input.ant-input-sm {
      font-size: 12px; }
    .ant-form .ant-form-item .ant-form-item-control .ant-input:hover, .ant-form .ant-form-item .ant-form-item-control .ant-input:focus,
    .ant-legacy-form .ant-form-item .ant-form-item-control .ant-input:hover,
    .ant-legacy-form .ant-form-item .ant-form-item-control .ant-input:focus {
      border-color: #DBA44F; }
    .ant-form .ant-form-item .ant-form-item-control .ant-input:focus,
    .ant-legacy-form .ant-form-item .ant-form-item-control .ant-input:focus {
      box-shadow: 0 0 0 2px rgba(219, 164, 79, 0.2); }
  .ant-form .ant-form-item .ant-form-item-control.has-error .ant-input,
  .ant-legacy-form .ant-form-item .ant-form-item-control.has-error .ant-input {
    border-color: #ea4335; }
  .ant-form .ant-form-item .ant-form-item-control.has-error:hover .ant-input, .ant-form .ant-form-item .ant-form-item-control.has-error:focus .ant-input,
  .ant-legacy-form .ant-form-item .ant-form-item-control.has-error:hover .ant-input,
  .ant-legacy-form .ant-form-item .ant-form-item-control.has-error:focus .ant-input {
    border-color: #ea4335; }
  .ant-form .ant-form-item .ant-form-item-control.has-warning .ant-input,
  .ant-legacy-form .ant-form-item .ant-form-item-control.has-warning .ant-input {
    border-color: #fbbc05; }
  .ant-form .ant-form-item .ant-form-item-control.has-warning:hover .ant-input, .ant-form .ant-form-item .ant-form-item-control.has-warning:focus .ant-input,
  .ant-legacy-form .ant-form-item .ant-form-item-control.has-warning:hover .ant-input,
  .ant-legacy-form .ant-form-item .ant-form-item-control.has-warning:focus .ant-input {
    border-color: #fbbc05; }
  .ant-form .ant-form-item .ant-form-item-control .ant-input-group .ant-input-group-addon,
  .ant-legacy-form .ant-form-item .ant-form-item-control .ant-input-group .ant-input-group-addon {
    min-width: 40px; }
    .ant-form .ant-form-item .ant-form-item-control .ant-input-group .ant-input-group-addon .anticon,
    .ant-legacy-form .ant-form-item .ant-form-item-control .ant-input-group .ant-input-group-addon .anticon {
      color: #202124 !important;
      font-size: 16px; }
    .ant-form .ant-form-item .ant-form-item-control .ant-input-group .ant-input-group-addon .icon,
    .ant-legacy-form .ant-form-item .ant-form-item-control .ant-input-group .ant-input-group-addon .icon {
      color: #202124 !important;
      font-size: 16px; }
  .ant-form .ant-form-item .ant-form-item-control .ant-input-group .ant-input-lg,
  .ant-legacy-form .ant-form-item .ant-form-item-control .ant-input-group .ant-input-lg {
    height: 40px; }
  .ant-form .ant-form-item .ant-form-item-control .ant-form-extra,
  .ant-form .ant-form-item .ant-form-item-control .ant-form-explain,
  .ant-legacy-form .ant-form-item .ant-form-item-control .ant-form-extra,
  .ant-legacy-form .ant-form-item .ant-form-item-control .ant-form-explain {
    padding-top: 10px;
    min-height: auto; }
    .ant-form .ant-form-item .ant-form-item-control .ant-form-extra strong,
    .ant-form .ant-form-item .ant-form-item-control .ant-form-explain strong,
    .ant-legacy-form .ant-form-item .ant-form-item-control .ant-form-extra strong,
    .ant-legacy-form .ant-form-item .ant-form-item-control .ant-form-explain strong {
      margin-bottom: 0;
      color: #202124; }
  .ant-form .ant-form-item .ant-form-item-control .ant-form-explain,
  .ant-legacy-form .ant-form-item .ant-form-item-control .ant-form-explain {
    min-height: 40px; }
  .ant-form .ant-form-item .ant-form-item-control .ant-checkbox-group,
  .ant-legacy-form .ant-form-item .ant-form-item-control .ant-checkbox-group {
    width: 100%; }
  .ant-form .ant-form-item .ant-form-item-control .ant-checkbox-wrapper .ant-checkbox,
  .ant-legacy-form .ant-form-item .ant-form-item-control .ant-checkbox-wrapper .ant-checkbox {
    font-size: 14px;
    color: #202124; }
    .ant-form .ant-form-item .ant-form-item-control .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner,
    .ant-legacy-form .ant-form-item .ant-form-item-control .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
      border-color: #e3e8ea;
      border-radius: 2px; }
    .ant-form .ant-form-item .ant-form-item-control .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-input:focus .ant-checkbox-inner,
    .ant-legacy-form .ant-form-item .ant-form-item-control .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-input:focus .ant-checkbox-inner {
      border-color: #DBA44F; }
    .ant-form .ant-form-item .ant-form-item-control .ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner,
    .ant-legacy-form .ant-form-item .ant-form-item-control .ant-checkbox-wrapper .ant-checkbox:hover .ant-checkbox-inner {
      border-color: #DBA44F; }
    .ant-form .ant-form-item .ant-form-item-control .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner,
    .ant-legacy-form .ant-form-item .ant-form-item-control .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
      border-color: #DBA44F;
      background-color: #DBA44F; }
    .ant-form .ant-form-item .ant-form-item-control .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked:after,
    .ant-legacy-form .ant-form-item .ant-form-item-control .ant-checkbox-wrapper .ant-checkbox.ant-checkbox-checked:after {
      border-color: #DBA44F; }
  .ant-form .ant-form-item .ant-form-item-control .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-legacy-form .ant-form-item .ant-form-item-control .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: #DBA44F; }
  .ant-form .ant-form-item .ant-form-item-control .ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox-checked .ant-checkbox-inner,
  .ant-legacy-form .ant-form-item .ant-form-item-control .ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #DBA44F;
    background-color: #DBA44F; }
  .ant-form .ant-form-item .ant-form-item-control .ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox-checked:after,
  .ant-legacy-form .ant-form-item .ant-form-item-control .ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox-checked:after {
    border-color: #DBA44F; }
  .ant-form .ant-form-item .ant-form-item-control .form-forgot-link,
  .ant-legacy-form .ant-form-item .ant-form-item-control .form-forgot-link {
    float: right;
    font-size: 16px;
    font-weight: 700;
    color: #DBA44F; }
  .ant-form .ant-form-item .ant-form-item-control .ant-btn.ant-btn-primary,
  .ant-legacy-form .ant-form-item .ant-form-item-control .ant-btn.ant-btn-primary {
    background: rgba(219, 164, 79, 0.9); }
  .ant-form .ant-form-item .ant-form-item-control strong,
  .ant-legacy-form .ant-form-item .ant-form-item-control strong {
    display: block;
    font-size: 15px;
    font-weight: 700;
    color: #202124;
    margin-bottom: 15px; }
  .ant-form .ant-form-item .ant-form-item-control .ant-input-affix-wrapper.ant-input-affix-wrapper-focused .ant-input,
  .ant-legacy-form .ant-form-item .ant-form-item-control .ant-input-affix-wrapper.ant-input-affix-wrapper-focused .ant-input {
    border: 0;
    box-shadow: none;
    outline: none; }
    .ant-form .ant-form-item .ant-form-item-control .ant-input-affix-wrapper.ant-input-affix-wrapper-focused .ant-input:hover, .ant-form .ant-form-item .ant-form-item-control .ant-input-affix-wrapper.ant-input-affix-wrapper-focused .ant-input:focus,
    .ant-legacy-form .ant-form-item .ant-form-item-control .ant-input-affix-wrapper.ant-input-affix-wrapper-focused .ant-input:hover,
    .ant-legacy-form .ant-form-item .ant-form-item-control .ant-input-affix-wrapper.ant-input-affix-wrapper-focused .ant-input:focus {
      border: 0;
      box-shadow: none;
      outline: none; }

.ant-form .ant-checkbox-group,
.ant-legacy-form .ant-checkbox-group {
  width: 100%; }

.ant-form .ant-form-item-explain,
.ant-form .ant-form-item-extra,
.ant-legacy-form .ant-form-item-explain,
.ant-legacy-form .ant-form-item-extra {
  margin: 0; }

.ant-form.ant-form-vertical .ant-form-item,
.ant-legacy-form.ant-form-vertical .ant-form-item {
  padding-bottom: 0; }

.ant-form.ant-form-horizontal .ant-form-item .ant-form-item-label,
.ant-legacy-form.ant-form-horizontal .ant-form-item .ant-form-item-label {
  text-align: left;
  line-height: 39px; }

.ant-form.ant-form-inline .ant-form-item .ant-form-item-label,
.ant-legacy-form.ant-form-inline .ant-form-item .ant-form-item-label {
  text-align: left;
  line-height: 32px;
  margin-right: 20px; }

.ant-form p,
.ant-legacy-form p {
  color: #96a5ab;
  margin-bottom: 10px; }

.ant-form .ant-picker,
.ant-legacy-form .ant-picker {
  width: 100%; }

.ant-form.profile-overview-form .ant-btn,
.ant-legacy-form.profile-overview-form .ant-btn {
  width: 120px; }

@media (max-width: 480px) {
  .ant-legacy-form.ant-legacy-form-inline .ant-legacy-form-item {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%; }
    .ant-legacy-form.ant-legacy-form-inline .ant-legacy-form-item:last-child {
      margin-bottom: 0; } }

/** /.Theme Form **/
/** Range Picker **/
.ant-calendar-picker:hover .ant-calendar-picker-input, .ant-calendar-picker:focus .ant-calendar-picker-input {
  border-color: #adb7bb !important;
  box-shadow: none !important;
  outline: none !important; }

.ant-calendar-picker input::-webkit-input-placeholder {
  color: #202124; }

.ant-calendar-picker input:-moz-placeholder {
  color: #202124; }

.ant-calendar-picker input::-moz-placeholder {
  color: #202124; }

.ant-calendar-picker input:-ms-input-placeholder {
  color: #202124; }

.ant-calendar-picker .ant-calendar-picker-input {
  background: #f5f7f7;
  border-color: #e3e8ea;
  color: #202124;
  font-size: 13px;
  font-weight: 700;
  text-align: right;
  padding-left: 15px !important; }
  .ant-calendar-picker .ant-calendar-picker-input:hover, .ant-calendar-picker .ant-calendar-picker-input:focus {
    border-color: #adb7bb;
    box-shadow: none;
    outline: none; }
  .ant-calendar-picker .ant-calendar-picker-input .ant-calendar-range-picker-input {
    font-weight: 700; }

.ant-calendar-picker .ant-calendar-picker-icon {
  color: #202124;
  font-size: 20px; }

.ant-calendar-picker .ant-calendar-picker-clear {
  font-size: 18px;
  top: 2px;
  right: 2px; }

.ant-calendar-picker.date-picker-bg-white:hover .ant-calendar-picker-input, .ant-calendar-picker.date-picker-bg-white:focus .ant-calendar-picker-input {
  border-color: #202124 !important; }

.ant-calendar-picker.date-picker-bg-white input::-webkit-input-placeholder {
  color: #96a5ab; }

.ant-calendar-picker.date-picker-bg-white input:-moz-placeholder {
  color: #96a5ab; }

.ant-calendar-picker.date-picker-bg-white input::-moz-placeholder {
  color: #96a5ab; }

.ant-calendar-picker.date-picker-bg-white input:-ms-input-placeholder {
  color: #96a5ab; }

.ant-calendar-picker.date-picker-bg-white .ant-calendar-picker-input {
  background: white;
  color: #96a5ab;
  text-align: left; }
  .ant-calendar-picker.date-picker-bg-white .ant-calendar-picker-input:hover .ant-calendar-picker-input, .ant-calendar-picker.date-picker-bg-white .ant-calendar-picker-input:focus .ant-calendar-picker-input {
    border-color: #202124 !important; }

.ant-calendar-picker.date-picker-bg-white .ant-calendar-picker-icon {
  color: #96a5ab !important;
  font-size: 14px; }

.ant-calendar-picker.input-text-right .ant-calendar-picker-input {
  text-align: left; }

.ant-calendar-picker.input-text-right .ant-calendar-picker-icon {
  font-size: 16px; }

.ant-calendar-picker-container {
  color: #202124;
  font-size: 14px; }
  .ant-calendar-picker-container input::-webkit-input-placeholder {
    color: #202124; }
  .ant-calendar-picker-container input:-moz-placeholder {
    color: #202124; }
  .ant-calendar-picker-container input::-moz-placeholder {
    color: #202124; }
  .ant-calendar-picker-container input:-ms-input-placeholder {
    color: #202124; }
  .ant-calendar-picker-container .ant-calendar-input {
    color: #202124; }
  .ant-calendar-picker-container .ant-calendar-header .ant-calendar-year-select,
  .ant-calendar-picker-container .ant-calendar-header .ant-calendar-month-select {
    color: #202124; }
  .ant-calendar-picker-container .ant-calendar-header a:hover {
    color: rgba(150, 165, 171, 0.5); }
  .ant-calendar-picker-container .ant-calendar-year-panel-year:hover {
    background: #f5f7f7;
    color: #202124 !important; }
  .ant-calendar-picker-container .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
    background: #202124; }
    .ant-calendar-picker-container .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover {
      color: #fff !important; }
  .ant-calendar-picker-container .ant-calendar-month-panel-month:hover {
    background: #f5f7f7;
    color: #202124 !important; }
  .ant-calendar-picker-container .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
    background: #202124; }
    .ant-calendar-picker-container .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
      color: #fff !important; }
  .ant-calendar-picker-container .ant-calendar-decade-panel-decade:hover {
    background: #f5f7f7;
    color: #202124 !important; }
  .ant-calendar-picker-container .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
    background: #202124; }
    .ant-calendar-picker-container .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover {
      color: #fff !important; }
  .ant-calendar-picker-container .ant-calendar-date:hover {
    background: #f5f7f7 !important; }
  .ant-calendar-picker-container .ant-calendar-selected-day .ant-calendar-date {
    background: #f9fafa !important;
    font-weight: 700 !important;
    color: #202124 !important;
    border-color: #202124 !important; }
  .ant-calendar-picker-container .ant-calendar-today .ant-calendar-date {
    color: #202124 !important;
    background: #f9fafa !important;
    border-color: #202124 !important; }
  .ant-calendar-picker-container .ant-calendar-footer .ant-calendar-footer-btn a {
    color: #202124; }

.ant-calendar-range .ant-calendar-in-range-cell::before {
  background: #202124;
  color: #fff; }

.ant-calendar-range .ant-calendar-in-range-cell .ant-calendar-date {
  color: #fff; }

/** /.Range Picker **/
/** Select **/
.ant-select {
  font-size: 14px;
  font-weight: 700;
  font-family: "Nunito Sans", sans-serif;
  color: #202124; }
  .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 34px; }
    .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
      height: 32px; }
    .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
      line-height: 32px; }
  .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-arrow {
    right: 7px; }
    .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-arrow .anticon {
      font-size: 12px; }
  .ant-select.select-alternative.ant-select-single:not(.ant-select-customize-input) {
    color: #96a5ab;
    font-weight: 500; }
    .ant-select.select-alternative.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      border-color: transparent; }
    .ant-select.select-alternative.ant-select-single:not(.ant-select-customize-input) .ant-select-arrow .anticon {
      color: #96a5ab !important; }
    .ant-select.select-alternative.ant-select-single:not(.ant-select-customize-input).color-icon .ant-select-arrow .anticon {
      color: #DBA44F !important; }
  .ant-select.select-alternative.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
    border-color: transparent;
    outline: none; }
  .ant-select.borderless-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: transparent; }
  .ant-select.borderless-select.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
    border-color: transparent;
    outline: none; }

.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  color: white !important; }

.ant-select-dropdown-menu .ant-select-dropdown-menu-item {
  padding: 5px 12px;
  font-size: 14px;
  font-weight: 500;
  color: #202124; }
  .ant-select-dropdown-menu .ant-select-dropdown-menu-item:hover {
    background: #f9fafa !important; }
  .ant-select-dropdown-menu .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
    color: #202124;
    background: #f9fafa; }
  .ant-select-dropdown-menu .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-active {
    color: #202124;
    background: #f9fafa; }

.ant-select-dropdown {
  z-index: 9999999 !important; }

/** /.Select **/
/** Theme List **/
.analysis-list li {
  width: 33.3333%;
  display: inline-block;
  text-align: center;
  padding-bottom: 20px; }

.analysis-list .title {
  display: block;
  font-size: 15px;
  font-weight: 700;
  color: #202124; }
  @media screen and (max-width: 1440px) {
    .analysis-list .title {
      font-size: 14px; } }

.analysis-list span {
  font-size: 16px;
  color: #202124;
  display: inline-block; }
  @media screen and (max-width: 1440px) {
    .analysis-list span {
      font-size: 15px; } }

@media (max-width: 767px) {
  .graph-report-list-h {
    display: flex;
    align-items: center;
    justify-content: center; }
    .graph-report-list-h .graph-report-list {
      padding-bottom: 0;
      padding-right: 30px; } }

@media (max-width: 560px) {
  .graph-report-list-h {
    flex-flow: row wrap;
    padding-bottom: 20px; }
    .graph-report-list-h .graph-report-list {
      flex: 1 1 100%;
      padding-bottom: 20px;
      padding-right: 0; } }

.graph-report-list {
  padding-bottom: 20px; }
  .graph-report-list .heading {
    padding-bottom: 15px; }
    @media screen and (max-width: 1024px) {
      .graph-report-list .heading {
        padding-bottom: 10px; } }
    .graph-report-list .heading .title {
      margin-bottom: 0; }
  .graph-report-list .graph-title {
    font-size: 15px;
    margin-bottom: 0; }
    @media screen and (max-width: 1440px) {
      .graph-report-list .graph-title {
        font-size: 14px; } }
  .graph-report-list .icon {
    font-size: 20px;
    margin-right: 15px;
    color: rgba(251, 188, 5, 0.7) !important; }
    @media screen and (max-width: 1440px) {
      .graph-report-list .icon {
        font-size: 18px; } }
  .graph-report-list .items {
    margin-left: 8px; }
    .graph-report-list .items li {
      position: relative;
      font-size: 14px;
      line-height: 18px;
      padding-left: 15px;
      padding-bottom: 10px; }
      .graph-report-list .items li:last-child {
        padding-bottom: 0; }
      .graph-report-list .items li:after {
        content: "";
        position: absolute;
        top: 5px;
        left: 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: rgba(251, 188, 5, 0.7); }
        @media screen and (max-width: 1440px) {
          .graph-report-list .items li:after {
            width: 6px;
            height: 6px; } }
      @media screen and (max-width: 1440px) {
        .graph-report-list .items li {
          font-size: 13px; } }

@media screen and (max-width: 1440px) {
  .legend-list-holder.pd-l-30 {
    padding-left: 10px; } }

@media screen and (max-width: 767px) {
  .legend-list-holder.pd-l-30 {
    padding-left: 0px; } }

.legend-list-holder .legend-list {
  padding-bottom: 20px; }
  @media screen and (max-width: 1440px) {
    .legend-list-holder .legend-list .pd-l-30 {
      padding-left: 15px; } }

.legend-list-holder .title {
  font-size: 15px;
  margin-bottom: 5px;
  padding-left: 20px;
  position: relative; }
  .legend-list-holder .title:after {
    content: "";
    position: absolute;
    top: 6px;
    left: 0;
    width: 10px;
    height: 10px;
    background: #202124;
    border-radius: 50%; }
  @media screen and (max-width: 1024px) {
    .legend-list-holder .title {
      font-size: 14px; }
      .legend-list-holder .title:after {
        width: 8px;
        height: 8px; } }
  .legend-list-holder .title.circle-danger:after {
    background: #ea4335; }
  .legend-list-holder .title.circle-primary:after {
    background: #DBA44F; }
  .legend-list-holder .title.circle-success:after {
    background: #34a853; }
  .legend-list-holder .title.circle-warning:after {
    background: #fbbc05; }

.legend-list-holder .subtitle {
  font-size: 13px;
  color: #96a5ab; }

.view-list {
  text-align: center; }
  .view-list li {
    display: inline-block;
    padding: 0 14px;
    margin: 0 20px 15px;
    position: relative; }
    .view-list li:first-child::after {
      display: none; }
    .view-list li::after {
      content: "";
      position: absolute;
      top: -10px;
      left: -20px;
      height: 140%;
      width: 1px;
      background-color: #DBA44F;
      transform: rotate(30deg); }
      @media screen and (max-width: 1440px) {
        .view-list li::after {
          top: -5px;
          height: 120%; } }
  .view-list .title {
    font-size: 16px;
    margin-bottom: 5px; }
    @media screen and (max-width: 1440px) {
      .view-list .title {
        font-size: 14px; } }
    @media screen and (max-width: 1300px) {
      .view-list .title {
        font-size: 13px; } }
    @media screen and (max-width: 480px) {
      .view-list .title {
        font-size: 12px; } }
  .view-list .number {
    font-size: 18px; }
    .view-list .number .icon {
      font-size: 16px; }
      @media screen and (max-width: 1440px) {
        .view-list .number .icon {
          font-size: 14px; } }
      @media screen and (max-width: 480px) {
        .view-list .number .icon {
          font-size: 12px; } }
    @media screen and (max-width: 1440px) {
      .view-list .number {
        font-size: 16px; } }
    @media screen and (max-width: 480px) {
      .view-list .number {
        font-size: 14px; } }

/** /.Theme List **/
/***********************
    Global CSS
***********************/
/** Main Navigation **/
.ant-menu-horizontal .ant-menu-item .anticon,
.ant-menu-horizontal .ant-menu-submenu-title .anticon {
  margin-right: 0;
  font-size: 18px;
  vertical-align: middle;
  padding-top: 40px; }

.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu-title {
  border-bottom: 0; }

.ant-menu-horizontal .ant-menu-item:hover {
  border-bottom: 0; }

.ant-menu-horizontal .ant-menu-item-active {
  color: #202124; }

.ant-menu-horizontal .ant-menu-submenu {
  line-height: 62px;
  border-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: transparent; }
  .ant-menu-horizontal .ant-menu-submenu .ant-menu-item {
    padding: 0 15px;
    transition: all ease-in-out 0.4s; }
  .ant-menu-horizontal .ant-menu-submenu .ant-menu-item-active {
    color: #202124; }
    .ant-menu-horizontal .ant-menu-submenu .ant-menu-item-active .icon {
      color: #202124 !important; }
  .ant-menu-horizontal .ant-menu-submenu .ant-menu-item-selected {
    color: #202124; }
    .ant-menu-horizontal .ant-menu-submenu .ant-menu-item-selected .icon {
      color: #202124 !important; }
  .ant-menu-horizontal .ant-menu-submenu .icon {
    font-size: 22px; }
  .ant-menu-horizontal .ant-menu-submenu .persone-detail {
    display: flex;
    align-items: center; }
  .ant-menu-horizontal .ant-menu-submenu .language {
    display: block;
    width: 30px;
    height: 30px;
    margin-right: 15px; }
  .ant-menu-horizontal .ant-menu-submenu .language-en {
    background-image: url(/images/flag.png);
    background-size: cover; }
  .ant-menu-horizontal .ant-menu-submenu .user-text {
    font-size: 14px;
    font-weight: 800; }
  .ant-menu-horizontal .ant-menu-submenu .persone-title-holder {
    padding: 0 15px; }

.ant-menu-horizontal.menu-icon-mr-r .ant-menu-item .anticon,
.ant-menu-horizontal.menu-icon-mr-r .ant-menu-submenu-title .anticon {
  margin-right: 7px; }

.ant-menu-submenu {
  line-height: 62px;
  border-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: transparent; }
  .ant-menu-submenu .ant-menu-item {
    padding: 0 15px;
    transition: all ease-in-out 0.4s; }
  .ant-menu-submenu .ant-menu-item-active {
    color: white; }
    .ant-menu-submenu .ant-menu-item-active .icon {
      color: #80adf7 !important; }
  .ant-menu-submenu .ant-menu-item-selected {
    color: white; }
  .ant-menu-submenu .icon {
    font-size: 16px;
    margin-right: 10px; }
  .ant-menu-submenu.submenu-line-height-40 {
    line-height: 40px; }

.ant-menu-submenu-popup {
  z-index: 9999999 !important; }

/** /.Main Navigation **/
/** Menu Vertical **/
.ant-menu-vertical .ant-menu-submenu-title {
  width: 100%; }

.ant-menu-vertical .ant-menu-item-selected {
  color: #689df6;
  background: transparent !important; }

/** /.Menu Vertical **/
/** Main Sider **/
.ant-menu.ant-menu-inline, .ant-menu.ant-menu-inline-collapsed {
  width: 100%;
  height: 100%;
  border: 0;
  background: transparent;
  padding-top: 20px; }
  .ant-menu.ant-menu-inline .ant-menu-submenu, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu {
    display: block !important; }
  .ant-menu.ant-menu-inline .ant-menu-item, .ant-menu.ant-menu-inline-collapsed .ant-menu-item {
    padding: 0 15px !important;
    text-align: left;
    height: auto !important;
    line-height: 50px !important;
    margin: 0;
    font-size: 13px;
    color: #202124;
    font-weight: 700; }
    .ant-menu.ant-menu-inline .ant-menu-item .icon, .ant-menu.ant-menu-inline-collapsed .ant-menu-item .icon {
      font-size: 16px;
      margin-right: 15px;
      color: rgba(32, 33, 36, 0.85); }
      @media (min-width: 1441px) {
        .ant-menu.ant-menu-inline .ant-menu-item .icon, .ant-menu.ant-menu-inline-collapsed .ant-menu-item .icon {
          font-size: 18px; } }
    .ant-menu.ant-menu-inline .ant-menu-item::after, .ant-menu.ant-menu-inline-collapsed .ant-menu-item::after {
      border-color: #e3e8ea;
      top: 17px;
      right: 15px;
      height: 20px; }
    @media (min-width: 1441px) {
      .ant-menu.ant-menu-inline .ant-menu-item, .ant-menu.ant-menu-inline-collapsed .ant-menu-item {
        font-size: 15px; } }
  .ant-menu.ant-menu-inline .ant-menu-item-selected,
  .ant-menu.ant-menu-inline .ant-menu-item-active, .ant-menu.ant-menu-inline-collapsed .ant-menu-item-selected,
  .ant-menu.ant-menu-inline-collapsed .ant-menu-item-active {
    color: #DBA44F !important;
    background: transparent !important; }
    .ant-menu.ant-menu-inline .ant-menu-item-selected::after,
    .ant-menu.ant-menu-inline .ant-menu-item-active::after, .ant-menu.ant-menu-inline-collapsed .ant-menu-item-selected::after,
    .ant-menu.ant-menu-inline-collapsed .ant-menu-item-active::after {
      border-color: #DBA44F; }
    .ant-menu.ant-menu-inline .ant-menu-item-selected .icon,
    .ant-menu.ant-menu-inline .ant-menu-item-active .icon, .ant-menu.ant-menu-inline-collapsed .ant-menu-item-selected .icon,
    .ant-menu.ant-menu-inline-collapsed .ant-menu-item-active .icon {
      color: #DBA44F !important; }
  .ant-menu.ant-menu-inline .ant-menu-item:active,
  .ant-menu.ant-menu-inline .ant-menu-submenu-title:active, .ant-menu.ant-menu-inline-collapsed .ant-menu-item:active,
  .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-title:active {
    color: #DBA44F !important;
    background: transparent !important; }
    .ant-menu.ant-menu-inline .ant-menu-item:active::after,
    .ant-menu.ant-menu-inline .ant-menu-submenu-title:active::after, .ant-menu.ant-menu-inline-collapsed .ant-menu-item:active::after,
    .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-title:active::after {
      border-color: #DBA44F; }
    .ant-menu.ant-menu-inline .ant-menu-item:active .icon,
    .ant-menu.ant-menu-inline .ant-menu-submenu-title:active .icon, .ant-menu.ant-menu-inline-collapsed .ant-menu-item:active .icon,
    .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-title:active .icon {
      color: #DBA44F !important; }
  .ant-menu.ant-menu-inline .ant-menu-submenu, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu {
    margin: 0; }
    .ant-menu.ant-menu-inline .ant-menu-submenu .ant-menu-item-group, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-item-group {
      padding: 0 15px; }
      .ant-menu.ant-menu-inline .ant-menu-submenu .ant-menu-item-group .ant-menu-item-group-title, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-item-group .ant-menu-item-group-title {
        padding-left: 15px; }
      .ant-menu.ant-menu-inline .ant-menu-submenu .ant-menu-item-group .ant-menu-item-group-list, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-item-group .ant-menu-item-group-list {
        padding: 0 15px; }
  .ant-menu.ant-menu-inline .ant-menu-sub, .ant-menu.ant-menu-inline-collapsed .ant-menu-sub {
    background: transparent; }
    .ant-menu.ant-menu-inline .ant-menu-sub .ant-menu-item, .ant-menu.ant-menu-inline-collapsed .ant-menu-sub .ant-menu-item {
      padding: 0 30px !important;
      padding-left: 50px !important;
      margin: 0 !important;
      font-size: 12px;
      font-weight: 700;
      color: rgba(32, 33, 36, 0.7); }
      @media (min-width: 1441px) {
        .ant-menu.ant-menu-inline .ant-menu-sub .ant-menu-item, .ant-menu.ant-menu-inline-collapsed .ant-menu-sub .ant-menu-item {
          font-size: 14px; } }
    .ant-menu.ant-menu-inline .ant-menu-sub .ant-menu-item-active, .ant-menu.ant-menu-inline-collapsed .ant-menu-sub .ant-menu-item-active {
      color: #DBA44F; }
      .ant-menu.ant-menu-inline .ant-menu-sub .ant-menu-item-active:before, .ant-menu.ant-menu-inline-collapsed .ant-menu-sub .ant-menu-item-active:before {
        background: #DBA44F; }
    .ant-menu.ant-menu-inline .ant-menu-sub .ant-menu-submenu .ant-menu-submenu-title, .ant-menu.ant-menu-inline-collapsed .ant-menu-sub .ant-menu-submenu .ant-menu-submenu-title {
      padding: 0 30px !important;
      padding-left: 50px !important;
      font-size: 12px;
      font-weight: 700;
      color: rgba(32, 33, 36, 0.7); }
      @media (min-width: 1441px) {
        .ant-menu.ant-menu-inline .ant-menu-sub .ant-menu-submenu .ant-menu-submenu-title, .ant-menu.ant-menu-inline-collapsed .ant-menu-sub .ant-menu-submenu .ant-menu-submenu-title {
          font-size: 14px; } }
    .ant-menu.ant-menu-inline .ant-menu-sub .ant-menu-submenu .ant-menu-sub, .ant-menu.ant-menu-inline-collapsed .ant-menu-sub .ant-menu-submenu .ant-menu-sub {
      padding: 0 25px; }
  .ant-menu.ant-menu-inline .ant-menu-submenu-title, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-title {
    padding: 0 15px !important;
    text-align: left;
    margin: 0;
    height: auto !important;
    line-height: 50px !important;
    font-size: 13px;
    color: #202124;
    font-weight: 700; }
    .ant-menu.ant-menu-inline .ant-menu-submenu-title .icon, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-title .icon {
      font-size: 16px;
      margin-right: 15px;
      color: rgba(32, 33, 36, 0.85); }
      @media (min-width: 1441px) {
        .ant-menu.ant-menu-inline .ant-menu-submenu-title .icon, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-title .icon {
          font-size: 18px; } }
    .ant-menu.ant-menu-inline .ant-menu-submenu-title:hover .icon, .ant-menu.ant-menu-inline .ant-menu-submenu-title:focus .icon, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-title:hover .icon, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-title:focus .icon {
      color: #DBA44F !important; }
    .ant-menu.ant-menu-inline .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu.ant-menu-inline .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu.ant-menu-inline .ant-menu-submenu-title:focus .ant-menu-submenu-arrow::before, .ant-menu.ant-menu-inline .ant-menu-submenu-title:focus .ant-menu-submenu-arrow::after, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-title:focus .ant-menu-submenu-arrow::before, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-title:focus .ant-menu-submenu-arrow::after {
      background: linear-gradient(to right, #DBA44F, #DBA44F) !important; }
    @media (min-width: 1441px) {
      .ant-menu.ant-menu-inline .ant-menu-submenu-title, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-title {
        font-size: 15px; } }
  .ant-menu.ant-menu-inline .ant-menu-submenu-arrow::before, .ant-menu.ant-menu-inline .ant-menu-submenu-arrow::after, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-arrow::before, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-arrow::after {
    background: linear-gradient(to right, #202124, #202124) !important; }
  .ant-menu.ant-menu-inline .ant-menu-submenu-open .ant-menu-submenu-title, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-open .ant-menu-submenu-title {
    color: #DBA44F; }
    .ant-menu.ant-menu-inline .ant-menu-submenu-open .ant-menu-submenu-title .icon, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-open .ant-menu-submenu-title .icon {
      color: #DBA44F !important; }
    .ant-menu.ant-menu-inline .ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu.ant-menu-inline .ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
      background: linear-gradient(to right, #DBA44F, #DBA44F) !important; }
  .ant-menu.ant-menu-inline .ant-menu-submenu-open .ant-menu-submenu .ant-menu-submenu-title, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-open .ant-menu-submenu .ant-menu-submenu-title {
    color: #202124; }
    .ant-menu.ant-menu-inline .ant-menu-submenu-open .ant-menu-submenu .ant-menu-submenu-title .icon, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-open .ant-menu-submenu .ant-menu-submenu-title .icon {
      color: #202124 !important; }
    .ant-menu.ant-menu-inline .ant-menu-submenu-open .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu.ant-menu-inline .ant-menu-submenu-open .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-open .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-open .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
      background: linear-gradient(to right, #202124, #202124) !important; }
  .ant-menu.ant-menu-inline .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title {
    color: #DBA44F; }
    .ant-menu.ant-menu-inline .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title .icon, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title .icon {
      color: #DBA44F !important; }
    .ant-menu.ant-menu-inline .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu.ant-menu-inline .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
      background: linear-gradient(to right, #DBA44F, #DBA44F); }
  .ant-menu.ant-menu-inline .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu .ant-menu-submenu-title, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu .ant-menu-submenu-title {
    color: #202124; }
    .ant-menu.ant-menu-inline .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu .ant-menu-submenu-title .icon, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu .ant-menu-submenu-title .icon {
      color: #202124 !important; }
    .ant-menu.ant-menu-inline .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu.ant-menu-inline .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
      background: linear-gradient(to right, #202124, #202124); }
  .ant-menu.ant-menu-inline .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title {
    color: #DBA44F; }
    .ant-menu.ant-menu-inline .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title .icon, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title .icon {
      color: #DBA44F !important; }
    .ant-menu.ant-menu-inline .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu.ant-menu-inline .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
      background: linear-gradient(to right, #DBA44F, #DBA44F); }
  .ant-menu.ant-menu-inline .ant-menu-submenu-active .ant-menu-submenu-title, .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-active .ant-menu-submenu-title {
    color: #DBA44F;
    background: transparent; }
  .ant-menu.ant-menu-inline .ant-menu-item-group-title, .ant-menu.ant-menu-inline-collapsed .ant-menu-item-group-title {
    padding: 0 15px;
    line-height: 50px;
    font-size: 13px;
    font-weight: 700;
    color: #202124; }
    .ant-menu.ant-menu-inline .ant-menu-item-group-title .icon, .ant-menu.ant-menu-inline-collapsed .ant-menu-item-group-title .icon {
      color: #202124 !important; }
  .ant-menu.ant-menu-inline.menu-alternative.ant-menu-inline, .ant-menu.ant-menu-inline-collapsed.menu-alternative.ant-menu-inline {
    background: none;
    padding-top: 0;
    border: none;
    height: 100%;
    width: 100%; }
    .ant-menu.ant-menu-inline.menu-alternative.ant-menu-inline .ant-menu-item, .ant-menu.ant-menu-inline-collapsed.menu-alternative.ant-menu-inline .ant-menu-item {
      padding: 0 15px !important;
      height: auto !important;
      line-height: 40px !important;
      margin: 0 0 5px;
      border-radius: 4px;
      color: #96a5ab; }
      .ant-menu.ant-menu-inline.menu-alternative.ant-menu-inline .ant-menu-item:last-child, .ant-menu.ant-menu-inline-collapsed.menu-alternative.ant-menu-inline .ant-menu-item:last-child {
        margin-bottom: 0; }
      .ant-menu.ant-menu-inline.menu-alternative.ant-menu-inline .ant-menu-item::after, .ant-menu.ant-menu-inline-collapsed.menu-alternative.ant-menu-inline .ant-menu-item::after {
        border-color: #e3e8ea;
        top: 10px; }
      .ant-menu.ant-menu-inline.menu-alternative.ant-menu-inline .ant-menu-item .icon,
      .ant-menu.ant-menu-inline.menu-alternative.ant-menu-inline .ant-menu-item .anticon, .ant-menu.ant-menu-inline-collapsed.menu-alternative.ant-menu-inline .ant-menu-item .icon,
      .ant-menu.ant-menu-inline-collapsed.menu-alternative.ant-menu-inline .ant-menu-item .anticon {
        color: #96a5ab !important;
        font-size: 16px;
        margin-right: 20px; }
      .ant-menu.ant-menu-inline.menu-alternative.ant-menu-inline .ant-menu-item .icon, .ant-menu.ant-menu-inline-collapsed.menu-alternative.ant-menu-inline .ant-menu-item .icon {
        line-height: 40px; }
      .ant-menu.ant-menu-inline.menu-alternative.ant-menu-inline .ant-menu-item.ant-menu-item-selected, .ant-menu.ant-menu-inline.menu-alternative.ant-menu-inline .ant-menu-item.ant-menu-item-active, .ant-menu.ant-menu-inline-collapsed.menu-alternative.ant-menu-inline .ant-menu-item.ant-menu-item-selected, .ant-menu.ant-menu-inline-collapsed.menu-alternative.ant-menu-inline .ant-menu-item.ant-menu-item-active {
        background: #f9fafa;
        color: #202124; }
        .ant-menu.ant-menu-inline.menu-alternative.ant-menu-inline .ant-menu-item.ant-menu-item-selected .icon,
        .ant-menu.ant-menu-inline.menu-alternative.ant-menu-inline .ant-menu-item.ant-menu-item-selected .anticon, .ant-menu.ant-menu-inline.menu-alternative.ant-menu-inline .ant-menu-item.ant-menu-item-active .icon,
        .ant-menu.ant-menu-inline.menu-alternative.ant-menu-inline .ant-menu-item.ant-menu-item-active .anticon, .ant-menu.ant-menu-inline-collapsed.menu-alternative.ant-menu-inline .ant-menu-item.ant-menu-item-selected .icon,
        .ant-menu.ant-menu-inline-collapsed.menu-alternative.ant-menu-inline .ant-menu-item.ant-menu-item-selected .anticon, .ant-menu.ant-menu-inline-collapsed.menu-alternative.ant-menu-inline .ant-menu-item.ant-menu-item-active .icon,
        .ant-menu.ant-menu-inline-collapsed.menu-alternative.ant-menu-inline .ant-menu-item.ant-menu-item-active .anticon {
          color: #202124 !important; }
      .ant-menu.ant-menu-inline.menu-alternative.ant-menu-inline .ant-menu-item.ant-menu-item:active, .ant-menu.ant-menu-inline.menu-alternative.ant-menu-inline .ant-menu-item.ant-menu-submenu-title:active, .ant-menu.ant-menu-inline-collapsed.menu-alternative.ant-menu-inline .ant-menu-item.ant-menu-item:active, .ant-menu.ant-menu-inline-collapsed.menu-alternative.ant-menu-inline .ant-menu-item.ant-menu-submenu-title:active {
        background: #f9fafa; }
        .ant-menu.ant-menu-inline.menu-alternative.ant-menu-inline .ant-menu-item.ant-menu-item:active .anticon, .ant-menu.ant-menu-inline.menu-alternative.ant-menu-inline .ant-menu-item.ant-menu-submenu-title:active .anticon, .ant-menu.ant-menu-inline-collapsed.menu-alternative.ant-menu-inline .ant-menu-item.ant-menu-item:active .anticon, .ant-menu.ant-menu-inline-collapsed.menu-alternative.ant-menu-inline .ant-menu-item.ant-menu-submenu-title:active .anticon {
          color: #202124 !important; }
  .ant-menu.ant-menu-inline.menu-w-round-circle .ant-menu-sub .ant-menu-item, .ant-menu.ant-menu-inline-collapsed.menu-w-round-circle .ant-menu-sub .ant-menu-item {
    padding-left: 30px !important; }
    .ant-menu.ant-menu-inline.menu-w-round-circle .ant-menu-sub .ant-menu-item:before, .ant-menu.ant-menu-inline-collapsed.menu-w-round-circle .ant-menu-sub .ant-menu-item:before {
      position: absolute;
      top: 21px;
      left: 50px;
      width: 7px;
      height: 7px;
      background: rgba(150, 165, 171, 0.6);
      border-radius: 50%;
      transition: all ease-in-out 0.4s; }
  .ant-menu.ant-menu-inline.menu-w-round-circle .ant-menu-sub .ant-menu-item-active:before, .ant-menu.ant-menu-inline-collapsed.menu-w-round-circle .ant-menu-sub .ant-menu-item-active:before {
    background: #DBA44F; }

/** /.Main Sider **/
/** User Profile Info **/
.user-info {
  padding: 0 10px 20px;
  margin-bottom: 20px; }
  .user-info .ant-avatar {
    margin-bottom: 20px; }
  .user-info .title {
    font-size: 17px;
    margin-bottom: 2px; }
  .user-info .designation {
    color: #96a5ab;
    font-size: 15px;
    margin-bottom: 20px;
    display: block; }
  .user-info .biography {
    color: #202124;
    margin-bottom: 0; }

/** Ends User Profile Info **/
/** Main Content **/
.main-content-inner-with-aside-right {
  display: flex; }
  .main-content-inner-with-aside-right .main-content-inner {
    width: 100%; }

.main-content-aside-right {
  width: 300px;
  background: #f9fafa;
  padding: 30px 20px;
  border-left: 1px solid #e3e8ea; }
  @media screen and (max-width: 1200px) {
    .main-content-aside-right {
      padding: 30px 20px; } }
  @media screen and (max-width: 1023px) {
    .main-content-aside-right {
      display: none; } }
  .main-content-aside-right .user-info {
    padding: 0 0 20px; }
  .main-content-aside-right .main-title {
    font-size: 16px;
    line-height: 10px;
    text-transform: uppercase;
    color: #202124;
    margin-bottom: 30px; }
    .main-content-aside-right .main-title.mr-t-30 {
      margin-top: 15px; }
  .main-content-aside-right .icon.icon-bg {
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
    color: #202124 !important;
    background: #f9fafa;
    border-radius: 50%;
    text-align: center;
    margin-bottom: 20px; }
    .main-content-aside-right .icon.icon-bg.danger {
      background: #ea4335;
      color: white !important; }
    .main-content-aside-right .icon.icon-bg.primary {
      background: #DBA44F;
      color: white !important; }
    .main-content-aside-right .icon.icon-bg.success {
      background: #34a853;
      color: white !important; }
    .main-content-aside-right .icon.icon-bg.warning {
      background: #fbbc05;
      color: white !important; }
    .main-content-aside-right .icon.icon-bg.secondary {
      background: #00cccc;
      color: white !important; }
  .main-content-aside-right .icon-holder .icon {
    margin-left: 10px;
    margin-bottom: 20px;
    cursor: pointer; }
    .main-content-aside-right .icon-holder .icon:first-child {
      margin-left: 0; }
    .main-content-aside-right .icon-holder .icon:hover {
      opacity: 0.8; }
  .main-content-aside-right .title {
    font-size: 15px;
    line-height: 1;
    color: #202124;
    margin-bottom: 3px; }
    .main-content-aside-right .title.mr-b-30 {
      margin-bottom: 20px;
      font-size: 13px; }
  .main-content-aside-right .subtitle {
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    color: #96a5ab; }
  .main-content-aside-right .sub-title-alternative {
    font-size: 15px;
    color: #202124;
    display: block;
    margin-bottom: 20px; }
  .main-content-aside-right .link {
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    color: #DBA44F;
    margin-bottom: 20px;
    transition: all ease-in-out 0.4s; }
    .main-content-aside-right .link:hover {
      color: #96a5ab; }
  .main-content-aside-right .main-content-aside-right-list li {
    color: #96a5ab;
    font-size: 14px;
    padding-bottom: 10px;
    transition: all ease-in-out 0.4s;
    cursor: pointer; }
    .main-content-aside-right .main-content-aside-right-list li.active-list {
      color: #DBA44F;
      font-weight: 700; }
    @media screen and (max-width: 1200px) {
      .main-content-aside-right .main-content-aside-right-list li {
        font-size: 13px; } }
    .main-content-aside-right .main-content-aside-right-list li:last-child {
      padding-bottom: 0 !important; }
  .main-content-aside-right .main-content-aside-right-list.aside-right-list-alternative li {
    padding-bottom: 20px;
    font-size: 15px; }
    .main-content-aside-right .main-content-aside-right-list.aside-right-list-alternative li .icon {
      font-size: 17px;
      margin-right: 8px; }
  .main-content-aside-right .main-content-aside-right-list.aside-right-list-hover {
    padding: 20px 0; }
    .main-content-aside-right .main-content-aside-right-list.aside-right-list-hover li {
      padding-bottom: 20px;
      font-size: 18px;
      color: #202124;
      font-weight: 700; }
      .main-content-aside-right .main-content-aside-right-list.aside-right-list-hover li .icon {
        font-size: 16px;
        margin-right: 7px;
        color: #202124 !important; }
      .main-content-aside-right .main-content-aside-right-list.aside-right-list-hover li:hover {
        color: #DBA44F; }
        .main-content-aside-right .main-content-aside-right-list.aside-right-list-hover li:hover .icon {
          color: #DBA44F !important; }

.main-content-inner {
  padding: 30px 30px 0;
  height: 100%;
  min-height: calc(90vh - 65px); }
  @media screen and (max-width: 991px) {
    .main-content-inner {
      padding: 30px 20px 0; } }

.main-content {
  position: relative;
  background: white;
  margin-left: 15.3%;
  margin-top: 75px; }
  .main-content.aside-collapsed {
    margin-left: 70px; }
    .main-content.aside-collapsed .main-header.ant-layout-header {
      width: 95.5%;
      margin-left: 70px; }
      @media (min-width: 1441px) {
        .main-content.aside-collapsed .main-header.ant-layout-header {
          width: 96.5%; } }
  @media screen and (max-width: 1024px) {
    .main-content {
      margin-left: 0; } }

.app-content {
  padding: 0px !important;
  height: 100% !important; }

/** /.Main Content **/
/** Divider **/
.ant-divider .ant-divider-inner-text {
  font-size: 14px; }

.ant-divider.ant-divider-horizontal {
  margin: 30px 0; }
  .ant-divider.ant-divider-horizontal.ant-divider-with-text-left {
    margin: 30px 0; }

.ant-divider.ant-divider-dashed {
  background: none; }

.ant-divider.divider-alternative .ant-divider-inner-text {
  color: #96a5ab; }

.ant-divider.divider-alternative.ant-divider-with-text-left {
  margin: 15px 0 0; }

/** /.Divider **/
/** Page Header **/
.ant-page-header .ant-page-header-back-button .anticon {
  font-size: 16px;
  color: #202124 !important; }

@media (max-width: 480px) {
  .ant-page-header.ant-page-header-compact .ant-page-header-heading-extra {
    white-space: unset !important; }
    .ant-page-header.ant-page-header-compact .ant-page-header-heading-extra > * {
      margin-left: 0;
      margin-right: 10px;
      margin-bottom: 10px; }
    .ant-page-header.ant-page-header-compact .ant-page-header-heading-extra .ant-btn:last-child {
      margin-bottom: 0; } }

/** Ends Page Header **/
/** Pagination **/
.ant-pagination .ant-pagination-prev .ant-pagination-item-link .anticon,
.ant-pagination .ant-pagination-prev .ant-pagination-item-link .icon,
.ant-pagination .ant-pagination-next .ant-pagination-item-link .anticon,
.ant-pagination .ant-pagination-next .ant-pagination-item-link .icon {
  font-size: 14px;
  color: rgba(32, 33, 36, 0.7) !important; }

.ant-pagination .ant-pagination-prev .ant-pagination-item-link:hover,
.ant-pagination .ant-pagination-next .ant-pagination-item-link:hover {
  color: #DBA44F !important; }
  .ant-pagination .ant-pagination-prev .ant-pagination-item-link:hover .anticon,
  .ant-pagination .ant-pagination-prev .ant-pagination-item-link:hover .icon,
  .ant-pagination .ant-pagination-next .ant-pagination-item-link:hover .anticon,
  .ant-pagination .ant-pagination-next .ant-pagination-item-link:hover .icon {
    color: #DBA44F !important; }

@media (max-width: 570px) {
  .ant-pagination .ant-pagination-prev,
  .ant-pagination .ant-pagination-next {
    margin-bottom: 10px; } }

@media (max-width: 570px) {
  .ant-pagination .ant-pagination-item {
    margin-bottom: 10px; } }

.ant-pagination .ant-pagination-item:hover, .ant-pagination .ant-pagination-item:focus {
  border-color: #DBA44F;
  color: #DBA44F; }
  .ant-pagination .ant-pagination-item:hover a, .ant-pagination .ant-pagination-item:focus a {
    color: #DBA44F; }

.ant-pagination .ant-pagination-item.ant-pagination-item-active {
  border-color: #DBA44F;
  color: #DBA44F; }
  .ant-pagination .ant-pagination-item.ant-pagination-item-active a {
    color: #DBA44F; }

.ant-pagination .ant-pagination-disabled {
  opacity: .7; }

.ant-pagination.danger .ant-pagination-prev .ant-pagination-item-link .anticon,
.ant-pagination.danger .ant-pagination-prev .ant-pagination-item-link .icon,
.ant-pagination.danger .ant-pagination-next .ant-pagination-item-link .anticon,
.ant-pagination.danger .ant-pagination-next .ant-pagination-item-link .icon {
  font-size: 14px;
  color: rgba(32, 33, 36, 0.7) !important; }

.ant-pagination.danger .ant-pagination-prev .ant-pagination-item-link:hover,
.ant-pagination.danger .ant-pagination-next .ant-pagination-item-link:hover {
  color: #ea4335 !important; }
  .ant-pagination.danger .ant-pagination-prev .ant-pagination-item-link:hover .anticon,
  .ant-pagination.danger .ant-pagination-prev .ant-pagination-item-link:hover .icon,
  .ant-pagination.danger .ant-pagination-next .ant-pagination-item-link:hover .anticon,
  .ant-pagination.danger .ant-pagination-next .ant-pagination-item-link:hover .icon {
    color: #ea4335 !important; }

@media (max-width: 570px) {
  .ant-pagination.danger .ant-pagination-prev,
  .ant-pagination.danger .ant-pagination-next {
    margin-bottom: 10px; } }

@media (max-width: 570px) {
  .ant-pagination.danger .ant-pagination-item {
    margin-bottom: 10px; } }

.ant-pagination.danger .ant-pagination-item:hover, .ant-pagination.danger .ant-pagination-item:focus {
  border-color: #ea4335;
  color: #ea4335; }
  .ant-pagination.danger .ant-pagination-item:hover a, .ant-pagination.danger .ant-pagination-item:focus a {
    color: #ea4335; }

.ant-pagination.danger .ant-pagination-item.ant-pagination-item-active {
  border-color: #ea4335;
  color: #ea4335; }
  .ant-pagination.danger .ant-pagination-item.ant-pagination-item-active a {
    color: #ea4335; }

.ant-pagination.danger .ant-pagination-disabled {
  opacity: .7; }

.ant-pagination.success .ant-pagination-prev .ant-pagination-item-link .anticon,
.ant-pagination.success .ant-pagination-prev .ant-pagination-item-link .icon,
.ant-pagination.success .ant-pagination-next .ant-pagination-item-link .anticon,
.ant-pagination.success .ant-pagination-next .ant-pagination-item-link .icon {
  font-size: 14px;
  color: rgba(32, 33, 36, 0.7) !important; }

.ant-pagination.success .ant-pagination-prev .ant-pagination-item-link:hover,
.ant-pagination.success .ant-pagination-next .ant-pagination-item-link:hover {
  color: #34a853 !important; }
  .ant-pagination.success .ant-pagination-prev .ant-pagination-item-link:hover .anticon,
  .ant-pagination.success .ant-pagination-prev .ant-pagination-item-link:hover .icon,
  .ant-pagination.success .ant-pagination-next .ant-pagination-item-link:hover .anticon,
  .ant-pagination.success .ant-pagination-next .ant-pagination-item-link:hover .icon {
    color: #34a853 !important; }

@media (max-width: 570px) {
  .ant-pagination.success .ant-pagination-prev,
  .ant-pagination.success .ant-pagination-next {
    margin-bottom: 10px; } }

@media (max-width: 570px) {
  .ant-pagination.success .ant-pagination-item {
    margin-bottom: 10px; } }

.ant-pagination.success .ant-pagination-item:hover, .ant-pagination.success .ant-pagination-item:focus {
  border-color: #34a853;
  color: #34a853; }
  .ant-pagination.success .ant-pagination-item:hover a, .ant-pagination.success .ant-pagination-item:focus a {
    color: #34a853; }

.ant-pagination.success .ant-pagination-item.ant-pagination-item-active {
  border-color: #34a853;
  color: #34a853; }
  .ant-pagination.success .ant-pagination-item.ant-pagination-item-active a {
    color: #34a853; }

.ant-pagination.success .ant-pagination-disabled {
  opacity: .7; }

.ant-pagination.warning .ant-pagination-prev .ant-pagination-item-link .anticon,
.ant-pagination.warning .ant-pagination-prev .ant-pagination-item-link .icon,
.ant-pagination.warning .ant-pagination-next .ant-pagination-item-link .anticon,
.ant-pagination.warning .ant-pagination-next .ant-pagination-item-link .icon {
  font-size: 14px;
  color: rgba(32, 33, 36, 0.7) !important; }

.ant-pagination.warning .ant-pagination-prev .ant-pagination-item-link:hover,
.ant-pagination.warning .ant-pagination-next .ant-pagination-item-link:hover {
  color: #fbbc05 !important; }
  .ant-pagination.warning .ant-pagination-prev .ant-pagination-item-link:hover .anticon,
  .ant-pagination.warning .ant-pagination-prev .ant-pagination-item-link:hover .icon,
  .ant-pagination.warning .ant-pagination-next .ant-pagination-item-link:hover .anticon,
  .ant-pagination.warning .ant-pagination-next .ant-pagination-item-link:hover .icon {
    color: #fbbc05 !important; }

@media (max-width: 570px) {
  .ant-pagination.warning .ant-pagination-prev,
  .ant-pagination.warning .ant-pagination-next {
    margin-bottom: 10px; } }

@media (max-width: 570px) {
  .ant-pagination.warning .ant-pagination-item {
    margin-bottom: 10px; } }

.ant-pagination.warning .ant-pagination-item:hover, .ant-pagination.warning .ant-pagination-item:focus {
  border-color: #fbbc05;
  color: #fbbc05; }
  .ant-pagination.warning .ant-pagination-item:hover a, .ant-pagination.warning .ant-pagination-item:focus a {
    color: #fbbc05; }

.ant-pagination.warning .ant-pagination-item.ant-pagination-item-active {
  border-color: #fbbc05;
  color: #fbbc05; }
  .ant-pagination.warning .ant-pagination-item.ant-pagination-item-active a {
    color: #fbbc05; }

.ant-pagination.warning .ant-pagination-disabled {
  opacity: .7; }

.ant-pagination.solid .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.solid .ant-pagination-next .ant-pagination-item-link {
  background: #f5f7f7; }
  .ant-pagination.solid .ant-pagination-prev .ant-pagination-item-link .anticon,
  .ant-pagination.solid .ant-pagination-prev .ant-pagination-item-link .icon,
  .ant-pagination.solid .ant-pagination-next .ant-pagination-item-link .anticon,
  .ant-pagination.solid .ant-pagination-next .ant-pagination-item-link .icon {
    color: rgba(32, 33, 36, 0.7) !important; }
  .ant-pagination.solid .ant-pagination-prev .ant-pagination-item-link:hover,
  .ant-pagination.solid .ant-pagination-next .ant-pagination-item-link:hover {
    background: #DBA44F;
    color: white !important;
    border-color: #b87e26; }
    .ant-pagination.solid .ant-pagination-prev .ant-pagination-item-link:hover .anticon,
    .ant-pagination.solid .ant-pagination-prev .ant-pagination-item-link:hover .icon,
    .ant-pagination.solid .ant-pagination-next .ant-pagination-item-link:hover .anticon,
    .ant-pagination.solid .ant-pagination-next .ant-pagination-item-link:hover .icon {
      color: white !important; }

.ant-pagination.solid .ant-pagination-item {
  background: #f5f7f7; }
  .ant-pagination.solid .ant-pagination-item:hover, .ant-pagination.solid .ant-pagination-item:focus {
    background: #DBA44F;
    border-color: #b87e26;
    color: white; }
    .ant-pagination.solid .ant-pagination-item:hover a, .ant-pagination.solid .ant-pagination-item:focus a {
      color: white; }
  .ant-pagination.solid .ant-pagination-item.ant-pagination-item-active {
    background: #DBA44F;
    border-color: #b87e26;
    color: white; }
    .ant-pagination.solid .ant-pagination-item.ant-pagination-item-active a {
      color: white; }

.ant-pagination.solid.danger .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.solid.danger .ant-pagination-next .ant-pagination-item-link {
  background: #f5f7f7; }
  .ant-pagination.solid.danger .ant-pagination-prev .ant-pagination-item-link .anticon,
  .ant-pagination.solid.danger .ant-pagination-prev .ant-pagination-item-link .icon,
  .ant-pagination.solid.danger .ant-pagination-next .ant-pagination-item-link .anticon,
  .ant-pagination.solid.danger .ant-pagination-next .ant-pagination-item-link .icon {
    color: rgba(32, 33, 36, 0.7) !important; }
  .ant-pagination.solid.danger .ant-pagination-prev .ant-pagination-item-link:hover,
  .ant-pagination.solid.danger .ant-pagination-next .ant-pagination-item-link:hover {
    background: #ea4335;
    color: white !important;
    border-color: #bf2114; }
    .ant-pagination.solid.danger .ant-pagination-prev .ant-pagination-item-link:hover .anticon,
    .ant-pagination.solid.danger .ant-pagination-prev .ant-pagination-item-link:hover .icon,
    .ant-pagination.solid.danger .ant-pagination-next .ant-pagination-item-link:hover .anticon,
    .ant-pagination.solid.danger .ant-pagination-next .ant-pagination-item-link:hover .icon {
      color: white !important; }

.ant-pagination.solid.danger .ant-pagination-item {
  background: #f5f7f7; }
  .ant-pagination.solid.danger .ant-pagination-item:hover, .ant-pagination.solid.danger .ant-pagination-item:focus {
    background: #ea4335;
    border-color: #bf2114;
    color: white; }
    .ant-pagination.solid.danger .ant-pagination-item:hover a, .ant-pagination.solid.danger .ant-pagination-item:focus a {
      color: white; }
  .ant-pagination.solid.danger .ant-pagination-item.ant-pagination-item-active {
    background: #ea4335;
    border-color: #bf2114;
    color: white; }
    .ant-pagination.solid.danger .ant-pagination-item.ant-pagination-item-active a {
      color: white; }

.ant-pagination.solid.success .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.solid.success .ant-pagination-next .ant-pagination-item-link {
  background: #f5f7f7; }
  .ant-pagination.solid.success .ant-pagination-prev .ant-pagination-item-link .anticon,
  .ant-pagination.solid.success .ant-pagination-prev .ant-pagination-item-link .icon,
  .ant-pagination.solid.success .ant-pagination-next .ant-pagination-item-link .anticon,
  .ant-pagination.solid.success .ant-pagination-next .ant-pagination-item-link .icon {
    color: rgba(32, 33, 36, 0.7) !important; }
  .ant-pagination.solid.success .ant-pagination-prev .ant-pagination-item-link:hover,
  .ant-pagination.solid.success .ant-pagination-next .ant-pagination-item-link:hover {
    background: #34a853;
    color: white !important;
    border-color: #226e36; }
    .ant-pagination.solid.success .ant-pagination-prev .ant-pagination-item-link:hover .anticon,
    .ant-pagination.solid.success .ant-pagination-prev .ant-pagination-item-link:hover .icon,
    .ant-pagination.solid.success .ant-pagination-next .ant-pagination-item-link:hover .anticon,
    .ant-pagination.solid.success .ant-pagination-next .ant-pagination-item-link:hover .icon {
      color: white !important; }

.ant-pagination.solid.success .ant-pagination-item {
  background: #f5f7f7; }
  .ant-pagination.solid.success .ant-pagination-item:hover, .ant-pagination.solid.success .ant-pagination-item:focus {
    background: #34a853;
    border-color: #226e36;
    color: white; }
    .ant-pagination.solid.success .ant-pagination-item:hover a, .ant-pagination.solid.success .ant-pagination-item:focus a {
      color: white; }
  .ant-pagination.solid.success .ant-pagination-item.ant-pagination-item-active {
    background: #34a853;
    border-color: #226e36;
    color: white; }
    .ant-pagination.solid.success .ant-pagination-item.ant-pagination-item-active a {
      color: white; }

.ant-pagination.solid.warning .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.solid.warning .ant-pagination-next .ant-pagination-item-link {
  background: #f5f7f7; }
  .ant-pagination.solid.warning .ant-pagination-prev .ant-pagination-item-link .anticon,
  .ant-pagination.solid.warning .ant-pagination-prev .ant-pagination-item-link .icon,
  .ant-pagination.solid.warning .ant-pagination-next .ant-pagination-item-link .anticon,
  .ant-pagination.solid.warning .ant-pagination-next .ant-pagination-item-link .icon {
    color: rgba(32, 33, 36, 0.7) !important; }
  .ant-pagination.solid.warning .ant-pagination-prev .ant-pagination-item-link:hover,
  .ant-pagination.solid.warning .ant-pagination-next .ant-pagination-item-link:hover {
    background: #fbbc05;
    color: white !important;
    border-color: #b18403; }
    .ant-pagination.solid.warning .ant-pagination-prev .ant-pagination-item-link:hover .anticon,
    .ant-pagination.solid.warning .ant-pagination-prev .ant-pagination-item-link:hover .icon,
    .ant-pagination.solid.warning .ant-pagination-next .ant-pagination-item-link:hover .anticon,
    .ant-pagination.solid.warning .ant-pagination-next .ant-pagination-item-link:hover .icon {
      color: white !important; }

.ant-pagination.solid.warning .ant-pagination-item {
  background: #f5f7f7; }
  .ant-pagination.solid.warning .ant-pagination-item:hover, .ant-pagination.solid.warning .ant-pagination-item:focus {
    background: #fbbc05;
    border-color: #b18403;
    color: white; }
    .ant-pagination.solid.warning .ant-pagination-item:hover a, .ant-pagination.solid.warning .ant-pagination-item:focus a {
      color: white; }
  .ant-pagination.solid.warning .ant-pagination-item.ant-pagination-item-active {
    background: #fbbc05;
    border-color: #b18403;
    color: white; }
    .ant-pagination.solid.warning .ant-pagination-item.ant-pagination-item-active a {
      color: white; }

.ant-pagination.mini .ant-pagination-options-quick-jumper input {
  padding: 1px 9px !important; }

/** Ends Pagination **/
/** Steps **/
.ant-steps .anticon {
  font-size: 16px; }

.ant-steps .ant-steps-item-icon .ant-steps-icon .anticon {
  color: #DBA44F !important; }

.ant-steps .ant-steps-item-custom .ant-steps-item-icon .ant-steps-icon .anticon {
  font-size: 24px; }

.ant-steps .ant-steps-item-error .ant-steps-item-icon .ant-steps-icon .anticon {
  color: #ea4335 !important; }

.ant-steps.ant-steps-small .anticon {
  font-size: 12px; }

@media (max-width: 567px) {
  .ant-steps.ant-steps-horizontal.steps-height .ant-steps-item-content {
    min-height: 78px; } }

.ant-steps.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-content {
  min-height: 100%; }

.ant-steps.ant-steps-navigation {
  box-shadow: #e8e8e8 0px -1px 0px 0px inset; }
  .ant-steps.ant-steps-navigation.ant-steps-vertical .ant-steps-item-container {
    padding-bottom: 0; }
  .ant-steps.ant-steps-navigation.ant-steps-vertical .ant-steps-item {
    margin-bottom: 15px; }
  .ant-steps.ant-steps-navigation.ant-steps-vertical .ant-steps-item-tail:after {
    display: none; }

.steps-content {
  margin-top: 20px;
  border: 1px dashed #e3e8ea;
  border-radius: 4px;
  background-color: #f9fafa;
  min-height: 200px;
  line-height: 200px;
  text-align: center; }

/** Ends Steps **/
/** Breadcrumb **/
.ant-breadcrumb {
  margin-bottom: 15px !important;
  margin-top: 0; }
  .ant-breadcrumb .ant-breadcrumb-link {
    font-weight: 700;
    color: #DBA44F; }
    .ant-breadcrumb .ant-breadcrumb-link > .icon + span,
    .ant-breadcrumb .ant-breadcrumb-link > .anticon + span {
      transition: all ease-in-out .4s;
      margin-left: 5px;
      display: inline-block; }
    .ant-breadcrumb .ant-breadcrumb-link a {
      color: #202124; }
      .ant-breadcrumb .ant-breadcrumb-link a > .icon,
      .ant-breadcrumb .ant-breadcrumb-link a > .anticon {
        transition: all ease-in-out .4s; }
        .ant-breadcrumb .ant-breadcrumb-link a > .icon + span,
        .ant-breadcrumb .ant-breadcrumb-link a > .anticon + span {
          margin-left: 5px;
          display: inline-block; }
      .ant-breadcrumb .ant-breadcrumb-link a:hover {
        color: #DBA44F; }
      .ant-breadcrumb .ant-breadcrumb-link a.ant-breadcrumb-link {
        color: #202124;
        transition: all ease-in-out .4s; }
        .ant-breadcrumb .ant-breadcrumb-link a.ant-breadcrumb-link > .icon + span,
        .ant-breadcrumb .ant-breadcrumb-link a.ant-breadcrumb-link > .anticon + span {
          margin-left: 5px;
          display: inline-block; }
        .ant-breadcrumb .ant-breadcrumb-link a.ant-breadcrumb-link:hover {
          color: #DBA44F; }
  .ant-breadcrumb.breadcrumb-black .ant-breadcrumb-link {
    font-weight: 700;
    color: #202124; }
    .ant-breadcrumb.breadcrumb-black .ant-breadcrumb-link > .icon + span,
    .ant-breadcrumb.breadcrumb-black .ant-breadcrumb-link > .anticon + span {
      transition: all ease-in-out .4s;
      margin-left: 5px;
      display: inline-block; }
    .ant-breadcrumb.breadcrumb-black .ant-breadcrumb-link a {
      color: #96a5ab; }
      .ant-breadcrumb.breadcrumb-black .ant-breadcrumb-link a > .icon,
      .ant-breadcrumb.breadcrumb-black .ant-breadcrumb-link a > .anticon {
        transition: all ease-in-out .4s; }
        .ant-breadcrumb.breadcrumb-black .ant-breadcrumb-link a > .icon + span,
        .ant-breadcrumb.breadcrumb-black .ant-breadcrumb-link a > .anticon + span {
          margin-left: 5px;
          display: inline-block; }
      .ant-breadcrumb.breadcrumb-black .ant-breadcrumb-link a:hover {
        color: #202124; }
      .ant-breadcrumb.breadcrumb-black .ant-breadcrumb-link a.ant-breadcrumb-link {
        color: #96a5ab;
        transition: all ease-in-out .4s; }
        .ant-breadcrumb.breadcrumb-black .ant-breadcrumb-link a.ant-breadcrumb-link > .icon + span,
        .ant-breadcrumb.breadcrumb-black .ant-breadcrumb-link a.ant-breadcrumb-link > .anticon + span {
          margin-left: 5px;
          display: inline-block; }
        .ant-breadcrumb.breadcrumb-black .ant-breadcrumb-link a.ant-breadcrumb-link:hover {
          color: #202124; }
  .ant-breadcrumb.breadcrumb-danger .ant-breadcrumb-link {
    font-weight: 700;
    color: #ea4335; }
    .ant-breadcrumb.breadcrumb-danger .ant-breadcrumb-link > .icon + span,
    .ant-breadcrumb.breadcrumb-danger .ant-breadcrumb-link > .anticon + span {
      transition: all ease-in-out .4s;
      margin-left: 5px;
      display: inline-block; }
    .ant-breadcrumb.breadcrumb-danger .ant-breadcrumb-link a {
      color: #202124; }
      .ant-breadcrumb.breadcrumb-danger .ant-breadcrumb-link a > .icon,
      .ant-breadcrumb.breadcrumb-danger .ant-breadcrumb-link a > .anticon {
        transition: all ease-in-out .4s; }
        .ant-breadcrumb.breadcrumb-danger .ant-breadcrumb-link a > .icon + span,
        .ant-breadcrumb.breadcrumb-danger .ant-breadcrumb-link a > .anticon + span {
          margin-left: 5px;
          display: inline-block; }
      .ant-breadcrumb.breadcrumb-danger .ant-breadcrumb-link a:hover {
        color: #ea4335; }
      .ant-breadcrumb.breadcrumb-danger .ant-breadcrumb-link a.ant-breadcrumb-link {
        color: #202124;
        transition: all ease-in-out .4s; }
        .ant-breadcrumb.breadcrumb-danger .ant-breadcrumb-link a.ant-breadcrumb-link > .icon + span,
        .ant-breadcrumb.breadcrumb-danger .ant-breadcrumb-link a.ant-breadcrumb-link > .anticon + span {
          margin-left: 5px;
          display: inline-block; }
        .ant-breadcrumb.breadcrumb-danger .ant-breadcrumb-link a.ant-breadcrumb-link:hover {
          color: #ea4335; }
  .ant-breadcrumb.breadcrumb-warning .ant-breadcrumb-link {
    font-weight: 700;
    color: #fbbc05; }
    .ant-breadcrumb.breadcrumb-warning .ant-breadcrumb-link > .icon + span,
    .ant-breadcrumb.breadcrumb-warning .ant-breadcrumb-link > .anticon + span {
      transition: all ease-in-out .4s;
      margin-left: 5px;
      display: inline-block; }
    .ant-breadcrumb.breadcrumb-warning .ant-breadcrumb-link a {
      color: #202124; }
      .ant-breadcrumb.breadcrumb-warning .ant-breadcrumb-link a > .icon,
      .ant-breadcrumb.breadcrumb-warning .ant-breadcrumb-link a > .anticon {
        transition: all ease-in-out .4s; }
        .ant-breadcrumb.breadcrumb-warning .ant-breadcrumb-link a > .icon + span,
        .ant-breadcrumb.breadcrumb-warning .ant-breadcrumb-link a > .anticon + span {
          margin-left: 5px;
          display: inline-block; }
      .ant-breadcrumb.breadcrumb-warning .ant-breadcrumb-link a:hover {
        color: #fbbc05; }
      .ant-breadcrumb.breadcrumb-warning .ant-breadcrumb-link a.ant-breadcrumb-link {
        color: #202124;
        transition: all ease-in-out .4s; }
        .ant-breadcrumb.breadcrumb-warning .ant-breadcrumb-link a.ant-breadcrumb-link > .icon + span,
        .ant-breadcrumb.breadcrumb-warning .ant-breadcrumb-link a.ant-breadcrumb-link > .anticon + span {
          margin-left: 5px;
          display: inline-block; }
        .ant-breadcrumb.breadcrumb-warning .ant-breadcrumb-link a.ant-breadcrumb-link:hover {
          color: #fbbc05; }
  .ant-breadcrumb.breadcrumb-success .ant-breadcrumb-link {
    font-weight: 700;
    color: #34a853; }
    .ant-breadcrumb.breadcrumb-success .ant-breadcrumb-link > .icon + span,
    .ant-breadcrumb.breadcrumb-success .ant-breadcrumb-link > .anticon + span {
      transition: all ease-in-out .4s;
      margin-left: 5px;
      display: inline-block; }
    .ant-breadcrumb.breadcrumb-success .ant-breadcrumb-link a {
      color: #202124; }
      .ant-breadcrumb.breadcrumb-success .ant-breadcrumb-link a > .icon,
      .ant-breadcrumb.breadcrumb-success .ant-breadcrumb-link a > .anticon {
        transition: all ease-in-out .4s; }
        .ant-breadcrumb.breadcrumb-success .ant-breadcrumb-link a > .icon + span,
        .ant-breadcrumb.breadcrumb-success .ant-breadcrumb-link a > .anticon + span {
          margin-left: 5px;
          display: inline-block; }
      .ant-breadcrumb.breadcrumb-success .ant-breadcrumb-link a:hover {
        color: #34a853; }
      .ant-breadcrumb.breadcrumb-success .ant-breadcrumb-link a.ant-breadcrumb-link {
        color: #202124;
        transition: all ease-in-out .4s; }
        .ant-breadcrumb.breadcrumb-success .ant-breadcrumb-link a.ant-breadcrumb-link > .icon + span,
        .ant-breadcrumb.breadcrumb-success .ant-breadcrumb-link a.ant-breadcrumb-link > .anticon + span {
          margin-left: 5px;
          display: inline-block; }
        .ant-breadcrumb.breadcrumb-success .ant-breadcrumb-link a.ant-breadcrumb-link:hover {
          color: #34a853; }

/** /.Breadcrumb **/
/** Graph Detail Holder **/
.graph-detail-holder {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  position: relative; }
  @media screen and (max-width: 480px) {
    .graph-detail-holder {
      width: 100%;
      padding-bottom: 0 !important; } }
  .graph-detail-holder .refresh-icon {
    position: absolute;
    top: 5px;
    right: 0px;
    cursor: pointer; }
  .graph-detail-holder .graph-detail {
    line-height: 1;
    position: relative;
    padding-left: 20px; }
    .graph-detail-holder .graph-detail:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border: 2px solid #202124; }
    .graph-detail-holder .graph-detail .graph-detail-title {
      font-size: 14px;
      color: #202124;
      margin-bottom: 10px; }
      @media screen and (max-width: 1440px) {
        .graph-detail-holder .graph-detail .graph-detail-title {
          font-size: 13px; } }
    .graph-detail-holder .graph-detail .graph-detail-ratio {
      font-size: 16px;
      font-weight: 800;
      color: #202124;
      display: block; }
      @media screen and (max-width: 1440px) {
        .graph-detail-holder .graph-detail .graph-detail-ratio {
          font-size: 15px; } }
      @media screen and (max-width: 480px) {
        .graph-detail-holder .graph-detail .graph-detail-ratio {
          font-size: 14px; } }
    @media screen and (max-width: 1440px) {
      .graph-detail-holder .graph-detail {
        padding-left: 10px; } }
    .graph-detail-holder .graph-detail.danger:after {
      border-color: #ea4335; }
    .graph-detail-holder .graph-detail.danger .graph-detail-title {
      color: #ea4335; }
    .graph-detail-holder .graph-detail.primary:after {
      border-color: #b87e26; }
    .graph-detail-holder .graph-detail.primary .graph-detail-title {
      color: #DBA44F; }
    .graph-detail-holder .graph-detail.success:after {
      border-color: #34a853; }
    .graph-detail-holder .graph-detail.success .graph-detail-title {
      color: #34a853; }
    .graph-detail-holder .graph-detail.warning:after {
      border-color: #fbbc05; }
    .graph-detail-holder .graph-detail.warning .graph-detail-title {
      color: #fbbc05; }
    @media screen and (max-width: 480px) {
      .graph-detail-holder .graph-detail {
        margin-bottom: 20px; } }

/** /.Graph Detail Holder **/
/** Picker **/
.ant-picker .ant-picker-input {
  height: 32px; }
  .ant-picker .ant-picker-input input::-webkit-input-placeholder {
    color: rgba(150, 165, 171, 0.5); }
  .ant-picker .ant-picker-input input:-moz-placeholder {
    color: rgba(150, 165, 171, 0.5); }
  .ant-picker .ant-picker-input input::-moz-placeholder {
    color: rgba(150, 165, 171, 0.5); }
  .ant-picker .ant-picker-input input:-ms-input-placeholder {
    color: rgba(150, 165, 171, 0.5); }

.ant-picker .ant-picker-range-separator .anticon,
.ant-picker .ant-picker-suffix .anticon {
  color: rgba(150, 165, 171, 0.5); }

.ant-picker.picker-w-bg {
  background: #f5f7f7 !important; }
  .ant-picker.picker-w-bg .ant-picker-input input::-webkit-input-placeholder {
    color: #202124; }
  .ant-picker.picker-w-bg .ant-picker-input input:-moz-placeholder {
    color: #202124; }
  .ant-picker.picker-w-bg .ant-picker-input input::-moz-placeholder {
    color: #202124; }
  .ant-picker.picker-w-bg .ant-picker-input input:-ms-input-placeholder {
    color: #202124; }
  .ant-picker.picker-w-bg .ant-picker-input:first-child {
    margin-left: 30px; }
  .ant-picker.picker-w-bg .ant-picker-active-bar {
    margin-left: 41px; }
  .ant-picker.picker-w-bg .ant-picker-suffix {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto 0 0;
    width: 40px;
    height: 32px;
    line-height: 32px;
    text-align: center; }
    .ant-picker.picker-w-bg .ant-picker-suffix > * {
      vertical-align: middle; }

.ant-picker.ant-picker-large .ant-picker-input {
  height: 38px; }

/** Picker **/
/** Beggin Tabs **/
.ant-tabs {
  font-size: 14px;
  color: #DBA44F; }
  .ant-tabs .ant-tabs-extra-content {
    line-height: 20px; }
  .ant-tabs .ant-tabs-bar {
    margin: 0 0 30px 0;
    border-color: #e3e8ea; }
  .ant-tabs .ant-tabs-nav {
    margin: 0 0 30px 0;
    border-color: #e3e8ea; }
    .ant-tabs .ant-tabs-nav .ant-tabs-tab {
      font-size: 16px;
      font-weight: 700;
      padding: 5px 0 20px;
      margin: 0 15px 0 0;
      color: #96a5ab; }
      .ant-tabs .ant-tabs-nav .ant-tabs-tab .icon {
        margin-right: 6px; }
      .ant-tabs .ant-tabs-nav .ant-tabs-tab .ant-tag {
        font-size: 13px;
        line-height: 22px;
        border-color: #e3e8ea;
        background: #f5f7f7;
        color: #DBA44F;
        margin-right: 0;
        margin-left: 8px; }
      .ant-tabs .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active, .ant-tabs .ant-tabs-nav .ant-tabs-tab:hover {
        color: #DBA44F !important; }
        .ant-tabs .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .icon, .ant-tabs .ant-tabs-nav .ant-tabs-tab:hover .icon {
          color: #DBA44F; }
        .ant-tabs .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tag, .ant-tabs .ant-tabs-nav .ant-tabs-tab:hover .ant-tag {
          background: #DBA44F;
          color: white;
          border-color: #b87e26; }
    .ant-tabs .ant-tabs-nav .ant-tabs-ink-bar {
      background-color: #DBA44F; }
  .ant-tabs.tabs-danger .ant-tabs-nav .ant-tabs-tab .ant-tag {
    color: #ea4335; }
  .ant-tabs.tabs-danger .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active, .ant-tabs.tabs-danger .ant-tabs-nav .ant-tabs-tab:hover {
    color: #ea4335 !important;
    border-color: #d62516 !important; }
    .ant-tabs.tabs-danger .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .icon, .ant-tabs.tabs-danger .ant-tabs-nav .ant-tabs-tab:hover .icon {
      color: #ea4335; }
    .ant-tabs.tabs-danger .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tag, .ant-tabs.tabs-danger .ant-tabs-nav .ant-tabs-tab:hover .ant-tag {
      background: #ea4335;
      color: white;
      border-color: #d62516; }
  .ant-tabs.tabs-danger .ant-tabs-nav .ant-tabs-ink-bar {
    background-color: #ea4335;
    border-color: #d62516; }
  .ant-tabs.tabs-success .ant-tabs-nav .ant-tabs-tab .ant-tag {
    color: #34a853; }
  .ant-tabs.tabs-success .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active, .ant-tabs.tabs-success .ant-tabs-nav .ant-tabs-tab:hover {
    color: #34a853 !important;
    border-color: #288140 !important; }
    .ant-tabs.tabs-success .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .icon, .ant-tabs.tabs-success .ant-tabs-nav .ant-tabs-tab:hover .icon {
      color: #34a853; }
    .ant-tabs.tabs-success .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tag, .ant-tabs.tabs-success .ant-tabs-nav .ant-tabs-tab:hover .ant-tag {
      background: #34a853;
      color: white;
      border-color: #288140; }
  .ant-tabs.tabs-success .ant-tabs-nav .ant-tabs-ink-bar {
    background-color: #34a853;
    border-color: #288140; }
  .ant-tabs.tabs-warning .ant-tabs-nav .ant-tabs-tab .ant-tag {
    color: #fbbc05; }
  .ant-tabs.tabs-warning .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active, .ant-tabs.tabs-warning .ant-tabs-nav .ant-tabs-tab:hover {
    color: #fbbc05 !important;
    border-color: #ca9703 !important; }
    .ant-tabs.tabs-warning .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .icon, .ant-tabs.tabs-warning .ant-tabs-nav .ant-tabs-tab:hover .icon {
      color: #fbbc05; }
    .ant-tabs.tabs-warning .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tag, .ant-tabs.tabs-warning .ant-tabs-nav .ant-tabs-tab:hover .ant-tag {
      background: #fbbc05;
      color: white;
      border-color: #ca9703; }
  .ant-tabs.tabs-warning .ant-tabs-nav .ant-tabs-ink-bar {
    background-color: #fbbc05;
    border-color: #ca9703; }
  .ant-tabs.ant-tabs-small {
    padding: 20px 0; }
  .ant-tabs.ant-tabs-large {
    padding: 12px 0; }
  .ant-tabs.tabs-alternative .ant-tabs-bar {
    padding: 20px 20px 0; }
    @media (max-width: 480px) {
      .ant-tabs.tabs-alternative .ant-tabs-bar {
        padding: 20px 0 0; } }
  .ant-tabs.tabs-alternative .ant-tabs-nav {
    padding: 20px 20px 0; }
    @media (max-width: 480px) {
      .ant-tabs.tabs-alternative .ant-tabs-nav {
        padding: 20px 0 0; } }
    .ant-tabs.tabs-alternative .ant-tabs-nav .ant-tabs-tab {
      font-size: 16px;
      color: #202124;
      font-weight: 700; }
  .ant-tabs.tabs-alternative .ant-tabs-content .ant-tabs-tabpane {
    padding: 0 20px 20px; }
  @media screen and (max-width: 480px) {
    .ant-tabs.tabs-alternative .ant-tabs-content .main-title-h {
      flex-flow: row wrap; }
      .ant-tabs.tabs-alternative .ant-tabs-content .main-title-h > * {
        flex: 1 1 100%; } }
  @media screen and (max-width: 480px) and (max-width: 480px) {
    .ant-tabs.tabs-alternative .ant-tabs-content .main-title-h .title {
      margin-bottom: 30px; } }
  @media screen and (max-width: 480px) and (max-width: 480px) {
    .ant-tabs.tabs-alternative .ant-tabs-content .main-title-h .text-right {
      text-align: center !important; } }
  @media screen and (max-width: 480px) {
      .ant-tabs.tabs-alternative .ant-tabs-content .main-title-h .ant-btn {
        padding: 0 11px; } }
  .ant-tabs.tabs-alternative .ant-tabs-content .title {
    font-size: 18px;
    font-weight: 800 !important;
    margin-bottom: 0; }
    @media screen and (max-width: 480px) {
      .ant-tabs.tabs-alternative .ant-tabs-content .title {
        width: 100%;
        margin-bottom: 15px; } }
  .ant-tabs.tabs-alternative .ant-tabs-content .subtitle {
    font-size: 16px;
    font-weight: 800 !important;
    color: #202124;
    display: block;
    margin-bottom: 10px; }
  .ant-tabs.tabs-alternative .ant-tabs-content .content {
    display: block;
    color: #96a5ab; }
  .ant-tabs.tabs-w-bg {
    overflow: visible; }
    .ant-tabs.tabs-w-bg .ant-tabs-nav {
      margin: -30px 20px 0;
      border: 1px solid #e3e8ea;
      border-radius: 4px;
      background: white; }
    .ant-tabs.tabs-w-bg .ant-tabs-nav-wrap {
      display: flex;
      justify-content: center; }
    .ant-tabs.tabs-w-bg .ant-tabs-nav .ant-tabs-tab {
      padding: 15px;
      margin: 0;
      color: #96a5ab; }
      .ant-tabs.tabs-w-bg .ant-tabs-nav .ant-tabs-tab .ant-tag {
        color: #96a5ab; }
      .ant-tabs.tabs-w-bg .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
        color: #DBA44F !important; }
        .ant-tabs.tabs-w-bg .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tag {
          background: #DBA44F;
          color: white;
          border-color: #b87e26; }
    .ant-tabs.tabs-w-bg .ant-tabs-nav .ant-tabs-ink-bar {
      border-radius: 4px; }
  .ant-tabs.ant-tabs-bottom .ant-tabs-nav .ant-tabs-tab {
    padding: 20px 0; }
  .ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab {
    height: 42px;
    line-height: 42px;
    margin-right: 5px !important;
    padding: 10px 12px; }
    .ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab .icon {
      margin-right: 10px !important; }
    .ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active, .ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab:hover {
      border-color: #cd8d2a !important;
      color: white !important;
      background: #DBA44F; }
      .ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .icon, .ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab:hover .icon {
        color: white; }
      .ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, .ant-tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-tab:hover .ant-tabs-tab-btn {
        color: white; }
  .ant-tabs.ant-tabs-card .ant-tabs-tab-active {
    border-bottom-color: #cd8d2a !important; }
  .ant-tabs.ant-tabs-card.tabs-danger .ant-tabs-nav .ant-tabs-tab {
    height: 42px;
    line-height: 42px;
    margin-right: 5px !important;
    padding: 10px 12px; }
    .ant-tabs.ant-tabs-card.tabs-danger .ant-tabs-nav .ant-tabs-tab .icon {
      margin-right: 10px !important; }
    .ant-tabs.ant-tabs-card.tabs-danger .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active, .ant-tabs.ant-tabs-card.tabs-danger .ant-tabs-nav .ant-tabs-tab:hover {
      border-color: #d62516 !important;
      color: white !important;
      background: #ea4335; }
      .ant-tabs.ant-tabs-card.tabs-danger .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .icon, .ant-tabs.ant-tabs-card.tabs-danger .ant-tabs-nav .ant-tabs-tab:hover .icon {
        color: white; }
      .ant-tabs.ant-tabs-card.tabs-danger .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, .ant-tabs.ant-tabs-card.tabs-danger .ant-tabs-nav .ant-tabs-tab:hover .ant-tabs-tab-btn {
        color: white; }
  .ant-tabs.ant-tabs-card.tabs-danger .ant-tabs-tab-active {
    border-bottom-color: #d62516 !important; }
  .ant-tabs.ant-tabs-card.tabs-success .ant-tabs-nav .ant-tabs-tab {
    height: 42px;
    line-height: 42px;
    margin-right: 5px !important;
    padding: 10px 12px; }
    .ant-tabs.ant-tabs-card.tabs-success .ant-tabs-nav .ant-tabs-tab .icon {
      margin-right: 10px !important; }
    .ant-tabs.ant-tabs-card.tabs-success .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active, .ant-tabs.ant-tabs-card.tabs-success .ant-tabs-nav .ant-tabs-tab:hover {
      border-color: #288140 !important;
      color: white !important;
      background: #34a853; }
      .ant-tabs.ant-tabs-card.tabs-success .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .icon, .ant-tabs.ant-tabs-card.tabs-success .ant-tabs-nav .ant-tabs-tab:hover .icon {
        color: white; }
      .ant-tabs.ant-tabs-card.tabs-success .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, .ant-tabs.ant-tabs-card.tabs-success .ant-tabs-nav .ant-tabs-tab:hover .ant-tabs-tab-btn {
        color: white; }
  .ant-tabs.ant-tabs-card.tabs-success .ant-tabs-tab-active {
    border-bottom-color: #288140 !important; }
  .ant-tabs.ant-tabs-card.tabs-warning .ant-tabs-nav .ant-tabs-tab {
    height: 42px;
    line-height: 42px;
    margin-right: 5px !important;
    padding: 10px 12px; }
    .ant-tabs.ant-tabs-card.tabs-warning .ant-tabs-nav .ant-tabs-tab .icon {
      margin-right: 10px !important; }
    .ant-tabs.ant-tabs-card.tabs-warning .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active, .ant-tabs.ant-tabs-card.tabs-warning .ant-tabs-nav .ant-tabs-tab:hover {
      border-color: #ca9703 !important;
      color: white !important;
      background: #fbbc05; }
      .ant-tabs.ant-tabs-card.tabs-warning .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .icon, .ant-tabs.ant-tabs-card.tabs-warning .ant-tabs-nav .ant-tabs-tab:hover .icon {
        color: white; }
      .ant-tabs.ant-tabs-card.tabs-warning .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, .ant-tabs.ant-tabs-card.tabs-warning .ant-tabs-nav .ant-tabs-tab:hover .ant-tabs-tab-btn {
        color: white; }
  .ant-tabs.ant-tabs-card.tabs-warning .ant-tabs-tab-active {
    border-bottom-color: #ca9703 !important; }

/** End Tabs **/
/** Beggin Tabs DropDown **/
.ant-tabs-dropdown {
  z-index: 9999999 !important; }

/** Badge **/
.ant-badge-status.badge-status-bottom .ant-badge-dot {
  width: 10px;
  height: 10px;
  top: 80%;
  transform: translate(10%, -50%); }

.ant-badge-status.badge-status-bottom .ant-badge-status-success {
  background: #48c76a; }

.ant-badge-status.lg .ant-badge-dot {
  width: 20px;
  height: 20px; }

/** Badge **/
/** Transfer **/
@media (max-width: 560px) {
  .ant-transfer .ant-transfer-list {
    margin-bottom: 10px; } }

.ant-transfer .ant-transfer-list-search-action {
  height: 32px;
  top: 16px;
  right: 16px; }

@media (max-width: 700px) {
  .ant-transfer .ant-table-wrapper {
    min-width: 100%; } }

/** Tag **/
.ant-tag.danger {
  background: #f0776c;
  color: white;
  border-color: #bf2114; }

.ant-tag.primary {
  background: #e5be82;
  color: white;
  border-color: #b87e26; }

.ant-tag.success {
  background: #50c971;
  color: white;
  border-color: #226e36; }

.ant-tag.warning {
  background: #fccc41;
  color: white;
  border-color: #b18403; }

.ant-tag.secondary {
  background: #0affff;
  color: white;
  border-color: teal; }

/** Tag **/
/** Popover **/
.ant-popover {
  z-index: 9999999 !important; }

.ant-popover-inner .ant-popover-message > .anticon,
.ant-popover-inner .ant-popover-message > .icon {
  top: 7px; }

.ant-popover-inner .ant-popover-title {
  font-size: 15px;
  padding: 8px 20px;
  color: #202124; }

.ant-popover-inner .ant-popover-inner-content {
  padding: 8px 20px; }

.ant-popover-inner .moby__extra-actions {
  display: none;
  max-width: 200px; }
  .ant-popover-inner .moby__extra-actions .graph-detail-btn {
    width: 100%;
    border: 0;
    padding: 0;
    background: transparent;
    color: #96a5ab;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 10px;
    transition: all ease-in-out 0.4s;
    box-shadow: none;
    line-height: 20px; }
    .ant-popover-inner .moby__extra-actions .graph-detail-btn .icon svg {
      width: 12px !important;
      height: 12px !important; }
      .ant-popover-inner .moby__extra-actions .graph-detail-btn .icon svg .st0,
      .ant-popover-inner .moby__extra-actions .graph-detail-btn .icon svg .st1 {
        transition: all ease-in-out 0.4s;
        fill: #96a5ab !important; }
    .ant-popover-inner .moby__extra-actions .graph-detail-btn span {
      margin-left: 5px; }
    .ant-popover-inner .moby__extra-actions .graph-detail-btn:hover {
      color: #202124; }
      .ant-popover-inner .moby__extra-actions .graph-detail-btn:hover .icon svg .st0,
      .ant-popover-inner .moby__extra-actions .graph-detail-btn:hover .icon svg .st1 {
        fill: #202124 !important; }
  .ant-popover-inner .moby__extra-actions .ant-select {
    width: 100%;
    font-size: 13px; }
    .ant-popover-inner .moby__extra-actions .ant-select .ant-select-selection__rendered {
      margin-left: 8px; }

@media screen and (max-width: 480px) {
  .ant-popover-inner .moby__extra-actions {
    display: block; } }

/** /.Popover **/
/** Message **/
.ant-message {
  z-index: 9999999 !important; }

/** Message Ends **/
/** Radar Chart **/
.radar-chart .area {
  fill-opacity: 0.7; }

.radar-chart.focus .area {
  fill-opacity: 0.3; }

.radar-chart.focus .area.focused {
  fill-opacity: 0.9; }

.area.germany,
.germany .circle {
  fill: #fbbc05;
  stroke: none; }

.area.argentina,
.argentina .circle {
  fill: #DBA44F;
  stroke: none; }

/** /.Radar Chart **/
/** Group List **/
.group-list .ant-list-item {
  position: relative;
  transition: all ease-in-out 0.4s;
  overflow: hidden;
  padding: 20px 10px; }
  .group-list .ant-list-item:hover {
    background: #f9fafa; }
    .group-list .ant-list-item:hover .list-menu {
      opacity: 1;
      visibility: visible; }

.group-list .ant-list-item-meta {
  cursor: pointer; }

.group-list .ant-list-item-meta-avatar {
  margin-right: 10px; }
  .group-list .ant-list-item-meta-avatar .ant-avatar {
    width: 50px;
    height: 50px;
    line-height: 50px; }

@media (max-width: 991px) {
  .group-list .ant-list-item-meta-content {
    margin-right: 60px; } }

.group-list .ant-list-item-meta-title {
  font-size: 15px;
  line-height: 25px;
  color: #202124;
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 135px; }
  @media (max-width: 991px) {
    .group-list .ant-list-item-meta-title {
      max-width: 100%; } }
  @media (max-width: 570px) {
    .group-list .ant-list-item-meta-title {
      max-width: 135px; } }

.group-list .ant-list-item-meta-description {
  font-size: 14px;
  line-height: 25px;
  color: #96a5ab;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 135px; }
  @media (max-width: 991px) {
    .group-list .ant-list-item-meta-description {
      max-width: 100%; } }
  @media (max-width: 570px) {
    .group-list .ant-list-item-meta-description {
      max-width: 135px; } }

.group-list .list-menu {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  max-width: 60px;
  margin: 0 10px 0 auto !important;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  visibility: hidden; }
  .group-list .list-menu li {
    display: inline-block;
    width: 50%;
    text-align: right; }
    .group-list .list-menu li .icon {
      cursor: pointer;
      color: #96a5ab !important;
      transition: all ease-in-out 0.4s; }
      .group-list .list-menu li .icon:hover {
        color: #202124 !important; }

.group-list.group-list-alternative .list-menu {
  top: 20px;
  visibility: visible;
  opacity: 1;
  font-size: 12px;
  color: rgba(150, 165, 171, 0.7);
  line-height: 25px; }
  .group-list.group-list-alternative .list-menu li {
    display: block;
    width: 100%; }
    .group-list.group-list-alternative .list-menu li .icon {
      font-size: 16px;
      color: rgba(150, 165, 171, 0.7) !important; }
      .group-list.group-list-alternative .list-menu li .icon:hover {
        color: #202124 !important; }
  @media screen and (max-width: 1440px) {
    .group-list.group-list-alternative .list-menu {
      font-size: 12px; } }
  .group-list.group-list-alternative .list-menu.list-menu-alternative {
    font-size: 13px;
    color: #96a5ab;
    line-height: 25px;
    max-width: 100px; }
    @media (max-width: 1370px) {
      .group-list.group-list-alternative .list-menu.list-menu-alternative {
        line-height: 15px; } }
    @media screen and (max-width: 991px) {
      .group-list.group-list-alternative .list-menu.list-menu-alternative {
        font-size: 11px; } }

.group-list.mail-group-list {
  height: 100vh; }
  .group-list.mail-group-list .ant-list-item {
    flex-flow: wrap;
    cursor: pointer; }
    .group-list.mail-group-list .ant-list-item:hover .mail-message {
      color: #202124; }
  .group-list.mail-group-list .mail-message {
    transition: all ease-in-out 0.4s;
    width: 100%;
    color: #96a5ab;
    margin-bottom: 0;
    padding: 5px 0 0 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    min-height: 40px;
    /* fallback */
    -webkit-line-clamp: 2;
    /* number of lines to show */
    -webkit-box-orient: vertical; }
    @media screen and (max-width: 1440px) {
      .group-list.mail-group-list .mail-message {
        font-size: 13px; } }

@media (max-width: 1200px) {
  .group-list.contact-list .ant-list-item-meta-title {
    max-width: 95px; } }

@media (max-width: 991px) {
  .group-list.contact-list .ant-list-item-meta-title {
    max-width: 135px; } }

.group-list.contact-list .list-menu {
  top: 22px; }
  @media (max-width: 1370px) {
    .group-list.contact-list .list-menu {
      max-width: 50px; } }

@media (max-width: 1023px) {
  .group-list.contact-list {
    margin-bottom: 30px; } }

/** /.Ends Group List **/
/** List **/
.ant-list .ant-list-item-action {
  margin-top: 20px !important; }
  .ant-list .ant-list-item-action .anticon {
    font-size: 14px; }

@media (max-width: 560px) {
  .ant-list.ant-list-vertical.ant-list-lg .ant-list-item {
    padding: 15px 0; } }

/** /.List **/
/** Order List **/
.order-list {
  margin: 0 0 0 20px;
  padding: 0; }
  .order-list li {
    font-size: 14px;
    color: #96a5ab;
    padding-bottom: 10px; }

/** Order List **/
/** Alert **/
.ant-alert .anticon {
  font-size: 14px; }

.ant-alert .ant-alert-icon {
  top: 10.5px !important; }

.ant-alert .ant-alert-close-icon {
  top: 12.5px !important; }

.ant-alert.ant-alert-with-description .ant-alert-icon {
  top: 19px !important; }

.ant-alert.alert-filled {
  border-color: #cd8d2a;
  background: #DBA44F;
  color: white; }
  .ant-alert.alert-filled .ant-alert-message,
  .ant-alert.alert-filled .ant-alert-description,
  .ant-alert.alert-filled .ant-alert-close-icon,
  .ant-alert.alert-filled .ant-alert-close-text,
  .ant-alert.alert-filled .anticon,
  .ant-alert.alert-filled .icon {
    color: white; }
  .ant-alert.alert-filled .ant-alert-close-icon .icon:hover,
  .ant-alert.alert-filled .ant-alert-close-icon .anticon:hover {
    color: rgba(32, 33, 36, 0.7); }
  .ant-alert.alert-filled.ant-alert-success {
    border-color: #288140;
    background: #34a853;
    color: white; }
    .ant-alert.alert-filled.ant-alert-success .ant-alert-message,
    .ant-alert.alert-filled.ant-alert-success .ant-alert-description,
    .ant-alert.alert-filled.ant-alert-success .ant-alert-close-icon,
    .ant-alert.alert-filled.ant-alert-success .ant-alert-close-text,
    .ant-alert.alert-filled.ant-alert-success .anticon,
    .ant-alert.alert-filled.ant-alert-success .icon {
      color: white; }
    .ant-alert.alert-filled.ant-alert-success .ant-alert-close-icon .icon:hover,
    .ant-alert.alert-filled.ant-alert-success .ant-alert-close-icon .anticon:hover {
      color: rgba(32, 33, 36, 0.7); }
  .ant-alert.alert-filled.ant-alert-error {
    border-color: #d62516;
    background: #ea4335;
    color: white; }
    .ant-alert.alert-filled.ant-alert-error .ant-alert-message,
    .ant-alert.alert-filled.ant-alert-error .ant-alert-description,
    .ant-alert.alert-filled.ant-alert-error .ant-alert-close-icon,
    .ant-alert.alert-filled.ant-alert-error .ant-alert-close-text,
    .ant-alert.alert-filled.ant-alert-error .anticon,
    .ant-alert.alert-filled.ant-alert-error .icon {
      color: white; }
    .ant-alert.alert-filled.ant-alert-error .ant-alert-close-icon .icon:hover,
    .ant-alert.alert-filled.ant-alert-error .ant-alert-close-icon .anticon:hover {
      color: rgba(32, 33, 36, 0.7); }
  .ant-alert.alert-filled.ant-alert-warning {
    border-color: #ca9703;
    background: #fbbc05;
    color: white; }
    .ant-alert.alert-filled.ant-alert-warning .ant-alert-message,
    .ant-alert.alert-filled.ant-alert-warning .ant-alert-description,
    .ant-alert.alert-filled.ant-alert-warning .ant-alert-close-icon,
    .ant-alert.alert-filled.ant-alert-warning .ant-alert-close-text,
    .ant-alert.alert-filled.ant-alert-warning .anticon,
    .ant-alert.alert-filled.ant-alert-warning .icon {
      color: white; }
    .ant-alert.alert-filled.ant-alert-warning .ant-alert-close-icon .icon:hover,
    .ant-alert.alert-filled.ant-alert-warning .ant-alert-close-icon .anticon:hover {
      color: rgba(32, 33, 36, 0.7); }

/** /.Alert **/
/** New Invoice **/
.new-invoice {
  position: relative; }
  @media (max-width: 991px) {
    .new-invoice .ant-row .ant-col {
      position: initial; } }
  @media (max-width: 991px) {
    .new-invoice .add-new {
      display: none; } }
  .new-invoice .invoice-list-btn {
    position: fixed;
    top: 110px;
    right: 20px;
    z-index: 9999;
    display: none; }
    @media (max-width: 991px) {
      .new-invoice .invoice-list-btn {
        display: block; } }
  .new-invoice .invoice-content-h .invoice-list-h {
    height: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; }
    @media screen and (max-width: 991px) {
      .new-invoice .invoice-content-h .invoice-list-h {
        position: absolute;
        width: 260px;
        top: 0;
        left: -200px;
        opacity: 0;
        visibility: hidden;
        background: #fff;
        z-index: 999;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05), 0 1px 20px 0 rgba(0, 0, 0, 0.05), 0px 1px 11px 0px rgba(0, 0, 0, 0.05); } }
    .new-invoice .invoice-content-h .invoice-list-h.invoice-list-holder-mobile-view {
      left: 0px;
      opacity: 1;
      visibility: visible; }
  @media (max-width: 1440px) {
    .new-invoice .invoice-content-h .group-list.group-list-alternative .list-menu.list-menu-alternative {
      line-height: 25px; } }
  .new-invoice .sub-header .welcome-text {
    margin-bottom: 0; }
    @media screen and (max-width: 1024px) {
      .new-invoice .sub-header .welcome-text {
        font-size: 16px; } }
  @media screen and (max-width: 480px) {
    .new-invoice .group-btn.text-right {
      text-align: center; } }
  .new-invoice .ant-card.list-card {
    min-height: 215px; }
    .new-invoice .ant-card.list-card .title {
      margin-bottom: 10px;
      font-size: 17px; }
      @media screen and (max-width: 1024px) {
        .new-invoice .ant-card.list-card .title {
          font-size: 15px; } }
    .new-invoice .ant-card.list-card .subtitle {
      display: block;
      font-size: 14px;
      padding-top: 5px; }
      @media screen and (max-width: 1024px) {
        .new-invoice .ant-card.list-card .subtitle {
          font-size: 13px; } }
    .new-invoice .ant-card.list-card .ant-input-number-input {
      padding-left: 12px; }
    .new-invoice .ant-card.list-card .card-list {
      color: #96a5ab;
      font-size: 15px; }
      .new-invoice .ant-card.list-card .card-list a {
        color: #96a5ab; }
        .new-invoice .ant-card.list-card .card-list a:hover {
          color: #DBA44F; }
      .new-invoice .ant-card.list-card .card-list .list-title,
      .new-invoice .ant-card.list-card .card-list .list-subtitle {
        width: 48%;
        display: inline-block; }
      .new-invoice .ant-card.list-card .card-list .ant-select {
        width: 100%; }
      .new-invoice .ant-card.list-card .card-list .ant-input-number {
        width: 100%; }
      .new-invoice .ant-card.list-card .card-list .list-title {
        color: #202124; }
      .new-invoice .ant-card.list-card .card-list .list-title-l-h {
        line-height: 32px; }
      .new-invoice .ant-card.list-card .card-list .list-subtitle {
        text-align: right; }
      @media screen and (max-width: 1024px) {
        .new-invoice .ant-card.list-card .card-list {
          font-size: 13px; } }
      .new-invoice .ant-card.list-card .card-list li {
        padding-bottom: 10px; }
        .new-invoice .ant-card.list-card .card-list li:last-child {
          padding-bottom: 0; }
        @media screen and (max-width: 1024px) {
          .new-invoice .ant-card.list-card .card-list li {
            padding-bottom: 5px; } }
    @media screen and (max-width: 1024px) {
      .new-invoice .ant-card.list-card {
        min-height: 210px; } }
    .new-invoice .ant-card.list-card.list-card-white-bg {
      background: white;
      border-color: transparent; }
      .new-invoice .ant-card.list-card.list-card-white-bg .ant-card-body {
        padding: 0; }
    .new-invoice .ant-card.list-card.list-card-height-140 {
      min-height: 185px; }
      @media (max-width: 991px) {
        .new-invoice .ant-card.list-card.list-card-height-140 {
          min-height: 145px; } }
  .new-invoice .logo-img-h {
    margin: 0;
    cursor: pointer; }
    .new-invoice .logo-img-h img {
      width: 100%; }
    @media (max-width: 991px) {
      .new-invoice .logo-img-h {
        max-width: 50%;
        margin: 0 auto; } }
  .new-invoice .invoice-add-btn {
    background: rgba(219, 164, 79, 0.3);
    border-color: rgba(219, 164, 79, 0.5);
    color: #202124;
    border-radius: 0; }
    .new-invoice .invoice-add-btn .icon,
    .new-invoice .invoice-add-btn .anticon {
      color: #202124 !important; }
    @media screen and (max-width: 1024px) {
      .new-invoice .invoice-add-btn {
        font-size: 14px; } }
  .new-invoice .invoice-form .ant-form-item {
    margin-bottom: 10px; }
  @media screen and (max-width: 1024px) {
    .new-invoice .invoice-form .ant-form-item-label > label {
      font-size: 14px; } }
  .new-invoice .invoice-form .prize-input {
    width: 270px;
    padding-left: 25px; }
    .new-invoice .invoice-form .prize-input .ant-form-item-control .ant-form-item-children {
      width: 200px;
      display: inline-block; }
      .new-invoice .invoice-form .prize-input .ant-form-item-control .ant-form-item-children .ant-input {
        padding-left: 10px; }
    .new-invoice .invoice-form .prize-input .ant-form-item-control textarea.ant-input {
      resize: none; }
  .new-invoice .invoice-form .ant-checkbox-wrapper .ant-checkbox + span {
    color: #202124;
    font-weight: 700; }
    @media screen and (max-width: 1024px) {
      .new-invoice .invoice-form .ant-checkbox-wrapper .ant-checkbox + span {
        font-size: 14px; } }
  .new-invoice .invoice-form .ant-checkbox-wrapper input[type="checkbox"] {
    width: 20px;
    height: 20px; }
    @media screen and (max-width: 1024px) {
      .new-invoice .invoice-form .ant-checkbox-wrapper input[type="checkbox"] {
        width: 14px;
        height: 14px; } }
  .new-invoice .invoice-form .ant-checkbox-wrapper .ant-checkbox-inner {
    width: 22px;
    height: 22px; }
    .new-invoice .invoice-form .ant-checkbox-wrapper .ant-checkbox-inner:after {
      top: 45%;
      width: 6px;
      height: 11.143px; }
      @media screen and (max-width: 1024px) {
        .new-invoice .invoice-form .ant-checkbox-wrapper .ant-checkbox-inner:after {
          top: 50%;
          width: 5.71428571px;
          height: 9.14285714px; } }
    @media screen and (max-width: 1024px) {
      .new-invoice .invoice-form .ant-checkbox-wrapper .ant-checkbox-inner {
        width: 16px;
        height: 16px; } }
  @media (max-width: 991px) {
    .new-invoice .ant-form-vertical {
      margin-bottom: 30px; } }
  .new-invoice .note {
    display: block;
    font-size: 17px;
    color: #202124;
    padding-bottom: 10px; }
    @media screen and (max-width: 1024px) {
      .new-invoice .note {
        font-size: 15px; } }
  .new-invoice p {
    font-size: 15px;
    color: #96a5ab;
    margin-bottom: 0; }
    @media screen and (max-width: 1024px) {
      .new-invoice p {
        font-size: 14px; } }
    @media screen and (max-width: 1200px) {
      .new-invoice p {
        margin-bottom: 30px; } }
  .new-invoice .discount-holder {
    overflow: hidden; }
    .new-invoice .discount-holder .label {
      font-size: 16px;
      color: #202124;
      display: block;
      line-height: 30px; }
      @media screen and (max-width: 1440px) {
        .new-invoice .discount-holder .label {
          font-size: 14px; } }
    .new-invoice .discount-holder .amount {
      font-size: 16px;
      font-weight: 700;
      color: #202124;
      display: block;
      line-height: 30px; }
      @media screen and (max-width: 1440px) {
        .new-invoice .discount-holder .amount {
          font-size: 14px; } }
      @media screen and (max-width: 1200px) {
        .new-invoice .discount-holder .amount {
          width: 100%; } }
    @media screen and (max-width: 1200px) {
      .new-invoice .discount-holder .pd-10 {
        width: 100%; }
      .new-invoice .discount-holder .ant-select {
        width: 100%; } }
  .new-invoice .payment-list li {
    color: #202124;
    padding-bottom: 10px; }
    .new-invoice .payment-list li:last-child .title,
    .new-invoice .payment-list li:last-child .number {
      font-weight: 800; }
  .new-invoice .payment-list .title,
  .new-invoice .payment-list .number {
    display: inline-block;
    width: 48%; }
  .new-invoice .payment-list .title {
    font-weight: 700; }

/** Ends New Invoice **/
/** Invoice View **/
.invoice-view .subtitle {
  display: block;
  font-size: 16px;
  color: #202124;
  margin-bottom: 10px; }

.invoice-view .text {
  color: #96a5ab;
  margin-bottom: 30px; }

/** Ends Invoice View **/
/** Fine Uploader **/
.fine-uploader-dropzone-container,
.react-fine-uploader-gallery-dropzone {
  border-color: #00cccc; }

.react-fine-uploader-gallery-file-input-container {
  width: 125px;
  background: #00cccc;
  border-color: #009999; }

.react-fine-uploader-gallery-total-progress-bar-container {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 6px;
  width: 80%; }

.react-fine-uploader-gallery-progress-bar,
.react-fine-uploader-gallery-total-progress-bar {
  background: #00cccc; }

.react-fine-uploader-gallery-files {
  padding: 30px 0;
  -ms-overflow-style: none;
  scrollbar-width: none; }
  .react-fine-uploader-gallery-files::-webkit-scrollbar {
    display: none; }

/** Ends Fine Uploader **/
/** Basic Uploader **/
.ant-upload-list .ant-upload-list-item {
  height: 40px; }
  .ant-upload-list .ant-upload-list-item:hover .ant-upload-list-item-card-actions .anticon {
    color: white !important; }
  .ant-upload-list .ant-upload-list-item:hover .ant-upload-list-item-info .anticon-loading,
  .ant-upload-list .ant-upload-list-item:hover .ant-upload-list-item-info .anticon-paper-clip {
    color: white !important; }

.ant-upload-list .ant-upload-list-item-info {
  padding: 10px;
  border-radius: 4px; }

.ant-upload-list .ant-upload-list-item-info .anticon-loading,
.ant-upload-list .ant-upload-list-item-info .anticon-paper-clip {
  top: 13px; }

.ant-upload-list .ant-upload-list-item-card-actions .anticon {
  font-size: 16px; }

.ant-upload-list.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-card-actions .anticon {
  color: #DBA44F !important; }

.ant-upload-list.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info .anticon-loading,
.ant-upload-list.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info .anticon-paper-clip {
  color: #DBA44F !important; }

.ant-upload-list-item-actions .anticon {
  color: white !important; }

/** Ends Basic Uploader **/
/** Grid View **/
.grid-demo-basic.ant-card .ant-row > *.ant-col,
.grid-demo-basic.ant-card .ant-row-flex > *.ant-col {
  padding-top: 15px;
  padding-bottom: 15px;
  background: #DBA44F;
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center; }
  .grid-demo-basic.ant-card .ant-row > *.ant-col:nth-child(even),
  .grid-demo-basic.ant-card .ant-row-flex > *.ant-col:nth-child(even) {
    background: #cd8d2a; }

.grid-demo-basic.ant-card .height-100 {
  height: 100px;
  line-height: 100px; }

.grid-demo-basic.ant-card .height-50 {
  height: 50px;
  line-height: 50px; }

.grid-demo-basic.ant-card .height-120 {
  height: 120px;
  line-height: 120px; }

.grid-demo-basic.ant-card .height-80 {
  height: 80px;
  line-height: 80px; }

.grid-demo-gutter.ant-card .ant-row > *.ant-col .gutter-box {
  padding-top: 15px;
  padding-bottom: 15px;
  background: #cd8d2a;
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center; }

/** Ends Grid View **/
/** Layouts View **/
.layout-demo-basic.ant-card .ant-layout-header,
.layout-demo-basic.ant-card .ant-layout-footer {
  background: #DBA44F;
  color: white; }

.layout-demo-basic.ant-card .ant-layout-sider {
  background: #d7993a;
  color: white;
  line-height: 120px; }
  @media (max-width: 580px) {
    .layout-demo-basic.ant-card .ant-layout-sider {
      flex: 0 0 100px !important;
      max-width: 100px !important;
      min-width: 100px !important;
      width: 100px !important; } }

.layout-demo-basic.ant-card .ant-layout-content {
  background: #cd8d2a;
  color: white;
  height: 120px;
  line-height: 120px; }

/** Ends Layouts View **/
/** Ends masonry View **/
.grid {
  display: flex;
  margin-left: -10px;
  margin-right: -10px; }
  .grid .grid-item {
    width: 31.33%;
    margin: 10px !important; }
    @media screen and (max-width: 991px) {
      .grid .grid-item {
        margin: 10px 5px !important; } }
    @media (max-width: 767px) {
      .grid .grid-item {
        width: 100%;
        margin: 0 0 30px !important; } }
  @media screen and (max-width: 991px) {
    .grid {
      margin-left: -5px;
      margin-right: -5px; } }

/** Ends masonry View **/
/** Sliders **/
.slider-icon .icon-wrapper {
  position: relative;
  padding: 0px 30px; }
  .slider-icon .icon-wrapper .anticon {
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    line-height: 1;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.25); }
  .slider-icon .icon-wrapper .anticon:first-child {
    left: 0; }
  .slider-icon .icon-wrapper .anticon:last-child {
    right: 0; }

/** Sliders Ends **/
/** Circle Graph **/
.apexcharts-text {
  font-size: 14px !important; }

/** Circle Graph Ends **/
/** Input Groups Card **/
.input-groups-card .ant-select .ant-select-selection--single {
  height: 32px; }

.mutli-inputs .ant-input {
  width: 30% !important; }

@media (max-width: 480px) {
  .multi-items .ant-input-number {
    width: 60px !important; } }

.multi-items .ant-input {
  width: 50% !important; }
  @media (max-width: 480px) {
    .multi-items .ant-input {
      width: 30% !important; } }

.multi-items .ant-picker {
  width: 50% !important; }

.multi-items .ant-select-auto-complete {
  width: 200px; }
  @media (max-width: 480px) {
    .multi-items .ant-select-auto-complete {
      width: 130px; } }

.multi-items-picker .ant-input {
  width: 50% !important; }

.multi-items-picker .ant-picker {
  width: 50% !important; }

.multi-items-combo .ant-select {
  width: 30% !important; }

.multi-items-combo .ant-cascader-picker {
  width: 70% !important; }

/** Input Groups Card **/
/** Moby Tabs **/
.card-container > .ant-tabs-card > .ant-tabs-content {
  height: 120px;
  margin-top: -15px; }
  .card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane-active {
    background: #fff !important;
    padding: 15px !important; }

.card-container > .ant-tabs-card > .ant-tabs-nav {
  border-color: #fff !important; }
  .card-container > .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border-color: transparent !important;
    background: transparent !important; }
  .card-container > .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
    border-color: #fff !important;
    background: #fff !important; }

/** Ends Moby Tabs **/
/** Carousels **/
.ant-carousel .slick-slide {
  text-align: center !important;
  overflow: hidden !important; }

.ant-carousel h3 {
  color: #fff !important; }

/** Ends Carousels **/
/** CK Editor **/
.ck-editor__editable {
  min-height: 200px; }

/** CK Editor **/
/** Graph Holder **/
.graph-h {
  width: 100%; }

/** Graph Holder Ends **/
/** Leaf Map **/
.leaflet-container {
  width: 100%;
  height: 100%;
  overflow: hidden; }

/** Leaf Map **/
/** Widgets **/
.widget-page-sub-titile {
  font-size: 16px;
  margin-bottom: 20px; }

/** Widgets **/
/** Crypto Page **/
.crypto-multi-card {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05), 0 1px 20px 0 rgba(0, 0, 0, 0.05), 0px 1px 11px 0px rgba(0, 0, 0, 0.05); }

/** Crypto Page **/
/** Contact Page **/
.contacts {
  line-height: 24px !important; }
  @media (max-width: 567px) {
    .contacts.pd-30 {
      padding: 30px 20px; } }
  .contacts .contact-list-btn {
    position: fixed;
    top: 100px;
    right: 30px;
    z-index: 9999;
    display: none; }
    @media (max-width: 991px) {
      .contacts .contact-list-btn {
        display: block; } }
  .contacts .contact-content-h {
    overflow: hidden; }
    @media (max-width: 991px) {
      .contacts .contact-content-h {
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05), 0 1px 20px 0 rgba(0, 0, 0, 0.05), 0px 1px 11px 0px rgba(0, 0, 0, 0.05); } }
  .contacts .contact-list-h {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; }
    @media (max-width: 991px) {
      .contacts .contact-list-h {
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05), 0 1px 20px 0 rgba(0, 0, 0, 0.05), 0px 1px 11px 0px rgba(0, 0, 0, 0.05); } }
    @media (max-width: 991px) {
      .contacts .contact-list-h {
        border-radius: 4px 0 0 4px !important;
        position: absolute;
        width: 50%;
        top: 0;
        left: -200px;
        opacity: 0;
        visibility: hidden;
        padding: 20px;
        background: #fff;
        z-index: 999;
        height: 801px;
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05), 0 1px 20px 0 rgba(0, 0, 0, 0.05), 0px 1px 11px 0px rgba(0, 0, 0, 0.05); } }
    @media (max-width: 570px) {
      .contacts .contact-list-h {
        padding: 10px;
        width: 60%;
        height: 1243px; } }
    @media (max-width: 480px) {
      .contacts .contact-list-h {
        width: 70%;
        height: 1331px; } }
    @media (max-width: 400px) {
      .contacts .contact-list-h {
        height: 1379px; } }
    @media (max-width: 320px) {
      .contacts .contact-list-h {
        width: 93.2%;
        height: 1461px; } }
    .contacts .contact-list-h.contact-list-mobile-view {
      left: 10px;
      opacity: 1;
      visibility: visible; }
  .contacts .ant-input-search.search-w-btn .icon-search_outlined {
    color: rgba(150, 165, 171, 0.5) !important;
    font-size: 14px !important; }
  @media (max-width: 480px) {
    .contacts .ant-tabs.tabs-alternative .ant-tabs-nav {
      padding: 20px 10px 0 !important; } }
  @media (max-width: 480px) {
    .contacts .ant-tabs.tabs-alternative .ant-tabs-content .main-title-h.pd-b-30 {
      padding-bottom: 20px !important; } }
  @media (max-width: 480px) {
    .contacts .ant-tabs.tabs-alternative .ant-tabs-content .main-title-h .text-right .ant-btn {
      margin-bottom: 10px; } }
  .contacts .ant-tabs .group-list .ant-list-item-meta-title,
  .contacts .ant-tabs .group-list .ant-list-item-meta-description {
    min-width: 98%; }
  @media (max-width: 991px) {
    .contacts .ant-tabs .group-list {
      height: 640px; } }

.conatct-card-tab-h {
  margin-bottom: 0 !important; }
  @media (max-width: 991px) {
    .conatct-card-tab-h {
      box-shadow: none; } }

.right-drawer .ant-drawer-body {
  padding: 0;
  overflow: hidden; }
  .right-drawer .ant-drawer-body .main-content-aside-right {
    width: 100%;
    height: 100%; }
    @media screen and (max-width: 1023px) {
      .right-drawer .ant-drawer-body .main-content-aside-right {
        display: block; } }

/** Contact Page Ends **/
/** Conversation **/
.conversations {
  overflow: hidden; }
  @media (max-width: 991px) {
    .conversations {
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05), 0 1px 20px 0 rgba(0, 0, 0, 0.05), 0px 1px 11px 0px rgba(0, 0, 0, 0.05);
      margin: 20px;
      border: 1px solid #e3e8ea;
      border-radius: 4px; } }
  .conversations .conversation-list-btn {
    position: fixed;
    top: 185px;
    right: 40px;
    z-index: 9999;
    display: none; }
    @media (max-width: 991px) {
      .conversations .conversation-list-btn {
        display: block; } }

/** Conversation **/
/** Mail Box **/
.mail-boxes {
  position: relative; }
  @media (max-width: 767px) {
    .mail-boxes {
      overflow: hidden;
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05), 0 1px 20px 0 rgba(0, 0, 0, 0.05), 0px 1px 11px 0px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      border: 1px solid #e3e8ea;
      margin: 20px; } }
  .mail-boxes .mailbox-list-btn {
    position: fixed;
    top: 105px;
    right: 40px;
    z-index: 9999;
    display: none; }
    @media (max-width: 767px) {
      .mail-boxes .mailbox-list-btn {
        display: block; } }
  @media (max-width: 991px) {
    .mail-boxes .ant-row .ant-col {
      position: initial; } }

/** Mail Box **/
/** Start Code Block **/
.code-section-container {
  text-align: left;
  margin: 20px;
  margin-top: 0px; }
  .code-section-container .toggle-code-snippet {
    border: none;
    background-color: transparent !important;
    padding: 0px !important;
    box-shadow: none !important;
    color: #888ea8 !important;
    border-bottom: 1px dashed #bfc9d4;
    border-radius: 0;
    margin-bottom: 10px; }
  .code-section-container .code-section {
    display: none;
    height: auto;
    padding: 0; }
    .code-section-container .code-section pre {
      height: auto;
      padding: 22px;
      border-radius: 6px;
      margin-bottom: 0;
      font-size: 87.5%;
      margin-top: 0; }
  .code-section-container.show-code .code-section {
    display: block; }

/** End Code Block **/
