/**
 *
 * Theme: Moby
 * Author: Mobythemes
 * Website: mobythemes.com
 * Version: 1.0
 * Date: Thu Dec 19 2019
 * Category: Pages
 * Title: PricingPlan styles
 *
 */
/**********************************************
  Custom Variables
***********************************************/
/***********************
  Input Placeholder Color
***********************/
/***********************
  Media Queries
***********************/
/**********************************************
  END OF Custom Variables
***********************************************/
/**********************************************
  ANT DESIGN THEME
***********************************************/
.pricing-plan .plan-card.ant-card {
  text-align: center;
  border-radius: 15px;
  font-family: "Roboto", sans-serif !important;
  margin: 3px; }
  @media (max-width: 767px) {
    .pricing-plan .plan-card.ant-card {
      margin: 0; } }
  .pricing-plan .plan-card.ant-card .close-icon {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 20px;
    color: rgba(32, 33, 36, 0.7);
    cursor: pointer;
    display: none; }
  .pricing-plan .plan-card.ant-card .ant-card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 420px;
    padding: 50px 20px; }
    @media (min-width: 992px) {
      .pricing-plan .plan-card.ant-card .ant-card-body {
        padding: 50px 30px; } }
    @media (max-width: 767px) {
      .pricing-plan .plan-card.ant-card .ant-card-body {
        min-height: 100%; } }
    .pricing-plan .plan-card.ant-card .ant-card-body .card-body-h {
      flex: 1 1; }
  .pricing-plan .plan-card.ant-card .icon {
    font-size: 50px;
    color: #DBA44F;
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      .pricing-plan .plan-card.ant-card .icon {
        font-size: 56px; } }
  .pricing-plan .plan-card.ant-card .img-icon {
    height: 100px;
    margin-bottom: 10px; }
  .pricing-plan .plan-card.ant-card .card-title {
    font-size: 22px;
    line-height: 22px;
    color: rgba(32, 33, 36, 0.65);
    font-weight: 700;
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      .pricing-plan .plan-card.ant-card .card-title {
        font-size: 25px;
        line-height: 25px;
        margin-bottom: 30px; } }
  .pricing-plan .plan-card.ant-card p {
    font-size: 14px;
    line-height: 20px; }
    @media (min-width: 992px) {
      .pricing-plan .plan-card.ant-card p {
        font-size: 18px;
        line-height: 22px; } }
  .pricing-plan .plan-card.ant-card .ant-divider {
    margin: 30px auto;
    min-width: 60%;
    width: 60%; }
    @media (min-width: 992px) {
      .pricing-plan .plan-card.ant-card .ant-divider {
        margin: 40px auto 30px;
        min-width: 40%;
        width: 40%; } }
  .pricing-plan .plan-card.ant-card .prize {
    font-size: 30px;
    line-height: 30px;
    color: rgba(32, 33, 36, 0.65);
    font-weight: 400;
    margin-bottom: 10px; }
    @media (min-width: 992px) {
      .pricing-plan .plan-card.ant-card .prize {
        font-size: 35px;
        line-height: 35px; } }
  .pricing-plan .plan-card.ant-card .subtitle {
    display: block; }
  .pricing-plan .plan-card.ant-card .ant-btn {
    margin-top: 30px;
    border-radius: 30px; }
  .pricing-plan .plan-card.ant-card.required-card {
    transform: scale(1.1);
    -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
    z-index: 7; }
    .pricing-plan .plan-card.ant-card.required-card.no-scale {
      transform: unset; }
    @media (max-width: 1300px) {
      .pricing-plan .plan-card.ant-card.required-card {
        margin-top: -10px; } }
    @media (max-width: 992px) {
      .pricing-plan .plan-card.ant-card.required-card {
        margin-top: -8px; } }
    @media (max-width: 767px) {
      .pricing-plan .plan-card.ant-card.required-card {
        margin: 20px 0 50px !important;
        transform: scale(1.03); } }
    .pricing-plan .plan-card.ant-card.required-card .card-title {
      background-image: linear-gradient(45deg, #DBA44F, #DBA44F);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent; }
    .pricing-plan .plan-card.ant-card.required-card .prize {
      color: #DBA44F; }
    .pricing-plan .plan-card.ant-card.required-card .ant-btn.ant-btn-primary {
      background-image: linear-gradient(45deg, #DBA44F, #DBA44F);
      color: white;
      border-color: transparent !important; }
      .pricing-plan .plan-card.ant-card.required-card .ant-btn.ant-btn-primary:hover, .pricing-plan .plan-card.ant-card.required-card .ant-btn.ant-btn-primary:focus {
        opacity: 0.8;
        color: white !important;
        border-color: transparent !important; }
  .pricing-plan .plan-card.ant-card.closeable-card .close-icon {
    display: block; }
  .pricing-plan .plan-card.ant-card.ribbon-left .close-icon {
    left: 0;
    right: 0;
    margin: 0 20px 0 auto;
    width: 20px; }
  .pricing-plan .plan-card.ant-card.ant-card-hoverable:hover {
    -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15); }
