.iconButton {
  width: 1.5em;
  height: 1.5em;
}

.iconButton svg {
  width: 75%;
  height: 75%;
}

.iconButton:hover {
  cursor: pointer;
}
