.loginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 500px;
}

.loginLogoCard{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}

.loginLogoCard h1{
margin-bottom: 50px;
}

.loginMainCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 2px 23px 0 rgba(155, 155, 155, 0.5);
  justify-content: space-between;
  width: 520px;
  height: 450px;
  margin-bottom: 100px;
  border-radius: 0px;
}

.loginFormCard {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 10px 10px 10px;
}

.loginAccount{
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.loginInfo {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.loginRegisterText {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.loginOrText {
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.loginImageCard {
  height: 100%;
  width: 100%;
  max-width: 340px;
}

.loginImage {
  width: 100%;
  height: 450px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

/* For Responsive */
@media screen and (max-width: 800px) {
  .loginMainCard {
    margin: 10px;
    box-shadow: none;
    display: flex;
    flex-direction: column;
  }
  .loginFormCard {
    margin: 10px auto !important;
    width: 100% !important;
  }
  .loginImage {
    display: none;
  }
}
