/**
 *
 * Theme: Moby
 * Author: Mobythemes
 * Website: mobythemes.com
 * Version: 1.0
 * Date: Mon Nov 04 2019
 * Title: SideBarDrawer styles
 *
 */
/**********************************************
  Custom Variables
***********************************************/
/***********************
  Input Placeholder Color
***********************/
/***********************
  Media Queries
***********************/
/**********************************************
  END OF Custom Variables
***********************************************/
/**********************************************
  ANT DESIGN THEME
***********************************************/
/** Drawer **/
.ant-drawer-left .ant-drawer-body {
  padding: 0; }
  .ant-drawer-left .ant-drawer-body .side-bar-inner-menu > .ant-menu > .ant-menu-item-only-child {
    margin-bottom: 0; }

/** /.Drawer **/
