.siteCard {
  width: 300px;
  min-height: 100px;
  margin: 10px;
  border: 2px solid white; }

.siteCard:hover {
  cursor: pointer;
  border: 2px solid grey;
  background-color: white !important; }

.siteCardIcon {
  width: 12%; }
