.notFoundContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 500px;
}

.notFoundMainCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0 2px 23px 0 rgba(155, 155, 155, 0.5);
  justify-content: space-between;
  width: 800px;
  margin-top: 40px;
  height: 450px;
}

.notFoundTextCard {
  margin: 20px auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cowImage {
  width: 340px;
}
