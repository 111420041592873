.heading {
  color: #4d4d4d;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: -0.05px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 5px;
}
