
.updateClinicForm {
  width: 600px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 100px auto;
}

.updateClinicForm label {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


.sectionTitle {
  border-bottom: 1px solid #005991;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.clinicButtons{
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
}
