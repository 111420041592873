
.card-button-update {
  margin-right: 15px;
}

.updateCreditCardForm {
  width: 600px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 100px auto;
}

.updateCreditCardForm label {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
