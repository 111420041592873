.submenuContainer {
    padding-top: 45px;
    display: none;
    width: 0;
}

.submenuActive {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #f7f7f7;
    width: 200px;
    transition: width 1s;
}

.submenuLink:hover {
    cursor: pointer;
}

.submenuItem {
    font-size: .9em;
    padding: 7px 5px 7px 13px;
}

.submenuTitle {
    font-weight: bold;
    border-bottom:1px solid #dfdfdf;
    color:#005991;
}

.submenuLink:hover {
    background-color:#ffffff;

}

.submenuItem.indented {
    padding-left: 26px;
}

.submenuIcon {
    padding-top: 5px;
    margin-right: 3px;
    vertical-align: baseline;
}
