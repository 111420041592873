.newCreditCardForm {
  width: 600px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 100px auto; }

.newCreditCardForm label {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.ant-form .ant-form-item .ant-form-item-label > label,
.ant-legacy-form .ant-form-item .ant-form-item-label > label {
  font-weight: 600;
  font-size: 16px; }

.ant-form .ant-form-item .ant-form-item-label > label:after,
.ant-legacy-form .ant-form-item .ant-form-item-label > label:after {
  display: none; }

.ant-form .ant-form-item .ant-form-item-label > label:before,
.ant-legacy-form .ant-form-item .ant-form-item-label > label:before {
  position: absolute;
  top: 0;
  right: -12px;
  height: 100%;
  margin-right: 0;
  font-size: 10px; }

.slash {
  font-size: 24px;
  margin-top: 42px; }

.card-buttons .ant-form-item-control-input-content {
  display: flex;
  justify-content: space-between;
  padding-right: 15px; }

.ant-row.card-form-mycvv {
  height: 111px; }

@media (max-width: 768px) {
  .slash {
    display: none;
    margin-top: 0; }
  .card-buttons .ant-form-item-control-input-content {
    padding-right: 0; }
  .ant-row.card-form-mycvv {
    height: auto; } }

.creditCardDisplay {
  width: 260px !important;
  height: 130px;
  border: 2px solid lightsteelblue !important;
  background-color: #47a4f0 !important;
  border-radius: 10px;
  margin-top: 70px; }

.creditCardDisplay img {
  height: 30px; }

.creditCardDisplayText {
  text-shadow: black 1px 1px 1px; }

.credit-card-container {
  display: flex;
  height: 180px;
  width: 100%;
  flex-direction: row; }

.card {
  position: absolute !important;
  width: 280px;
  height: 160px;
  background: linear-gradient(25deg, #0f509e, #1399cd); }

.card.ant-card > .ant-card-body {
  max-height: 80px; }

.credit-card-number {
  text-align: center;
  font-size: 20px;
  padding-top: 40px; }

.credit-card-buttons {
  padding-top: 12px; }

.card-chip-image {
  height: 30px; }

.card-brand-image {
  height: 30px; }

.add-card-logo {
  font-size: 50px; }

.bg-visa {
  background: linear-gradient(25deg, #0f509e, #1399cd) !important;
  color: white !important; }

.bg-mastercard {
  background: linear-gradient(25deg, #fbfbfb, #e8e9e5) !important; }

.bg-dinersclub {
  background: linear-gradient(25deg, #fff, #eee) !important; }

.bg-blank {
  background: linear-gradient(25deg, #fbfbfb, #e8e9e5) !important; }

.bg-amex {
  background: linear-gradient(25deg, #308c67, #a3f2cf) !important;
  color: white !important; }

.bg-dankort {
  background: linear-gradient(25deg, #ccc, #999) !important; }

.bg-discover {
  background: linear-gradient(25deg, #fff, #eee) !important; }

.bg-elo {
  background: linear-gradient(25deg, #211c18, #aaa7a2) !important;
  color: white !important; }

.bg-hipercard {
  background: linear-gradient(25deg, #8b181b, #de1f27) !important;
  color: white !important; }

.credit-card-mask {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0 18px;
  align-items: center;
  width: 280px;
  height: 160px;
  background: rgba(255, 255, 255, 0);
  transition: 0.3s ease-in-out;
  position: absolute;
  z-index: 1; }
  .credit-card-mask .icon-edit_outlined::before,
  .credit-card-mask .icon-delete_outlined::before {
    font-size: 40px;
    color: rgba(0, 0, 0, 0);
    transition: 0.3s ease-in-out; }
  .credit-card-mask .label {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.1);
    transition: 0.3s ease-in-out; }
  .credit-card-mask:hover {
    background: rgba(255, 255, 255, 0.5); }
    .credit-card-mask:hover .icon-edit_outlined,
    .credit-card-mask:hover .icon-delete_outlined {
      display: block; }
      .credit-card-mask:hover .icon-edit_outlined:hover::before,
      .credit-card-mask:hover .icon-delete_outlined:hover::before {
        font-size: 50px;
        color: rgba(0, 0, 0, 0.7);
        transition: 0.3s ease-in-out; }
      .credit-card-mask:hover .icon-edit_outlined::before,
      .credit-card-mask:hover .icon-delete_outlined::before {
        font-size: 50px;
        color: rgba(0, 0, 0, 0.2);
        transition: 0.3s ease-in-out; }
    .credit-card-mask:hover .label {
      font-size: 30px;
      color: rgba(0, 0, 0, 0.1);
      transition: 0.3s ease-in-out; }
      .credit-card-mask:hover .label:hover {
        font-size: 40px;
        color: rgba(0, 0, 0, 0.4);
        transition: 0.3s ease-in-out; }
