.infoCard {
  width: 200px;
  height: 200px;
  margin: 10px;
  background-color: rgb(221, 221, 221) !important;
}

.infoCard:hover {
  cursor: pointer;
  border: 2px solid grey;
  background-color: white !important;
}

.infoCardBody {
  display: flex;
  flex-direction: column;
}

.infoCardIcon {
  align-self: center;
  margin-bottom: 10px;
}

.infoCardTitle {
  height: 1.5em;
  line-height: 1em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.infoCardDescription {
  height: 5em;
  line-height: 1.3em;
  text-overflow: ellipsis;
  overflow: hidden;
}
