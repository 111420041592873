.sidebarButton {
    padding: 15px 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: grey;
    font-size: 1.3em;
}

.sidebarButton:focus {
    outline: none;
}

.sidebarButton:hover {
    color: #000000;
    background-color: #FFFFFF;
}

.sidebarButton.active {
    color: #005991;
    background-color: #f7f7f7;
}
