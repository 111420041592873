.site-screen-table-container {
  margin: 10px 0; }
  .site-screen-table-container .ant-list-bordered .ant-list-header {
    background-color: #f9fafa;
    display: flex;
    justify-content: center; }

@media screen and (max-width: 800px) {
  .tableDiv {
    padding: 1em 0 0 0; } }
