.alertToast {
  position: fixed;
  top: 50px;
  right: 10px;
  min-width: 200px;
  max-width: 300px;
  z-index: 1000;
}

/* .primary {
  background-color: #cce5ff ;
}

.secondary {
  background-color: #e2e3e5 ;
}

.success {
  background-color: #d4edda 
}

.danger {
  background-color: #f8d7da ;
}

.warning {
  background-color: #fff3cd ;
}

.info {
  background-color: #d1ecf1 ;
}

.light {
  background-color: #fefefe ;
}

.dark {
  background-color: #d6d8d9 !important;
} */
