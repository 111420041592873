
.updateBillingEntityForm {
  width: 600px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 100px auto;
}

.updateBillingEntityForm label {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
