.confirmContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 500px;
}

.logoConfirmation{
  border-radius: 50%;
  width: 150px;
  height: 150px;
}

.confirmMainCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 2px 23px 0 rgba(155, 155, 155, 0.5);
  justify-content: space-between;
  width: 600px;
  height: 400px;
  border-radius: 20px;
  margin-bottom: 100px;
  margin-top: 20px;
}

.confirmFormCard {
  margin: 20px auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmInfo {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.confirmLoginText {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.confirmOrText {
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.confirmImage {
  height: 450px;
  width: 340px;
}

/* For Responsive */
@media screen and (max-width: 800px) {
  .confirmMainCard {
    margin: 10px;
    box-shadow: none;
    display: flex;
    flex-direction: column;
  }
  .confirmFormCard {
    margin: 10px auto !important;
    width: 100% !important;
  }
  .confirmImage {
    display: none;
  }
}
