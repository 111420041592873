.registerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 500px;
}

.registerMainCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 2px 23px 0 rgba(155, 155, 155, 0.5);
  justify-content: space-between;
  width: 800px;
  margin-top: 20px;
  margin-bottom: 100px;
  border-radius: 20px;
  min-height: 650px;
}

.registerFormCard {
  margin: 20px auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.registerAccount{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.registerInfo {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.registerLoginText {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.registerOrText {
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.registerImage {
  height: 450px;
  width: 340px;
}

.password-hint {
  position: absolute;
  top: 10px;
  right: -20px;
}

/* For Responsive */
@media screen and (max-width: 800px) {
  .registerMainCard {
    margin: 10px;
    box-shadow: none;
    display: flex;
    flex-direction: column;
  }
  .registerFormCard {
    margin: 10px auto !important;
    width: 100% !important;
  }
  .registerImage {
    display: none;
  }
}
