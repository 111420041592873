/**
 *
 * Theme: Moby
 * Author: Mobythemes
 * Website: mobythemes.com
 * Version: 1.0
 * Date: Sat Dec 14 2019
 * Category: RealEstate
 * Title: Aside styles
 *
 */
/**********************************************
  Custom Variables
***********************************************/
/***********************
  Input Placeholder Color
***********************/
/***********************
  Media Queries
***********************/
/**********************************************
  END OF Custom Variables
***********************************************/
/**********************************************
  ANT DESIGN THEME
***********************************************/
.aside {
  background: white;
  flex: 0 0 15% !important;
  max-width: 15% !important;
  min-width: 15% !important;
  width: 15% !important;
  position: fixed !important;
  left: 0;
  top: 0;
  transition: all ease-in-out .3s;
  box-shadow: 0 0px 6px 2px rgba(0, 0, 0, 0.02), 0 1px 20px 0 rgba(0, 0, 0, 0.05), 0px 1px 11px 0px rgba(0, 0, 0, 0.05);
  border-radius: 0 8px 8px 0;
  overflow: hidden;
  z-index: 999999; }
  .aside.ant-layout-sider-collapsed {
    flex: 0 0 60px !important;
    max-width: 60px !important;
    min-width: 60px !important;
    width: 60px !important; }
    .aside.ant-layout-sider-collapsed .side-bar-header {
      justify-content: center !important; }
      .aside.ant-layout-sider-collapsed .side-bar-header .drawer-icon {
        display: none !important; }
    .aside.ant-layout-sider-collapsed .side-bar-inner-menu {
      width: 100% !important; }
      .aside.ant-layout-sider-collapsed .side-bar-inner-menu > .ant-menu-item.ant-menu-item-only-child {
        margin-bottom: 0px; }
      .aside.ant-layout-sider-collapsed .side-bar-inner-menu .ant-menu-inline-collapsed > .ant-menu-item,
      .aside.ant-layout-sider-collapsed .side-bar-inner-menu .ant-menu-inline-collapsed > .ant-menu-submenu {
        text-align: center; }
        .aside.ant-layout-sider-collapsed .side-bar-inner-menu .ant-menu-inline-collapsed > .ant-menu-item .icon,
        .aside.ant-layout-sider-collapsed .side-bar-inner-menu .ant-menu-inline-collapsed > .ant-menu-submenu .icon {
          margin-right: 0; }
        .aside.ant-layout-sider-collapsed .side-bar-inner-menu .ant-menu-inline-collapsed > .ant-menu-item .icon + span,
        .aside.ant-layout-sider-collapsed .side-bar-inner-menu .ant-menu-inline-collapsed > .ant-menu-submenu .icon + span {
          display: inline-block;
          max-width: 0;
          opacity: 0; }
        .aside.ant-layout-sider-collapsed .side-bar-inner-menu .ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-title,
        .aside.ant-layout-sider-collapsed .side-bar-inner-menu .ant-menu-inline-collapsed > .ant-menu-submenu .ant-menu-submenu-title {
          padding: 0 !important;
          text-align: center; }
      .aside.ant-layout-sider-collapsed .side-bar-inner-menu .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
      .aside.ant-layout-sider-collapsed .side-bar-inner-menu .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu {
        padding: 0 15px !important;
        text-align: center; }
        .aside.ant-layout-sider-collapsed .side-bar-inner-menu .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .icon,
        .aside.ant-layout-sider-collapsed .side-bar-inner-menu .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu .icon {
          margin-right: 0; }
        .aside.ant-layout-sider-collapsed .side-bar-inner-menu .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .icon + span,
        .aside.ant-layout-sider-collapsed .side-bar-inner-menu .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu .icon + span {
          display: inline-block;
          max-width: 0;
          opacity: 0; }
        .aside.ant-layout-sider-collapsed .side-bar-inner-menu .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-title,
        .aside.ant-layout-sider-collapsed .side-bar-inner-menu .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu .ant-menu-submenu-title {
          padding: 0 !important;
          text-align: center; }
  .aside .ant-layout-sider-children {
    width: 100%; }
  .aside .side-bar-menu {
    width: 70px; }
  .aside .side-bar-inner-menu {
    width: 100%;
    border-right: 1px solid #e3e8ea;
    background: #f5f7f7; }
    .aside .side-bar-inner-menu .ant-menu.ant-menu-inline, .aside .side-bar-inner-menu .ant-menu.ant-menu-inline-collapsed {
      height: calc(100vh - 75px); }
    .aside .side-bar-inner-menu .ant-menu.ant-menu-sub {
      height: 100%; }
    .aside .side-bar-inner-menu .ant-menu > .ant-menu-item.ant-menu-item-only-child {
      margin-bottom: 0px; }
  @media screen and (max-width: 1024px) {
    .aside {
      display: none; } }

.side-bar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid #e3e8ea;
  border-bottom: 1px solid #e3e8ea;
  padding: 0 10px 0 15px;
  line-height: 74px;
  height: 75px;
  width: 100%; }
  .side-bar-header > .ant-typography {
    color: #202124;
    font-size: 20px; }
  .side-bar-header .side-menu-text {
    font-weight: 700;
    color: #202124;
    font-size: 15px; }
  .side-bar-header .logo {
    font-size: 31px !important; }
  .side-bar-header .drawer-icon {
    padding: 0 2px;
    vertical-align: -1px; }
    .side-bar-header .drawer-icon.icon {
      cursor: pointer;
      font-size: 22px; }
      .side-bar-header .drawer-icon.icon:hover {
        color: #202124 !important; }
