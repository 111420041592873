/**
 *
 * MyHeader styles
 *
 */
/**********************************************
  Custom Variables
***********************************************/
/***********************
  Input Placeholder Color
***********************/
/***********************
  Media Queries
***********************/
/**********************************************
  END OF Custom Variables
***********************************************/
/**********************************************
  ANT DESIGN THEME
***********************************************/
/**********************************************
  Custom Variables
***********************************************/
/***********************
  Input Placeholder Color
***********************/
/***********************
  Media Queries
***********************/
/**********************************************
  END OF Custom Variables
***********************************************/
/**********************************************
  ANT DESIGN THEME
***********************************************/
/** Placeholder Color Mixin **/
/*******************
  Cards
*******************/
.main-header.ant-layout-header {
  background: white;
  padding: 0 30px 0 0;
  height: 75px;
  line-height: 35px;
  border-bottom: 1px solid #e3e8ea;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  box-shadow: 0 7px 17px -6px rgba(0, 0, 0, 0.1); }
  @media (min-width: 1025px) {
    .main-header.ant-layout-header {
      width: 84.7%;
      margin-left: 15.3%; } }
  @media (max-width: 767px) {
    .main-header.ant-layout-header {
      height: 65px; } }
  @media (min-width: 1025px) {
    .main-header.ant-layout-header .p-relative {
      margin-left: 30px; } }
  .main-header.ant-layout-header .drawer-icon {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 74px;
    font-size: 22px;
    cursor: pointer; }
    .main-header.ant-layout-header .drawer-icon:hover {
      color: #202124 !important; }
  .main-header.ant-layout-header .logo-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 18.3%;
    width: 60px;
    margin: 0 auto;
    z-index: 999; }
    .main-header.ant-layout-header .logo-holder .logo {
      cursor: pointer;
      font-size: 50px;
      line-height: 74px; }
      @media screen and (max-width: 1024px) {
        .main-header.ant-layout-header .logo-holder .logo {
          font-size: 45px; } }
      @media screen and (max-width: 480px) {
        .main-header.ant-layout-header .logo-holder .logo {
          font-size: 40px;
          line-height: 64px; } }
    @media screen and (max-width: 1024px) {
      .main-header.ant-layout-header .logo-holder {
        left: 100px;
        right: 0;
        width: 60px;
        margin: 0;
        height: auto;
        line-height: 62px; } }
    @media screen and (max-width: 992px) {
      .main-header.ant-layout-header .logo-holder {
        width: 50px;
        height: 64px; } }
    @media screen and (max-width: 767px) {
      .main-header.ant-layout-header .logo-holder {
        margin: 0 auto 0 90px; } }
    @media screen and (max-width: 480px) {
      .main-header.ant-layout-header .logo-holder {
        margin: 0 auto 0 0;
        left: 70px; } }
  .main-header.ant-layout-header .side-bar-drawer {
    display: none; }
    .main-header.ant-layout-header .side-bar-drawer .icon {
      font-size: 22px; }
    @media screen and (max-width: 1024px) {
      .main-header.ant-layout-header .side-bar-drawer {
        display: block;
        position: absolute;
        top: 0;
        left: 30px;
        width: 50px;
        z-index: 999;
        line-height: 85px; } }
    @media (max-width: 767px) {
      .main-header.ant-layout-header .side-bar-drawer {
        line-height: 70px; } }
    @media screen and (max-width: 480px) {
      .main-header.ant-layout-header .side-bar-drawer {
        left: 20px; } }
  .main-header.ant-layout-header .ant-menu-horizontal {
    line-height: 70px;
    border-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: transparent; }
    .main-header.ant-layout-header .ant-menu-horizontal .ant-menu-submenu .ant-menu-submenu-title {
      padding-right: 10px; }
    .main-header.ant-layout-header .ant-menu-horizontal .ant-menu-submenu-open.ant-menu-submenu-active {
      color: #202124;
      border-color: #202124; }
    .main-header.ant-layout-header .ant-menu-horizontal .ant-menu-item {
      padding: 0 12px;
      margin: 0 !important;
      transition: all ease-in-out 0.4s; }
      @media screen and (max-width: 1024px) {
        .main-header.ant-layout-header .ant-menu-horizontal .ant-menu-item {
          padding: 0 10px; } }
      .main-header.ant-layout-header .ant-menu-horizontal .ant-menu-item .icon {
        font-size: 20px;
        line-height: inherit;
        cursor: pointer; }
        @media screen and (max-width: 1024px) {
          .main-header.ant-layout-header .ant-menu-horizontal .ant-menu-item .icon {
            font-size: 18px; } }
      .main-header.ant-layout-header .ant-menu-horizontal .ant-menu-item:hover {
        border-bottom-color: transparent !important; }
    .main-header.ant-layout-header .ant-menu-horizontal .ant-menu-item-active,
    .main-header.ant-layout-header .ant-menu-horizontal .ant-menu-item-selected {
      color: #202124;
      border-color: transparent; }
      .main-header.ant-layout-header .ant-menu-horizontal .ant-menu-item-active .icon,
      .main-header.ant-layout-header .ant-menu-horizontal .ant-menu-item-selected .icon {
        color: #202124 !important;
        cursor: pointer; }
    @media (max-width: 560px) {
      .main-header.ant-layout-header .ant-menu-horizontal .setting-hide-md {
        display: none; } }
    .main-header.ant-layout-header .ant-menu-horizontal .ant-menu-item .person-detail {
      cursor: default;
      display: flex;
      align-items: center;
      color: #202124; }
    .main-header.ant-layout-header .ant-menu-horizontal .language {
      display: block;
      width: 30px;
      height: 30px;
      margin-right: 15px; }
      @media screen and (max-width: 1024px) {
        .main-header.ant-layout-header .ant-menu-horizontal .language {
          width: 27px;
          height: 27px;
          margin-right: 0; } }
    .main-header.ant-layout-header .ant-menu-horizontal .language-en {
      background-image: url(/images/usa.png);
      background-size: cover; }
    .main-header.ant-layout-header .ant-menu-horizontal .user-text {
      font-size: 14px;
      font-weight: 700; }
    .main-header.ant-layout-header .ant-menu-horizontal .person-title-holder {
      padding: 0 5px; }
      @media screen and (max-width: 1024px) {
        .main-header.ant-layout-header .ant-menu-horizontal .person-title-holder {
          padding: 0px 5px; } }
    .main-header.ant-layout-header .ant-menu-horizontal .person-title {
      display: block;
      width: 35px;
      min-width: 35px;
      height: 35px;
      font-weight: 700;
      transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1); }
      .main-header.ant-layout-header .ant-menu-horizontal .person-title:hover {
        border-color: #DBA44F; }
      @media screen and (max-width: 1024px) {
        .main-header.ant-layout-header .ant-menu-horizontal .person-title {
          width: 30px;
          height: 30px;
          min-width: 30px;
          line-height: 30px;
          font-size: 13px; } }
    @media screen and (max-width: 1024px) {
      .main-header.ant-layout-header .ant-menu-horizontal {
        line-height: 70px; } }
    @media (max-width: 767px) {
      .main-header.ant-layout-header .ant-menu-horizontal {
        line-height: 60px; } }
  .main-header.ant-layout-header .header-search-bar {
    position: absolute;
    top: 0;
    left: 100%;
    right: 0;
    width: 100%;
    background: white;
    height: 74px;
    line-height: 70px;
    z-index: 99999;
    display: flex;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: all ease-in-out 0.1s; }
    .main-header.ant-layout-header .header-search-bar.visible {
      visibility: visible;
      opacity: 1;
      left: 0; }
    .main-header.ant-layout-header .header-search-bar .ant-input-affix-wrapper.borderless input::-webkit-input-placeholder {
      color: rgba(32, 33, 36, 0.7); }
    .main-header.ant-layout-header .header-search-bar .ant-input-affix-wrapper.borderless input:-moz-placeholder {
      color: rgba(32, 33, 36, 0.7); }
    .main-header.ant-layout-header .header-search-bar .ant-input-affix-wrapper.borderless input::-moz-placeholder {
      color: rgba(32, 33, 36, 0.7); }
    .main-header.ant-layout-header .header-search-bar .ant-input-affix-wrapper.borderless input:-ms-input-placeholder {
      color: rgba(32, 33, 36, 0.7); }
    .main-header.ant-layout-header .header-search-bar .ant-input-affix-wrapper.borderless .ant-input {
      font-size: 15px;
      color: rgba(32, 33, 36, 0.7); }
    .main-header.ant-layout-header .header-search-bar .ant-input-affix-wrapper.borderless .ant-input-suffix .anticon {
      color: rgba(32, 33, 36, 0.7) !important;
      cursor: pointer; }

/** Notifications Popver **/
.header-popover .ant-popover-inner-content {
  padding: 0 !important; }
  .header-popover .ant-popover-inner-content .language-list {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e3e8ea; }
    .header-popover .ant-popover-inner-content .language-list:not(:last-child) {
      margin-bottom: 15px; }
    .header-popover .ant-popover-inner-content .language-list .ant-avatar {
      margin-right: 10px; }
    .header-popover .ant-popover-inner-content .language-list .title {
      flex: 1 1 0;
      font-size: 16px; }

.header-popover .popover-content {
  min-width: 300px;
  overflow: hidden; }

.header-popover .setting-popover .ant-tabs .ant-tabs-bar {
  margin: -30px 60px 0; }

.header-popover .cover {
  min-height: 60px;
  line-height: 60px;
  text-align: center;
  border-bottom: 1px solid #e3e8ea;
  border-radius: 4px 4px 0 0; }
  .header-popover .cover .cover-title {
    padding: 20px;
    margin: 0;
    color: #202124; }
    .header-popover .cover .cover-title .icon {
      color: white !important;
      margin-right: 10px; }

.header-popover .popover-content-list {
  height: 100%; }
  .header-popover .popover-content-list li {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #e3e8ea;
    cursor: pointer;
    transition: all ease-in-out 0.4s; }
    .header-popover .popover-content-list li:hover {
      background: #f5f7f7; }
    .header-popover .popover-content-list li:last-child {
      border-bottom: 0; }
    .header-popover .popover-content-list li .icon {
      font-size: 12px; }
  .header-popover .popover-content-list .subtitle {
    font-size: 15px;
    color: #96a5ab;
    display: block; }

.header-popover .popover-content-info {
  width: 100%; }
  .header-popover .popover-content-info .icon {
    font-size: 16px;
    margin-right: 10px; }
  .header-popover .popover-content-info .subtitle i {
    margin-right: 3px; }
  .header-popover .popover-content-info .title .icon {
    font-size: 17px; }

.header-popover .arrow-icon {
  font-size: 14px;
  color: #96a5ab; }

.header-popover .shopping-cart-holder {
  height: 270px; }

.header-popover .shopping-cart {
  margin: 20px;
  padding: 10px;
  border: 1px solid #e3e8ea;
  border-radius: 4px;
  display: flex;
  justify-content: space-between; }

.header-popover .shopping-info {
  flex: 1 1 0; }

.header-popover .ant-avatar {
  margin-right: 10px; }
  .header-popover .ant-avatar.shopping-product.ant-avatar-lg {
    height: 65px;
    width: 65px;
    line-height: 65px; }

.header-popover .color-list {
  padding-bottom: 10px; }
  .header-popover .color-list li {
    display: inline-block;
    margin-left: 10px;
    width: 20px;
    height: 20px;
    border-radius: 5px; }
    .header-popover .color-list li:first-child {
      margin-left: 0; }
  .header-popover .color-list .clr {
    background: #8d7c9d; }
  .header-popover .color-list .clr1 {
    background: #b8c0ff; }
  .header-popover .color-list .clr2 {
    background: #ffc7a6; }

.header-popover .radio-btn-circle {
  padding-bottom: 10px !important; }
  .header-popover .radio-btn-circle .ant-radio-button-wrapper {
    width: 30px;
    height: 30px;
    line-height: 30px;
    padding: 0 !important;
    border-radius: 50% !important;
    margin-right: 10px !important;
    text-align: center;
    border: 1px solid #e3e8ea !important; }
    .header-popover .radio-btn-circle .ant-radio-button-wrapper:before {
      display: none; }
    .header-popover .radio-btn-circle .ant-radio-button-wrapper:hover, .header-popover .radio-btn-circle .ant-radio-button-wrapper:focus {
      box-shadow: none;
      outline: none; }

#Language .ant-popover-inner-content {
  padding: 0; }
  #Language .ant-popover-inner-content .language-list {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e3e8ea;
    padding: 10px 20px; }
    #Language .ant-popover-inner-content .language-list:not(:last-child) {
      border-bottom: 1px solid #e3e8ea; }
    #Language .ant-popover-inner-content .language-list .ant-avatar {
      margin-right: 10px; }
    #Language .ant-popover-inner-content .language-list .title {
      flex: 1 1 0;
      color: #202124; }

/** Ends Notifications Popver **/
