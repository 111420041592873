/**********************************************
  Custom Variables
***********************************************/
/***********************
  Input Placeholder Color
***********************/
/***********************
  Media Queries
***********************/
/**********************************************
  END OF Custom Variables
***********************************************/
/**********************************************
  ANT DESIGN THEME
***********************************************/
.sectionDiv:hover .sectionTitle {
  border-bottom: 1px solid #96a5ab !important; }

.sectionTitle {
  border-bottom: 1px solid #e3e8ea !important;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 10px 0;
  transition: 0.5s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .sectionTitle:hover {
    border-bottom: 1px solid #96a5ab !important; }

.sectionTitle h2 {
  color: fade(#202124, 70%);
  margin-right: auto; }

.sectionTitle button {
  border-bottom: none; }

.sectionSubtitle {
  justify-content: flex-end; }

.sectionSubtitle p {
  margin: 0;
  font-size: 0.7em;
  color: #96a5ab;
  text-align: right; }

.sectionBody {
  min-width: 200px;
  width: 400px;
  justify-content: flex-start; }
