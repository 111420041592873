
.newCreditCardForm {
  width: 600px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 100px auto;
}

.newCreditCardForm label {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ant-form .ant-form-item .ant-form-item-label > label,
.ant-legacy-form .ant-form-item .ant-form-item-label > label {
  font-weight: 600;
  font-size: 16px; 
}
.ant-form .ant-form-item .ant-form-item-label > label:after,
.ant-legacy-form .ant-form-item .ant-form-item-label > label:after {
  display: none; 
}

.ant-form .ant-form-item .ant-form-item-label > label:before,
.ant-legacy-form .ant-form-item .ant-form-item-label > label:before {
  position: absolute;
  top: 0;
  right: -12px;
  height: 100%;
  margin-right: 0;
  font-size: 10px; 
}

.slash {
  font-size: 24px;
  margin-top: 42px;
}

.card-buttons .ant-form-item-control-input-content {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}

.card-buttons-add.ant-btn {
  margin-right: 15px;
}

.ant-row.card-form-mycvv {
  height: 111px;
}

.credit-card-image {
  padding-top: 10px;
}

@media (max-width: 768px) {
  .slash {
    display: none;
    margin-top: 0;
  }

  .card-buttons .ant-form-item-control-input-content {
    padding-right: 0;
  }
  
  .ant-row.card-form-mycvv {
    height: auto;
  }
}