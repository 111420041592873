.frameContainer {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: 1000;
}

.frameMainView {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.frameContent {
    margin: 0 auto;
    width: 90%;
    min-width: 350px;
}

.logoHeader {
    color: #efb001;
    width: 32px;
   
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logoText {
      font-size: 1.1vw !important;
  }
  