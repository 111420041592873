
.tableDiv {
  width: 100%;
  height: 100%;
  padding: 1em 2em 0 2em;
}

@media screen and (max-width: 800px) {
  .tableDiv {
    padding: 1em 0 0 0;
  }
}
