.newSiteForm {
  width: 600px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 100px auto; }

.newSiteForm label {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.siteButtons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px; }

.updateSiteSection {
  width: 400px; }

.organizationRow {
  width: 100%; }

.price-arrows {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  .price-arrows .price-arrows-span .icon-arrow_forward_ios, .price-arrows .price-arrows-span .icon-arrow_back_ios {
    transition: 0.3s ease-in-out; }
    .price-arrows .price-arrows-span .icon-arrow_forward_ios::before, .price-arrows .price-arrows-span .icon-arrow_back_ios::before {
      color: rgba(0, 0, 0, 0.2);
      transition: 0.3s ease-in-out; }
  .price-arrows:hover .price-arrows-span .icon-arrow_forward_ios::before, .price-arrows:hover .price-arrows-span .icon-arrow_back_ios::before {
    color: rgba(0, 0, 0, 0.7);
    transition: 0.3s ease-in-out; }

@media (max-width: 767px) {
  .price-arrows {
    display: none !important; } }
