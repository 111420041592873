.footerContainer {
  display: none;
  height: 30px;
  background-color: #efb001;
  flex-direction: row;
  align-items: center; }

.footerContainer p {
  margin: 0 auto 0 auto; }

/**********************************************
  Custom Variables
***********************************************/
/***********************
  Input Placeholder Color
***********************/
/***********************
  Media Queries
***********************/
/**********************************************
  END OF Custom Variables
***********************************************/
/**********************************************
  ANT DESIGN THEME
***********************************************/
.footer.ant-layout-footer {
  padding: 20px 30px;
  background: transparent;
  border-top: 1px solid #e3e8ea;
  margin-top: 10px; }
  .footer.ant-layout-footer h3 {
    font-size: 16px;
    color: #96a5ab;
    margin: 0;
    font-weight: 500 !important; }
    @media screen and (max-width: 480px) {
      .footer.ant-layout-footer h3 {
        font-size: 15px;
        margin-bottom: 10px;
        text-align: center; } }
  .footer.ant-layout-footer .footer-main-nav {
    text-align: right; }
    .footer.ant-layout-footer .footer-main-nav li {
      display: inline-block;
      padding: 0 20px; }
      .footer.ant-layout-footer .footer-main-nav li:last-child {
        padding-right: 0; }
        @media screen and (max-width: 480px) {
          .footer.ant-layout-footer .footer-main-nav li:last-child {
            padding-right: 20px; } }
      .footer.ant-layout-footer .footer-main-nav li a {
        font-size: 14px;
        color: #96a5ab;
        transition: all ease-in-out .4s; }
        .footer.ant-layout-footer .footer-main-nav li a:hover {
          color: #202124; }
        @media screen and (max-width: 480px) {
          .footer.ant-layout-footer .footer-main-nav li a {
            font-size: 13px; } }
    @media screen and (max-width: 480px) {
      .footer.ant-layout-footer .footer-main-nav {
        text-align: center; } }
  @media screen and (max-width: 480px) {
    .footer.ant-layout-footer {
      padding: 15px 20px; } }
