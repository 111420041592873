/**
 *
 * Theme: Moby
 * Author: Mobythemes
 * Website: mobythemes.com
 * Version: 1.0
 * Date: Mon Nov 18 2019
 * Title: SubHeader styles
 *
 */
/**********************************************
  Custom Variables
***********************************************/
/***********************
  Input Placeholder Color
***********************/
/***********************
  Media Queries
***********************/
/**********************************************
  END OF Custom Variables
***********************************************/
/**********************************************
  ANT DESIGN THEME
***********************************************/
.sub-header .welcome-text {
  margin-bottom: 0; }

.sub-header p {
  font-size: 14px;
  color: rgba(150, 165, 171, 0.5);
  margin: 10px 0 0; }

.sub-header .prev-breadcrumb-links {
  color: gray; }
  .sub-header .prev-breadcrumb-links.root-breadcumb-link:hover {
    color: gray;
    cursor: default; }

@media screen and (max-width: 768px) {
  .sub-header .flex {
    justify-content: space-between !important;
    margin-top: 15px; } }

@media screen and (max-width: 480px) {
  .sub-header .flex {
    flex-flow: row wrap !important; } }

.sub-header .sub-header-list-h {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%; }
  @media (max-width: 991px) {
    .sub-header .sub-header-list-h {
      justify-content: space-between;
      padding-top: 15px; } }
  @media (max-width: 567px) {
    .sub-header .sub-header-list-h {
      flex-flow: row wrap; }
      .sub-header .sub-header-list-h > * {
        flex: 1 1 100%; } }

.sub-header .sub-header-list {
  display: flex;
  align-items: center; }
  @media (max-width: 567px) {
    .sub-header .sub-header-list {
      padding-bottom: 15px; } }
  .sub-header .sub-header-list li {
    padding: 0 20px;
    position: relative; }
    .sub-header .sub-header-list li::after {
      content: "";
      position: absolute;
      top: 7px;
      right: 0;
      height: 80%;
      border-right: 1px solid #ccd3d6; }
      @media screen and (max-width: 320px) {
        .sub-header .sub-header-list li::after {
          border-right: 0;
          border-bottom: 1px solid #ccd3d6;
          width: 100%; } }
    .sub-header .sub-header-list li:last-child::after {
      display: none; }
    @media screen and (max-width: 320px) {
      .sub-header .sub-header-list li:last-child {
        border-bottom: 1px solid #ccd3d6;
        margin-bottom: 0; } }
    .sub-header .sub-header-list li span {
      display: block; }
    .sub-header .sub-header-list li .sub-header-list-title {
      font-size: 15px;
      font-weight: 700;
      color: rgba(150, 165, 171, 0.5);
      margin-bottom: 10px; }
      @media screen and (max-width: 1440px) {
        .sub-header .sub-header-list li .sub-header-list-title {
          font-size: 14px; } }
      @media screen and (max-width: 1023px) {
        .sub-header .sub-header-list li .sub-header-list-title {
          font-size: 13px; } }
      @media screen and (max-width: 480px) {
        .sub-header .sub-header-list li .sub-header-list-title {
          font-size: 11px; } }
      @media screen and (max-width: 320px) {
        .sub-header .sub-header-list li .sub-header-list-title {
          margin-bottom: 5px; } }
    .sub-header .sub-header-list li .sub-header-list-date {
      font-size: 16px;
      font-weight: 700;
      color: #202124; }
      @media screen and (max-width: 1440px) {
        .sub-header .sub-header-list li .sub-header-list-date {
          font-size: 15px; } }
      @media screen and (max-width: 1023px) {
        .sub-header .sub-header-list li .sub-header-list-date {
          font-size: 14px; } }
      @media screen and (max-width: 480px) {
        .sub-header .sub-header-list li .sub-header-list-date {
          font-size: 12px; } }
    .sub-header .sub-header-list li .sub-header-list-text {
      font-size: 16px;
      font-weight: 700;
      color: #ea4335; }
      @media screen and (max-width: 1440px) {
        .sub-header .sub-header-list li .sub-header-list-text {
          font-size: 15px; } }
      @media screen and (max-width: 1023px) {
        .sub-header .sub-header-list li .sub-header-list-text {
          font-size: 14px; } }
      @media screen and (max-width: 480px) {
        .sub-header .sub-header-list li .sub-header-list-text {
          font-size: 12px; } }
    @media screen and (max-width: 768px) {
      .sub-header .sub-header-list li:first-child {
        padding-left: 0; } }
    @media screen and (max-width: 480px) {
      .sub-header .sub-header-list li {
        padding: 0 10px;
        width: 33.33%; } }
    @media screen and (max-width: 320px) {
      .sub-header .sub-header-list li {
        padding: 0 0px 10px;
        margin-bottom: 10px;
        width: 100%; } }
  @media screen and (max-width: 480px) {
    .sub-header .sub-header-list {
      margin-right: 0 !important;
      width: 100%;
      justify-content: space-between; } }
  @media screen and (max-width: 320px) {
    .sub-header .sub-header-list {
      flex-flow: row wrap;
      justify-content: center; } }

@media (max-width: 567px) {
  .sub-header {
    padding: 30px 20px !important; } }
